import { compareDesc, startOfDay } from 'date-fns'
import moment from 'moment'
import { toDate } from 'util/date/formatDate'
import { formatNumber, parseNumber } from 'util/number'
import { isEmpty } from 'util/validation/Util'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { MedicaoAnteriorFormModel } from './MedicaoAnteriorForm'
import { MedicaoPeso, MedicoesAtendimentoAnterior, MedicoesNascimento } from './model-medicoes'

export const calculatePeso = (
  pesoAtual: string,
  dataInicioAtendimento: Date,
  medicoes: MedicoesAtendimentoAnterior[],
  medicoesNoNascimento: MedicoesNascimento,
  medicoesAnteriores: MedicaoAnteriorFormModel[]
): MedicaoPeso => {
  const pesoNumber = parseNumber(pesoAtual)
  const hasPesoAtual = !isNaN(pesoNumber)

  if (hasPesoAtual) {
    return {
      dataMedicao: dataInicioAtendimento.getTime(),
      peso: formatNumber(pesoNumber),
    }
  }

  const allMedicoes: MedicaoPeso[] = []

  if (!isEmpty(medicoes)) {
    allMedicoes.push(
      ...medicoes.map((medicao) => {
        return { dataMedicao: startOfDay(medicao.dataMedicao).getTime(), peso: formatNumber(medicao.valorPeso) }
      })
    )
  }

  if (!isEmpty(medicoesNoNascimento?.peso)) {
    const pesoNascimentoNumber = parseNumber(medicoesNoNascimento.peso)
    allMedicoes.push({
      dataMedicao: startOfDay(toDate(medicoesNoNascimento.dataUltimaAtualizacao)).getTime(),
      peso: formatNumber(pesoNascimentoNumber),
    })
  }

  if (!isEmpty(medicoesAnteriores)) {
    allMedicoes.push(
      ...medicoesAnteriores
        .filter((medicao) => !!medicao.peso)
        .map((medicao) => ({
          dataMedicao: startOfDay(toDate(medicao.data)).getTime(),
          peso: formatNumber(medicao.peso),
        }))
    )
  }

  return allMedicoes
    .sort((a, b) => compareDesc(a.dataMedicao, b.dataMedicao) || parseNumber(a.peso) - parseNumber(b.peso))
    .first()
}

export const mountMedicoesNoNascimento = (
  pesoAoNascer: string,
  medicoesNoNascimentoSalvas: MedicoesNascimento,
  dataNascimentoCidadao: LocalDate
): MedicoesNascimento => {
  return !isEmpty(pesoAoNascer) || !isEmpty(medicoesNoNascimentoSalvas?.peso)
    ? {
        id: null,
        peso: !isEmpty(pesoAoNascer) ? pesoAoNascer : formatNumber(parseFloat(medicoesNoNascimentoSalvas?.peso)),
        dataUltimaAtualizacao: dataNascimentoCidadao,
      }
    : null
}

const format = (data) => moment(data).format('DD/MM/YY')

export function reduceMedicoesIguais<T extends Pick<HistoricoMedicaoModel, 'dataMedicao'>>(
  medicoes: ReadonlyArray<T>,
  key: keyof T
): T[] {
  const mapMedicoesUnicas = medicoes?.reduce(
    (acc, curr) => (curr[key] ? acc.set(`${format(curr.dataMedicao)}|${curr[key]}`, curr) : acc),
    new Map<string, T>()
  )

  return Array.from(mapMedicoesUnicas.values())
}

import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { NewsHeadingSection } from './NewsHeadingSection'

export function NewsInternalEstabilizacaoV53() {
  return (
    <VFlow>
      <NewsHeadingSection title='Versão 5.3.28' id='estabilizacao-5-3-28'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção na lógica da TRIA para avaliação de risco de insegurança alimentar. Agora, a regra considera a
            idade do cidadão na data de preenchimento da Ficha de cadastro individual (FCI), garantindo que apenas
            fichas preenchidas por cidadãos com 18 anos ou mais sejam utilizadas na verificação.
          </Text>
          <Text component='li'>
            Implementação de nova opção de motivo de visita, chamado "Pessoa idosa", no modelo de informação de Visita
            domiciliar territorial.
          </Text>
          <Text component='li'>
            Adicionada a opção de criar tipos de serviços exclusivos para unidades do tipo UPA e Policlínicas.
          </Text>
          <Text component='li'>
            Atualização para incluir o CBO 2234-05 (Farmacêutico) como possibilidade de compartilhamento de cuidado para
            eMulti, conforme a Portaria GM/MS Nº 6.010, de 10 de dezembro de 2024.
          </Text>
          <Text component='li'>
            Ajuste na exibição do texto no campo Anotações do histórico clínico da Folha de rosto para atendimento de
            Visita domiciliar e territorial realizado pelo aplicativo.
          </Text>
          <Text component='li'>
            Atualização de regras para registro de aplicação do imunobiológico Vacina influenza trivalente, incluindo a
            estratégia "Rotina", inativação das doses D1 e D2 da estratégia "Especial" e atualização das regras das
            doses relacionadas às estratégias "Especial", "Campanha indiscriminada" e "Vacinação escolar".
          </Text>
          <Text component='li'>
            Corrigido erro no processamento de relatórios na etapa "Limpeza dos dados antigos" que acontecia em algumas
            instalações.
          </Text>
          <Text component='li'>
            Correção de cenário em que um imunobiológico contra a Covid-19 estava sendo exibido no calendário vacinal da
            Busca ativa de vacinação.
          </Text>
          <Text component='li'>
            Foi removida a possibilidade de preencher o campo "Raça/Cor" com a opção "sem informação".
          </Text>
          <Text component='li'>
            Implementação de sinalização visual (em formato de tag) nos imóveis que possuam inconsistência e
            possibilidade de filtrar imóveis com inconsistências, no Acompanhamento do território.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.27' id='estabilizacao-5-3-27'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Ajuste na validação de data de nascimento ao registrar um marcador de consumo alimentar em um atendimento.
          </Text>
          <Text component='li'>
            Correção da mensagem de dica apresentada no módulo de agenda para usuários que não possuem o recurso
            "Agendar".
          </Text>
          <Text component='li'>
            Correção no histórico da discussão de caso, que não exibia a palavra "Motivo" ao registrar a troca do
            profissional de referência no Cuidado compartilhado.
          </Text>
          <Text component='li'>
            Correção na impressão da Ficha de notificação de caso suspeito no atendimento, que não exibia o nome social
            do cidadão.
          </Text>
          <Text component='li'>
            Correção de cenário onde os termos relacionados aos medicamentos Isotretinoína, Tretinoína ou Acitretina
            eram gerados com duplicidade ao realizar a impressão.
          </Text>
          <Text component='li'>
            Ao atualizar um responsável familiar através do módulo de Acompanhamento do território e informar um CPF
            para ele, também será criada uma Ficha de cadastro individual automática para este cidadão.
          </Text>
          <Text component='li'>
            Disponibilização da impressão da visita domiciliar gerada no aplicativo e-SUS Território através do
            histórico do cidadão.
          </Text>
          <Text component='li'>
            Correção do cenário em que não era possível atualizar o cadastro de um imóvel no cenário em que mais de um
            registro era encontrado para o imóvel.
          </Text>
          <Text component='li'>
            Correção de problema na impressão do histórico de atendimento domiciliar pelo aplicativo e-SUS AD,
            garantindo que as informações sejam exibidas corretamente nos relatórios.
          </Text>
          <Text component='li'>
            Correções gerais de erros de envio de imunobiológicos RIA-C e RIA-Carga para a RNDS.
          </Text>
          <Text component='li'>
            Inclusão da indicação "15 meses" no cartão da dose de reforço do imunobiológico VIP (Vacina polio
            injetável).
          </Text>
          <Text component='li'>
            Atualização de regras para registro de aplicação dos imunobiológicos HEXA, incluindo a estratégia de Rotina
            e atualizada a regra da estratégia Especial da dose R1.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.26' id='estabilizacao-5-3-26'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Agora, a Busca ativa de vacinação considera as doses de reforço (REF) do imunobiológico VIP como
            equivalentes às doses R1 e R2 do imunobiológico VOP, caso estas já tenham sido aplicadas.
          </Text>
          <Text component='li'>
            Informações de resultados de exames estruturados registrados em Atendimentos odontológicos importados no PEC
            passarão a ser exibidas no Histórico clínico do cidadão.
          </Text>
          <Text component='li'>
            Ajuste no tamanho do texto apresentado na tabela de prescrições digitais para evitar quebra de layout.
          </Text>
          <Text component='li'>
            Inclusão de tratamento de nome social do cidadão nos termos de consentimento gerados na prescrição de
            medicamentos.
          </Text>
          <Text component='li'>
            Com a implementação das equivalências dos imunobiológicos VIP e VOP na Busca ativa de vacinação, foi
            removida a mensagem relacionada à dose de reforço (REF) do imunobiológico VIP, que estava sendo sinalizada
            como atrasada, mesmo com as doses R1 e R2 do imunobiológico VOP aplicadas.
          </Text>
          <Text component='li'>
            Correção no modal de "Agendar consulta" do módulo de Garantia do acesso para exibir as lotações
            profissionais corretamente.
          </Text>
          <Text component='li'>
            Correção nas impressões de agendamento entre profissionais sem o cidadão, onde o nome do profissional
            convidado não era apresentado.
          </Text>
          <Text component='li'>
            Correção de cenário onde durante a importação de determinadas CIAPs estavam apresentando erro quando
            preenchidas nos campos "OutroCIAP".
          </Text>
          <Text component='li'>
            Correção de cenário onde o sistema impedia a evolução de um problema/condição no SOAP em cidadãos
            unificados.
          </Text>
          <Text component='li'>
            Correção de erro que fazia o botão de impressão do acompanhamento de cidadãos vinculados e da busca ativa
            desaparecer.
          </Text>
          <Text component='li'>
            Correção de problema em que, ao preencher um campo de texto no SOAP, retirar o foco e incluir novamente, as
            opções de desfazer e refazer digitação não funcionavam corretamente.
          </Text>
          <Text component='li'>Correção de problema que impedia a criação de novos perfis customizados.</Text>
          <Text component='li'>
            Correção de erro que impedia o profissional de finalizar o atendimento de observação e mostrava o campo
            "Intervenções e/ou procedimentos clínicos realizados" como pendente.
          </Text>
          <Text component='li'>
            Correção de erro que impedia o profissional de finalizar um atendimento de observação ao duplicar um
            CID10/CIAP2.
          </Text>
          <Text component='li'>
            Correção de cenário em que a unidade de fornecimento era substituída pela unidade de medida na reimpressão
            de medicamentos.
          </Text>
          <Text component='li'>
            Correção de cenário em que a quantidade total receitada não estava sendo exibida nos termos de
            responsabilidade ao reimprimir os medicamentos.
          </Text>
          <Text component='li'>
            Correção aplicada no campo número de moradores que estava gerando impacto nos critérios de completude do
            cadastro no módulo de Acompanhamento de território.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.25' id='estabilizacao-5-3-25'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            A partir desta versão, medidas de peso informadas na Puericultura também passam a ser consideradas para
            exibição na modal de Prescrição de Medicamentos, caso sejam mais recentes.
          </Text>
          <Text component='li'>
            Foi implementada a nova estratégia de "Vacinação escolar" assim como suas respectivas regras de aplicação
            para mais de 20 imunobiológicos (HepB, VFA, BCG, VPP23, VIP, SCR, dT, VPC10, INF3, VAR, MenC, PENTA, ROTA,
            DTP, HepAinf, SCRV, dTpa, VPC13, MenACWY, Moderna-Spikevax, Pfizer para menores de 5 anos e DNG).
          </Text>
          <Text component='li'>
            Foram incluídos no sistema os procedimentos pertencentes ao subgrupo 04 - Telessaúde e atualizados os
            procedimentos do grupo 09 - Procedimentos para Ofertas de Cuidados Integrados.
          </Text>
          <Text component='li'>
            Correção de um problema no qual, ao evoluir um cuidado compartilhado, o histórico de atendimentos
            relacionados não era exibido corretamente.
          </Text>
          <Text component='li'>
            Ajuste de cenário que impedia a atualização do sistema e a unificação de bases devido a um valor nulo nos
            registros de problemas/condições.
          </Text>
          <Text component='li'>
            Correção de cenário que impedia a finalização do atendimento caso dois problemas/condições possuíssem a
            mesma data e hora de fim.
          </Text>
          <Text component='li'>
            Ajuste de erro onde havia divergência no número de aplicações e na data nos cartões das doses aplicadas no
            histórico do atendimento de vacinação.
          </Text>
          <Text component='li'>
            Correção de cenário onde era possível realizar dois agendamentos ao mesmo tempo e no mesmo horário para um
            mesmo profissional.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.24' id='estabilizacao-5-3-24'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro onde, após a atualização do sistema, alguns campos referentes ao CID10 não eram carregados.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.23' id='estabilizacao-5-3-23'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Correção de cenário onde o profissional fixava-se em sua própria agenda.</Text>
          <Text component='li'>
            Atualizadas as regras de aprazamento e faixa etária para os imunobiológicos Vacina rotavírus (ROTA) e Vacina
            sarampo, caxumba, rubéola (SCR).
          </Text>
          <Text component='li'>
            Correção da validação do campo "número de moradores" no domicílio, que impedia que imóveis sem moradores
            ficassem com o cadastro completo.
          </Text>
          <Text component='li'>
            Correção de erro onde não estava sendo possível utilizar o link de redefinição de senha em instalações que
            não possuíam o SMTP configurado.
          </Text>
          <Text component='li'>
            Correção de cenário em que ocorria erro ao realizar a impressão de um medicamento na Prescrição de
            medicamentos.
          </Text>
          <Text component='li'>
            Ajuste na mensagem apresentada para o usuário quando uma vacina era retificada enquanto o servidor da RNDS
            não estivesse funcionando.
          </Text>
          <Text component='li'>
            Correção de cenário onde um erro era apresentado ao realizar a reimpressão de medicamentos via Folha de
            rosto.
          </Text>
          <Text component='li'>
            Correção da importação de fichas de Atendimento individual para cenários em que a ficha possua a lista de
            encaminhamentos vazia.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.22' id='estabilizacao-5-3-22'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Ao adicionar uma medição anterior através do cartão lateral do SOAP, o peso mais recente do cidadão será
            considerado na modal de Prescrição de medicamentos.
          </Text>
          <Text component='li'>
            Inclusão de uma nova regra para o novo termo de responsabilidade/esclarecimento da Prescrição de
            medicamentos, passando a contemplar as opções de identidade de gênero e sexo indeterminado.
          </Text>
          <Text component='li'>
            Inclusão de novos Grupos de atendimentos disponíveis para seleção ao registrar aplicação de imunobiológico.
          </Text>
          <Text component='li'>
            Inclusão de novo imunobiológico COVID-19 SERUM/ZALIKA e atualização de diversas regras de aplicação para
            outros imunobiológicos contra COVID-19.
          </Text>
          <Text component='li'>
            Correção do cenário em que ocorria chave duplicada em relação aos problemas e condições recebidos de bases
            unificadas ao tentar finalizar um atendimento.
          </Text>
          <Text component='li'>
            Inclusão dos campos de localização e código do domicílio na tabela de acompanhamento dos cidadãos
            vinculados.
          </Text>
          <Text component='li'>
            Correção de cenário em que os campos de data inicial e final, CID e CIAP dos atendimentos não estavam sendo
            processados corretamente, impactando relatórios e o histórico de atendimentos.
          </Text>
          <Text component='li'>
            Ajustes no módulo de importação de CNES, onde, em algumas instalações, não estava sendo possível importar o
            arquivo.
          </Text>
          <Text component='li'>
            Correção de cenário onde uma página em branco era gerada ao imprimir medicamentos com preenchimento manual.
          </Text>
          <Text component='li'>Correção de erro que impedia a atualização do sistema em algumas instalações.</Text>
          <Text component='li'>
            Ajuste no campo Desfecho da escuta inicial para manter a opção "Adicionar cidadão na lista de atendimentos"
            como padrão já pré-selecionado ao finalizar a escuta.
          </Text>
          <Text component='li'>
            Correção de cenário onde algumas famílias estavam sendo duplicadas ao se atualizar as Fichas de cadastro
            domiciliar e territoriais correspondentes a elas.
          </Text>
          <Text component='li'>
            Correção na filtragem do campo "Problemas / Condições avaliadas - Outros CIAP 2" no módulo de Relatório de
            atendimento individual.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.21' id='estabilizacao-5-3-21'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro relacionado ao motivo do desfecho no envio de registros para RNDS.
          </Text>
          <Text component='li'>Correção de cenário que impedia a atualização do sistema em algumas instalações.</Text>
          <Text component='li'>
            Adicionada a possibilidade de ver as doses fracionadas de medicamentos por turno no Histórico clínico do
            cidadão de Atendimentos odontológicos.
          </Text>
          <Text component='li'>
            Correção de cenário em que cidadãos sem informações de doses eram exibidos incorretamente na listagem da
            Busca Ativa de Vacinação.
          </Text>
          <Text component='li'>
            Adicionada mensagem informativa na Busca ativa de vacinação, onde as doses de reforço (REF) do
            imunobiológico VIP serão exibidas como atrasadas, mesmo que as doses R1 e R2 do imunobiológico VOP já tenham
            sido aplicadas.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.20' id='estabilizacao-5-3-20'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Atualização da tela de links úteis e inclusão do link do Educa e-SUS APS.</Text>
          <Text component='li'>
            Implementada a funcionalidade de reimpressão de receitas médicas no cartão de Medicamentos, disponível na
            Folha de rosto e no Atendimento.
          </Text>
          <Text component='li'>
            Inclusão do novo termo de responsabilidade na Prescrição de medicamentos com Talidomida, para cidadãos do
            sexo feminino e menores de 55 anos.
          </Text>
          <Text component='li'>
            Retirada do campo subjetivo enviado para a RNDS no Registro de atendimento clínico.
          </Text>
          <Text component='li'>
            Atualização para melhorar a performance dos relatórios gerenciais quando o banco de dados RocksDB está
            habilitado na instalação.
          </Text>
          <Text component='li'>
            Alteração da terminologia "Idoso" ou "Idosos" para "Pessoa idosa" ou "Pessoas idosas" respectivamente.
          </Text>
          <Text component='li'>
            Correção de cenário onde o uso de nome social do profissional não era aplicado em alguns agendamentos
            futuros.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.19' id='estabilizacao-5-3-19'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Atualizações globais no sistema para assegurar compatibilidade com a nova opção de sexo "Indeterminado".
          </Text>
          <Text component='li'>
            Agora é possível visualizar doses fracionadas de medicamentos por turno no histórico clínico do cidadão para
            atendimentos individuais.
          </Text>
          <Text component='li'>
            Ajustes no histórico clínico para exibir corretamente o nome social dos profissionais.
          </Text>
          <Text component='li'>
            Correção no campo de modalidade assistencial transmitido para a RNDS no Registro de Atendimento Clínico.
          </Text>
          <Text component='li'>Inclusão de um link para a página de "Links Úteis" na tela inicial do sistema.</Text>
          <Text component='li'>
            Implementação de restrição para impedir a seleção da opção "Manter cidadão na lista de atendimento" em
            registros de atendimentos realizados em datas anteriores.
          </Text>
          <Text component='li'>Inclusão do peso do cidadão na modal de prescrição de medicamentos.</Text>
          <Text component='li'>
            Adição de alerta para informar sobre alergias recentemente registradas, garantindo que o profissional
            visualize avisos de alergia durante o atendimento.
          </Text>
          <Text component='li'>
            As quebras de linha inseridas no campo de recomendações agora são exibidas corretamente na impressão das
            prescrições de medicamentos.
          </Text>
          <Text component='li'>
            Atualização das regras para registro da aplicação das vacinas VIP (código 22), dT (código 25), VOP (código
            28) e dTpa (código 57), com os ajustes necessários no calendário vacinal infantil.
          </Text>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}

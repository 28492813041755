import { Form, FormProps, FormRenderProps, parse } from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { FormApi } from 'final-form'
import createCalculator from 'final-form-calculate'
import { MedicamentoCatmat, TipoFrequencia, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useCallback, useMemo } from 'react'
import { metaPath } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'
import { MedicaoAnteriorFormModel } from 'view/atendimentos/detail/components/modals/medicoes/MedicaoAnteriorForm'

import { MedicamentoFormModel, TipoDoseEnum } from '../model-prescricao'
import { prescricaoFormValidator as validator } from '../utils/validator-prescricao'
import AdicionarMedicamentoFormPanel from './AdicionarMedicamentoFormPanel'

export interface PrescricaoMedicamentoFormProps extends FormProps<MedicamentoFormModel> {
  isReplicar: boolean
  medicamentoToEdit: MedicamentoFormModel
  pesoAtual?: string
  pesoAoNascer?: string
  medicoesAnteriores?: MedicaoAnteriorFormModel[]
  onClearEditedMedicamento(newEditedValue: MedicamentoFormModel): void
  onVerifyMedicamentoDuplicated(medicamento: MedicamentoFormModel): boolean
  scrollModal(): void
  showDoseAlert: boolean
}

const path = metaPath<MedicamentoFormModel>()

const unidadeMedidaCalculator = createCalculator({
  field: path.principioAtivoCombo.absolutePath(),
  updates: {
    [path.unidadeMedidaDose.absolutePath()]: (medicamento: MedicamentoCatmat, values: MedicamentoFormModel) => {
      const unidadeMedidaDose = resolveValue(values, path.unidadeMedidaDose)
      const isEditFirstLoad = resolveValue(values, path.isEditFirstLoad)
      const isRegistroManual = resolveValue(values, path.registroManual)
      if (!isRegistroManual) {
        if (isEditFirstLoad) {
          return unidadeMedidaDose
        } else {
          if (medicamento) {
            if (!!medicamento.unidadeMedidaDose && unidadeMedidaDose !== medicamento.unidadeMedidaDose) {
              return medicamento.unidadeMedidaDose
            } else {
              return unidadeMedidaDose
            }
          } else {
            return null
          }
        }
      } else {
        return unidadeMedidaDose
      }
    },
    [path.qtDose.absolutePath()]: (medicamento: MedicamentoCatmat, values: MedicamentoFormModel) => {
      const qtDose = resolveValue(values, path.qtDose)
      const isRegistroManual = resolveValue(values, path.registroManual)
      if (!isRegistroManual) {
        if (medicamento) {
          return qtDose
        } else {
          return null
        }
      } else {
        return qtDose
      }
    },
    [path.isEditFirstLoad.absolutePath()]: () => {
      return false
    },
  },
})

export default function PrescricaoMedicamentoForm(props: PrescricaoMedicamentoFormProps) {
  const {
    onSubmit,
    isReplicar,
    medicamentoToEdit,
    pesoAtual,
    pesoAoNascer,
    medicoesAnteriores,
    onClearEditedMedicamento,
    onVerifyMedicamentoDuplicated,
    scrollModal,
    showDoseAlert,
  } = props

  const {
    atendimentoProfissional: { iniciadoEm: dataAtendimento },
    isRegistroTardio,
  } = useAtendimentoContext()

  const defaultValue: Partial<MedicamentoFormModel> = {
    id: uuidv4(),
    dataInicioTratamento: parse(dataAtendimento),
    escalaDuracao: UnidadeMedidaTempoEnum.DIAS,
    tipoFrequencia: TipoFrequencia.INTERVALO,
    tipoDose: TipoDoseEnum.COMUM,
  }

  const handleOnSubmit = useCallback(
    (medicamentoToAdd: MedicamentoFormModel, formApi: FormApi) => {
      onSubmit(medicamentoToAdd, formApi)
      formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
      setTimeout(formApi.reset)
      onClearEditedMedicamento(null)
    },
    [onClearEditedMedicamento, onSubmit]
  )

  const render = useCallback(
    (renderProps: FormRenderProps<MedicamentoFormModel>) => {
      return (
        <AdicionarMedicamentoFormPanel
          medicamentoToEdit={medicamentoToEdit}
          pesoAtual={pesoAtual}
          pesoAoNascer={pesoAoNascer}
          medicoesAnteriores={medicoesAnteriores}
          onClearEditedMedicamento={onClearEditedMedicamento}
          isEdit={!!medicamentoToEdit}
          isReplicar={isReplicar}
          onVerifyMedicamentoDuplicated={onVerifyMedicamentoDuplicated}
          showDoseAlert={showDoseAlert}
          {...renderProps}
        />
      )
    },
    [
      medicamentoToEdit,
      pesoAtual,
      pesoAoNascer,
      medicoesAnteriores,
      onClearEditedMedicamento,
      isReplicar,
      onVerifyMedicamentoDuplicated,
      showDoseAlert,
    ]
  )

  return useMemo(
    () => (
      <Form<MedicamentoFormModel>
        render={render}
        initialValues={medicamentoToEdit || defaultValue}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        decorators={[unidadeMedidaCalculator]}
        validate={validator(dataAtendimento, isRegistroTardio)}
        onSubmit={handleOnSubmit}
        onSubmitSucceeded={scrollModal}
        onSubmitFailed={scrollModal}
      />
    ),
    [dataAtendimento, defaultValue, handleOnSubmit, isRegistroTardio, medicamentoToEdit, render, scrollModal]
  )
}

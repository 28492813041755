/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { LoadingIndicator } from 'components/loading'
import { getLotacaoResponsavel } from 'components/lotacao-responsavel/utils/utils-lotacaoResponsavel'
import { useFlags } from 'config/useFlagsContext'
import { format } from 'date-fns'
import { useProcessarUltimaFichaTerritorioMutation, useVisualizarImoveisQuery } from 'graphql/hooks.generated'
import { TerritorioMicroareaQueryInput, TipoDomicilioEnum, TipoEnderecoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'
import { humanizeDate } from 'util/date/humanize-date'
import { useCadastroImovelCache } from 'view/cadastro-imovel/hooks/useCadastroImovelCache'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'
import { FamiliasTerritorio } from 'view/reterritorializacao/model'
import {
  getNomesResponsaveisFamiliares,
  getTooltipResponsaveisFamiliares,
  numeroTotalCidadaosImovel,
} from 'view/reterritorializacao/utils/reterritorializacao'
import { convertVisualizacaoMicroareaFilterToInput } from 'view/visualizacaoTerritorial/converter'
import {
  TerritorioMicroareaItemModel,
  TerritorioModel,
  TipoResponsavelEnum,
  VisualizacaoMicroareasFilterModel,
} from 'view/visualizacaoTerritorial/model'
import { ImovelModel } from 'view/visualizacaoTerritorial/model'
import { getTipoImovelIcon } from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { MENSAGEM_FICHA_NAO_PROCESSADA_IMOVEL } from '../../visualizacao-territorial-constants'
import { StatusCadastroImovelTag } from './StatusCadastroImovelTag'
import { StatusInconsistenciaImovelTag } from './StatusInconsistenciaImovelTag'

interface MicroareaLogradouroListItemBodyProps {
  rowItem: TerritorioMicroareaItemModel
  territorio: TerritorioModel
  isTerritorioFiltradoAtivo: boolean
  microareaFilters: VisualizacaoMicroareasFilterModel
  isExpanded: boolean
  lotacaoId: ID
  profissionalId: ID
  saveVisualizacaoCacheState(): void
  deleteVisualizacaoCacheState(): void
}

interface NomeResponsavelFieldProps {
  familias: FamiliasTerritorio[]
  nomeResponsavelTecnico: string
  tipoResponsavel: TipoResponsavelEnum
}

interface ImovelCardProps extends ImovelModel {
  lotacaoId: ID
  profissionalId: ID
  saveVisualizacaoCacheState(): void
  deleteVisualizacaoCacheState(): void
}

export default function MicroareaLogradouroListItemBody(props: MicroareaLogradouroListItemBodyProps) {
  const {
    rowItem: {
      bairro,
      logradouro,
      tipoLogradouroId,
      tipoLogradouroNome,
      tipoLogradouroDne,
      cep,
      municipio,
      dsei,
      poloBase,
      aldeia,
      municipioAldeia,
      tipoEndereco,
    },
    territorio: {
      cnes,
      ine,
      microarea,
      isCadastroAtualizadoMenosDeUmAno,
      isCadastroCompleto,
      isImovelVisitadoMenosDeUmMes,
    },
    microareaFilters,
    isTerritorioFiltradoAtivo,
    isExpanded,
    lotacaoId,
    profissionalId,
    saveVisualizacaoCacheState,
    deleteVisualizacaoCacheState,
  } = props

  const [queryInput, setQueryInput] = useState<TerritorioMicroareaQueryInput>()

  const history = useHistory()
  const match = useRouteMatch()
  const { CADASTRO_IMOVEL_ENABLED } = useFlags()

  const isTipoEnderecoIndigena = tipoEnderecoIndigena.includes(tipoEndereco)

  const {
    data: { territorioLogradouro },
    loading,
  } = useVisualizarImoveisQuery({
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
    skip: !isExpanded || isUndefinedOrNull(queryInput),
  })

  useEffect(() => {
    if (isExpanded) {
      setQueryInput({
        bairro,
        logradouro,
        tipoLogradouroId,
        cnes,
        microarea,
        ine,
        isCadastroAtualizadoMenosDeUmAno,
        isCadastroCompleto,
        cep,
        isImovelVisitadoMenosDeUmMes,
        dseiId: dsei?.id,
        poloBaseId: poloBase?.id,
        aldeiaId: aldeia?.id,
        tipoEndereco: tipoEndereco,
        useFilterLikeDiatrics: false,
        ...convertVisualizacaoMicroareaFilterToInput(microareaFilters),
      })
    }
  }, [
    bairro,
    cep,
    cnes,
    ine,
    isCadastroAtualizadoMenosDeUmAno,
    isCadastroCompleto,
    isExpanded,
    isImovelVisitadoMenosDeUmMes,
    logradouro,
    microarea,
    tipoLogradouroId,
    dsei,
    poloBase,
    aldeia,
    tipoEndereco,
    microareaFilters,
  ])

  const theme = useTheme()
  const styles = createStyles(theme)

  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const { setCacheState } = useCadastroImovelCache(acesso.id)
  const { getServerTimeNow } = useServerTime()
  const dataAtual = format(getServerTimeNow(), 'yyyy-MM-dd')

  const handleCadastrarImovel = () => {
    setCacheState({
      familiaCidadaoModalState: {
        atualizacaoCidadao: false,
        cadastroCidadaoModal: { modalOpen: false },
        cadastroFamiliaModal: { modalOpen: false, editionId: undefined },
      },
      listaLogradouroState: { fillEnderecoFields: true },
      form: {
        dataColeta: dataAtual,
        responsabilidade: getLotacaoResponsavel(
          profissional,
          acesso.cbo,
          acesso.equipe,
          acesso.unidadeSaude,
          acesso.id
        ),
        microarea: microarea ?? 'FA',
        statusForaArea: microarea === 'FA',
        tipoEndereco: tipoEndereco,
        stMicroareaPoloBase: isTipoEnderecoIndigena && microarea === undefined,
        endereco: tipoEndereco === TipoEnderecoEnum.LOGRADOURO && {
          cep,
          logradouro: { nome: logradouro },
          tipoLogradouro: {
            id: tipoLogradouroId,
            nome: tipoLogradouroNome,
            numeroDne: tipoLogradouroDne,
          },
          bairro: { nome: bairro },
          uf: municipio.uf,
          municipio: municipio,
        },
        enderecoIndigena: isTipoEnderecoIndigena && {
          dsei: dsei,
          poloBase: poloBase,
          aldeia: aldeia,
          municipio: municipioAldeia,
          uf: municipioAldeia.uf,
        },
      },
    })
    saveVisualizacaoCacheState()
    setTimeout(() => history.push(`${match.url}/cadastrarImovel?endereco=true&redirect=true`))
  }

  return (
    <VFlow vSpacing={0} style={styles.containerLogradouro} onClick={(e) => e.stopPropagation()}>
      {loading ? (
        <div
          css={css`
            margin: 1rem 0;
          `}
        >
          <LoadingIndicator />
        </div>
      ) : (
        <Fragment>
          {territorioLogradouro?.map((value) => (
            <LogradouroImovelItemBody
              key={value.id}
              imovelId={value.id}
              lotacaoId={lotacaoId}
              profissionalId={profissionalId}
              cep={value.cep}
              numero={value.numero}
              numeroMoradores={value.numeroMoradores}
              condicoesMoradia={value.condicoesMoradia}
              nomeResponsavelTecnico={value?.responsavelTecnico?.nome}
              dataUltimaVisita={value.dataUltimaVisita}
              logradouro={logradouro}
              nomeTipoLogradouro={tipoLogradouroNome}
              bairro={bairro}
              isTipoEnderecoIndigena={isTipoEnderecoIndigena}
              tipoDomicilio={value.descricaoTipoDomicilio}
              materialPredominante={value.descricaoMaterialParede}
              numeroResidenciaIndigena={value.numeroResidenciaIndigena}
              saveVisualizacaoCacheState={saveVisualizacaoCacheState}
              deleteVisualizacaoCacheState={deleteVisualizacaoCacheState}
              isCadastroRecusado={value.statusRecusa}
              {...value}
            />
          ))}
          {CADASTRO_IMOVEL_ENABLED && (
            <HFlow justifyContent='flex-end' alignItems='center'>
              <Text
                style={css`
                  margin-right: -1rem;
                `}
              >
                Adicione um novo imóvel se não o encontrar na listagem
              </Text>
              <Tooltip
                text={`${
                  !isTerritorioFiltradoAtivo
                    ? 'Ação bloqueada. Não é possível cadastrar imóveis sob responsabilidade de equipes inativas.'
                    : ''
                }`}
              >
                <Button
                  kind='primary'
                  skin='ghost'
                  size='small'
                  disabled={!isTerritorioFiltradoAtivo}
                  onClick={handleCadastrarImovel}
                  style={css`
                    border: 1px solid ${theme.pallete.primary.c40};
                    margin: 1rem;
                  `}
                >
                  <HFlow>
                    <Icon
                      icon='plus'
                      style={css`
                        margin-right: -0.5rem;
                      `}
                    />
                    Cadastrar imóvel
                  </HFlow>
                </Button>
              </Tooltip>
            </HFlow>
          )}
        </Fragment>
      )}
    </VFlow>
  )
}

const LogradouroImovelItemBody = React.memo((props: ImovelCardProps) => {
  const {
    imovelId,
    complemento,
    numero,
    dataUltimaVisita,
    nomeResponsavelTecnico,
    familias,
    tipoImovel,
    isTipoEnderecoIndigena,
    tipoDomicilio,
    materialPredominante,
    numeroResidenciaIndigena,
    numeroMoradores,
    condicoesMoradia,
    ultimaFichaCadastrada,
    dataUltimaAtualizacao,
    saveVisualizacaoCacheState,
    isCadastroCompleto,
    isCadastroRecusado,
    possuiInconsistencia,
  } = props

  const history = useHistory()
  const match = useRouteMatch()
  const theme = useTheme()
  const styles = createStyles(theme)

  const [processarTerritorio] = useProcessarUltimaFichaTerritorioMutation()

  const handleProcessarTerritorio = (input: string) => processarTerritorio({ variables: { input } })

  const handleVisualizarImovel = () => {
    saveVisualizacaoCacheState()
    handleProcessarTerritorio(imovelId)
    setTimeout(() => history.push(`${match.url}/visualizarImovel/${imovelId}`))
  }

  const isFichaNaoProcessadaAplicativo = isUndefinedOrNull(ultimaFichaCadastrada)

  const numeroTotalCidadaos = numeroTotalCidadaosImovel(familias)

  const { ImovelIcon, tooltip, fill, tipoResponsavel } = getTipoImovelIcon(
    tipoImovel,
    condicoesMoradia?.tipoDomicilio ?? TipoDomicilioEnum.OUTRO
  )

  const numeroCidadaos = renderizarNumeroMoradores(
    isFichaNaoProcessadaAplicativo,
    tipoResponsavel === TipoResponsavelEnum.TECNICO,
    numeroMoradores,
    numeroTotalCidadaos
  )

  return (
    <HFlow
      style={css`
        padding: 0.5rem 1rem;
        border-bottom: 1px solid ${theme.pallete.gray.c70};
      `}
    >
      <Tooltip text={tooltip}>
        <ImovelIcon
          css={css`
            fill: ${fill};
            margin-top: 1.35rem;
          `}
        />
      </Tooltip>

      <Grid gapVertical={0} style={styles.imovelCardItem}>
        <Cell
          size={12}
          style={css`
            padding-top: 0.5rem;
            margin-bottom: 0.5rem;
          `}
        >
          <HFlow>
            {isTipoEnderecoIndigena ? (
              <Text fontWeight='bold' variant='h3'>
                nº {numeroResidenciaIndigena} {tipoDomicilio ? ` | ${tipoDomicilio} ` : ''}{' '}
                {materialPredominante ? ` | ${materialPredominante} ` : ''}
              </Text>
            ) : (
              <Text fontWeight='bold' variant='h3'>
                nº {numero} {complemento ? ` | ${complemento} ` : ''}
              </Text>
            )}
            <HFlow hSpacing={0.5}>
              <StatusInconsistenciaImovelTag possuiInconsistencia={possuiInconsistencia} />
              <StatusCadastroImovelTag
                isCadastroCompleto={isCadastroCompleto}
                isStatusRecusaCadastro={isCadastroRecusado}
              />
            </HFlow>
            {!ultimaFichaCadastrada?.isProcessado && (
              <HFlow hSpacing={0.25} alignItems='center'>
                <Icon icon='exclamationTriangleOutline' color={theme.pallete.status.info.main} />
                <Text color='info'>{MENSAGEM_FICHA_NAO_PROCESSADA_IMOVEL}</Text>
              </HFlow>
            )}
          </HFlow>
        </Cell>
        {!isTipoEnderecoIndigena && (
          <Cell size={3}>
            <Text fontWeight='bold'>Última visita </Text>
            <Text>{dataUltimaVisita ? humanizeDate(dataUltimaVisita) : 'desconhecida'}</Text>
          </Cell>
        )}
        <Cell
          size={3}
          style={css`
            white-space: break-spaces;
          `}
        >
          <Text fontWeight='bold'>Última atualização </Text>
          <Text>{dataUltimaAtualizacao ? humanizeDate(dataUltimaAtualizacao) : 'desconhecida'}</Text>
        </Cell>
        {tipoResponsavel !== TipoResponsavelEnum.SEM_RESPONSAVEL ? (
          <Fragment>
            <Cell
              size={3}
              style={css`
                white-space: break-spaces;
              `}
            >
              {tipoResponsavel === TipoResponsavelEnum.TECNICO ? (
                <Text fontWeight='bold'>Responsável técnico </Text>
              ) : (
                <Text fontWeight='bold'>Responsável familiar </Text>
              )}
              <NomeResponsavelField
                familias={familias}
                nomeResponsavelTecnico={nomeResponsavelTecnico}
                tipoResponsavel={tipoResponsavel}
              />
            </Cell>
            <Cell size={2}>
              <Text fontWeight='bold'>Nº de cidadãos </Text>
              {numeroCidadaos ?? 'desconhecido'}
            </Cell>
          </Fragment>
        ) : (
          <Cell size={5} />
        )}
        {isTipoEnderecoIndigena && <Cell size={3} />}
        <Cell
          size={1}
          style={css`
            margin-top: -1rem;
          `}
        >
          <Button skin='ghost' kind='primary' onClick={handleVisualizarImovel} style={styles.button}>
            <Icon icon='zoomOutline' />
          </Button>
        </Cell>
      </Grid>
    </HFlow>
  )
})

const createStyles = (theme: Theme) => ({
  containerLogradouro: css`
    scroll-behavior: smooth;
    overflow: auto;
    white-space: nowrap;
    max-height: 25rem;
  `,
  imovelCardItem: css`
    width: 65rem;
    align-items: start;
  `,
  button: css`
    color: ${theme.pallete.gray.c20};
    padding: 0.5rem;
    margin-left: 3rem;
  `,
})

const NomeResponsavelField = (props: NomeResponsavelFieldProps) => {
  const { familias, nomeResponsavelTecnico, tipoResponsavel } = props

  if (tipoResponsavel === TipoResponsavelEnum.TECNICO) {
    return <Text>{nomeResponsavelTecnico?.titleCase() ?? 'Desconhecido'}</Text>
  } else if (tipoResponsavel === TipoResponsavelEnum.FAMILIAR) {
    return <ResponsavelTooltipAndText familias={familias} />
  }
}

const renderizarNumeroMoradores = (
  isFichaNaoProcessadaAplicativo: boolean,
  isResponsavelTecnico: boolean,
  numeroMoradoresFicha: number,
  numeroTotalCidadaos: number
) => {
  if (isFichaNaoProcessadaAplicativo && isResponsavelTecnico) return null

  return isResponsavelTecnico ? numeroMoradoresFicha : numeroTotalCidadaos
}

const ResponsavelTooltipAndText = (props: { familias: FamiliasTerritorio[] }) => {
  const { familias } = props

  return (
    <Tooltip text={getTooltipResponsaveisFamiliares(familias)} placement='bottom-end'>
      <Text>{familias.length !== 0 ? getNomesResponsaveisFamiliares(familias) : 'não informado'}</Text>
    </Tooltip>
  )
}

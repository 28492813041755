import { useAlertasSdcQuery } from 'graphql/hooks.generated'
import { AlertasSdcQuery, CondutaSdc, GrupoSdcDengueEnum, SdcInput } from 'graphql/types.generated'
import { useLocalStorage, UseStorageOptions } from 'hooks/useStorage'
import React, { useCallback, useEffect, useMemo } from 'react'
import { createContext, useContext, useState } from 'react'

import { SdcDetalhesModal } from './components/SdcDetalhesModal'
import { AlertaSuporteDecisaoClinica, isAlertaSdc, isCondutaSdc, SdcAtendimentoValues } from './model-sdc'

interface AlertasSdcProviderProps {
  cacheKey?: string
  children: React.ReactNode
  options?: UseStorageOptions<AlertaSuporteDecisaoClinica[]>
}

export interface SdcContextModel {
  alertasSdc: AlertaSuporteDecisaoClinica[]
  condutasSdc: CondutaSdc[]
  hasAlertaNaoLido: boolean
  hasCondutaAplicada: boolean
  setModalOpen(value: boolean): void
  setAlertaSelecionado(alerta?: AlertaSuporteDecisaoClinica): void
  setSdcAtendimentoValues(value: SdcAtendimentoValues): void
  setGrupoDengue(grupoDengue: GrupoSdcDengueEnum): void
  marcarTodosAlertasComoLidos(): void
  marcarAlertaComoLido(alerta: AlertaSuporteDecisaoClinica): void
  marcarTodosAlertasComoExibidos(): void
  marcarAlertaComoExibido(alerta: AlertaSuporteDecisaoClinica): void
  marcarCondutaComoAplicada(conduta: CondutaSdc): void
  deleteCache(): void
}

const AlertasSdcContext = createContext<SdcContextModel>(undefined)

const CACHE_KEY_ALERTAS = '|ALERTAS'
const CACHE_KEY_CONDUTAS = '|CONDUTAS'

// TODO@CODEBENDERS refatorar e modularizar este hook pois está com muitas responsabilidades que podem ser segmentadas #25073
export const AlertasSdcProvider = (props: AlertasSdcProviderProps) => {
  const { children, cacheKey, options } = props

  const [alertasSdc, setAlertasSdc] = useState<AlertaSuporteDecisaoClinica[]>([])
  const [condutasSdc, setCondutasSdc] = useState<CondutaSdc[]>([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [alertaSelecionado, setAlertaSelecionado] = useState<AlertaSuporteDecisaoClinica>()
  const [hasAlertaNaoLido, setHasAlertaNaoLido] = useState(false)
  const [hasCondutaAplicada, setHasCondutaAplicada] = useState(false)
  const [sdcAtendimentoValues, setSdcAtendimentoValues] = useState<SdcAtendimentoValues>()
  const [grupoDengue, setGrupoDengue] = useState<GrupoSdcDengueEnum>(null)

  const [getCachedAlertas, setCachedAlertas, deleteCachedAlerts] = useLocalStorage<AlertaSuporteDecisaoClinica[]>(
    cacheKey + CACHE_KEY_ALERTAS,
    options
  )
  const [getCachedCondutasAplicadas, setCachedCondutasAplicadas, deleteCachedCondutasAplicadas] = useLocalStorage<
    CondutaSdc[]
  >(cacheKey + CACHE_KEY_CONDUTAS)

  const problemasCondicoes = sdcAtendimentoValues?.problemasCondicoes
  const alertasSdcInput: SdcInput = useMemo(
    () => ({
      ciaps2Cids10: problemasCondicoes?.map((problemaCondicao) => {
        return {
          cid10: problemaCondicao.cid?.codigo,
          ciap2: problemaCondicao.ciap?.codigo,
        }
      }),
      prontuarioId: sdcAtendimentoValues?.prontuarioId,
      dataInicioAtendimentoProfissional: sdcAtendimentoValues?.atendProfData,
      grupoDengue,
      pesoCidadao:
        sdcAtendimentoValues?.pesoCidadao != null
          ? parseFloat(sdcAtendimentoValues?.pesoCidadao?.replace(',', '.'))
          : null,
    }),
    [grupoDengue, problemasCondicoes, sdcAtendimentoValues]
  )

  useEffect(() => {
    const cachedAlertas = getCachedAlertas() ?? []
    const cachedCondutasAplicadas = getCachedCondutasAplicadas() ?? []

    setAlertasSdc(cachedAlertas)
    setHasCondutaAplicada(cachedCondutasAplicadas.length > 0)
  }, [getCachedAlertas, getCachedCondutasAplicadas])

  useEffect(() => {
    setHasAlertaNaoLido(alertasSdc?.some((alerta) => !alerta.visualizado))
  }, [alertasSdc])

  useEffect(() => {
    alertasSdc.isEmpty() ? setAlertaSelecionado(null) : !alertaSelecionado && setAlertaSelecionado(alertasSdc[0])
  }, [alertaSelecionado, alertasSdc])

  useAlertasSdcQuery({
    variables: { input: alertasSdcInput },
    fetchPolicy: 'cache-and-network',
    skip: !sdcAtendimentoValues,
    onCompleted: (data: AlertasSdcQuery) => {
      if (!!sdcAtendimentoValues) {
        const eventosSdc = data?.eventosSdc ?? []
        const novosAlertas = filterAlertas(eventosSdc)
        const novasCondutas = filterCondutas(eventosSdc)

        const cachedAlertas = getCachedAlertas() ?? []

        const alertasExistentes = new Map(
          [...(alertasSdc ?? []), ...(cachedAlertas ?? [])].map((alerta) => [alerta.id, alerta])
        )

        const alertasAtualizados = atualizarAlertas(novosAlertas, alertasExistentes)

        setAlertasSdc(alertasAtualizados as AlertaSuporteDecisaoClinica[])
        setCondutasSdc(novasCondutas as CondutaSdc[])
        !!alertasAtualizados && setCachedAlertas(alertasAtualizados)
      }
    },
  })

  const atualizarAlertas = useCallback(
    (novosAlertas, alertasExistentes) =>
      (novosAlertas ?? []).map((novoAlerta) => {
        const alertaExistente = alertasExistentes.get(novoAlerta.id)

        if (!alertaExistente) {
          setHasAlertaNaoLido(true)
          return { ...novoAlerta, visualizado: false, exibido: false }
        }

        return alertaExistente
      }),
    []
  )

  const marcarAlertaComoExibido = useCallback(
    (alerta: AlertaSuporteDecisaoClinica) => {
      alerta.exibido = true
      !!alertasSdc && setCachedAlertas(alertasSdc)
    },
    [alertasSdc, setCachedAlertas]
  )

  const marcarTodosAlertasComoExibidos = useCallback(() => {
    alertasSdc?.forEach((alerta) => {
      if (alerta.notificar) alerta.exibido = true
    })
    !!alertasSdc && setCachedAlertas(alertasSdc)
  }, [alertasSdc, setCachedAlertas])

  const marcarAlertaComoLido = useCallback(
    (alerta: AlertaSuporteDecisaoClinica) => {
      alerta.visualizado = true
      alerta.exibido = true
      setHasAlertaNaoLido(alertasSdc?.some((alerta) => !alerta.visualizado))
      !!alertasSdc && setCachedAlertas(alertasSdc)
    },
    [alertasSdc, setCachedAlertas]
  )

  const marcarTodosAlertasComoLidos = useCallback(() => {
    alertasSdc?.forEach((alerta) => {
      alerta.visualizado = true
      alerta.exibido = true
    })
    setHasAlertaNaoLido(false)
    !!alertasSdc && setCachedAlertas(alertasSdc)
  }, [alertasSdc, setCachedAlertas])

  const marcarCondutaComoAplicada = useCallback(
    (conduta: CondutaSdc) => {
      setHasCondutaAplicada(true)
      const cachedCondutasAplicadas = getCachedCondutasAplicadas() ?? []
      setCachedCondutasAplicadas([...cachedCondutasAplicadas, conduta])
    },
    [getCachedCondutasAplicadas, setCachedCondutasAplicadas]
  )

  const deleteCache = useCallback(() => {
    deleteCachedAlerts()
    deleteCachedCondutasAplicadas()
  }, [deleteCachedAlerts, deleteCachedCondutasAplicadas])

  const value: SdcContextModel = useMemo(
    () => ({
      alertasSdc,
      condutasSdc,
      hasAlertaNaoLido,
      hasCondutaAplicada,
      setModalOpen,
      setAlertaSelecionado,
      setSdcAtendimentoValues,
      setGrupoDengue,
      marcarTodosAlertasComoLidos,
      marcarAlertaComoLido,
      marcarTodosAlertasComoExibidos,
      marcarAlertaComoExibido,
      marcarCondutaComoAplicada,
      deleteCache,
    }),
    [
      alertasSdc,
      condutasSdc,
      hasAlertaNaoLido,
      hasCondutaAplicada,
      marcarAlertaComoExibido,
      marcarAlertaComoLido,
      marcarCondutaComoAplicada,
      marcarTodosAlertasComoExibidos,
      marcarTodosAlertasComoLidos,
      deleteCache,
    ]
  )

  return (
    <AlertasSdcContext.Provider value={value}>
      <SdcDetalhesModal
        isModalOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        alertaSelecionado={alertaSelecionado}
      />
      {children}
    </AlertasSdcContext.Provider>
  )
}

const filterAlertas = (eventosSdc: { __typename?: string }[]): AlertaSuporteDecisaoClinica[] =>
  eventosSdc?.filter(isAlertaSdc)

const filterCondutas = (eventosSdc: { __typename?: string }[]): CondutaSdc[] => eventosSdc?.filter(isCondutaSdc)

export const useAlertasSdc = () => useContext(AlertasSdcContext)

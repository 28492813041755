import { HFlow, Omit, Tag, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { IvcfSubgrupoPerguntaEnum } from 'graphql/types.generated'
import React from 'react'
import { IVCF_GRUPOS, IVCF_SUBGRUPOS } from 'view/atendimentos/detail/soap/objetivo/ivcf/model-ivcf'

interface IvcfSubgrupoSelectFieldProps
  extends Omit<SelectFieldProps<IvcfSubgrupoPerguntaEnum>, 'items' | 'itemToString'> {}

export function IvcfSubgrupoSelectField(props: IvcfSubgrupoSelectFieldProps) {
  return (
    <SelectField<IvcfSubgrupoPerguntaEnum>
      items={items}
      itemToString={renderString}
      renderItem={renderItem}
      multiple
      {...props}
    />
  )
}

const renderString = (item: IvcfSubgrupoPerguntaEnum) => item && IVCF_SUBGRUPOS[item].label

const renderItem = (item: IvcfSubgrupoPerguntaEnum) => {
  const subgrupo = IVCF_SUBGRUPOS[item]
  return (
    <HFlow hSpacing={1} alignItems='center'>
      <Tag>{IVCF_GRUPOS[subgrupo.grupo].title}</Tag>
      <Text>{subgrupo.label}</Text>
    </HFlow>
  )
}

const items: IvcfSubgrupoPerguntaEnum[] = Object.values(IvcfSubgrupoPerguntaEnum)

import { CidadaoTerritorioBasico } from 'graphql/types.generated'
import { applyMask, masks } from 'util/mask'

import { FamiliaInconsistenciaModel, TipoInconsistenciaEnum } from '../model'

export const calculateInconsistencias = (
  familias: FamiliaInconsistenciaModel[]
): Map<TipoInconsistenciaEnum, string[]> => {
  const inconsistenciasMap: Map<TipoInconsistenciaEnum, string[]> = new Map()

  familias?.forEach((familia) => {
    const responsavel = familia.cidadaosTerritorioBasico.find((cidadao) => {
      return cidadao.cpf === familia.cpfCnsResponsavel || cidadao.cns === familia.cpfCnsResponsavel
    })
    if (!!responsavel) {
      const labelNomeCpfCnsResponsavel = createLabelNomeCpfCns(responsavel)

      if (!familia.isResponsavelVivo || responsavel?.isFaleceu) {
        createReferencia(inconsistenciasMap, TipoInconsistenciaEnum.RESPONSAVEL_OBITO, labelNomeCpfCnsResponsavel)
      }
    }

    const labelCpfCnsResponsavel = createLabelCpfCnsResponsavel(familia.cpfCnsResponsavel)

    if (!familia.isResponsavelAindaReside) {
      createReferencia(inconsistenciasMap, TipoInconsistenciaEnum.RESPONSAVEL_MUDOUSE, labelCpfCnsResponsavel)
    }

    if (!familia.isResponsavelCadastrado) {
      createReferencia(
        inconsistenciasMap,
        TipoInconsistenciaEnum.RESPONSAVEL_SEM_CADASTRO_INDIVIDUAL,
        labelCpfCnsResponsavel
      )
    }

    if (!familia.isResponsavelDeclarado) {
      createReferencia(inconsistenciasMap, TipoInconsistenciaEnum.RESPONSAVEL_NAO_DECLARADO, labelCpfCnsResponsavel)
    }

    if (!familia.isResponsavelUnico) {
      createReferencia(inconsistenciasMap, TipoInconsistenciaEnum.RESPONSAVEL_OUTRO_DOMICILIO, labelCpfCnsResponsavel)
    }
  })

  return inconsistenciasMap
}

const createReferencia = (
  inconsistenciasMap: Map<TipoInconsistenciaEnum, string[]>,
  tipoInconsistencia: TipoInconsistenciaEnum,
  referenciaText: string
) => {
  const identificacao = inconsistenciasMap.get(tipoInconsistencia)
  if (identificacao) identificacao.push(referenciaText)
  else inconsistenciasMap.set(tipoInconsistencia, [referenciaText])
}

const createLabelCpfCnsResponsavel = (cpfCns: string): string =>
  `CPF / CNS informado para o responsável: ${cpfCns.length === 11 ? applyMask(cpfCns, masks.cpf) : cpfCns}`

const createLabelNomeCpfCns = ({ cpf, cns, nome, nomeSocial }: CidadaoTerritorioBasico): string => {
  return `${(nomeSocial ?? nome)?.titleCase()} | ${applyMask(cpf, masks.cpf) ?? cns}`
}

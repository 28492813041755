/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { ReceitaMedicamento, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import { dateAsDdMmYyyy, formatDate } from 'util/date/formatDate'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'
import { periodoTratamento } from 'view/atendimentos/detail/soap/plano/prescricao-medicamento/components/PeriodoTratamentoSelectField'

interface HistoricoPlanoPecPrescricaoPanelProps {
  prescricaoMedicamentos?: ReceitaMedicamento[]
}
export default function HistoricoPlanoPecPrescricaoPanel({
  prescricaoMedicamentos,
}: HistoricoPlanoPecPrescricaoPanelProps) {
  return (
    <HistoricoDetailTable hasLines>
      <VFlow>
        {prescricaoMedicamentos.map((prescricao) => (
          <VFlow key={prescricao.id} vSpacing={0}>
            <Text fontWeight='bold'>
              {`${prescricao.medicamento.principioAtivo} ${prescricao.medicamento.concentracao ?? ''}`.trim()}
            </Text>
            <Text style={styles.separator}>
              <Text style={styles.inline}>{prescricao.posologia}</Text>
              <Text style={styles.inlineBlock}>{prescricao.aplicacaoMedicamento.nome}</Text>
              {prescricao.medicamento.formaFarmaceutica && (
                <Text style={styles.inlineBlock}>{prescricao.medicamento.formaFarmaceutica.nome}</Text>
              )}
              {(prescricao.dtInicioTratamento || prescricao.dtFimTratamento) && (
                <HLabel style={styles.inlineBlock} title='Período:'>
                  {prescricao.dtInicioTratamento && formatDate(prescricao.dtInicioTratamento)}
                  {prescricao.medidaTempoTratamento !== UnidadeMedidaTempoEnum.INDETERMINADO
                    ? ` - ${dateAsDdMmYyyy(prescricao.dtFimTratamento)}`
                    : ` - ${periodoTratamento[prescricao.medidaTempoTratamento]}`}
                </HLabel>
              )}
              <HLabel style={styles.inlineBlock} title='Quantidade:'>
                {prescricao.quantidadeReceitada}
              </HLabel>
            </Text>
            {prescricao.recomendacoes && (
              <HLabel title='Recomendações:'>
                <Text
                  style={css`
                    overflow-wrap: anywhere;
                  `}
                >
                  {prescricao.recomendacoes}
                </Text>
              </HLabel>
            )}
          </VFlow>
        ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}

const styles = {
  separator: css`
    & > :not(:last-child):after {
      content: '|';
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  `,
  inlineBlock: css`
    display: inline-block;
  `,
  inline: css`
    display: inline;
  `,
}

/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { blue, ColorScale, gray, green, orange, pink, purple, red, turquoise, yellow } from 'bold-ui/lib/styles/colors'
import { SubtipoRecord } from 'components/form/field/select/SubtipoAtendimentoSelectField/SubtipoAtendimentoSelectField'
import {
  AtencaoDomiciliarModalidade,
  CategoriaProblemaEnum,
  ClassificacaoPrioridadeCuidadoEnum,
  ClassificacaoRisco,
  CuidadoCompartilhadoDwFragment,
  DimensaoProcedimento,
  ExameEspecificoEnum,
  FatoAtendimentoIndividualExames,
  FornecimentoOdontoEnum,
  HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment,
  HistoricoCuidadoCompartilhadoHeaderFragment,
  OrigemAtendimento,
  PageInfo,
  RacionalidadeEmSaudeEnum,
  StatusAvaliadoEnum,
  SubtipoAtendimentoHistoricoClinico,
  TipoAtendimentoProfissional,
  TipoConsultaOdontoEnum,
  TipoEncaminhamentoExternoEnum,
  TipoGlicemia,
  TipoRegistroHistoricoClinico,
  TurnoEnum,
  VigilanciaEmSaudeBucalEnum,
} from 'graphql/types.generated'
import { ReactComponent as AtendimentoDomiciliar } from 'images/historico/icone-atendimento-domiciliar.svg'
import { ReactComponent as AtividadeColetiva } from 'images/historico/icone-atividade-coletiva.svg'
import { ReactComponent as AvaliacaoElegibilidade } from 'images/historico/icone-avaliacao-elegibilidade.svg'
import { ReactComponent as Consulta } from 'images/historico/icone-consulta.svg'
import { ReactComponent as ConsumoAlimentar } from 'images/historico/icone-consumo-alimentar.svg'
import { ReactComponent as CuidadoCompartilhado } from 'images/historico/icone-cuidado-compartilhado.svg'
import { ReactComponent as EscutaInicial } from 'images/historico/icone-escuta-inicial.svg'
import { ReactComponent as Odonto } from 'images/historico/icone-odonto.svg'
import { ReactComponent as PreNatal } from 'images/historico/icone-pre-natal.svg'
import { ReactComponent as Procedimentos } from 'images/historico/icone-procedimentos.svg'
import { ReactComponent as Puericultura } from 'images/historico/icone-puericultura.svg'
import { ReactComponent as Puerperio } from 'images/historico/icone-puerperio.svg'
import { ReactComponent as Vacinacao } from 'images/historico/icone-vacinacao.svg'
import { ReactComponent as VisitaDomiciliar } from 'images/historico/icone-visita-domiciliar.svg'
import { ReactComponent as Zika } from 'images/historico/icone-zika-microcefalia.svg'
import React from 'react'
import { TipoAtendimentoHistorico } from 'types/enums'
import shadowIcon from 'util/shadowIcon'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { HistoricoSectionType } from '../components/HistoricoSection'

export interface HistoricoAtendimentoModel extends CuidadoCompartilhadoDwFragment {
  idAtendRecente?: ID
  idAtendProcessado?: ID
  codigoUnicoRegistro?: string
  dataAtendimento?: Instant
  showDataAtendimento?: boolean
  turno?: TurnoEnum
  tipoApresentacao?: TipoRegistroHistoricoClinico
  isAtendRecente: boolean
  tipoAtendProf?: TipoAtendimentoProfissional
  coSubtipoAtendimento?: number
  origemAtendimento?: OrigemAtendimento
  cpfCnsCidadao?: string
  classificacaoRisco?: ClassificacaoRisco
  isCancelado?: boolean
  idadeGestacional?: {
    dias: number
    semanas: number
  }
  condicoesAvaliadas?: string[]
  examesRealizadosZika?: string[]
  profissional?: {
    id: string
    nome: string
    nomeCivil: string
    nomeSocial?: string
  }
  cnsProfissional?: string
  cbo?: {
    id: string
    nome: string
    cbo2002: string
  }
  estagiario?: {
    id: string
    nome: string
    nomeCivil: string
    nomeSocial?: string
  }
  cboEstagio?: {
    id: string
    nome: string
    cbo2002: string
  }

  unidadeSaude?: {
    id: string
    nome?: string
    cnes: string
  }
  equipe?: {
    id: string
    ine: string
    nome: string
  }
  hasPrescricaoMedicamento?: boolean
  hasOrientacao?: boolean
  hasAtestado?: boolean
  hasAlergia?: boolean
  hasSolicitacaoExame?: boolean
  hasResultadoExame?: boolean
  hasEncaminhamento?: boolean
  hasEncaminhamentoEspecializado?: boolean
  hasProcedimentoClinico?: boolean
  hasMarcadorConsumoAlimentar?: boolean
  hasCuidadoCompartilhado: boolean
  hasGuiasEncaminhamentos?: boolean
  hasIvcf?: boolean

  // Dados clínicos agregados usados na listagem do histórico
  dadosClinicos?: string
  condicoesVacinacao?: HistoricoCondicoesVacinacao
  tipoConsultaOdonto?: TipoConsultaOdontoEnum
  fichasConcatenadas?: FichasConcatenadasHistorico

  cuidadoCompartilhado?: HistoricoCuidadoCompartilhadoHeaderFragment
  cuidadoCompartilhadoEvolucao?: HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment

  hasObservacao?: boolean
  isAtendObsFinalizado?: boolean
  inicioAtendimentoObservacao?: Instant
  fimAtendimentoObservacao?: Instant

  nomeFinalizadorObservacao?: string
  cnsFinalizadorObservacao?: string
  cboFinalizadorObservacao?: string
  ubsFinalizadorObservacao?: string
  ineFinalizadorObservacao?: string
}

export type HistoricoDetalhesModel = CuidadoCompartilhadoDwFragment &
  Pick<
    HistoricoAtendimentoModel,
    | 'tipoApresentacao'
    | 'origemAtendimento'
    | 'tipoAtendProf'
    | 'codigoUnicoRegistro'
    | 'cpfCnsCidadao'
    | 'isAtendRecente'
    | 'idAtendRecente'
    | 'fichasConcatenadas'
    | 'dataAtendimento'
    | 'hasObservacao'
    | 'cuidadoCompartilhadoEvolucao'
    | 'cuidadoCompartilhado'
  >

export type FichasConcatenadasHistorico = {
  uuidProcedimento?: string
  uuidZika?: string
}

export type HistoricoCondicoesVacinacao = {
  idAtend: ID
  isViajante?: boolean
  isGestante?: boolean
  isPuerpera?: boolean
  isComunicanteHanseniase?: boolean
}

export type HistoricoAtendimentoListagemTableModel = {
  content: HistoricoAtendimentoModel[]
  pageInfo: PageInfo
}

export type ProcedimentoHistoricoModel = {
  codigo?: string
  descricao: string
  categoriaProblema: CategoriaProblemaEnum
}
export interface SubtipoAtendimentoTag {
  id: ID
  nome: SubtipoAtendimentoHistoricoClinico
  tagColor: string
}

const consultaSubtiposTags: SubtipoAtendimentoTag[] = [
  {
    id: 1,
    nome: SubtipoAtendimentoHistoricoClinico.CONSULTA_PROGRAMADA_CUIDADO_CONTINUADO,
    tagColor: blue.c40,
  },
  {
    id: 2,
    nome: SubtipoAtendimentoHistoricoClinico.CONSULTA,
    tagColor: blue.c40,
  },
  {
    id: 4,
    nome: SubtipoAtendimentoHistoricoClinico.ESCUTA_INICIAL_ORIENTACAO,
    tagColor: blue.c40,
  },
  {
    id: 5,
    nome: SubtipoAtendimentoHistoricoClinico.CONSULTA_DIA,
    tagColor: blue.c40,
  },
  {
    id: 6,
    nome: SubtipoAtendimentoHistoricoClinico.URGENCIA,
    tagColor: red.c40,
  },
  {
    id: 7,
    nome: SubtipoAtendimentoHistoricoClinico.PROGRAMADA,
    tagColor: blue.c40,
  },
  {
    id: 8,
    nome: SubtipoAtendimentoHistoricoClinico.NAO_PROGRAMADA,
    tagColor: blue.c40,
  },
  {
    id: 9,
    nome: SubtipoAtendimentoHistoricoClinico.VISITA_DOMICILIAR_POS_OBITO,
    tagColor: blue.c40,
  },
]

const ADSubtiposTags: SubtipoAtendimentoTag[] = [
  {
    id: 7,
    nome: SubtipoAtendimentoHistoricoClinico.PROGRAMADA,
    tagColor: green.c40,
  },
  {
    id: 8,
    nome: SubtipoAtendimentoHistoricoClinico.NAO_PROGRAMADA,
    tagColor: green.c40,
  },
  {
    id: 9,
    nome: SubtipoAtendimentoHistoricoClinico.VISITA_DOMICILIAR_POS_OBITO,
    tagColor: green.c40,
  },
]

const visitaSubtiposTags: SubtipoAtendimentoTag[] = [
  {
    id: 1,
    nome: SubtipoAtendimentoHistoricoClinico.VISITA_REALIZADA,
    tagColor: green.c40,
  },
  {
    id: 2,
    nome: SubtipoAtendimentoHistoricoClinico.VISITA_RECUSADA,
    tagColor: green.c40,
  },
  {
    id: 3,
    nome: SubtipoAtendimentoHistoricoClinico.CIDADAO_AUSENTE,
    tagColor: green.c40,
  },
]

const consumoAlimentarSubtiposTags: SubtipoAtendimentoTag[] = [
  {
    id: 1,
    nome: SubtipoAtendimentoHistoricoClinico.CRIANCAS_MENORES_6_MESES,
    tagColor: turquoise.c40,
  },
  {
    id: 2,
    nome: SubtipoAtendimentoHistoricoClinico.CRIANCAS_6_A_23_MESES,
    tagColor: turquoise.c40,
  },
  {
    id: 3,
    nome: SubtipoAtendimentoHistoricoClinico.CIDADAOS_MAIORES_2_ANOS,
    tagColor: turquoise.c40,
  },
]

const atividadeColetivaSubtiposTags: SubtipoAtendimentoTag[] = [
  {
    id: 4,
    nome: SubtipoAtendimentoHistoricoClinico.EDUCACAO_SAUDE,
    tagColor: turquoise.c40,
  },
  {
    id: 5,
    nome: SubtipoAtendimentoHistoricoClinico.ATENDIMENTO_GRUPO,
    tagColor: turquoise.c40,
  },
  {
    id: 6,
    nome: SubtipoAtendimentoHistoricoClinico.AVALIACAO_PROCEDIMENTO_COLETIVO,
    tagColor: turquoise.c40,
  },
  {
    id: 7,
    nome: SubtipoAtendimentoHistoricoClinico.MOBILIZACAO_SOCIAL,
    tagColor: turquoise.c40,
  },
]

export interface TipoAtendimentoHistoricoProps {
  descricao: string
  iconColor: string
  barColor: string
  icon?: React.ReactElement
  tags?: SubtipoAtendimentoTag[]
}

export const encaminhamentoExternoRecord: Record<TipoEncaminhamentoExternoEnum, string> = {
  CAPS: 'CAPS',
  INTERNACAO_HOSPITALAR: 'Internação Hospitalar',
  INTERSETORIAL: 'Intersetorial',
  SERVICO_ATENCAO_DOMICILIAR: 'Serviço Atenção Domiciliar',
  URGENCIA: 'Urgência',
  SERVICO_ESPECIALIZADO: 'Serviço Especializado',
}

export const tipoRegistroHistoricoData: Partial<Record<
  TipoRegistroHistoricoClinico | TipoAtendimentoProfissional,
  TipoAtendimentoHistoricoProps
>> = {
  [TipoRegistroHistoricoClinico.CONSULTA || TipoAtendimentoProfissional.CONSULTA]: {
    descricao: TipoAtendimentoHistorico.CONSULTA,
    barColor: blue.c40,
    iconColor: blue.c40,
    icon: <Consulta css={shadowIcon(blue.c60)} />,
    tags: consultaSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.PUERICULTURA || TipoAtendimentoProfissional.PUERICULTURA]: {
    descricao: TipoAtendimentoHistorico.PUERICULTURA,
    barColor: turquoise.c60,
    iconColor: turquoise.c60,
    icon: <Puericultura css={shadowIcon(turquoise.c60)} />,
    tags: consultaSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.PRE_ATENDIMENTO]: {
    descricao: TipoAtendimentoHistorico.PRE_ATENDIMENTO,
    barColor: pink.c40,
    iconColor: pink.c40,
    icon: <EscutaInicial css={shadowIcon(pink.c60)} />,
  },
  [TipoRegistroHistoricoClinico.PRE_NATAL || TipoAtendimentoProfissional.PRE_NATAL]: {
    descricao: TipoAtendimentoHistorico.PRE_NATAL,
    barColor: purple.c40,
    iconColor: purple.c40,
    icon: <PreNatal css={shadowIcon(purple.c60)} />,
    tags: consultaSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.PUERPERIO || TipoAtendimentoProfissional.PUERPERIO]: {
    descricao: TipoAtendimentoHistorico.PUERPERIO,
    barColor: blue.c70,
    iconColor: blue.c50,
    icon: <Puerperio css={shadowIcon(blue.c80)} />,
    tags: consultaSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.ESCUTA_INICIAL || TipoAtendimentoProfissional.ESCUTA_INICIAL]: {
    descricao: TipoAtendimentoHistorico.ESCUTA_INICIAL,
    barColor: pink.c40,
    iconColor: pink.c40,
    icon: <EscutaInicial css={shadowIcon(pink.c60)} />,
  },
  [TipoRegistroHistoricoClinico.ATENDIMENTO_ODONTOLOGICO || TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO]: {
    descricao: TipoAtendimentoHistorico.ATENDIMENTO_ODONTOLOGICO,
    barColor: orange.c60,
    iconColor: orange.c40,
    icon: <Odonto css={shadowIcon(orange.c60)} />,
    tags: consultaSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.ATENDIMENTO_DOMICILIAR || TipoAtendimentoProfissional.ATENDIMENTO_DOMICILIAR]: {
    descricao: TipoAtendimentoHistorico.ATENDIMENTO_DOMICILIAR,
    barColor: green.c60,
    iconColor: green.c40,
    icon: <AtendimentoDomiciliar css={shadowIcon(green.c60)} />,
    tags: ADSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.VISITA_DOMICILIAR]: {
    descricao: TipoAtendimentoHistorico.VISITA_DOMICILIAR_TERRITORIAL,
    barColor: green.c60,
    iconColor: green.c40,
    icon: <VisitaDomiciliar css={shadowIcon(green.c60)} />,
    tags: visitaSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.ATENDIMENTO_PROCEDIMENTOS || TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS]: {
    descricao: TipoAtendimentoHistorico.ATENDIMENTO_PROCEDIMENTOS,
    barColor: yellow.c60,
    iconColor: yellow.c40,
    icon: <Procedimentos css={shadowIcon(yellow.c60)} />,
  },
  [TipoRegistroHistoricoClinico.VACINACAO || TipoAtendimentoProfissional.VACINACAO]: {
    descricao: TipoAtendimentoHistorico.VACINACAO,
    barColor: yellow.c60,
    iconColor: yellow.c40,
    icon: <Vacinacao css={shadowIcon(yellow.c60)} />,
  },
  [TipoRegistroHistoricoClinico.ATIVIDADE_COLETIVA]: {
    descricao: TipoAtendimentoHistorico.ATIVIDADE_COLETIVA,
    barColor: turquoise.c60,
    iconColor: turquoise.c40,
    icon: <AtividadeColetiva css={shadowIcon(turquoise.c60)} />,
    tags: atividadeColetivaSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.CONSUMO_ALIMENTAR]: {
    descricao: TipoAtendimentoHistorico.MARCADORES_CONSUMO_ALIMENTAR,
    barColor: turquoise.c60,
    iconColor: turquoise.c40,
    icon: <ConsumoAlimentar css={shadowIcon(turquoise.c60)} />,
    tags: consumoAlimentarSubtiposTags,
  },
  [TipoRegistroHistoricoClinico.AVALIACAO_ELEGIBILIDADE || TipoAtendimentoProfissional.AVALIACAO_ELEGIBILIDADE]: {
    descricao: TipoAtendimentoHistorico.AVALIACAO_ELEGIBILIDADE,
    barColor: turquoise.c60,
    iconColor: turquoise.c40,
    icon: <AvaliacaoElegibilidade css={shadowIcon(turquoise.c60)} />,
  },
  [TipoRegistroHistoricoClinico.ZIKA || TipoAtendimentoProfissional.ZIKA]: {
    descricao: TipoAtendimentoHistorico.SINDOME_NEUROLOGICA_ZIKA_MICROCEFALIA,
    barColor: turquoise.c60,
    iconColor: turquoise.c40,
    icon: <Zika css={shadowIcon(turquoise.c60)} />,
  },
  [TipoRegistroHistoricoClinico.OBSERVACAO || TipoAtendimentoProfissional.OBSERVACAO]: {
    descricao: TipoAtendimentoHistorico.OBSERVACAO,
    barColor: yellow.c60,
    iconColor: yellow.c40,
    icon: <Procedimentos css={shadowIcon(yellow.c60)} />,
  },
  [TipoRegistroHistoricoClinico.CUIDADO_COMPARTILHADO]: {
    descricao: TipoAtendimentoHistorico.CUIDADO_COMPARTILHADO,
    barColor: pink.c70,
    iconColor: pink.c60,
    icon: <CuidadoCompartilhado css={shadowIcon(pink.c60)} />,
  },
}

export const categoriaProcedimentoEnum: Record<CategoriaProblemaEnum, string> = {
  [CategoriaProblemaEnum.CIAP]: 'CIAP',
  [CategoriaProblemaEnum.CID10]: 'CID10',
  [CategoriaProblemaEnum.OUTRO]: 'Outro',
  [CategoriaProblemaEnum.EXAME]: 'Exame',
  [CategoriaProblemaEnum.CID10_PRINCIPAL]: 'CID10 - Principal',
  [CategoriaProblemaEnum.CID10_SECUNDARIO_UM]: 'CID10 - Secundário 1',
  [CategoriaProblemaEnum.CID10_SECUNDARIO_DOIS]: 'CID10 - Secundário 2',
}

export interface EscutaHistoricoTagModel {
  style?: SerializedStyles
  descricao: string
}

const riscoAndPrioridadeTagStyles = {
  vermelho: css`
    background-color: ${red.c40};
    color: white;
  `,
  amarelo: css`
    background-color: ${yellow.c60};
    color: white;
  `,
  verde: css`
    background-color: ${green.c40};
    color: white;
  `,
  azul: css`
    background-color: ${blue.c40};
    color: white;
  `,
}

export const recordEscutaHistoricoTagStyle: Record<ClassificacaoRisco, EscutaHistoricoTagModel> = {
  [ClassificacaoRisco.NAO_CLASSIFICADO]: { style: null, descricao: '' },
  [ClassificacaoRisco.PEC_ALTA]: { style: riscoAndPrioridadeTagStyles.vermelho, descricao: 'Risco alto' },
  [ClassificacaoRisco.PEC_BAIXA]: { style: riscoAndPrioridadeTagStyles.verde, descricao: 'Risco baixo' },
  [ClassificacaoRisco.PEC_INTERMEDIARIA]: {
    style: riscoAndPrioridadeTagStyles.amarelo,
    descricao: 'Risco intermediário',
  },
  [ClassificacaoRisco.PEC_NAO_AGUDA]: { style: riscoAndPrioridadeTagStyles.azul, descricao: 'Não aguda' },
}

export const recordCuidadoCompartilhadoPrioridadeTagStyle: Record<
  ClassificacaoPrioridadeCuidadoEnum,
  EscutaHistoricoTagModel
> = {
  [ClassificacaoPrioridadeCuidadoEnum.MUITO_ALTA]: {
    style: riscoAndPrioridadeTagStyles.vermelho,
    descricao: 'Prioridade muito alta',
  },
  [ClassificacaoPrioridadeCuidadoEnum.ALTA]: {
    style: riscoAndPrioridadeTagStyles.amarelo,
    descricao: 'Prioridade alta',
  },
  [ClassificacaoPrioridadeCuidadoEnum.MEDIA]: {
    style: riscoAndPrioridadeTagStyles.verde,
    descricao: 'Prioridade média',
  },
  [ClassificacaoPrioridadeCuidadoEnum.BAIXA]: {
    style: riscoAndPrioridadeTagStyles.azul,
    descricao: 'Prioridade baixa',
  },
}
//TODO
export const accordionBloqueadoMensagem: Partial<Record<SubtipoAtendimentoHistoricoClinico, string>> = {
  [SubtipoAtendimentoHistoricoClinico.CIDADAO_AUSENTE]:
    SubtipoRecord[SubtipoAtendimentoHistoricoClinico.CIDADAO_AUSENTE],
  [SubtipoAtendimentoHistoricoClinico.VISITA_RECUSADA]: 'Atendimento recusado',
}

interface HistoricoSectionInfo {
  title: string
  color: string
}

export const HistoricoSectionStyles: Record<HistoricoSectionType, HistoricoSectionInfo> = {
  S: { title: 'Subjetivo', color: pink.c40 },
  O: { title: 'Objetivo', color: blue.c40 },
  A: { title: 'Avaliação', color: yellow.c40 },
  P: { title: 'Plano', color: green.c40 },
  AN: { title: 'Antecedentes', color: gray.c20 },
  D: { title: 'Finalização do atendimento', color: gray.c20 },
  I: { title: 'Informações Complementares', color: gray.c20 },
  RA: { title: 'Registros Anteriores', color: turquoise.c40 },
  AD: { title: 'Dados da atenção domiciliar', color: gray.c20 },
}

export const historicoTipoGlicemiaRecord: Record<TipoGlicemia, string> = {
  [TipoGlicemia.NAO_ESPECIFICADO]: 'Não especificado',
  [TipoGlicemia.JEJUM]: 'Jejum',
  [TipoGlicemia.POSPRANDIAL]: 'Pós-prandial',
  [TipoGlicemia.PREPRANDIAL]: 'Pré-prandial',
}

export interface HistoricoPanelProps extends HistoricoPecPanelProps, HistoricoDwPanelProps {
  isAtendRecente: boolean
}

export interface HistoricoPecPanelProps {
  idAtend: ID
  idadeOrigemAtendimento: IdadeOrigemAtendimento
  dataNascimento?: LocalDate
  isAtendObservacao?: boolean
}

export interface InformacoesAdministrativasProfissional {
  nome?: string
  nomeCivil?: string
  nomeSocial?: string
  cbo?: string
  nomeUnidadeSaude?: string
  cnesUnidadeSaude?: string
  nomeEquipe?: string
  ineEquipe?: string
}

export interface HistoricoDwPanelProps {
  uuidRegistro: ID
  cpfOuCnsCidadao?: string
  idadeOrigemAtendimento: IdadeOrigemAtendimento
}

export interface InformacoesAdministrativas {
  profissionalPrincipal?: InformacoesAdministrativasProfissional

  nomeCivilEstagiario?: string
  nomeSocialEstagiario?: string
  cboEstagiario?: string
  nomeEquipeEstagiario?: string
  ineEquipeEstagiario?: string

  profissionalCompartilhado?: InformacoesAdministrativasProfissional

  codigoInep?: string
  nomeInep?: string
  cpfCnsCuidador?: string
  tipoCuidador?: string
  localAtendimento?: string
  stAtendimentoCompartilhado?: boolean
  stCuidadoCompartilhado?: boolean
  stRegistroTardio?: boolean
  dataCriacaoRegistro?: Instant

  profissionalFinalizadorObservacao?: InformacoesAdministrativasProfissional

  tipoParticipacaoProfissionalConvidado?: string
}

export interface HistoricoSubsectionStyles {
  subjetivo: SerializedStyles
  objetivo: SerializedStyles
  avaliacao: SerializedStyles
  plano: SerializedStyles
  desfecho: SerializedStyles
  infoComplementares: SerializedStyles
  dadosAtencaoDomiciliar: SerializedStyles
  registrosAnteriores: SerializedStyles
}
export interface IdadeOrigemAtendimento {
  dataNascimentoCidadao: LocalDate
  dataAtendimento: LocalDate
  origemAtendimento: OrigemAtendimento
}

export const historicoSubsectionStyles: HistoricoSubsectionStyles = {
  subjetivo: css`
    color: ${HistoricoSectionStyles['S'].color};
  `,
  objetivo: css`
    color: ${HistoricoSectionStyles['O'].color};
  `,
  avaliacao: css`
    color: ${HistoricoSectionStyles['A'].color};
  `,
  plano: css`
    color: ${HistoricoSectionStyles['P'].color};
  `,
  desfecho: css`
    color: ${HistoricoSectionStyles['D'].color};
  `,
  infoComplementares: css`
    color: ${HistoricoSectionStyles['I'].color};
  `,
  dadosAtencaoDomiciliar: css`
    color: ${HistoricoSectionStyles['AD'].color};
  `,
  registrosAnteriores: css`
    color: ${HistoricoSectionStyles['RA'].color};
  `,
}

export const exameEspecificoRecord: Partial<Record<ExameEspecificoEnum, string>> = {
  [ExameEspecificoEnum.HEMOGLOBINA_GLICADA]: '%',
  [ExameEspecificoEnum.COLESTEROL_TOTAL]: 'mg/dL',
  [ExameEspecificoEnum.COLESTEROL_HDL]: 'mg/dL',
  [ExameEspecificoEnum.COLESTEROL_LDL]: 'mg/dL',
  [ExameEspecificoEnum.TRIGLICERIDIOS]: 'mg/dL',
  [ExameEspecificoEnum.DOSAGEM_DE_CREATINA]: 'mg/dL',
  [ExameEspecificoEnum.CLEARANCE_DE_CREATINA]: 'mL/min/1,73m²',
}

interface StatusMarcosDesenvolvimento {
  titulo: string
  cor: string
}

export const marcosDesenvolvimentoStatusRecord: Record<StatusAvaliadoEnum, StatusMarcosDesenvolvimento> = {
  PRESENTE: { titulo: 'Presente', cor: green.c40 },
  PRESENTE_COM_ATRASO: { titulo: 'Presente com atraso', cor: yellow.c40 },
  AUSENTE: { titulo: 'Ausente', cor: red.c40 },
  NAO_AVALIADO: null,
}

interface StatusMarcosDesenvolvimento {
  titulo: string
  cor: string
}

export const MEDICOES_ATENDIMENTO_PEC: Array<keyof HistoricoMedicaoModel> = [
  'valorPeso',
  'valorAltura',
  'valorImc',
  'valorCircunferenciaAbdominal',
  'valorPerimetroCefalico',
  'perimetroPanturrilha',
  'valorPressaoArterial',
  'valorFrequenciaRespiratoria',
  'valorFrequenciaCardiaca',
  'valorTemperatura',
  'valorSaturacaoO2',
  'valorVacinacaoEmDia',
  'tipoGlicemia',
  'dum',
]

export const MEDICOES_PRE_NATAL_PEC: Array<keyof HistoricoMedicaoModel> = [
  'valorAlturaUterina',
  'valorBatimentoCardiacoFetal',
  'tipoGravidez',
]

export const vigilanciaEmSaudeBucalRecord: Record<VigilanciaEmSaudeBucalEnum, string> = {
  [VigilanciaEmSaudeBucalEnum.ABSCESSO_DENTO_ALVEOLAR]: 'Abcesso dentoalveolar',
  [VigilanciaEmSaudeBucalEnum.ALTERACAO_EM_TECIDOS_MOLES]: 'Alteração em tecidos moles',
  [VigilanciaEmSaudeBucalEnum.DOR_DE_DENTE]: 'Dor de dente',
  [VigilanciaEmSaudeBucalEnum.FENDAS_OU_FISSURAS_LABIO_PALATAIS]: 'Fendas ou fissuras lábio palatais',
  [VigilanciaEmSaudeBucalEnum.FLUOROSE_DENTARIA_MODERADA_OU_SEVERA]: 'Fluorose dentária moderada ou severa',
  [VigilanciaEmSaudeBucalEnum.TRAUMATISMO_DENTO_ALVEOLAR]: 'Traumatismo dentoalveolar',
  [VigilanciaEmSaudeBucalEnum.NAO_IDENTIFICADO]: 'Não identificado',
}

export const racionalidadeEmSaudeDesfecho: Record<RacionalidadeEmSaudeEnum, string> = {
  [RacionalidadeEmSaudeEnum.MEDICINA_TRADICIONAL_CHINESA]: 'Medicina tradicional chinesa - 01',
  [RacionalidadeEmSaudeEnum.ANTROPOSOFIA_APLICADA_A_SAUDE]: 'Antroposofia aplicada à saúde - 02',
  [RacionalidadeEmSaudeEnum.HOMEOPATIA]: 'Homeopatia - 03',
  [RacionalidadeEmSaudeEnum.FITOTERAPIA]: 'Fitoterapia - 04',
  [RacionalidadeEmSaudeEnum.AYUVEDA]: 'Ayurveda - 05',
  [RacionalidadeEmSaudeEnum.OUTRA]: 'Outra - 06',
}

export const fornecimentoOdontoDesfecho: Record<FornecimentoOdontoEnum, string> = {
  [FornecimentoOdontoEnum.CREME_DENTAL]: 'Creme dental',
  [FornecimentoOdontoEnum.ESCOVA_DENTAL]: 'Escova dental',
  [FornecimentoOdontoEnum.FIO_DENTAL]: 'Fio dental',
}

interface ModalidadeHistoricoAD {
  descricao: string
  cor: ColorScale
}

export const modalidadeCorRecord: Partial<Record<AtencaoDomiciliarModalidade, ModalidadeHistoricoAD>> = {
  MODALIDADE_AD_1: { descricao: 'AD1', cor: green },
  MODALIDADE_AD_2: { descricao: 'AD2', cor: blue },
  MODALIDADE_AD_3: { descricao: 'AD3', cor: red },
}

export const tipoConsultaOdontoRecord: Record<TipoConsultaOdontoEnum, string> = {
  PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA: '1ª consulta',
  CONSULTA_DE_RETORNO: 'Consulta de retorno',
  CONSULTA_DE_MANUTENCAO: 'Consulta de manutenção',
}

export type CidadaoHistorico = {
  id: ID
  cpf?: string
  cns?: string
  nome: string
  nomeSocial?: string
  dataNascimento?: LocalDate
}

export interface HistoricoDadosClinicosModel {
  descricao: string
  codigo: string
  terminologia?: string
}

export interface HistoricoResultadosExamesAvaliados {
  dimensaoProcedimento: DimensaoProcedimento
  resultadoEspecifico?: FatoAtendimentoIndividualExames
}

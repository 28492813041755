/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ButtonLink } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import { useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { grupoCboSolicitacaoExame } from '../acessos'
import {
  urlSolicitacaoExameAltoCusto,
  urlSolicitacaoExameComum,
  urlSolicitacaoProcedOci,
} from './SolicitarExamesRootView'

export interface AdicionarSolicitacaoExameButtonsProps {
  imprimirTooltip: string
  disabledImpressao: boolean
  handleImpressao: () => void
}

export function AdicionarSolicitacaoExameButtons(props: AdicionarSolicitacaoExameButtonsProps) {
  const { imprimirTooltip, disabledImpressao, handleImpressao } = props

  const match = useRouteMatch()

  const { PILOTO_SOLICITAR_PROCED_OCI_ENABLED } = useFlags()
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  return (
    <CheckPermission permission={grupoCboSolicitacaoExame.adicionar}>
      {PILOTO_SOLICITAR_PROCED_OCI_ENABLED ? (
        <div css={styles.box}>
          <HFlow>
            <div css={styles.wrapper}>
              <HFlow>
                <ButtonLink type='button' kind='primary' size='small' to={`${match.url}${urlSolicitacaoExameComum}`}>
                  <HFlow hSpacing={0.5}>
                    <Icon icon='plus' />
                    Adicionar exame comum
                  </HFlow>
                </ButtonLink>
                <ButtonLink
                  type='button'
                  kind='primary'
                  skin='outline'
                  size='small'
                  to={`${match.url}${urlSolicitacaoExameAltoCusto}`}
                >
                  <HFlow hSpacing={0.5}>
                    <Icon icon='plus' />
                    Adicionar exame de alto custo
                  </HFlow>
                </ButtonLink>
              </HFlow>
            </div>

            <div css={styles.divider} />

            <div css={styles.wrapper}>
              <Tooltip text='Adicionar Oferta de Cuidado Integrado'>
                <ButtonLink
                  type='button'
                  kind='primary'
                  skin='outline'
                  size='small'
                  to={`${match.url}${urlSolicitacaoProcedOci}`}
                >
                  <HFlow hSpacing={0.5}>
                    <Icon icon='plus' />
                    Adicionar OCI
                  </HFlow>
                </ButtonLink>
              </Tooltip>
            </div>
          </HFlow>
        </div>
      ) : (
        <HFlow hSpacing={0.5}>
          <ButtonLink type='button' kind='primary' size='small' to={`${match.url}${urlSolicitacaoExameComum}`}>
            <HFlow hSpacing={0.5}>
              <Icon icon='plus' />
              Adicionar exame comum
            </HFlow>
          </ButtonLink>
          <ButtonLink
            type='button'
            kind='primary'
            skin='outline'
            size='small'
            to={`${match.url}${urlSolicitacaoExameAltoCusto}`}
          >
            <HFlow hSpacing={0.5}>
              <Icon icon='plus' />
              Adicionar exame de alto custo
            </HFlow>
          </ButtonLink>

          <Tooltip text={imprimirTooltip} placement='bottom'>
            <Button kind='normal' size='small' onClick={handleImpressao} disabled={disabledImpressao}>
              <HFlow hSpacing={0.5}>
                <Icon icon='printerOutline' />
                Imprimir
              </HFlow>
            </Button>
          </Tooltip>
        </HFlow>
      )}
    </CheckPermission>
  )
}

const createStyles = (theme: Theme) => ({
  box: css`
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 2px;
    border: 1px solid ${theme.pallete.divider};
    width: fit-content;
  `,
  divider: css`
    width: 1px;
    background-color: ${theme.pallete.divider};
  `,
  wrapper: css`
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  `,
})

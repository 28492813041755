import { Icon } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useCancelarAgendamentoMutation, useDataCriacaoAgendadoQuery } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useCallback } from 'react'
import {
  JustificativaCancelamentoAgendaComboEnum as JustificativaEnum,
  JustificativaCancelamentoAgendaRecord,
} from 'types/enums'
import { EventoAgendaLotacao, TipoAgendamento } from 'view/agenda/model-agenda'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model-acoesAgendamento'
import { agendamentoRegistradoEarlierThanOneHour, createJustificativaProfissional } from '../utils'

interface CancelarAgendamentoButtonProps extends Omit<AcaoAgendamentoButtonProps, 'event'> {
  event: Pick<
    EventoAgendaLotacao,
    'id' | 'tipo' | 'nomeCidadao' | 'motivoReserva' | 'dataCriacao' | 'lotacao' | 'isAtencaoDomiciliar'
  >
  openModalCancelarAgendamento(): void
  onAction?(): void
}

export function CancelarAgendamentoButton(props: CancelarAgendamentoButtonProps) {
  const { event, disabled, tooltip, isDropdown = false, openModalCancelarAgendamento, onAction } = props
  const alert = useAlert()
  const handleException = useErrorHandler()
  const {
    data: {
      profissional: { nome: nomeProfissional },
      acesso: { id: lotacaoId },
    },
  } = useSession({ fetchPolicy: 'cache-first' })

  const { getServerTimeNow } = useServerTime()

  const [cancelarAgendamento, { loading }] = useCancelarAgendamentoMutation()

  const { refetch, loading: loadingDataCriacaoAgendado } = useDataCriacaoAgendadoQuery({
    variables: { id: event.id },
    fetchPolicy: 'network-only',
    skip: true,
  })

  const handleCancelarAgendamento = useCallback(
    (justificativa: JustificativaEnum, justificativaOutra?: string) =>
      confirm({
        title: 'Deseja excluir o agendamento?',
        type: 'danger',
        confirmLabel: 'Excluir',
        onConfirm: () =>
          cancelarAgendamento({
            variables: {
              input: {
                id: event.id,
                justificativa:
                  JustificativaCancelamentoAgendaRecord[justificativa].justificativaCancelarAgendamentoEnum,
                justificativaOutra,
              },
            },
          })
            .then(() => {
              alert(
                'success',
                event.tipo === TipoAgendamento.CONSULTA
                  ? `Agendamento de ${event.nomeCidadao.titleCase()} foi cancelado.`
                  : `Reserva de horário para ${event.motivoReserva.titleCase()} foi cancelada`
              )
              onAction?.()
            })
            .catch(handleException),
      })(),
    [
      cancelarAgendamento,
      event.id,
      event.tipo,
      event.nomeCidadao,
      event.motivoReserva,
      handleException,
      alert,
      onAction,
    ]
  )

  const onClick = useCallback(async () => {
    const {
      data: { agendado },
    } = await refetch()

    if (agendamentoRegistradoEarlierThanOneHour(agendado.dataCriacao, getServerTimeNow())) {
      handleCancelarAgendamento(
        JustificativaEnum.OUTRA,
        createJustificativaProfissional(nomeProfissional, getServerTimeNow())
      )
    } else if (event.tipo === 'reserva' && event.lotacao.id === lotacaoId) {
      handleCancelarAgendamento(JustificativaEnum.JUSTIFICATIVA_AUTOMATICA_PROFISSIONAL)
    } else {
      openModalCancelarAgendamento()
    }
  }, [
    refetch,
    event.tipo,
    event.lotacao.id,
    lotacaoId,
    handleCancelarAgendamento,
    nomeProfissional,
    getServerTimeNow,
    openModalCancelarAgendamento,
  ])

  return (
    <MenuAcoesAgendamentoButton
      text='Cancelar'
      type='danger'
      tooltip={tooltip}
      disabled={disabled}
      loading={loading || loadingDataCriacaoAgendado}
      isDropdown={isDropdown}
      onClick={onClick}
      icon={<Icon icon='trashOutline' />}
    />
  )
}

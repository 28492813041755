/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HFlow, Theme, useTheme, VFlow } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { useFlags } from 'config/useFlagsContext'
import { useConfiguracaoRndsQuery } from 'graphql/hooks.generated'
import { SdcPopperButton } from 'hooks/sdc/components/SdcPopperButton'
import { SdcToastList } from 'hooks/sdc/components/SdcToastList'
import { useCabecalhoFixo } from 'hooks/useCabecalhoFixo'
import { forwardRef, Fragment, Ref, useRef } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

import { ButtonAcessoRnds } from '../ButtonAcessoRnds'
import { AtendimentoHeaderCidadaoSection } from './AtendimentoHeaderCidadaoSection'
import { AtendimentoHeaderTabs } from './AtendimentoHeaderTabs'

export interface AtendimentoHeaderProps {
  cidadaoId: ID
  cnsCidadao?: string
  cnsProfissional?: string
  cnes?: string
  municipioId?: ID
  renderFolhaRosto?: boolean
  renderSoap?: boolean
  renderVacinacao?: boolean
  renderHistorico?: boolean
  renderCadastroCidadao?: boolean
  renderEscutaInicial?: boolean
  renderDiscussaoCaso?: boolean
  renderAgendamentos?: boolean
  labelEscutaPreAtendimento?: string
  dataAtendimento?: Instant
  showAlertasSdc?: boolean
  isRetificacao?: boolean
}

export const AtendimentoHeader = forwardRef((props: AtendimentoHeaderProps, ref: Ref<HTMLElement>) => {
  const {
    cidadaoId,
    labelEscutaPreAtendimento,
    cnes,
    cnsProfissional,
    cnsCidadao,
    municipioId,
    dataAtendimento,
    renderFolhaRosto = true,
    renderSoap = true,
    renderVacinacao = true,
    renderHistorico = true,
    renderCadastroCidadao = true,
    renderAgendamentos = true,
    renderEscutaInicial = false,
    renderDiscussaoCaso = false,
    showAlertasSdc = false,
    isRetificacao = false,
  } = props

  const theme = useTheme()
  const isFixo = useCabecalhoFixo()
  const classes = createStyles(theme, isFixo)
  const sdcButtonRef = useRef<HTMLDivElement>()
  const { data: configRnds } = useConfiguracaoRndsQuery()
  const { CONDUTA_SDC_ENABLED } = useFlags()

  return (
    <header ref={ref} css={classes.container}>
      <PageContainer fluid>
        <VFlow vSpacing={0.25}>
          <HFlow alignItems='flex-start' hSpacing={0.25} justifyContent='space-between' style={classes.header}>
            <AtendimentoHeaderCidadaoSection
              cidadaoId={cidadaoId}
              dataAtendimento={dataAtendimento}
              showTelefoneAndUnidade={renderDiscussaoCaso}
            />

            {!isRetificacao && (
              <ButtonAcessoRnds
                cnes={cnes}
                cnsCidadao={cnsCidadao}
                cnsProfissional={cnsProfissional}
                municipioId={municipioId}
                rndsAtiva={!!configRnds?.configuracaoCertificadoHabilitado?.rndsHabilitado}
                conexaoAtiva={configRnds?.conexao?.internet?.habilitado}
              />
            )}
          </HFlow>
          {!isRetificacao ? (
            <HFlow style={classes.atendimentoHeader}>
              <AtendimentoHeaderTabs
                cidadaoId={cidadaoId}
                labelEscutaPreAtendimento={labelEscutaPreAtendimento}
                renderAgendamentos={renderAgendamentos}
                renderCadastroCidadao={renderCadastroCidadao}
                renderEscutaInicial={renderEscutaInicial}
                renderFolhaRosto={renderFolhaRosto}
                renderHistorico={renderHistorico}
                renderSoap={renderSoap}
                renderVacinacao={renderVacinacao}
                renderDiscussaoCaso={renderDiscussaoCaso}
              />
              {showAlertasSdc && CONDUTA_SDC_ENABLED && (
                <Fragment>
                  <SdcPopperButton ref={sdcButtonRef} />
                  <SdcToastList referenceElement={sdcButtonRef} />
                </Fragment>
              )}
            </HFlow>
          ) : (
            <Alert styles={{ container: classes.alertRetificacao }} type='info'>
              Você está editando o atendimento de procedimento de {formatDateAndHoursMinutes(dataAtendimento, '-')}
            </Alert>
          )}
        </VFlow>
      </PageContainer>
    </header>
  )
})

const createStyles = (theme: Theme, isFixo: boolean) => ({
  container: css`
    border-bottom: 1px solid ${theme.pallete.divider};
    background-color: ${theme.pallete.surface.main};
    position: sticky;
    top: 0;
    z-index: 9;
    ${isFixo ? `box-shadow: 0 1px 5px 0 ${theme.pallete.divider}, 0 2px 1px -1px ${theme.pallete.divider};` : ''}
  `,
  header: css`
    margin-top: 1rem;
    gap: 2rem;
  `,
  atendimentoHeader: css`
    grid-template-columns: 1fr auto;
  `,
  alertRetificacao: css`
    width: 100%;
  `,
  buttonSdc: css`
    margin-bottom: 0.5rem;
  `,
})

import { ExposedDropdown } from 'components/dropdown/ExposedDropdown'
import { GarantiaAcessoSortEnum } from 'graphql/types.generated'
import React from 'react'

import { GarantiaAcessoFilterModel } from '../model-garantiaAcesso'

interface GarantiaAcessoSortDropdownProps {
  filter: GarantiaAcessoFilterModel
  onChange(filter: GarantiaAcessoFilterModel): void
}

export function GarantiaAcessoSortDropdown(props: GarantiaAcessoSortDropdownProps) {
  const { filter, onChange } = props
  const items = Object.values(GarantiaAcessoSortEnum)

  const onChangeSelect = (sort: GarantiaAcessoSortEnum) => {
    onChange({
      ...filter,
      ordenacao: sort,
    })
  }

  return (
    <ExposedDropdown<GarantiaAcessoSortEnum>
      label='Ordenar por:'
      selectedItem={filter.ordenacao}
      items={items}
      itemToString={itemToString}
      onChange={onChangeSelect}
    />
  )
}

const itemToString = (sort: GarantiaAcessoSortEnum) => garantiaAcessoSortMap[sort]

const garantiaAcessoSortMap: { [key in GarantiaAcessoSortEnum]: string } = {
  [GarantiaAcessoSortEnum.CLASSIFICACAO_DE_PRIORIDADE]: 'Classificação por prioridade',
  [GarantiaAcessoSortEnum.ORDEM_CHEGADA_CRESCENTE]: 'Ordem de chegada crescente',
  [GarantiaAcessoSortEnum.ORDEM_CHEGADA_DECRESCENTE]: 'Ordem de chegada decrescente',
}

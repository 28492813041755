/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DropdownItem, HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'

import { SdcAvisosCard } from './SdcAvisosCard'

// TODO@CODEBENDERS necessidade de melhoria na experiência de navegação no menu lateral #25074
export const SdcDetalhesModalSideMenu = ({ alertaSelecionado }: { alertaSelecionado: AlertaSuporteDecisaoClinica }) => {
  const theme = useTheme()
  const styles = createStyles(theme)

  const { alertasSdc, marcarTodosAlertasComoLidos, setAlertaSelecionado } = useAlertasSdc()

  return (
    <div css={styles.container}>
      <HFlow alignItems='center' justifyContent='space-between' style={styles.header}>
        <Text fontWeight='bold'>Notificações disparadas</Text>
        <DropdownButton isInsideModal>
          <DropdownItem onClick={marcarTodosAlertasComoLidos}>Marcar todos como lidos</DropdownItem>
        </DropdownButton>
      </HFlow>

      <VFlow vSpacing={0.5} css={styles.alertsList}>
        {alertasSdc.map((alerta) => (
          <SdcAvisosCard
            key={alerta.id}
            alerta={alerta}
            onClick={setAlertaSelecionado}
            selecionado={alerta.id === alertaSelecionado.id}
          />
        ))}
      </VFlow>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 20rem;
    padding: 1.5rem 0 2rem 1rem;
    background-color: ${theme.pallete.surface.background};
  `,
  header: css`
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    height: fit-content;
  `,

  alertsList: css`
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;

    > div {
      width: 100%;
    }

    ::-webkit-scrollbar {
      width: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background-color: ${theme.pallete.gray.c90};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: ${theme.pallete.gray.c70};
    }
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tag, Theme, useTheme } from 'bold-ui'
import { orange } from 'bold-ui/lib/styles/colors'

interface StatusInconsistenciaImovelTagProps {
  possuiInconsistencia: boolean
}

export function StatusInconsistenciaImovelTag({ possuiInconsistencia }: StatusInconsistenciaImovelTagProps) {
  const theme = useTheme()
  const classes = createStyles(theme)

  return possuiInconsistencia ? <Tag style={classes.tagInconsistencia}>Inconsistência</Tag> : null
}

const createStyles = (theme: Theme) => ({
  tagInconsistencia: css`
    background-color: ${orange.c70};
    color: ${theme.pallete.gray.c10};
  `,
})

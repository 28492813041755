import { AgeRangeValue } from 'components/date/AgeRange'
import {
  FaixaEtariaEnum,
  IdentidadeGeneroEnum,
  IvcfSubgrupoPerguntaEnum,
  RiscoVulnerabilidadeIvcfEnum,
  SexoEnum,
} from 'graphql/types.generated'

export interface AcompanhamentoFilterModel {
  sexo?: SexoEnum[]
  identidadeGenero?: IdentidadeGeneroEnum[]
  faixaEtariaRange?: AgeRangeValue
  tipoFaixaEtaria?: FaixaEtariaEnum
  ivcfRiscoVulnerabilidade?: RiscoVulnerabilidadeIvcfEnum[]
  ivcfSubgrupo?: IvcfSubgrupoPerguntaEnum[]
  ivcfPontuacao?: AgeRangeValue
  exibirCidadaoIdosoSemIvcf?: boolean
  isBotaoIvcfAtivo?: boolean
}

export const faixaEtaria: Record<FaixaEtariaEnum, string> = {
  [FaixaEtariaEnum.TODAS_FAIXAS]: 'Todas as faixas',
  [FaixaEtariaEnum.CRIANCA]: 'Criança (0 a 10 anos)',
  [FaixaEtariaEnum.ADOLESCENTE]: 'Adolescente (11 a 19 anos)',
  [FaixaEtariaEnum.ADULTO]: 'Adulto (20 a 59 anos)',
  [FaixaEtariaEnum.IDOSO]: 'Pessoa idosa (60 anos ou mais)',
  [FaixaEtariaEnum.OUTRA]: 'Outra',
}

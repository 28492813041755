/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Interpolation } from '@emotion/styled'
import { ActionableToastList, HFlow, Icon, Tooltip } from 'bold-ui'
import { useToastMessages } from 'bold-ui/lib/hooks'
import { Ellipsis } from 'components/Ellipsis'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'
import React, { RefObject, useCallback, useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'

const TOAST_CONTAINER_HORIZONTAL_PADDING = 0.25

interface SdcToastListProps {
  referenceElement: RefObject<HTMLElement>
}

export const SdcToastList = (props: SdcToastListProps) => {
  const { referenceElement } = props
  const toastRef = useRef<HTMLDivElement>()

  const { alertasSdc, marcarTodosAlertasComoExibidos, setModalOpen, setAlertaSelecionado } = useAlertasSdc()
  const { toastMessages, showToast } = useToastMessages()
  const { analytics } = useFirebase()
  const {
    styles: { popper: toastStyle },
    attributes,
  } = usePopper(referenceElement.current, toastRef.current, {
    placement: 'bottom-end',
  })

  const handleLerButtonClick = useCallback(
    (alerta: AlertaSuporteDecisaoClinica) => {
      setAlertaSelecionado(alerta)
      setModalOpen(true)
      analytics.logEvent('abrir_modal_SDC')
    },
    [analytics, setAlertaSelecionado, setModalOpen]
  )

  const handleCloseButtonClick = useCallback(() => {
    analytics.logEvent('fechar_toast_SDC')
  }, [analytics])

  const newToastMessage = useCallback(
    (alerta: AlertaSuporteDecisaoClinica) => {
      return {
        newToast: true,
        title: 'Suporte à Decisão Clínica',
        buttonLabel: <LerAlertaButtonLabel />,
        action: () => handleLerButtonClick(alerta),
        message: (
          <Ellipsis variant='h5' maxLines={2}>
            {alerta.titulo}
          </Ellipsis>
        ),
        secondsVisible: 5,
        onClose: handleCloseButtonClick,
      }
    },
    [handleCloseButtonClick, handleLerButtonClick]
  )

  useEffect(() => {
    const alertasNovosNaoExibidos = alertasSdc.filter((alerta) => !alerta.exibido && alerta.notificar)

    alertasNovosNaoExibidos.forEach((alertaSdc) => {
      showToast(newToastMessage(alertaSdc))
    })

    marcarTodosAlertasComoExibidos()
  }, [alertasSdc, marcarTodosAlertasComoExibidos, newToastMessage, showToast])

  return (
    <div ref={toastRef} css={[toastStyle as Interpolation, styles.toastListContainer]} {...attributes.popper}>
      <ActionableToastList data={toastMessages} />
    </div>
  )
}

const LerAlertaButtonLabel = React.memo(() => (
  <Tooltip text='Ler notificação'>
    <HFlow hSpacing={0.5}>
      <Icon icon='zoomOutline' /> Ler notificação
    </HFlow>
  </Tooltip>
))

const styles = {
  toastListContainer: css`
    width: 100%;
    > div {
      top: 0;
      display: flex;
      flex-direction: column-reverse;
      padding: 1rem ${TOAST_CONTAINER_HORIZONTAL_PADDING}rem 0;
      max-width: calc(17rem + ${2 * TOAST_CONTAINER_HORIZONTAL_PADDING}rem);
    }
  `,
}

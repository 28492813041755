import {
  useAcompanhamentoCidadaosVulnerabilidadeAlimentarQuery,
  useAcompanhamentoVulnerabilidadeCardQuery,
  useIsBeneficiarioBolsaFamiliaQuery,
} from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { calculateAge } from 'util/date/calculateAge'

import { ProntuarioCard } from '../ProntuarioCard'
import { AcompanhamentoVulnerabilidadeCard } from './AcompanhamentoVulnerabilidadeCard'

interface AcompanhamentoVulnerabilidadeSectionProps {
  cidadaoId: ID
  dataReferencia: Instant
}

export function AcompanhamentoVulnerabilidadeSection(props: AcompanhamentoVulnerabilidadeSectionProps) {
  const { cidadaoId, dataReferencia } = props

  const [showAcompanhamentoVulnerabilidade, setShowAcompanhamentoVulnerabilidade] = useState<boolean>(false)

  const {
    data: { cidadao },
    loading: loadingVulnerabilidadeCardQuery,
  } = useAcompanhamentoVulnerabilidadeCardQuery({
    variables: { cidadaoId },
    context: { newRequest: true },
  })

  const isCidadaoVulneravel = isCidadaoVulneravelAlimentar(cidadao)
  const familiaresValidosIds = Array.from(
    new Set(
      cidadao?.familias
        .flatMap((familia) => familia.cidadaos)
        .filter(
          (cidadaoUnico) => cidadaoUnico.id !== cidadao.id && isCidadaoOlderThan18Years(cidadaoUnico, dataReferencia)
        )
        .map((cidadao) => cidadao.id) ?? []
    )
  )

  const {
    data: { cidadaos },
    loading: loadingVulnerabilidadeAlimentarQuery,
  } = useAcompanhamentoCidadaosVulnerabilidadeAlimentarQuery({
    variables: {
      input: {
        idCidadaoList: familiaresValidosIds,
      },
    },
    context: { newRequest: true },
    skip: isCidadaoVulneravel,
  })

  const familiarVulneravel = cidadaos?.content.find((familiar) => isCidadaoVulneravelAlimentar(familiar))

  const { data, loading: loadingBeneficiarioBolsaFamiliaQuery } = useIsBeneficiarioBolsaFamiliaQuery({
    variables: { cidadaoId },
    context: { newRequest: true },
  })

  const isBeneficiarioBolsaFamilia = data?.cidadao?.beneficiarioBolsaFamilia
  const isVulneravelAlimentar = isCidadaoVulneravel || !isUndefinedOrNull(familiarVulneravel)
  const dataRegistro = isCidadaoVulneravel
    ? cidadao.informacoesSocioEconomicasDW.dataRegistro
    : familiarVulneravel && familiarVulneravel.informacoesSocioEconomicasDW.dataRegistro

  const loading =
    loadingVulnerabilidadeCardQuery || loadingVulnerabilidadeAlimentarQuery || loadingBeneficiarioBolsaFamiliaQuery
  useEffect(() => {
    setShowAcompanhamentoVulnerabilidade(!loading && (isBeneficiarioBolsaFamilia || isVulneravelAlimentar))
  }, [isBeneficiarioBolsaFamilia, isVulneravelAlimentar, loading])

  return showAcompanhamentoVulnerabilidade ? (
    <ProntuarioCard title='Acompanhamento de vulnerabilidade'>
      <AcompanhamentoVulnerabilidadeCard
        dataRegistro={dataRegistro}
        isBeneficiarioBolsaFamilia={isBeneficiarioBolsaFamilia}
      />
    </ProntuarioCard>
  ) : null
}

const isCidadaoVulneravelAlimentar = (cidadao): boolean => {
  const isCidadaoMaiorIdade = isCidadaoOlderThan18Years(cidadao, cidadao?.informacoesSocioEconomicasDW?.dataRegistro)
  const isCidadaoVulneravel =
    cidadao?.informacoesSocioEconomicasDW &&
    cidadao.informacoesSocioEconomicasDW.alimentosAcabaramSemDinheiro &&
    cidadao.informacoesSocioEconomicasDW.comeuPoucoPorFaltaDeDinheiro

  return isCidadaoVulneravel && isCidadaoMaiorIdade
}

const isCidadaoOlderThan18Years = (cidadao, dataReferencia: LocalDate | Instant): boolean => {
  const idadeEmAnos: number = cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, dataReferencia).years
  return idadeEmAnos >= 18
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Tooltip } from 'bold-ui'
import { gray, orange } from 'bold-ui/lib/styles/colors'
import { StatusInformation } from 'components/StatusInformation'
import { isBefore } from 'date-fns'
import React from 'react'
import { useAgendaAuthorization } from 'view/agenda/authorization/useAgendaAuthorization'
import { useAgendaContext } from 'view/agenda/hooks/agenda-context/useAgendaContext'

import { AgendaComponents } from './Agenda'
import { AgendaMessages, AgendaTooltips, SlotStatus } from './model-agenda'

export interface AgendaTimeSlotProps {
  start: number
  end: number
}

export interface AgendaTimeSlotWrapperProps extends AgendaTimeSlotProps {
  children: React.ReactNode
  resource: null | /* time slot label */ undefined /* time slot label */
  messages: AgendaMessages
  status: SlotStatus
  disablePastDates: boolean
  disablePartialSlots: boolean
  firstOfStatus: boolean

  components: Pick<AgendaComponents<any>, 'AvailableSlotHover' | 'TimeSlot'>
}

export function AgendaTimeSlotWrapper(props: AgendaTimeSlotWrapperProps): JSX.Element {
  const {
    children,
    resource,
    start,
    end,
    messages,
    disablePastDates,
    disablePartialSlots,
    firstOfStatus,
    status,
    components: { AvailableSlotHover, TimeSlot = ({ children }) => <React.Fragment>{children}</React.Fragment> },
  } = props

  const { isAD, lotacao } = useAgendaContext()
  const { hasAuthorizationAgendar } = useAgendaAuthorization(isAD)

  if (resource === undefined) return children as JSX.Element /* time slot label */

  const child: any = React.Children.only(children)
  const isPastDisabled = disablePastDates && isBefore(start, Date.now())
  const isPartialDisabled = disablePartialSlots && status === SlotStatus.PARTIALLY_OCCUPIED
  const isAvailable =
    !isPastDisabled &&
    (status === SlotStatus.AVAILABLE || (status === SlotStatus.PARTIALLY_OCCUPIED && !disablePartialSlots))
  const classNames = `${child?.props?.className} ${getClassNames(!!isAvailable, status, firstOfStatus)}`
  const tooltip = getTooltip(
    status,
    isPastDisabled,
    isPartialDisabled,
    hasAuthorizationAgendar(lotacao),
    messages.tooltips
  )

  return (
    <div css={styles}>
      <TimeSlot start={start} end={end}>
        <Tooltip placement='bottom' text={tooltip}>
          {React.cloneElement(child, {
            className: classNames,
            children: [
              child?.props?.children,

              ...[
                status === SlotStatus.UNAVAILABLE && firstOfStatus && (
                  <Text key={`${start}|${end}|1`} fontWeight='bold'>
                    {messages.unavailableSlot}
                  </Text>
                ),
                isPartialDisabled && (
                  <StatusInformation
                    key={`${start}|${end}|2`}
                    color={orange.c40}
                    icon='exclamationTriangleFilled'
                    text={messages.insufficientTime}
                  />
                ),
                AvailableSlotHover && isAvailable && (
                  <div className='rbc-time-slot-hover' key={`${start}|${end}|3`}>
                    <AvailableSlotHover start={start} end={end} />
                  </div>
                ),
              ].filter((e) => e),
            ],
          })}
        </Tooltip>
      </TimeSlot>
    </div>
  )
}

const getTooltip = (
  status: SlotStatus,
  isPastDisabled: boolean,
  isPartialDisabled: boolean,
  hasAuthorizationAgendar: boolean,
  tooltips: AgendaTooltips
): string => {
  if (status === SlotStatus.UNAVAILABLE) {
    return tooltips.unavailableSlot
  }

  if (isPartialDisabled) {
    return tooltips.insufficientTime
  }

  if (isPastDisabled) {
    return tooltips.disabledPastSlot
  }

  if (!hasAuthorizationAgendar) {
    return tooltips.unauthorized
  }

  return undefined
}

const getClassNames = (isAvailable: boolean, status: SlotStatus, isFirst: boolean) =>
  `rbc-time-slot-${!isAvailable ? 'disabled' : 'available'}
  ${isFirst ? 'first' : ''}
  ${status === SlotStatus.UNAVAILABLE ? 'rbc-time-slot-unavailable' : ''}
  ${status === SlotStatus.PARTIALLY_OCCUPIED ? 'rbc-time-slot-partially-occupied' : ''}`

const styles = css`
  .rbc-time-slot-unavailable {
    background-color: ${gray.c90};
    padding: 0.9rem 1rem;
    height: 100%;
    width: 100%;
  }

  .rbc-time-slot-disabled {
    cursor: not-allowed;
    z-index: 1;
    position: relative;
  }

  .rbc-time-slot-hover {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .rbc-time-slot-partially-occupied {
    display: flex;
    align-items: flex-end;
    padding: 0.2rem 0.8rem;

    background-color: ${orange.c90};

    &.first {
      align-items: flex-start;
    }
  }

  .rbc-time-slot-available {
    position: relative;
    z-index: 1;

    &:hover {
      .rbc-time-slot-hover {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`

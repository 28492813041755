import { Cell, Grid, HFlow, Theme, useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

import { StatusAtendimentoType } from '../list/listaAtendimentoStyles'

interface AtendimentoListingItemProps {
  status: StatusAtendimentoType
  time: React.ReactNode
  tags: React.ReactNode
  lotacao: React.ReactNode
  actions: React.ReactNode
  'data-testid'?: string
}

export default function AtendimentoListingItem(props: AtendimentoListingItemProps) {
  const { status, time, tags, lotacao, actions, 'data-testid': string } = props
  const { classes, css } = useStyles(createStyles)

  return (
    <div className={css(classes.container)} data-testid={string}>
      <div className={css(status.styleBox, classes.boxIcon)} />
      <Grid style={classes.content}>
        <Cell size={5} style={[classes.cell, classes.cellVerticalCenter]}>
          {time}
        </Cell>
        <Cell size={3} style={[classes.cell, classes.cellVerticalCenter]}>
          {tags}
        </Cell>
        <Cell size={4} alignSelf='center' style={classes.cell}>
          <HFlow alignItems='center' style={{ display: 'flex' }}>
            {lotacao}
            {actions}
          </HFlow>
        </Cell>
      </Grid>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    border: `1px solid ${theme.pallete.divider}`,
    minHeight: '3rem',
    display: 'flex',
    backgroundColor: theme.pallete.surface.main,
    borderRadius: 2,
  } as CSSProperties,
  content: {
    flex: 1,
    padding: '0.5rem 0',
  } as CSSProperties,
  cell: {
    padding: '0.25rem 0',
  } as CSSProperties,
  cellVerticalCenter: {
    display: 'flex',
    alignItems: 'center',
  } as CSSProperties,
  boxIcon: {
    width: '0.5rem',
    marginRight: '1rem',
  } as CSSProperties,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, SelectMenuItem, SelectMenuItemProps, Theme, useTheme } from 'bold-ui'
import { ReactComponent as PinFilled } from 'images/agenda/pin-filled.svg'

import { LotacaoAgendaSelectTipoGrupoCabecalho } from '../model-lotacaoAgendaSelectField'

interface LotacaoAgendaSelectGrupoCabecalhoProps extends SelectMenuItemProps {
  grupoTipo: LotacaoAgendaSelectTipoGrupoCabecalho
}

//TODO (RNG: #24561): Avaliar generalizar este componente para ser usado em outros selects (#21942)
export function LotacaoAgendaSelectGrupoCabecalho(props: LotacaoAgendaSelectGrupoCabecalhoProps) {
  const { grupoTipo, ...rest } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <SelectMenuItem css={styles.headerItem} {...rest}>
      {grupoTipo === LotacaoAgendaSelectTipoGrupoCabecalho.CABECALHO_FIXADOS ? (
        <HFlow alignItems='center' hSpacing={0.2}>
          <Heading level={4} color='primary'>
            Acesso rápido
          </Heading>
          <PinFilled css={styles.pinIcon} />
        </HFlow>
      ) : (
        <Heading level={4} color='primary'>
          Outros Profissionais
        </Heading>
      )}
    </SelectMenuItem>
  )
}

const createStyles = (theme: Theme) => ({
  headerItem: css`
    pointer-events: none;
    cursor: not-allowed;
    padding: 0.3rem 0.5rem;
    background-color: ${theme.pallete.primary.c90};
  `,
  pinIcon: css`
    fill: ${theme.pallete.primary.c40};
    height: 1rem;
    width: 1rem;
  `,
})

import moment from 'moment'
import { beforeEqualToday, cns, cpf, createValidator, ErrorObject, hora, range, required } from 'util/validation'

import AtestadoModel from '../components/model-atestado'

export const atestadoPadraoFormValidator = (possuiCpfCns: string, dataAtendimento: number) =>
  createValidator<AtestadoModel>(
    {
      data: [required, beforeEqualToday],
      hora: [required, hora],
      dias: [required, range(1, 999)],
    },
    (values: AtestadoModel, errors: ErrorObject<AtestadoModel>) => {
      const minDate = moment(dataAtendimento).subtract(90, 'd').format('YYYY-MM-DD')
      if (!errors.data && moment(values.data).isBefore(minDate)) {
        errors.data = 'Limite de 90 dias anteriores à data do atendimento.'
      } else if (moment(values.data).isAfter(moment(dataAtendimento))) {
        errors.data = 'Deve ser anterior ou igual à data do atendimento.'
      }

      if (!errors.cpfCns && !values.naoPossui && !possuiCpfCns) {
        errors.cpfCns = required(values.cpfCns)

        const [lengthCpf, cpfRule] = cpf
        const [lengthCns, cnsRule] = cns

        if (values?.cpfCns) {
          if (lengthCpf && cpfRule(values.cpfCns) && lengthCns && cnsRule(values.cpfCns)) {
            errors.cpfCns = 'CPF/CNS inválido.'
          }
        }
      }

      return errors
    }
  )

import { RadioGroupField, RadioGroupFieldProps, RadioOption } from 'components/form'
import { OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import React from 'react'

interface ConsistenciaAlimentoRadioGroupFieldProps
  extends Omit<RadioGroupFieldProps<OpcaoTipoPerguntaEnum>, 'options'> {}

export function ConsistenciaAlimentoRadioGroupField(props: ConsistenciaAlimentoRadioGroupFieldProps) {
  return <RadioGroupField options={options} {...props} />
}

const options: RadioOption<OpcaoTipoPerguntaEnum>[] = [
  { value: OpcaoTipoPerguntaEnum.EM_PEDACOS, label: 'Em pedaços' },
  { value: OpcaoTipoPerguntaEnum.AMASSADA, label: 'Amassada' },
  { value: OpcaoTipoPerguntaEnum.PASSADA_NA_PENEIRA, label: 'Passada na peneira' },
  { value: OpcaoTipoPerguntaEnum.LIQUIDIFICADA, label: 'Liquidificada' },
  { value: OpcaoTipoPerguntaEnum.SO_O_CALDO, label: 'Só o caldo' },
  { value: OpcaoTipoPerguntaEnum.NAO_SABE, label: 'Não sabe' },
]

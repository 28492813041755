import { InfoLabel } from 'bold-ui'
import React from 'react'

import { ResultadoEspecificoQualitativo } from '../../model-resultadosExames'
import { getResultadoExameQualitativoLabel } from '../../util-resultadosExames'

interface ResultadoExameQualitativoViewProps {
  value: ResultadoEspecificoQualitativo
}

export const ResultadoExameQualitativoView = (props: ResultadoExameQualitativoViewProps) => {
  const { value } = props

  return <InfoLabel title='Resultado'>{getResultadoExameQualitativoLabel(value)}</InfoLabel>
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { Fragment, useMemo } from 'react'

import { getCondicaoDente } from '../../../util-EvolucoesOdontologicasPlano'
import { condicoesQueAlteramDente, OdontogramaPosicaoProtese } from '../../odontograma-field/model'
import { CounterTooltip } from '../components/CounterTooltip'
import { DenteModel, DenteProps } from '../types/Dente'

type DenteEventsProps = {
  onClick?(ref?: SVGSVGElement): void
  onFocus?(): void
  onBlur?(): void
  disableStateEffects?: boolean
  disabled?: boolean
}

export const dentePossuiCondicoesQueAlteram = (dente: DenteModel) =>
  dente?.condicoes?.some((item) => condicoesQueAlteramDente.includes(item))

export const getDenteEvents = (props: DenteEventsProps) => {
  const ESPACO_KEY = 32

  return {
    focusable: !props.disableStateEffects && !props.disabled,
    tabIndex: props.disableStateEffects || props.disabled ? -1 : 0,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
    onClick: (ref) => !props.disabled && props.onClick && props.onClick(ref),
    onKeyDown: (e) => {
      if (e.keyCode === ESPACO_KEY || e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        !props.disabled && props.onClick && props.onClick()
      }
    },
  }
}

const getDenteTooltipText = (dente: DenteModel) => {
  if (!dente?.procedimentos?.length && !dente?.condicoes?.length) return null

  dente?.condicoes?.sort((a, b) => getCondicaoDente(a).title.localeCompare(getCondicaoDente(b).title))

  let res = ''

  res += dente?.condicoes?.map((condicao) => getCondicaoDente(condicao).title + '\n').join('') || ''

  res +=
    dente?.procedimentos?.length > 0
      ? dente.procedimentos.length + (dente?.procedimentos.length > 1 ? ' procedimentos' : ' procedimento')
      : ''

  return res
}

export interface DenteWrapperProps extends Omit<DenteProps, 'innerRef'> {
  placement?: OdontogramaPosicaoProtese
  children: JSX.Element
  readonly?: boolean
}

export function DenteWrapper(props: DenteWrapperProps) {
  const { placement, children, disabled, style, value, active, readonly } = props
  const classes = useMemo(() => denteWrapperStyles(placement), [placement])

  return (
    <div css={[classes.denteContainer, style]}>
      <Fragment>
        {!disabled && (!active || readonly) && (
          <CounterTooltip
            position={placement === 'inferior' ? { top: '3.25rem' } : { bottom: '3.25rem' }}
            value={(value?.condicoes?.length || 0) + (value?.procedimentos?.length || 0)}
            text={getDenteTooltipText(value)}
            style={css`
              white-space: pre-line;
              text-align: left;
              padding: 0.5rem;
            `}
          />
        )}

        {children}

        {(!active || readonly) && value?.condicoes?.length === 1 && !!getCondicaoDente(value?.condicoes[0]).initials && (
          <Text fontSize={1} fontWeight={700} style={classes.initials}>
            {getCondicaoDente(value?.condicoes[0]).initials}
          </Text>
        )}
      </Fragment>
    </div>
  )
}

const denteWrapperStyles = (placement: DenteWrapperProps['placement']) => ({
  denteContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  `,
  initials: css`
    position: absolute;
    ${placement === 'superior' ? 'bottom: 8px' : 'top: 8px'};
    pointer-events: none;
  `,
})

import { CidadaoSelectField, CidadaoSelectFieldModel, Form } from 'components/form'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'
import { useHistory } from 'react-router'

export function AgendaCidadaoForm() {
  const history = useHistory()
  const { analytics } = useFirebase()

  const handleSelectCidadao = (cidadao?: CidadaoSelectFieldModel) => {
    if (cidadao) {
      history.push(`/cidadao/${cidadao.id}/agendamentos`)
      analytics.logEvent('visualizar_agendamentos_cidadao_AG')
    }
  }

  return (
    <Form<CidadaoSelectFieldModel>
      render={() => (
        <CidadaoSelectField
          name='cidadao'
          label='Agenda do cidadão'
          onChange={handleSelectCidadao}
          menuMinWidth={500}
          popperProps={{ placement: 'top-start' }}
        />
      )}
    />
  )
}

import axios, { AxiosPromise } from 'axios'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'

const API_ENDPOINT = '/api/acompanhamentoSaude'
const EXPORTAR_CSV_ENDPOINT = `${API_ENDPOINT}/exportarCSV`

export class AcompanhamentoSaudeRelatorio {
  exportarCSV(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post(EXPORTAR_CSV_ENDPOINT, filter, { responseType: 'blob' })
  }

  exportarPDF(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post(`${API_ENDPOINT}/exportarPDF`, filter, { responseType: 'blob' })
  }

  exportarIvcfPDF(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post(`${API_ENDPOINT}/exportarIvcfPDF`, filter, { responseType: 'blob' })
  }

  downloadRelatorioOperacionalCrianca(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post(`${API_ENDPOINT}/relatorioOperacionalCrianca`, filter, { responseType: 'blob' })
  }

  downloadRelatorioOperacionalGestante(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post(`${API_ENDPOINT}/relatorioOperacionalGestante`, filter, { responseType: 'blob' })
  }

  downloadRelatorioOperacionalRiscoCardiovascular(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post(`${API_ENDPOINT}/relatorioOperacionalRiscoCardiovascular`, filter, {
      responseType: 'blob',
    })
  }
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { StatusInformation } from 'components/StatusInformation'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { MutableRefObject, useEffect, useMemo, useRef } from 'react'
import { Accordion } from 'react-accessible-accordion'
import { meta as soapMeta } from 'view/atendimentos/atendimento-individual/model'

interface IvcfResultadoAccordionProps {
  children: (ref: MutableRefObject<HTMLDivElement>, collapse: () => void) => JSX.Element
  isRegistradoAgora?: boolean
  disableEdit?: boolean
  disableEditTooltip?: string
}

const ACCORDION_UUID = 'respostasIvcf'

export function IvcfResultadoAccordion(props: IvcfResultadoAccordionProps) {
  const { children, isRegistradoAgora, disableEdit, disableEditTooltip } = props

  const theme = useTheme()
  const { analytics } = useFirebase()
  const styles = useMemo(() => createStyles(theme), [theme])

  const accordionRef = useRef(null)

  const { isExpanded, handleAccordionItemClick, resetExpandedItems } = useAccordionControl({})

  const {
    input: { value, onChange },
  } = usePecField({
    name: soapMeta.objetivo.atendimentosEspecificos.ivcf.absolutePath(),
    subscription: { value: true },
  })

  const isAccordionExpanded = isExpanded(ACCORDION_UUID)
  const accordionButtonTooltip = value.isEditing && 'Não é possível minimizar as respostas durante a edição.'
  const tooltipText = disableEdit && disableEditTooltip

  useEffect(() => {
    if (value.isEditing && !isAccordionExpanded) {
      handleAccordionItemClick(ACCORDION_UUID)
    }
  }, [isAccordionExpanded, value.isEditing, handleAccordionItemClick])

  const handleAccordionClick = () => {
    if (!value.isEditing) {
      handleAccordionItemClick(ACCORDION_UUID)
    }
  }

  const handleEdit = () => {
    onChange({ ...value, isEditing: true })
    analytics.logEvent('click_editar_ivcf')
    if (!isAccordionExpanded) {
      handleAccordionItemClick(ACCORDION_UUID)
    }
  }

  return (
    <div ref={accordionRef}>
      <Accordion allowZeroExpanded={true}>
        <AccordionCompositeItem
          uuid={ACCORDION_UUID}
          dangerouslySetExpanded={isAccordionExpanded}
          accordionButtonTooltip={accordionButtonTooltip}
          onClick={handleAccordionClick}
          isIconDisabled={value.isEditing}
          header={
            <HFlow hSpacing={0.5} alignItems='center' style={styles.accordionItemHeader}>
              <Heading level={4}>Respostas do instrumento</Heading>
              {isRegistradoAgora && <StatusInformation icon='clockOutline' text='Registrado agora' />}
            </HFlow>
          }
          headerButtons={
            <Tooltip text={tooltipText}>
              <Button size='small' skin='ghost' onClick={handleEdit} disabled={value.isEditing || disableEdit}>
                <Icon icon='penOutline' />
              </Button>
            </Tooltip>
          }
          body={<div onClick={(e) => e.stopPropagation()}>{children(accordionRef, resetExpandedItems)}</div>}
          accordionButtonContentStyle={styles.accordionButtonContent}
        />
      </Accordion>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  accordionButtonContent: css`
    background: ${theme.pallete.surface.main};
  `,
  accordionItemHeader: css`
    width: 100%;
  `,
})

import { Cell, Grid, HFlow, Icon } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { CheckboxField, FormDebouncedValueSpy, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import React from 'react'
import { Form } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { grupoCboSolicitacaoExame } from '../acessos'
import { SolicitacaoExameFilterModel } from './ExamesTable'
import { SolicitacaoExamesFilterPopper } from './ExamesTableFilterPopperForm'

export interface SolicitacaoExamesTableFilterProps {
  dataInicioAtendimento: Date
  initialValues?: SolicitacaoExameFilterModel
  onChange(values: SolicitacaoExameFilterModel): void
}

const path = metaPath<SolicitacaoExameFilterModel>()

export default function SolicitacaoExameTableFilter(props: SolicitacaoExamesTableFilterProps) {
  const { dataInicioAtendimento, initialValues, onChange } = props

  const renderPopper = (ctrl: PopperControls) => (
    <SolicitacaoExamesFilterPopper
      dataInicioAtendimento={dataInicioAtendimento}
      initialValues={initialValues}
      onSubmit={onChange}
      onClose={ctrl.close}
    />
  )

  const renderForm = () => {
    return (
      <>
        <FormDebouncedValueSpy onChange={(formState) => onChange(formState.values)} wait={300} />
        <Grid gap={0.5} alignItems='center' justifyContent='space-evenly'>
          <Cell size={5}>
            <TextField name={path.query} placeholder='Pesquise por solicitação' icon='zoomOutline' />
          </Cell>
          <Cell flexGrow={1} alignSelf='center'>
            <CheckPermission permission={grupoCboSolicitacaoExame.adicionar}>
              <CheckboxField name={path.somenteMeus} label='Ver somente as minhas solicitações' />
            </CheckPermission>
          </Cell>
          <Cell alignSelf='flex-end'>
            <PopperButton kind='primary' size='small' skin='default' placement='bottom-end' renderPopper={renderPopper}>
              <HFlow hSpacing={0.5}>
                <Icon icon='filterOutline' />
                Filtros
              </HFlow>
            </PopperButton>
          </Cell>
        </Grid>
      </>
    )
  }

  return <Form<SolicitacaoExameFilterModel> onSubmit={onChange} render={renderForm} initialValues={initialValues} />
}

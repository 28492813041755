import { Alert, Cell, FormControl, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { AccordionInformation } from 'components/accordion/AccordionInformation'
import { RadioField } from 'components/form/final-form/RadioField/RadioField'
import { RichTextField } from 'components/form/final-form/RichTextField/RichTextField'
import { useFlags } from 'config/useFlagsContext'
import { css } from 'emotion'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CondutaGroupEnum,
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  DevolutivaCuidadoCompartilhadoFieldsModel,
  DiscussaoCasoExecutanteFieldsModel,
  LabelEvolucaoCuidadoCompartilhadoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'

interface DevolutivaCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel>
  cidadaoFaleceu: boolean
}

export const DevolutivaCuidadoCompartilhadoField = (props: DevolutivaCuidadoCompartilhadoFieldProps) => {
  const { path, cidadaoFaleceu } = props

  const { CUIDADO_COMPARTILHADO_CIDADAO_OBITO } = useFlags()
  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const name = path.devolutiva

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<DevolutivaCuidadoCompartilhadoFieldsModel>
      name={name}
      pathCondutaGroup={path.condutaGroup}
      value={CondutaGroupEnum.DEVOLUTIVA_DISCUSSAO_CASO}
      isRadioHidden={CUIDADO_COMPARTILHADO_CIDADAO_OBITO && cidadaoFaleceu}
    >
      <VFlow>
        {CUIDADO_COMPARTILHADO_CIDADAO_OBITO && cidadaoFaleceu ? (
          <VFlow>
            <Alert type='warning' style={{ padding: '0.5rem', inlineSize: '-webkit-fill-available' }} inline>
              <strong>Cidadão veio a óbito, reconduza o cuidado para finalizá-lo.</strong>
              <br />A única conduta disponível é a Recondução do cuidado. Ele será finalizado automaticamente quando
              reconduzido.
            </Alert>
            <FormControl label={TIPO_DEVOLUTIVA_LABEL} required>
              <HFlow hSpacing={0.5} alignItems='center'>
                <RadioField
                  label={LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO]}
                  name={name.conduta}
                  value={CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO}
                  disabled
                />
                <Tooltip text={tooltipText} style={classes.tooltip}>
                  <Icon icon='infoCircleFilled' fill='normal' size={1} style={classes.infoIcon} />
                </Tooltip>
              </HFlow>
            </FormControl>
          </VFlow>
        ) : (
          <VFlow>
            <Text>
              Responda a pergunta relacionada ao relato de caso, caso faltem informações solicite aqui mais informações
              para o profissional solicitante.
            </Text>
            <Grid>
              <Cell size={10}>
                <AccordionInformation
                  type='normal'
                  uuid='discussaoCaso-info'
                  header='Como preencher o Tipo de devolutiva'
                >
                  <VFlow vSpacing={0.2}>
                    <Text>
                      <strong>Pedido de esclarecimento:</strong> Use esta opção se surgirem dúvidas ou faltarem
                      informações, solicitando esclarecimentos adicionais sobre o caso.
                    </Text>
                    <Text>
                      <strong>Teleconsultoria:</strong> Use esta opção para compartilhar perspectivas iniciais e
                      conhecimento de caso.
                    </Text>
                    <Text>
                      <strong>Recondução de cuidado:</strong> Use esta opção caso não esteja dentro do escopo do cuidado
                      compartilhado.
                    </Text>
                  </VFlow>
                </AccordionInformation>
              </Cell>
            </Grid>
            <FormControl label={TIPO_DEVOLUTIVA_LABEL} required>
              <HFlow>
                <VFlow vSpacing={0.5}>
                  <RadioField
                    label={
                      LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO]
                    }
                    name={name.conduta}
                    value={CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO}
                  />
                </VFlow>
                <VFlow vSpacing={0.5}>
                  <RadioField
                    label={LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA]}
                    name={name.conduta}
                    value={CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA}
                  />{' '}
                </VFlow>
                <VFlow vSpacing={0.5}>
                  <RadioField
                    label={LabelEvolucaoCuidadoCompartilhadoRecord[CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO]}
                    name={name.conduta}
                    value={CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO}
                  />
                </VFlow>
              </HFlow>
            </FormControl>
          </VFlow>
        )}
        <RichTextField
          name={name.resposta}
          label='Observações'
          placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
          maxLength={1000}
          required
        />
      </VFlow>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}

const TIPO_DEVOLUTIVA_LABEL = 'Tipo de devolutiva'

const tooltipText =
  'Recondução de Cuidado: Conduta utilizada na situação em que o caso não esteja dentro do escopo do cuidado compartilhado.'

const styles = (theme: Theme) => ({
  infoIcon: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
  tooltip: css`
    margin-bottom: 0.5rem;
  `,
})

import { TipoExameEnum } from 'graphql/types.generated'
import { createValidator, maxLength, required } from 'util/validation'

import { SolicitacaoExameModel } from './model'

export const solicitacaoExameValidator = createValidator<SolicitacaoExameModel>(
  {
    examesSolicitados: [required],
    justificativa: [required, maxLength(500)],
    observacao: [maxLength(250)],
  },
  (values, errors) => {
    if (values.tipoExame === TipoExameEnum.ALTO_CUSTO || values.tipoExame === TipoExameEnum.OCI) {
      errors.cid10 = required(values.cid10)
    }

    return errors
  }
)

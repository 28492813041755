import { isAfter } from 'date-fns'
import { TipoFrequencia, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import { round } from 'lodash'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { parseFraction, parseNumber } from 'util/number'
import {
  afterEqualTo,
  beforeEqualTo,
  composeRules,
  createValidator,
  ErrorObject,
  maxLength,
  minLength,
  minRange,
  range,
  required,
} from 'util/validation'
import { isEmpty } from 'util/validation/Util'

import { MedicamentoFormModel, TipoDoseEnum } from '../model-prescricao'
import { calculateDataFimTratamento, duracaoFrequencia, isCampoInvalido } from './utils-prescricao'
export const prescricaoFormValidator = (dataAtendimento: Date, isRegistroTardio: Boolean = false) =>
  createValidator<MedicamentoFormModel>(
    {
      viaAdministracao: [required],
      principioAtivoText: [maxLength(200)],
      qtDose: [required, minLength(1), maxLength(5), minRange(0.01)],
      qtDoseManha: [minLength(1), maxLength(5), minRange(0.01)],
      qtDoseTarde: [minLength(1), maxLength(5), minRange(0.01)],
      qtDoseNoite: [minLength(1), maxLength(5), minRange(0.01)],
      unidadeMedidaDose: [required],
      concentracaoText: [maxLength(100)],
      dataInicioTratamento: [required],
      quantidade: [required, range(1, 999)],
      duracao: [range(1, 999)],
      recomendacoes: [maxLength(500)],
    },
    (values: MedicamentoFormModel, errors: ErrorObject<MedicamentoFormModel>) => {
      if (!values) {
        return errors
      }
      errors.qtDose = errors.qtDose ?? validateFractionalField(values.qtDose)

      if (values.dataInicioTratamento) {
        const dataInicioTratamento = new Date(values.dataInicioTratamento)
        const dataAtual = new Date()
        if (dataInicioTratamento > dataAtual && isRegistroTardio) {
          errors.dataInicioTratamento = beforeEqualTo(values.dataInicioTratamento, dataAtual.toString(), 'data atual')
        }
        if (dataInicioTratamento < dataAtendimento) {
          errors.dataInicioTratamento = afterEqualTo(
            values.dataInicioTratamento,
            dateAsYyyyMmDd(dataAtendimento),
            'data do atendimento'
          )
        }
      }

      if (values.registroManual) {
        errors.principioAtivoText = composeRules([required, maxLength(200)])(values.principioAtivoText)
        errors.formaFarmaceutica = required(values.formaFarmaceutica)
        errors.tipoReceita = required(values.tipoReceita)
        errors.unidadeFornecimento = composeRules([required, minLength(2), maxLength(100)])(values.unidadeFornecimento)
      } else {
        !values.principioAtivoCombo && (errors.principioAtivoCombo = required(values.principioAtivoCombo))
      }
      if (UnidadeMedidaTempoEnum.INDETERMINADO !== values.escalaDuracao && !values.duracao) {
        errors.duracao = required(values.duracao)
      }

      if (values.usoContinuo) {
        errors.duracao = isCampoInvalido(!isEmpty(values.duracao))(values.duracao)
        errors.escalaDuracao = isCampoInvalido(values.escalaDuracao !== UnidadeMedidaTempoEnum.INDETERMINADO)(
          values.escalaDuracao
        )
      }

      if (values.tipoDose === TipoDoseEnum.UNICA) {
        errors.intervaloDose = isCampoInvalido(!isEmpty(values.intervaloDose))(values.intervaloDose)
        errors.frequenciaDose = isCampoInvalido(!isEmpty(values.frequenciaDose))(values.frequenciaDose)
        errors.unidadeMedidaTempoFrequenciaTurno = isCampoInvalido(!isEmpty(values.unidadeMedidaTempoFrequenciaTurno))(
          values.unidadeMedidaTempoFrequenciaTurno
        )
        errors.quantidadePeriodoFrequenciaTurno = isCampoInvalido(!isEmpty(values.quantidadePeriodoFrequenciaTurno))(
          values.quantidadePeriodoFrequenciaTurno
        )
        const duracao = typeof values.duracao === 'string' ? parseNumber(values.duracao) : values.duracao
        errors.duracao = isCampoInvalido(duracao !== 1)(values.duracao)
        errors.escalaDuracao = isCampoInvalido(values.escalaDuracao !== UnidadeMedidaTempoEnum.DIAS)(
          values.escalaDuracao
        )
      } else {
        if (TipoFrequencia.INTERVALO === values.tipoFrequencia) {
          errors.intervaloDose = range(1, 99)(values.intervaloDose)
          validateDurationWithinBounds(
            values.intervaloDose,
            'intervaloDose',
            true,
            values.dataInicioTratamento,
            values.unidadeMedidaTempoFrequenciaTurno,
            values.duracao,
            values.escalaDuracao,
            errors
          )
        } else if (TipoFrequencia.FREQUENCIA === values.tipoFrequencia) {
          errors.frequenciaDose = composeRules([required, range(1, 99)])(values.frequenciaDose)
          errors.quantidadePeriodoFrequenciaTurno = range(1, 99)(values.quantidadePeriodoFrequenciaTurno)

          validateDurationWithinBounds(
            values.quantidadePeriodoFrequenciaTurno,
            'quantidadePeriodoFrequenciaTurno',
            false,
            values.dataInicioTratamento,
            values.unidadeMedidaTempoFrequenciaTurno,
            values.duracao,
            values.escalaDuracao,
            errors
          )
        } else if (TipoFrequencia.TURNO === values.tipoFrequencia) {
          errors.turno = required(values.turno)
          errors.quantidadePeriodoFrequenciaTurno = composeRules([required, range(1, 99)])(
            values.quantidadePeriodoFrequenciaTurno
          )
          errors.unidadeMedidaTempoFrequenciaTurno = required(values.unidadeMedidaTempoFrequenciaTurno)

          validateDurationWithinBounds(
            values.quantidadePeriodoFrequenciaTurno,
            'quantidadePeriodoFrequenciaTurno',
            false,
            values.dataInicioTratamento,
            values.unidadeMedidaTempoFrequenciaTurno,
            values.duracao,
            values.escalaDuracao,
            errors
          )
        } else if (TipoFrequencia.FRACIONADO === values.tipoFrequencia) {
          errors.qtDoseManha = errors.qtDoseManha ?? validateFractionalField(values.qtDoseManha)
          errors.qtDoseTarde = errors.qtDoseTarde ?? validateFractionalField(values.qtDoseTarde)
          errors.qtDoseNoite = errors.qtDoseNoite ?? validateFractionalField(values.qtDoseNoite)
          errors.quantidadePeriodoFrequenciaTurno = composeRules([required, range(1, 99)])(
            values.quantidadePeriodoFrequenciaTurno
          )
          errors.unidadeMedidaTempoFrequenciaTurno = required(values.unidadeMedidaTempoFrequenciaTurno)

          const turnosPreenchidos = [!!values.qtDoseManha, !!values.qtDoseTarde, !!values.qtDoseNoite].filter(Boolean)
            .length
          const totalDoseFracionada = round(
            (parseFraction(values.qtDoseManha) ?? 0) +
              (parseFraction(values.qtDoseTarde) ?? 0) +
              (parseFraction(values.qtDoseNoite) ?? 0),
            2
          )
          const totalDose = parseNumber(values.qtDose) ?? 0

          if (turnosPreenchidos < 2) {
            errors.turnosError = 'É necessário preencher pelo menos dois turnos.'
          }

          if (totalDose !== totalDoseFracionada) {
            errors.totalDoseError = `Quantidade total das doses fracionadas diferente da dose total. Dose total: ${totalDose} | Doses fracionadas: ${totalDoseFracionada}`
          }

          validateDurationWithinBounds(
            values.quantidadePeriodoFrequenciaTurno,
            'quantidadePeriodoFrequenciaTurno',
            false,
            values.dataInicioTratamento,
            values.unidadeMedidaTempoFrequenciaTurno,
            values.duracao,
            values.escalaDuracao,
            errors
          )
        }
      }
      return errors
    }
  )

function validateFractionalField(value: string) {
  if (value?.includes('/')) {
    if (value.indexOf('/') > 1) {
      return 'Insira no máximo 1 carácter antes da barra.'
    } else if (value.endsWith('/')) {
      return 'Insira um valor após a barra.'
    } else if (value.endsWith('0')) {
      return 'Não é possível realizar uma divisão por zero.'
    }
  } else if (value?.includes(',')) {
    if (value.indexOf(',') > 2) {
      return 'Insira no máximo 2 caracteres antes da vírgula.'
    } else if (value.endsWith(',')) {
      return 'Insira um valor após a vírgula.'
    }
  }
}

export const validateDurationWithinBounds = (
  firstValue: number,
  firstValueKey: string,
  isIntervalo: boolean,
  dataInicioTratamento: MedicamentoFormModel['dataInicioTratamento'],
  unidadeMedidaTempoFrequenciaTurno: MedicamentoFormModel['unidadeMedidaTempoFrequenciaTurno'],
  duracao: MedicamentoFormModel['duracao'],
  escalaDuracao: MedicamentoFormModel['escalaDuracao'],
  errors: ErrorObject<MedicamentoFormModel>
) => {
  const isAfterDuration = isAfter(
    calculateDataFimTratamento(firstValue, dataInicioTratamento, unidadeMedidaTempoFrequenciaTurno),
    calculateDataFimTratamento(duracao, dataInicioTratamento, escalaDuracao)
  )

  if (isAfterDuration) {
    if (firstValue && duracao) {
      errors[firstValueKey] = isCampoInvalido(isAfterDuration)(firstValue)
      errors.duracao = isCampoInvalido(isAfterDuration)(duracao)
      errors.frequenciaDuracao = duracaoFrequencia(
        firstValue,
        dataInicioTratamento,
        unidadeMedidaTempoFrequenciaTurno,
        escalaDuracao
      )(duracao)
    }
  } else {
    errors[firstValueKey] = composeRules([required, range(1, 99)])(firstValue)
    !isIntervalo && (errors.unidadeMedidaTempoFrequenciaTurno = required(unidadeMedidaTempoFrequenciaTurno))
  }
}

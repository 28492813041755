/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Theme, useTheme } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { useAcessoConectesusRndsMutation } from 'graphql/hooks.generated'
import { Fragment } from 'react'

interface ButtonAcessoRndsProps {
  cnsProfissional: string
  cnsCidadao: string
  cnes: string
  municipioId: Number
  rndsAtiva: Boolean
  conexaoAtiva: Boolean
}

export const ButtonAcessoRnds = (props: ButtonAcessoRndsProps) => {
  const { cnsProfissional, cnes, cnsCidadao, municipioId, rndsAtiva, conexaoAtiva } = props
  const [gerarLinkAcessoMutation] = useAcessoConectesusRndsMutation()
  const handleRejection = useErrorHandler()
  const onClickRnds = () => {
    gerarLinkAcessoMutation({
      variables: {
        input: {
          cnes: cnes,
          cnsCidadao: cnsCidadao,
          cnsProfissional: cnsProfissional,
          municipio: municipioId,
        },
      },
    })
      .then((result) => {
        window.open(result.data.acessoConecteSus, '_blank')
      })
      .catch(handleRejection)
  }

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Fragment>
      {cnsCidadao && rndsAtiva && conexaoAtiva && (
        <Button kind='primary' size='medium' style={styles.button} onClick={onClickRnds}>
          <div css={styles.divSpan}>
            <span>SUS Digital </span>
            <span css={styles.profissionalText}>Profissional</span>
          </div>
        </Button>
      )}
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  button: css`
    padding: 0.3rem 1rem;
    line-height: 1.2rem;
  `,
  profissionalText: css`
    display: block;

    ${theme.breakpoints.up('lg')} {
      display: inline;
    }
  `,
  divSpan: css`
    display: block;
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DropdownItem, ExternalStyles, Tooltip } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { GrupoSdcDengueEnum } from 'graphql/types.generated'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'
import { useState } from 'react'
import { MetaPath } from 'util/metaPath'

import { PlanoFormModel } from '../PlanoForm'
import SdcCondutaModal from './SdcCondutaModal'

interface SdcCondutaPlanoButtonProps {
  name: MetaPath<PlanoFormModel>
  style?: ExternalStyles
}

export default function SdcCondutaPlanoButton(props: SdcCondutaPlanoButtonProps) {
  const { name, style: externalStyles } = props

  const [modalOpen, setModalOpen] = useState(false)

  const { setGrupoDengue, hasCondutaAplicada } = useAlertasSdc()

  const medicamentosFormField = usePecField({ name: name.prescricaoMedicamento.medicamentos })

  const hasMedicamentosAtendimentoAtual = medicamentosFormField?.input?.value?.length > 0

  return (
    <div css={externalStyles}>
      <SdcCondutaModal name={name} modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Tooltip text={tooltipCondutaRecomentada(hasCondutaAplicada, hasMedicamentosAtendimentoAtual)}>
        <DropdownButton
          text='Aplicar conduta recomendada'
          icon='angleDown'
          skin='default'
          kind='primary'
          disabled={hasCondutaAplicada || hasMedicamentosAtendimentoAtual}
          dropdownStyle={styles.dropdown}
          isInsideModal
        >
          <DropdownItem onClick={() => setGrupoDengue(GrupoSdcDengueEnum.A)}>Grupo A</DropdownItem>
        </DropdownButton>
      </Tooltip>
    </div>
  )
}

const tooltipCondutaRecomentada = (hasCondutaAplicada: boolean, hasMedicamento: boolean): string => {
  if (hasCondutaAplicada) {
    return 'A conduta recomendada já foi aplicada neste atendimento.'
  } else if (hasMedicamento) {
    return 'Não é possível aplicar a conduta porque há uma prescrição de medicamentos inserida. Remova a prescrição para aplicar a conduta recomendada.'
  }
  return null
}

const styles = {
  dropdown: css`
    width: 100%;
    text-align: left;
  `,
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Theme, useTheme, VFlow } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useCallback } from 'react'

import { SdcNotificationBadge } from './SdcNotificationBadge'

export interface SdcAvisosCardProps {
  alerta: AlertaSuporteDecisaoClinica
  onClick(AlertaSuporteDecisaoClinica): void
  selecionado: boolean
}

export const SdcAvisosCard = (props: SdcAvisosCardProps) => {
  const { alerta, onClick, selecionado = false } = props

  const mostrarBadgeNotificacao = !(alerta.visualizado || selecionado)
  const theme = useTheme()
  const styles = createStyles(theme, selecionado)

  const handleClick = useCallback(() => onClick(alerta), [onClick, alerta])

  return (
    <div css={styles.wrapper}>
      <HFlow
        hSpacing={0.5}
        alignItems='center'
        justifyContent='space-between'
        onClick={handleClick}
        style={styles.contentCard}
      >
        <SdcNotificationBadge visible={mostrarBadgeNotificacao} />
        <VFlow vSpacing={0}>
          <Ellipsis variant='h5' maxLines={2} fontWeight='bold'>
            {alerta.titulo}
          </Ellipsis>
          <Ellipsis variant='h5' maxLines={2} fontWeight='normal'>
            {alerta.subtitulo}
          </Ellipsis>
        </VFlow>
        <Icon icon='angleRight' style={styles.icone} />
      </HFlow>
    </div>
  )
}

const createStyles = (theme: Theme, selecionado: boolean) => ({
  wrapper: css`
    width: 100%;
    height: 7rem;
    cursor: pointer;
  `,
  contentCard: css`
    border: 1px solid ${selecionado ? theme.pallete.primary.main : theme.pallete.gray.c60};
    border-radius: 0.125rem;
    background-color: ${selecionado ? theme.pallete.status.info.c90 : theme.pallete.gray.c100};
    padding: 1rem 0.5rem;
    width: 100%;
    height: 100%;
  `,
  icone: css`
    margin-right: 0.5rem;
  `,
})

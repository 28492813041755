import { Action } from 'graphql/types.generated'
import { applyMask, masks } from 'util/mask'

import { ImovelItemModel } from '../ImoveisTerritorioTable'
import { FamiliasTerritorio } from '../model'

export const formatAddress = (
  tipoLogradouroNome: string,
  logradouro: string,
  numero: string,
  complemento: string,
  cep: string
) => {
  return `${tipoLogradouroNome ? `${tipoLogradouroNome} ` : ''}${logradouro}${numero ? `, ${numero}` : ''}${
    complemento ? ` - ${complemento}` : ''
  }${cep ? `, ${applyMask(cep, masks.cep)}` : ''}`.titleCase()
}

export const getNomesResponsaveisFamiliares = (familias: FamiliasTerritorio[]) => {
  const nomesResponsaveis = familias
    .map((familia) => familia.responsavel?.nomeSocial?.titleCase() ?? familia.responsavel?.nome.titleCase())
    .filter((nomeResponsavel) => !!nomeResponsavel)

  const nomesResponsaveisLength = nomesResponsaveis.length

  if (nomesResponsaveisLength === 0) return ['-']

  if (nomesResponsaveisLength > 2) {
    nomesResponsaveis.splice(2, nomesResponsaveisLength - 2)
    nomesResponsaveis[1] += ` + ${nomesResponsaveisLength - 2}`
  }

  return nomesResponsaveis.map((nome, idx, arr) => (idx === arr.length - 1 ? nome : `${nome}, `))
}

export const numeroTotalCidadaosImovel = (familias: FamiliasTerritorio[]) =>
  familias.reduce((totalCidadaos, familia) => (totalCidadaos += familia.cidadaosTerritorioBasico.length), 0)

export const getTooltipResponsaveisFamiliares = (familias: FamiliasTerritorio[]) =>
  familias
    .map((familia) => familia.responsavel?.nomeSocial?.titleCase() ?? familia.responsavel?.nome.titleCase())
    .filter((nomeResponsavel) => !!nomeResponsavel)
    .slice(2)
    .join(', ')

export const validAction = {
  enabled: true,
  hint: '',
}

export const disabledAction = {
  enabled: false,
  hint: '',
}

export const maxCheckedAction = (maxCheckedItems: number) => ({
  enabled: false,
  hint: `Seleção bloqueada. Por razões de segurança você pode reterritorializar até ${maxCheckedItems} imóveis por vez.`,
})

export const getMaximumCheckedAction = (
  checkedItems: Set<string>,
  imovelDto: ImovelItemModel,
  maxCheckedItems: number
): Action =>
  checkedItems.size >= maxCheckedItems && !checkedItems.has(imovelDto.id)
    ? maxCheckedAction(maxCheckedItems)
    : validAction

export const getPermissionReterritorializarAction = (imovelDto: ImovelItemModel): Action => {
  const actions = [imovelDto.permissaoReterritorializar]
  actions[0].enabled &&
    imovelDto.familias.forEach((familia) => {
      familia.cidadaosTerritorioBasico.forEach((cidadaos) => actions.push(cidadaos.permissaoReterritorializar))
    })

  return actions.find((action) => !action.enabled) ?? validAction
}

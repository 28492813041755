/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Link, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { SideMenu } from 'components/side-menu-navegation/SideMenu'
import { SideMenuAccordionItem } from 'components/side-menu-navegation/SideMenuAccordionItem'
import { SideMenuSublink } from 'components/side-menu-navegation/SideMenuSublink'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ReactComponent as EducaEsusApsImage } from 'images/novidades/educa_esus_aps.svg'
import { Accordion } from 'react-accessible-accordion'

import useNewsItems, { NewsItem, NewsVersion } from '../hooks/useNewsItems'
import { NewsSideMenuHeader } from './NewsSideMenuHeader'

const ALTURA_CABECALHO_TO_MENU = 125
const ALTURA_CABECALHO = 340
const MAX_WIDTH = 350

interface NewsSideMenuProps {
  currentVersion: string
  selectedVersion: string
  setSelectedSession: (version: NewsVersion) => void
}

export const NewsSideMenu = (props: NewsSideMenuProps) => {
  const { currentVersion, selectedVersion, setSelectedSession } = props
  const items = useNewsItems()
  const theme = useTheme()
  const styles = createStyles(theme)
  const { analytics } = useFirebase()

  const handleClick = (item: NewsItem) => {
    setSelectedSession(item.version)
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }

  const handleEducaEsusClick = () => {
    analytics.logEvent('educa_esus_aps_novidades')
  }

  return (
    <SideMenu alturaCabecalho={ALTURA_CABECALHO} alturaCabecalhoToMenu={ALTURA_CABECALHO_TO_MENU} maxWidth={MAX_WIDTH}>
      <NewsSideMenuHeader />
      <Accordion preExpanded={[currentVersion]} allowZeroExpanded>
        {items.map((item, index) => {
          return (
            <SideMenuAccordionItem
              title={item.title}
              index={index}
              totalItems={items.length}
              isMenuActive={selectedVersion === item.version}
              onClickAccordionButton={() => handleClick(item)}
              firstItemTagContent='Versão atual'
              hasHeader
              key={item.title}
            >
              {item.children.map((subItem) => {
                return <SideMenuSublink to={subItem.to} key={subItem.title} title={subItem.title} />
              })}
            </SideMenuAccordionItem>
          )
        })}
      </Accordion>
      <HFlow style={styles.educaCard}>
        <EducaEsusApsImage />

        <VFlow>
          <Heading level={4} color='primary'>
            Educa e-SUS APS
          </Heading>
          <Text>
            Oferta nacional de educação permanente em saúde digital para a atenção primária à saúde.{' '}
            <Link href='https://educaesusaps.medicina.ufmg.br/' target='_blank' onClick={handleEducaEsusClick}>
              Acessar plataforma
            </Link>
          </Text>
        </VFlow>
      </HFlow>
    </SideMenu>
  )
}

const createStyles = (theme: Theme) => ({
  educaCard: css`
    margin-top: 0.5rem;
    padding: 1rem;
    border: 0.0625rem solid ${theme.pallete.gray.c80};
    border-radius: ${theme.radius.input};
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, HFlow, Icon, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'

import { SdcNotificationBadge } from './SdcNotificationBadge'

interface SdcPopperAlert {
  alerta: AlertaSuporteDecisaoClinica
  onAlertaClick?(alerta: AlertaSuporteDecisaoClinica): void
}

export const SdcPopperAlert = (props: SdcPopperAlert) => {
  const { alerta, onAlertaClick } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const { analytics } = useFirebase()

  const handleAlertaClick = () => {
    analytics.logEvent('lupa_SDC')
    onAlertaClick?.(alerta)
  }

  return (
    <HFlow hSpacing={0.5} style={styles.alert}>
      <SdcNotificationBadge visible={!alerta.visualizado} />
      <VFlow vSpacing={0}>
        <Ellipsis variant='h5' maxLines={2} fontWeight='bold'>
          {alerta.titulo}
        </Ellipsis>
        <Ellipsis variant='h5' maxLines={2} fontWeight='normal'>
          {alerta.subtitulo}
        </Ellipsis>
      </VFlow>
      <Cell alignSelf='center'>
        <Button size='small' skin='ghost' onClick={handleAlertaClick}>
          <Tooltip text='Ler toda notificação'>
            <Icon icon='zoomOutline' />
          </Tooltip>
        </Button>
      </Cell>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  alert: css`
    padding: 0.75rem 0 0.75rem 0.5rem;
    border-bottom: 1px solid ${theme.pallete.divider};
    grid-template-columns: min-content 1fr min-content;
  `,
  subtitleText: css`
    color: ${theme.pallete.gray.c10};
  `,
})

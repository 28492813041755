import { EquipeSelectModel, UnidadeSaudeSelectModel } from 'components/form'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { CidadaoTerritorioBasico } from 'graphql/types.generated'

export const MAXIMUM_RETERRITORIALIZACAO_SIZE = 30

export interface ReterritorializacaoBuscaFormModel {
  bairro?: string
  logradouro?: string
  cep?: string
  unidadeSaude?: UnidadeSaudeSelectModel
  equipe?: EquipeSelectModel
  microarea?: string
}

export interface ReterritorializacaoAtualizacaoFormModel {
  novaMicroarea?: string
  unidadeSaude?: UnidadeSaudeSelectModel
  equipe?: EquipeSelectModel
  lotacao?: LotacaoAndEstagioSelectFieldModel
}

export interface FamiliasTerritorio {
  responsavel?: CidadaoTerritorioBasico
  cidadaosTerritorioBasico?: CidadaoTerritorioBasico[]
}

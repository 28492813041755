/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HLabel } from 'components/HLabel'
import { OrigemAtendimentoRecord } from 'types/enums'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { HOJE, humanizeDate } from 'util/date/humanize-date'

import { IdadeOrigemAtendimento } from '../../../model/model-historico'

interface HistoricoIdadeOrigemAtendimentoProps {
  idadeOrigemAtendimento: IdadeOrigemAtendimento
  isCompartilhamentoCuidado?: boolean
  dataInicioCompartilhamento?: LocalDate
  tipoParticipacaoCidadao?: string
  tipoVisita?: number
}

export default function HistoricoIdadeOrigemAtendimento(props: HistoricoIdadeOrigemAtendimentoProps) {
  const {
    idadeOrigemAtendimento,
    isCompartilhamentoCuidado = false,
    dataInicioCompartilhamento,
    tipoParticipacaoCidadao,
    tipoVisita,
  } = props

  const ocorreuEm = humanizeDate(idadeOrigemAtendimento.dataAtendimento)
  const tipoHistorico = isCompartilhamentoCuidado ? 'Conduta' : 'Atendimento'
  const titleAtendimentoOcorreu = ocorreuEm === HOJE ? `${tipoHistorico} ocorreu` : `${tipoHistorico} ocorreu há`

  return (
    <div css={styles.separator}>
      <HLabel title={`${titleAtendimentoOcorreu}:`} style={styles.inline}>
        {ocorreuEm}
      </HLabel>
      {dataInicioCompartilhamento && (
        <HLabel title='Início do compartilhamento' style={styles.inline}>
          {formatDateAndHoursMinutes(dataInicioCompartilhamento)}
        </HLabel>
      )}

      <HLabel title='Registros com origem:' style={styles.inline}>
        {OrigemAtendimentoRecord[idadeOrigemAtendimento.origemAtendimento]}
      </HLabel>

      {tipoVisita && (
        <HLabel title='Visita:' style={styles.inline}>
          {tipoVisita === 1 ? 'Ao cidadão' : 'Familiar'}
        </HLabel>
      )}

      <HLabel title='Idade do paciente no dia:' style={styles.inline}>
        {humanizeAge(idadeOrigemAtendimento.dataNascimentoCidadao, idadeOrigemAtendimento.dataAtendimento)}
      </HLabel>

      {tipoParticipacaoCidadao && (
        <HLabel title='Forma de participação do paciente:' style={styles.inline}>
          {tipoParticipacaoCidadao}
        </HLabel>
      )}
    </div>
  )
}

const styles = {
  separator: css`
    & > :not(:last-child):after {
      content: '|';
      margin: 0 0.25rem;
    }
  `,
  inline: css`
    display: inline;
  `,
}

import {
  useEsquemaCalendarioVacinalQuery,
  useRegistrosAplicadosVacinacaoQuery,
  useRegistrosDoseVacinacaoLazyQuery,
} from 'graphql/hooks.generated'
import { GrupoAlvoVacinacaoEnum, RegistroAplicado, SexoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { groupBy, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { calculateAge } from 'util/date/calculateAge'
import {
  convertEsquemaCalendarioVacinalQueryToProps,
  updateDosesCalendarioVacinalProps,
  updateDosesOutrosImunosProps,
} from 'view/atendimentos/atendimento-vacinacao/condicoes/converter'
import { InfoCidadaoVacina } from 'view/atendimentos/atendimento-vacinacao/model'
import { VacinacaoModal } from 'view/atendimentos/atendimento-vacinacao/VacinacaoModal'
import { CidadaoTabSectionModel } from 'view/cidadao/types/model-cidadaoTabSection'

import { VacinacaoPanel } from '../components/vacinacao/VacinacaoPanel'
import { InfoCardModalVacinacao } from './VacinacaoCalendarioView'
import { EstadoBotaoCalendario } from './VacinacaoView'

interface VacinacaoCalendarioReadOnlyViewProps {
  cidadao: CidadaoTabSectionModel
  dataReferencia: Instant
  estadoBotaoCalendario: EstadoBotaoCalendario
  grupoAlvoCidadao: GrupoAlvoVacinacaoEnum
  prontuarioId: string
  mostrarCalendarioCompleto: boolean
  setMostrarCalendarioCompleto: (value: boolean) => void
  sexoIdentidadeGenero: SexoEnum
}

export function VacinacaoCalendarioReadOnlyView(props: VacinacaoCalendarioReadOnlyViewProps) {
  const {
    cidadao,
    dataReferencia,
    grupoAlvoCidadao,
    prontuarioId,
    mostrarCalendarioCompleto,
    setMostrarCalendarioCompleto,
    estadoBotaoCalendario,
    sexoIdentidadeGenero,
  } = props
  const [infoCardModal, setInfoCardModal] = useState<InfoCardModalVacinacao>(null)
  const [isVacinacaoModalOpen, setIsVacinacaoModalOpen] = useState(false)

  const dosesEquivalentesAplicadas = new Map<ID, Set<ID>>()

  const {
    data: { registrosAplicadosVacinacao },
  } = useRegistrosAplicadosVacinacaoQuery({
    variables: {
      input: {
        prontuarioId: prontuarioId,
        cidadaoId: cidadao.id,
        cpf: cidadao.cpf,
      },
    },
  })

  const [
    registrosDoseLazyQuery,
    { data: registrosDoseVacinacaoQueryResult, loading },
  ] = useRegistrosDoseVacinacaoLazyQuery()

  const infoCidadaoVacina: InfoCidadaoVacina = {
    cidadaoCpf: cidadao.cpf,
    cidadaoId: cidadao.id,
    prontuarioId: prontuarioId,
  }

  const handleOnDoseClicked = (info: InfoCardModalVacinacao) => {
    setInfoCardModal(info)
    setIsVacinacaoModalOpen(true)
  }

  const { getServerTimeNow } = useServerTime()

  const { data } = useEsquemaCalendarioVacinalQuery({
    variables: {
      input: {
        sexo: sexoIdentidadeGenero,
        grupoAlvo: grupoAlvoCidadao,
        gestante: !isEmpty(cidadao.prontuario.preNatalAtivo),
        dataNascimento: cidadao.dataNascimento,
        dataAtendimento: dataReferencia,
      },
    },
    fetchPolicy: 'network-only',
  })

  const calendario = groupBy(data.esquemaCalendarioVacinal, 'imunobiologico.id')

  const handleVacinacaoModalClose = () => {
    setInfoCardModal(null)
    setIsVacinacaoModalOpen(false)
  }

  const idadeEmAnos = cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, Number(getServerTimeNow())).years

  const calendarioVacinalProps = convertEsquemaCalendarioVacinalQueryToProps(
    data,
    {
      ...cidadao,
      idadeEmAnos: idadeEmAnos,
    },
    dataReferencia,
    prontuarioId,
    calendario,
    registrosAplicadosVacinacao,
    dosesEquivalentesAplicadas
  )

  const clonedDoses = JSON.parse(JSON.stringify(calendarioVacinalProps))
  const calendarioDosesProps = updateDosesCalendarioVacinalProps(
    clonedDoses.abaCalendario,
    [],
    dosesEquivalentesAplicadas
  )

  const condicoesVacinacao = {
    gestante: !!cidadao?.prontuario?.preNatalAtivo,
    puerpera: cidadao?.prontuario?.puerpera,
    viajante: false,
    comunicanteHanseniase: false,
  }
  const outrasDosesProps = updateDosesOutrosImunosProps(
    clonedDoses.abaOutros,
    [],
    { ...cidadao, idadeEmAnos: idadeEmAnos },
    prontuarioId,
    calendario
  )

  return (
    <>
      <VacinacaoModal
        handleModalClose={handleVacinacaoModalClose}
        registrosDoseQueryResult={registrosDoseVacinacaoQueryResult}
        modalOpen={isVacinacaoModalOpen}
        setIsVacinacaoModalOpen={setIsVacinacaoModalOpen}
        loading={loading}
        infoCardModal={infoCardModal}
        condicoesVacinacao={condicoesVacinacao}
        dataAtendimento={dataReferencia}
        dataNascimento={cidadao.dataNascimento}
        registrosAplicados={registrosAplicadosVacinacao as RegistroAplicado[]}
        infoCidadaoVacina={infoCidadaoVacina}
        isAcompanhamentoVacinal
        readOnly
      />
      <VacinacaoPanel
        imunobiologicos={estadoBotaoCalendario === 'CALENDARIO_VACINAL' ? calendarioDosesProps : outrasDosesProps}
        mostrarCalendarioCompleto={mostrarCalendarioCompleto}
        setMostrarCalendarioCompleto={setMostrarCalendarioCompleto}
        registrosDoseLazyQuery={registrosDoseLazyQuery}
        handleOnDoseClicked={handleOnDoseClicked}
        outrosImunobiologicos={estadoBotaoCalendario !== 'CALENDARIO_VACINAL'}
        readOnly
      />
    </>
  )
}

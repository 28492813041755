import { Alert, ButtonGroup, Heading, Link, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { useRouter } from 'hooks/useRouter'
import * as React from 'react'
import { matchPath, Redirect } from 'react-router'

import { ButtonLink, PecSwitch, PrivateRoute } from '../../../../components/route'
import Permissions from '../../../../types/Permissions'
import { TransmissaoRecebimentoCnesView } from './cnesTable/TransmissaoRecebimentoCnesView'
import { TransmissaoHorarioGeracao } from './components/TransmissaoHorarioGeracao'
import { TransmissaoRecebimentoLoteBox } from './loteTable/TransmissaoRecebimentoLoteBox'

export interface TransmissaoConfiguracoesProps {}

export function TransmissaoRecebimentoGeralView() {
  const { match, history } = useRouter()

  const { PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED } = useFlags()

  const isLote = matchPath(history.location.pathname, `${match.url}/lote`)
  const isCnes = matchPath(history.location.pathname, `${match.url}/cnes`)
  const btnLinkStyles = {
    padding: '0.25rem 1rem',
  }

  return (
    <>
      <VFlow>
        <VFlow vSpacing={0.5}>
          <TransmissaoHorarioGeracao />

          {PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED && (
            <Alert type='info' inline>
              Agora é possível fazer envio de fichas por API. Verifique a{' '}
              <Link
                href='https://integracao.esusab.ufsc.br/ledi/documentacao/api_transmissao/api_transmissao_registro_LEDI.html'
                target='_blank'
                rel='noopener noreferrer'
              >
                <strong>documentação</strong>
              </Link>{' '}
              para saber como utilizar.
            </Alert>
          )}
        </VFlow>

        <VFlow vSpacing={0.5}>
          <Heading level={3}>Selecione tipo de agrupamento</Heading>
          <ButtonGroup>
            <ButtonLink
              to={`${match.url}/lote`}
              kind={isLote ? 'primary' : 'normal'}
              size='small'
              style={btnLinkStyles}
            >
              Lote
            </ButtonLink>
            <ButtonLink
              to={`${match.url}/cnes`}
              kind={isCnes ? 'primary' : 'normal'}
              size='small'
              style={btnLinkStyles}
            >
              CNES
            </ButtonLink>
          </ButtonGroup>
        </VFlow>
        <PecSwitch>
          <Redirect exact path={`${match.url}`} to={`${match.url}/lote`} />
          <PrivateRoute
            permission={Permissions.transmissaoDeDados.visualizarRecebimentoDeDados}
            exact
            path={`${match.url}/lote`}
            component={TransmissaoRecebimentoLoteBox}
          />
          <PrivateRoute
            permission={Permissions.transmissaoDeDados.visualizarRecebimentoDeDados}
            exact
            path={`${match.url}/cnes`}
            component={TransmissaoRecebimentoCnesView}
          />
        </PecSwitch>
      </VFlow>
    </>
  )
}

import api from 'api'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'
import { dateAsYyyyMmDdHhMm } from 'util/date/formatDate'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

import {
  TipoRelatorioOperacionalEnum,
  tipoRelatorioOperacionalToApiCallRecord,
} from './model-acompanhamentoCondicaoSaude'

export const downloadAcompanhamentoCondicaoSaudeCSV = (input: AcompanhamentoCondicaoSaudeQueryInput) => {
  return api.acompanhamentoSaude.exportarCSV(input).catch(defaultHandleError)
}

export const downloadAcompanhamentoCondicaoSaudePDF = (
  input: AcompanhamentoCondicaoSaudeQueryInput,
  isIvcfPrint: boolean
) => {
  if (isIvcfPrint) {
    return api.acompanhamentoSaude.exportarIvcfPDF(input).catch(defaultHandleError)
  } else {
    return api.acompanhamentoSaude.exportarPDF(input).catch(defaultHandleError)
  }
}

export const downloadRelatorio = (
  relatorio: TipoRelatorioOperacionalEnum,
  filter: AcompanhamentoCondicaoSaudeQueryInput
) => {
  const relatorioSelecionado = tipoRelatorioOperacionalToApiCallRecord[relatorio]
  const fileName = `${relatorioSelecionado.fileName}-${dateAsYyyyMmDdHhMm(new Date())}.pdf`

  return relatorioSelecionado
    .api(filter)
    .then((response) => {
      const pdf = new Blob([response.data], { type: 'application/pdf' })
      downloadFile(pdf, fileName)
    })
    .catch((error) => defaultHandleError(error))
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Paper, Text } from 'bold-ui'
import { blue, ColorScale, purple } from 'bold-ui/lib/styles/colors'
import { format } from 'date-fns'
import { useMemo } from 'react'

import { AgendaEventModel } from './model-agenda'

const defaultContent = (event: AgendaEventModel) => (
  <HFlow>
    <Text fontWeight='bold'>{event.title}</Text>
    <Icon icon='clockOutline' color={blue.c40} size={1} />
    <Text>
      {format(event.start, 'HH:mm')} às {format(event.end, 'HH:mm')}
    </Text>
  </HFlow>
)

export function AgendaEvent<TEvent extends AgendaEventModel>(
  props: React.PropsWithChildren<{ event: TEvent; color?: ColorScale; eventHasOverlap: boolean }>
) {
  const { event, color = purple, children } = props
  const styles = useMemo(() => createStyles(color), [color])

  return (
    <Paper elevation={10} style={styles}>
      {children || defaultContent(event)}
    </Paper>
  )
}

const createStyles = (color: ColorScale) => css`
  height: 100%;
  background: ${color.c90};
  border: 1px solid ${color.c60};
  padding: 1rem;
`

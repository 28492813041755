import { ExposedDropdown } from 'components/dropdown/ExposedDropdown'
import React from 'react'
import {
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

interface ListaCuidadoCompartilhadoSortDropdownProps {
  items: CuidadoCompartilhadoSortEnum[]
  filter: ListaCuidadoCompartilhadoFilterModel
  onChange(filter: ListaCuidadoCompartilhadoFilterModel): void
}

const LCCSortMap: { [key in CuidadoCompartilhadoSortEnum]: string } = {
  [CuidadoCompartilhadoSortEnum.STATUS_EXECUTANTE]: 'Classificação por status',
  [CuidadoCompartilhadoSortEnum.STATUS_SOLICITANTE]: 'Classificação por status',
  [CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE]: 'Classificação por prioridade',
  [CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE]: 'Classificação por prioridade',
}

export function ListaCuidadoCompartilhadoSortDropdown(props: ListaCuidadoCompartilhadoSortDropdownProps) {
  const { items, filter, onChange } = props

  const onChangeSelect = (sort: CuidadoCompartilhadoSortEnum) => {
    onChange({
      ...filter,
      pageParams: {
        ...filter.pageParams,
        sort,
      },
    })
  }

  return (
    <ExposedDropdown<CuidadoCompartilhadoSortEnum>
      label='Ordenar por:'
      selectedItem={filter.pageParams.sort}
      items={items}
      itemToString={itemToString}
      onChange={onChangeSelect}
    />
  )
}

const itemToString = (sort: CuidadoCompartilhadoSortEnum) => LCCSortMap[sort]

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { HFlow, Tag, Text, useTheme, VFlow } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { HLabel } from 'components/HLabel'
import { LoadingIndicator } from 'components/loading'
import { useDadosUltimosContatosQuery } from 'graphql/hooks.generated'
import { Sort } from 'graphql/types.generated'
import React from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import CiapCidLabel from 'view/atendimentos/detail/historico/components/accordion/header/components/CiapCidLabel'
import { HistoricoDadosClinicos } from 'view/atendimentos/detail/historico/components/accordion/header/title/HistoricoDadosClinicos'
import HistoricoHeaderEllipsis from 'view/atendimentos/detail/historico/components/HistoricoHeaderEllipsis'

import { CidadaoFolhaRosto } from '../../model'
import { convertContatoToModel } from './converter-ultimosContatos'
import { DetalhesUltimosContatosButton } from './detalhes/DetalhesUltimosContatosButton'

export interface FolhaRostoUltimosContatosPanelProps {
  atendimentoId?: ID
  unidadeSaudeCnes: string
  cidadao: Pick<CidadaoFolhaRosto, 'id' | 'cpf' | 'dataNascimento'>
}

export const FolhaRostoUltimosContatosPanel = (props: FolhaRostoUltimosContatosPanelProps) => {
  const {
    atendimentoId,
    cidadao: { id: cidadaoId, cpf: cidadaoCpf, dataNascimento: cidadaoDataNascimento },
    unidadeSaudeCnes,
  } = props
  const theme = useTheme()

  const { data, loading } = useDadosUltimosContatosQuery({
    variables: {
      input: {
        cidadaoId,
        cidadaoCpf,
        somenteMeusRegistros: false,
        unidadeSaudeCnes,
        sortDirection: Sort.DESC,
        excludeEscutaOrPreAtendimentoFromAtendProf: atendimentoId,
        pageParams: {
          size: 5,
          fetchPageInfo: false,
        },
      },
    },
    context: { newRequest: true },
  })

  if (loading) return <LoadingIndicator />
  const values = data?.historico?.content?.map(convertContatoToModel)

  const tagObservacao = (
    <Tag
      key='Observação'
      style={css`
        color: ${theme.pallete.gray.c10};
        background-color: ${theme.pallete.gray.c80};
        margin-left: 0.5rem;
      `}
    >
      Observação
    </Tag>
  )

  return (
    <VFlow vSpacing={0.25}>
      <Text variant='h3'>Últimos contatos</Text>
      {values?.map((contato, index) => (
        <Container key={index}>
          <ColorBar color={contato.barColor} />
          <VFlow
            vSpacing={0.25}
            style={css`
              margin: 0.5rem 0rem;
              flex-grow: 1;
            `}
          >
            <HFlow alignItems='center' justifyContent='space-between'>
              <HFlow hSpacing={0.5}>
                {React.cloneElement(contato.icon, { style: { height: '1.5rem', width: '1.5rem' } })}
                <div>
                  <Text style={styles.title}>{contato.title}</Text>
                  {!!contato.dataOrTurno && (
                    <Text style={styles.subTitle}>
                      {' '}
                      | {contato.dataOrTurno}
                      {contato.headerTags?.map((tag) => (
                        <Tag
                          key={tag.label}
                          style={css`
                            color: ${theme.pallete.primary.c100};
                            background-color: ${tag.color};
                            margin-left: 0.5rem;
                          `}
                        >
                          {tag.label}
                        </Tag>
                      ))}
                      {!!contato.hasObservacao && tagObservacao}
                    </Text>
                  )}
                </div>
                {!!contato.classificacaoRisco && (
                  <Tag style={contato.classificacaoRisco.style}>{contato.classificacaoRisco.descricao}</Tag>
                )}
                {!!contato.idadeGestacional && (
                  <Tag type='info'>
                    {`${'semana'.pluralizeAndConcatValue(contato.idadeGestacional.semanas)}
                    e ${'dia'.pluralizeAndConcatValue(contato.idadeGestacional.dias)}`}
                  </Tag>
                )}
              </HFlow>
              <DetalhesUltimosContatosButton
                contato={contato}
                dataNascimentoCidadao={cidadaoDataNascimento}
                style={css`
                  margin-right: 0.5rem;
                `}
              />
            </HFlow>
            {contato.contentTags?.map((tag) => (
              <Tag
                key={tag.label}
                style={css`
                  color: ${theme.pallete.primary.c100};
                  background-color: ${tag.color};
                `}
              >
                {tag.label}
              </Tag>
            ))}
            {!!contato?.dadosClinicos && <HistoricoDadosClinicos isTitleCase dadosClinicos={contato.dadosClinicos} />}
            {contato.isCuidadoCompartilhado && (
              <VFlow vSpacing={0}>
                <CiapCidLabel ciap={contato.ciap} cid10={contato.cid10} isBold />
              </VFlow>
            )}
            {!!contato?.examesRealizadosZika && (
              <HistoricoHeaderEllipsis tooltipText={stringGrouper(...contato.examesRealizadosZika)}>
                <HLabel title='Exames realizados:'>
                  {contato?.examesRealizadosZika && stringGrouper(...contato.examesRealizadosZika)}
                </HLabel>
              </HistoricoHeaderEllipsis>
            )}
          </VFlow>
        </Container>
      ))}
    </VFlow>
  )
}

const ColorBar = styled.div<{ color: string }, {}>`
  background-image: linear-gradient(to top, ${(props) => props.color}, ${(props) => props.color});
  min-width: 0.5rem;
  margin-right: 0.5rem;
  border-bottom-width: 20px;
`

const Container = styled.div`
  border: 1px solid ${gray.c80};
  min-height: 2.5rem;
  display: flex;
  border-radius: 2;
  background-color: ${gray.c90};
`

const styles = {
  title: css`
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
  `,
  subTitle: css`
    font-size: 1rem;
    font-style: normal;
  `,
}

import { ExternalStyles, Spinner, TableFooter, Text, Theme, useStyles, VFlow } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import React, { CSSProperties, Dispatch, SetStateAction } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { AccordionControls } from './useAccordionControl'

export interface AccordionListProps<T> {
  data: any
  loading: boolean
  onChange: Dispatch<SetStateAction<any>>
  onAccordionChange?(items: string[]): void
  header?: React.ReactNode
  subheader?: React.ReactNode

  accordionItemHeader(row: T): React.ReactNode

  accordionItemBody(row: T): React.ReactNode

  accordionItemButtons?(row: T): React.ReactNode

  loadingPlaceholder?: string
  noResultPlaceholder?: string
  paginate?: boolean
  allowMultipleExpanded?: boolean
  sizeOptions?: number[]

  controls?: Pick<AccordionControls, 'isExpanded' | 'handleAccordionItemClick'>
  style?: ExternalStyles
}

export default function AccordionList<T extends { id: ID }>(props: AccordionListProps<T>) {
  const {
    data,
    loading,
    onChange,
    onAccordionChange,
    header,
    subheader,
    accordionItemHeader,
    accordionItemBody,
    accordionItemButtons,
    loadingPlaceholder,
    noResultPlaceholder,
    allowMultipleExpanded = true,
    sizeOptions,
    paginate = true,
    controls,
    style,
  } = props
  const { classes, css } = useStyles(createStyles)

  const content = data?.content
  const isEmpty = () => !data || data.content?.length === 0

  const { onPageChange, onSizeChange, page, size, totalPages, totalElements } = usePagedTableProps({
    loading,
    result: data,
    onChange,
  })

  return (
    <VFlow vSpacing={0.5}>
      {header && <div>{header}</div>}
      {loading && (
        <div className={classes.loadingContainer}>
          <Spinner />
          {loadingPlaceholder}
        </div>
      )}
      {!loading && isEmpty() && <Text style={classes.noResultContainer}>{noResultPlaceholder}</Text>}

      <>
        {subheader && <div>{subheader}</div>}
        <Accordion
          allowZeroExpanded
          allowMultipleExpanded={allowMultipleExpanded}
          className={css(classes.accordion, style)}
          onChange={onAccordionChange}
        >
          <VFlow vSpacing={0.5}>
            {content?.map((row: T) => (
              <AccordionCompositeItem
                key={row.id}
                header={accordionItemHeader(row)}
                body={accordionItemBody(row)}
                headerButtons={accordionItemButtons ? accordionItemButtons(row) : null}
                dangerouslySetExpanded={controls?.isExpanded?.(row.id)}
                onClick={() => controls?.handleAccordionItemClick(row.id)}
              />
            ))}
          </VFlow>
        </Accordion>
      </>
      {!isEmpty() && paginate && (
        <TableFooter
          style={{ borderRadius: '2px' }}
          page={page}
          pageSize={size}
          totalPages={totalPages}
          totalElements={totalElements}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
          sizeOptions={sizeOptions}
        />
      )}
    </VFlow>
  )
}

AccordionList.defaultProps = {
  loadingPlaceholder: 'Carregando resultados',
  noResultPlaceholder: 'Nenhum registro encontrado',
} as Partial<AccordionListProps<any>>

const createStyles = (theme: Theme) => ({
  accordion: {
    display: 'flex',
    flexDirection: 'column',
  } as CSSProperties,
  loadingContainer: {
    color: theme.pallete.primary.main,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *:first-of-type': {
      marginRight: '0.5rem',
    },
  } as CSSProperties,
  noResultContainer: {
    color: theme.pallete.text.secondary,
    fontStyle: 'italic',
    textAlign: 'center',
  } as CSSProperties,
})

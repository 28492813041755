import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import {
  GarantiaAcessoOrigemRegistroEnum,
  GarantiaAcessoSortEnum,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import qs from 'qs'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import AtendimentoListing from 'view/atendimentos/components/AtendimentoListing'

import GarantiaAcessoForm from './cadastro/GarantiaAcessoForm'
import GarantiaAcessoTableFilter from './filtros/GarantiaAcessoTableFilter'
import GarantiaAcessoTable from './GarantiaAcessoTable'
import { GarantiaAcessoFilterModel } from './model-garantiaAcesso'

interface UrlParams {
  cidadaoId: string
}

export default function GarantiaAcessoView() {
  const location = useLocation()
  const {
    acesso: {
      unidadeSaude: { tipoEstabelecimento },
    },
  } = useAcessoLotacaoOrEstagio()
  const isCEO = tipoEstabelecimento === TipoEstabelecimentoEnum.CEO

  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 5,
  })

  const [filter, setFilter] = useState<GarantiaAcessoFilterModel>({
    nomeCpfCns: '',
    lotacoes: [],
    equipes: [],
    tiposAtendimento: [],
    tiposServico: [],
    origem: isCEO
      ? [GarantiaAcessoOrigemRegistroEnum.GARANTIA_ACESSO]
      : [GarantiaAcessoOrigemRegistroEnum.CUIDADO_COMPARTILHADO, GarantiaAcessoOrigemRegistroEnum.GARANTIA_ACESSO],
    ordenacao: GarantiaAcessoSortEnum.CLASSIFICACAO_DE_PRIORIDADE,
    periodo: null,
    pageParams: {
      size: 15,
    },
  })

  return (
    <>
      <Breadcrumb title='Garantia do acesso' />
      <AtendimentoListing
        title='Garantia do acesso'
        heading={<GarantiaAcessoForm cidadaoId={urlParams.cidadaoId} />}
        filter={<GarantiaAcessoTableFilter filter={filter} setFilter={setFilter} />}
        children={<GarantiaAcessoTable filter={filter} onChangeFilter={setFilter} />}
        footer={undefined}
      />
    </>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { RadioGroupField, RadioOption } from 'components/form'
import { OpcoesResultadoExameQualitativoEnum } from 'graphql/types.generated'

import { FactoryResultadoExameEspecificoFieldProps } from '../../model-resultadosExames'
import { getResultadoExameEspecificoProperties, getResultadoExameQualitativoLabel } from '../../util-resultadosExames'

export const ResultadoExameQualitativoField = (props: FactoryResultadoExameEspecificoFieldProps) => {
  const { name, exame } = props

  const { options } = getResultadoExameEspecificoProperties(exame)

  return (
    <RadioGroupField
      name={name}
      options={convertEnumsToRadioOptions(options as OpcoesResultadoExameQualitativoEnum[])}
      style={{
        hFlow: css`
          padding-top: 0;
        `,
      }}
      clearable
    />
  )
}

const convertEnumsToRadioOptions = (
  values: OpcoesResultadoExameQualitativoEnum[]
): RadioOption<OpcoesResultadoExameQualitativoEnum>[] =>
  values.map((value) => ({ value, label: getResultadoExameQualitativoLabel(value) }))

import { SimNaoEnum } from 'components/form'
import { IvcfPerguntaEnum, IvcfSubgrupoPerguntaEnum, RiscoVulnerabilidadeIvcfEnum } from 'graphql/types.generated'

import { IvcfAplicacaoModel, IvcfFieldModel } from './model-ivcf'

export function calculateIvcf(aplicacao: IvcfAplicacaoModel, idadeCidadaoEmAnos: number): IvcfFieldModel {
  let resultado: number = 0
  let riscoVulnerabilidade: RiscoVulnerabilidadeIvcfEnum = null
  let dimensoesAlteradas: IvcfSubgrupoPerguntaEnum[] = []
  const isEditing: boolean = false

  // ID: 1 - Idade
  if (idadeCidadaoEmAnos >= 75 && idadeCidadaoEmAnos < 85) {
    resultado++
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.IDADE)
  } else if (idadeCidadaoEmAnos >= 85) {
    resultado += 3
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.IDADE)
  }

  // ID: 2 - Percepção da Saúde
  if (aplicacao[IvcfPerguntaEnum.PERCEPCAO_SAUDE_PERGUNTA_1] === SimNaoEnum.NAO) {
    resultado++
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.PERCEPCAO_SAUDE)
  }

  // ID: 3, 4 e 5 - AVD INSTRUMENTAL
  if (
    aplicacao[IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_1] === SimNaoEnum.SIM ||
    aplicacao[IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_2] === SimNaoEnum.SIM ||
    aplicacao[IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_3] === SimNaoEnum.SIM
  ) {
    resultado += 4
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.AVD_INSTRUMENTAL)
  }

  // ID: 6 - AVD BASICA
  if (aplicacao[IvcfPerguntaEnum.AVD_BASICA_PERGUNTA_1] === SimNaoEnum.SIM) {
    resultado += 6
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.AVD_BASICA)
  }

  // ID: 7, 8 e 9 - Cognição
  if (aplicacao[IvcfPerguntaEnum.COGNICAO_PERGUNTA_1] === SimNaoEnum.SIM) {
    resultado++
  }
  if (aplicacao[IvcfPerguntaEnum.COGNICAO_PERGUNTA_2] === SimNaoEnum.SIM) {
    resultado++
  }
  if (aplicacao[IvcfPerguntaEnum.COGNICAO_PERGUNTA_3] === SimNaoEnum.SIM) {
    resultado += 2
  }
  if (
    [
      aplicacao[IvcfPerguntaEnum.COGNICAO_PERGUNTA_1],
      aplicacao[IvcfPerguntaEnum.COGNICAO_PERGUNTA_2],
      aplicacao[IvcfPerguntaEnum.COGNICAO_PERGUNTA_3],
    ].includes(SimNaoEnum.SIM)
  ) {
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.COGNICAO)
  }

  // ID: 10 e 11 - Humor
  if (aplicacao[IvcfPerguntaEnum.HUMOR_PERGUNTA_1] === SimNaoEnum.SIM) {
    resultado += 2
  }
  if (aplicacao[IvcfPerguntaEnum.HUMOR_PERGUNTA_2] === SimNaoEnum.SIM) {
    resultado += 2
  }

  if (
    [aplicacao[IvcfPerguntaEnum.HUMOR_PERGUNTA_1], aplicacao[IvcfPerguntaEnum.HUMOR_PERGUNTA_2]].includes(
      SimNaoEnum.SIM
    )
  ) {
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.HUMOR)
  }

  // ID: 12 e 13 - Alcance, preensao e pinca
  if (aplicacao[IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_1] === SimNaoEnum.SIM) {
    resultado++
  }
  if (aplicacao[IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_2] === SimNaoEnum.SIM) {
    resultado++
  }

  if (
    [
      aplicacao[IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_1],
      aplicacao[IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_2],
    ].includes(SimNaoEnum.SIM)
  ) {
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.ALCANCE_PREENSAO_PINCA)
  }

  // ID: 14.1.1, 14.1.2, 14.2, 14.3, 14.4 - Capacidade aerobica e/ou muscular
  if (
    aplicacao[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_1] === SimNaoEnum.SIM ||
    aplicacao[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_2] === SimNaoEnum.SIM ||
    aplicacao[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_3] === SimNaoEnum.SIM ||
    aplicacao[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_4] === SimNaoEnum.SIM ||
    aplicacao[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_5] === SimNaoEnum.SIM
  ) {
    resultado += 2
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.CAPACIDADE_AEROBICA_MUSCULAR)
  }

  // ID: 15 - Marcha
  if (aplicacao[IvcfPerguntaEnum.MARCHA_PERGUNTA_1] === SimNaoEnum.SIM) {
    resultado += 2
  }
  // ID: 16 - Marcha
  if (aplicacao[IvcfPerguntaEnum.MARCHA_PERGUNTA_2] === SimNaoEnum.SIM) {
    resultado += 2
  }
  if (
    [aplicacao[IvcfPerguntaEnum.MARCHA_PERGUNTA_1], aplicacao[IvcfPerguntaEnum.MARCHA_PERGUNTA_2]].includes(
      SimNaoEnum.SIM
    )
  ) {
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.MARCHA)
  }

  // ID: 17 - Continencia esfincteriana
  if (aplicacao[IvcfPerguntaEnum.CONTINENCIA_PERGUNTA_1] === SimNaoEnum.SIM) {
    resultado += 2
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.CONTINENCIA_ESFINCTERIANA)
  }

  // ID: 18 - Visao
  if (aplicacao[IvcfPerguntaEnum.VISAO_PERGUNTA_1] === SimNaoEnum.SIM) {
    resultado += 2
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.VISAO)
  }

  // ID: 19 - Audicao
  if (aplicacao[IvcfPerguntaEnum.AUDICAO_PERGUNTA_1] === SimNaoEnum.SIM) {
    resultado += 2
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.AUDICAO)
  }

  // ID: 20.1, 20.2 e 20.3 - Comorbidade múltipla
  if (
    aplicacao[IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_1] === SimNaoEnum.SIM ||
    aplicacao[IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_2] === SimNaoEnum.SIM ||
    aplicacao[IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_3] === SimNaoEnum.SIM
  ) {
    resultado += 4
    dimensoesAlteradas.push(IvcfSubgrupoPerguntaEnum.COMORBIDADE_MULTIPLA)
  }

  if (resultado < 7) {
    riscoVulnerabilidade = RiscoVulnerabilidadeIvcfEnum.BAIXO
  } else if (resultado < 15) {
    riscoVulnerabilidade = RiscoVulnerabilidadeIvcfEnum.MODERADO
  } else {
    riscoVulnerabilidade = RiscoVulnerabilidadeIvcfEnum.ALTO
  }

  return {
    aplicacao,
    resultado,
    riscoVulnerabilidade,
    dimensoesAlteradas,
    isEditing,
  }
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CheckboxProps, Select, SelectProps, useTheme } from 'bold-ui'
import { Checkbox, SelectEmptyItem, SelectHelperMenuItem, useLocale } from 'bold-ui'
import {
  DEFAULT_SELECT_PAGE_PARAM,
  DEFAULT_SELECT_SIZE,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { LotacoesAgendaSelectDocument } from 'graphql/hooks.generated'
import {
  AcessoCbo,
  LotacaoAgendaSelectFragment,
  LotacoesAgendaSelectQuery,
  LotacoesAgendaSelectQueryVariables,
} from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { emptyArray } from 'util/array'

import { LotacaoAgendaSelectGrupoCabecalho } from './cabecalho/LotacaoAgendaSelectGrupoCabecalho'
import { LotacaoAgendaSelectComAcessoRapidoItem } from './item/LotacaoAgendaSelectComAcessoRapidoItem'
import { LotacaoAgendaSelectGrupoVazioItem } from './item/LotacaoAgendaSelectGrupoVazioItem'
import { LotacaoAgendaSelectProfissionalItem } from './item/LotacaoAgendaSelectProfissionalItem'
import { LotacaoAgendaSelectSemAcessoRapidoItem } from './item/LotacaoAgendaSelectSemAcessoRapidoItem'
import {
  isLotacaoAgendaSelectModel,
  LotacaoAgendaSelectItemModel,
  LotacaoAgendaSelectTipoGrupoCabecalho,
  LotacaoAgendaSelectTipoGrupoVazio,
} from './model-lotacaoAgendaSelectField'
export type LotacaoAgendaSelectModel = LotacaoAgendaSelectFragment

export interface LotacaoAgendaSelectProps
  extends Omit<SelectProps<LotacaoAgendaSelectItemModel>, 'items' | 'itemToString'> {
  loadItemsOnOpen?: boolean
  isAgendaAd: boolean
  somenteCadastrarAgendamento?: boolean
  cbosAcesso?: AcessoCbo[]
  excludeProfissionaisIds?: ID[]
  includeEquipesComVinculo?: boolean
  hasAgendaFixada?: boolean
  hideAcessoRapido?: boolean
}

LotacaoAgendaSelect.defaultProps = {
  loadItemsOnOpen: true,
  excludeProfissionaisIds: emptyArray,
  isAgendaAd: false,
  includeEquipesComVinculo: false,
  hasAgendaFixada: false,
  hideAcessoRapido: false,
} as Partial<LotacaoAgendaSelectProps>

const MIN_INPUT_LENGTH = 2

export function LotacaoAgendaSelect(props: LotacaoAgendaSelectProps) {
  const {
    loadItemsOnOpen,
    isAgendaAd,
    somenteCadastrarAgendamento,
    cbosAcesso,
    excludeProfissionaisIds,
    includeEquipesComVinculo,
    hasAgendaFixada,
    hideAcessoRapido,
    ...rest
  } = props

  const locale = useLocale()
  const [incluirInativasComAgendamentosFuturos, setIncluirInativasComAgendamentosFuturos] = useState(false)

  // Adicionado pois a query deve desconsiderar o filtro da combo nos casos em que o checkbox de lotações inativas for alterado
  const [filterByInput, setFilterByInput] = useState(true)

  const { analytics } = useFirebase()
  useEffect(() => {}, [analytics, incluirInativasComAgendamentosFuturos])

  const handleIncluirInativasCheckboxChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const checked = ev.target.checked
      if (checked) analytics.logEvent('incluir_lotacoes_inativas_AG')
      setIncluirInativasComAgendamentosFuturos(checked)
      loadItemsOnOpen && setFilterByInput(false)
    },
    [analytics, loadItemsOnOpen]
  )

  const skip = useCallback((inputString) => !loadItemsOnOpen && inputString?.trim()?.length < MIN_INPUT_LENGTH, [
    loadItemsOnOpen,
  ])
  const variables = useCallback(
    (inputQuery: string) => {
      const apenasFixadas = (inputQuery?.length ?? 0) < MIN_INPUT_LENGTH && hasAgendaFixada
      const query = filterByInput ? inputQuery : null
      return {
        input: {
          query,
          isAgendaAd,
          somenteCadastrarAgendamento,
          incluirInativasComAgendamentosFuturos,
          cbosAcesso,
          excludeProfissionaisIds,
          includeEquipesComVinculo,
          pageParams,
        },
        inputAgendaFixada: { query, excludeProfissionaisIds },
        apenasFixadas,
        semFixadas: hideAcessoRapido,
      }
    },
    [
      hasAgendaFixada,
      filterByInput,
      isAgendaAd,
      somenteCadastrarAgendamento,
      incluirInativasComAgendamentosFuturos,
      cbosAcesso,
      excludeProfissionaisIds,
      includeEquipesComVinculo,
      hideAcessoRapido,
    ]
  )

  const { skipping, selectProps } = useAsyncQuerySelect<
    LotacaoAgendaSelectItemModel,
    LotacoesAgendaSelectQuery,
    LotacoesAgendaSelectQueryVariables
  >({
    query: LotacoesAgendaSelectDocument,
    extractItems: hideAcessoRapido ? extractItemsSemAcessoRapido : extractItemsComAcessoRapido,
    variables,
    skip,
    debounceTime: 500,
    refetchOnVariablesChange: true,
  })

  return (
    <Select<LotacaoAgendaSelectItemModel>
      renderItem={renderItem}
      itemToString={itemToString}
      placeholder='Busque um profissional pelo seu nome, CNS, equipe ou CBO'
      onInput={() => setFilterByInput(true)}
      components={{
        Item: (props) =>
          hideAcessoRapido ? (
            <LotacaoAgendaSelectSemAcessoRapidoItem {...props} selectMaxItems={DEFAULT_SELECT_SIZE} />
          ) : (
            <LotacaoAgendaSelectComAcessoRapidoItem {...props} selectMaxItems={DEFAULT_SELECT_SIZE} />
          ),
        EmptyItem: () =>
          !loadItemsOnOpen ? (
            <SelectHelperMenuItem>
              {skipping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
            </SelectHelperMenuItem>
          ) : (
            <SelectEmptyItem />
          ),
        AppendItem: () =>
          !somenteCadastrarAgendamento && (
            <IncluirInativasComAgendamentosFuturosCheckbox
              checked={incluirInativasComAgendamentosFuturos}
              onChange={handleIncluirInativasCheckboxChange}
            />
          ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}

const IncluirInativasComAgendamentosFuturosCheckbox = (props: Pick<CheckboxProps, 'checked' | 'onChange'>) => {
  const theme = useTheme()
  return (
    <div
      data-testid='incluir-inativas-checkbox-container'
      css={css`
        padding: 0.5rem;
        background-color: ${theme.pallete.surface.background};
      `}
    >
      <Checkbox label='Mostrar lotações inativas com agendamentos futuros' {...props} />
    </div>
  )
}

const extractItemsComAcessoRapido = (data: LotacoesAgendaSelectQuery): LotacaoAgendaSelectItemModel[] => {
  const fixados = data?.lotacoesFixadasAgenda ?? []
  const outros = data?.lotacoesAgenda?.content ?? []
  const outrosSemFixados = outros.filter((item) => !fixados.some((fixado) => fixado.id === item.id))

  return [
    LotacaoAgendaSelectTipoGrupoCabecalho.CABECALHO_FIXADOS,
    ...(fixados.length > 0 ? fixados : [LotacaoAgendaSelectTipoGrupoVazio.FIXADOS_VAZIO]),
    LotacaoAgendaSelectTipoGrupoCabecalho.CABECALHO_OUTROS,
    ...(outrosSemFixados.length > 0 ? outrosSemFixados : [LotacaoAgendaSelectTipoGrupoVazio.OUTROS_VAZIO]),
  ]
}

const extractItemsSemAcessoRapido = (data: LotacoesAgendaSelectQuery): LotacaoAgendaSelectItemModel[] => {
  const outros = data?.lotacoesAgenda?.content ?? []
  return [...(outros.length > 0 ? outros : [LotacaoAgendaSelectTipoGrupoVazio.VAZIO])]
}

const itemToString = (item: LotacaoAgendaSelectItemModel) =>
  isLotacaoAgendaSelectModel(item) ? item.profissional.nome : item

const renderItem = (item: LotacaoAgendaSelectItemModel) => {
  switch (item) {
    case LotacaoAgendaSelectTipoGrupoVazio.FIXADOS_VAZIO:
    case LotacaoAgendaSelectTipoGrupoVazio.OUTROS_VAZIO:
    case LotacaoAgendaSelectTipoGrupoVazio.VAZIO:
      return <LotacaoAgendaSelectGrupoVazioItem grupoTipo={item} />
    case LotacaoAgendaSelectTipoGrupoCabecalho.CABECALHO_FIXADOS:
    case LotacaoAgendaSelectTipoGrupoCabecalho.CABECALHO_OUTROS:
      return <LotacaoAgendaSelectGrupoCabecalho grupoTipo={item} />
    default:
      return <LotacaoAgendaSelectProfissionalItem item={item} />
  }
}

const pageParams = {
  ...DEFAULT_SELECT_PAGE_PARAM,
  size: DEFAULT_SELECT_SIZE + 1,
  sort: ['nome'],
}

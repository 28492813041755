import { Checkbox, Tooltip } from 'bold-ui'
import { ReceitaMedicamentoSdcDto } from 'graphql/types.generated'
import React from 'react'

interface PrescricaoProps {
  prescricao: ReceitaMedicamentoSdcDto
  handleTogglePrescricao: (prescricao: ReceitaMedicamentoSdcDto) => void
}

export function PrescricaoCheckbox(props: PrescricaoProps) {
  const { prescricao, handleTogglePrescricao } = props
  const { medicamentoCatmat, motivoRestricaoPrescricao, prescrever } = prescricao
  const { principioAtivo, concentracao } = medicamentoCatmat.medicamento

  return (
    <Tooltip text={motivoRestricaoPrescricao}>
      <span>
        <Checkbox
          label={`${principioAtivo} ${concentracao}`}
          disabled={!prescrever}
          onChange={() => handleTogglePrescricao(prescricao)}
        />
      </span>
    </Tooltip>
  )
}

import { HorarioAgendaSelectFieldModel } from 'components/form'
import { add, isBefore, parseISO, startOfDay } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { isAfterOrEqual } from 'util/date/compare'
import { dateAsDdMmYyyy } from 'util/date/formatDate'
import { createValidator, email, maxLength, required, telefone } from 'util/validation'

import {
  AgendamentoConsultaCompartilhadaModel,
  AgendamentoConsultaModel,
  AgendamentoReservaModel,
  CidadaoAgendamentoConsultaCompartilhada,
  ContatoCidadaoAgendamento,
} from '../model-agenda'

const validarDataAgendamento = (data: LocalDate, now: Date, error: Record<string, any>) => {
  const hoje = startOfDay(now)
  const daqui12MesesAnd1dia = add(hoje, { months: 12, days: 1 })

  if (isAfterOrEqual(parseISO(data), daqui12MesesAnd1dia)) {
    error.data = `Deve ser anterior à ${dateAsDdMmYyyy(daqui12MesesAnd1dia)}`
    error[FORM_ERROR] = 'Não é possível cadastrar agendamentos com mais de 12 meses de antecedência.'
  }
}

const horarioReservaValidator = createValidator<HorarioAgendaSelectFieldModel>(
  {
    inicial: [required],
    final: [required],
  },
  (values, error) => {
    const valorHoraInicial = Number(values?.inicial)
    const valorHoraFinal = Number(values?.final)
    if (valorHoraInicial && valorHoraFinal && valorHoraInicial >= valorHoraFinal)
      error.final = 'Deve ser posterior ao horário inicial.'

    return error
  }
)

const horarioConsultaValidator = createValidator<HorarioAgendaSelectFieldModel>({
  inicial: [required],
})

export const agendamentoReservaValidator = (now: Date) =>
  createValidator<AgendamentoReservaModel>(
    {
      data: [required],
      horario: horarioReservaValidator,
      motivoReserva: [required],
      observacoes: [maxLength(200)],
    },
    (values, error) => {
      validarDataAgendamento(values.data, now, error)

      if (!error.data && isBefore(values.horario?.inicial, now)) {
        error[FORM_ERROR] = 'Não é possível cadastrar agendamentos em horários passados.'
      }

      return error
    }
  )

const contatoCidadaoValidator = (emailRequired: boolean, telefoneCelularRequired: boolean) =>
  createValidator<ContatoCidadaoAgendamento>(
    {
      email: [email, maxLength(50)],
      telefoneCelular: [telefone],
    },
    (values, error) => {
      if (emailRequired) {
        error.email = required(values?.email) || error.email
      }
      if (telefoneCelularRequired) {
        error.telefoneCelular = required(values?.telefoneCelular) || error.telefoneCelular
      }

      return error
    }
  )

export const agendamentoConsultaValidator = (now: Date) =>
  createValidator<AgendamentoConsultaModel>(
    {
      data: [required],
      horario: horarioConsultaValidator,
      observacoes: [maxLength(200)],
      cidadao: [required],
    },
    (values, error) => {
      validarDataAgendamento(values.data, now, error)

      error.contatoCidadao = contatoCidadaoValidator(
        !!values?.cidadao?.contato.email,
        !!values?.cidadao?.contato.telefoneCelular
      )(values?.contatoCidadao)

      return error
    }
  )

const cidadaoAgendamentoCompartilhadoValidator = createValidator<CidadaoAgendamentoConsultaCompartilhada>(
  {},
  (values, error) => {
    if (values?.cidadao) {
      error.contato = contatoCidadaoValidator(
        !!values?.cidadao?.contato.email,
        !!values?.cidadao?.contato.telefoneCelular
      )(values?.contato)
    }

    return error
  }
)

export const agendamentoConsultaCompartilhadaValidator = (now: Date, isCuidadoCompartilhado?: boolean) =>
  createValidator<AgendamentoConsultaCompartilhadaModel>(
    {
      data: [required],
      observacoes: [maxLength(200)],
      lotacaoConvidada: !isCuidadoCompartilhado && [required],
      cidadaoParticipante: cidadaoAgendamentoCompartilhadoValidator,
    },
    (values, error) => {
      if (!error.data) {
        error.horario = { inicial: required(values.horario?.inicial) }
        validarDataAgendamento(values.data, now, error)
      }

      return error
    }
  )

export const agendamentoConsultaPresencialCuidadoCompartilhadoValidator = (now: Date) =>
  createValidator<AgendamentoConsultaModel>(
    {
      data: [required],
      observacoes: [maxLength(200)],
    },
    (values, error) => {
      if (!error.data) {
        error.horario = { inicial: required(values.horario?.inicial) }
        validarDataAgendamento(values.data, now, error)
      }

      return error
    }
  )

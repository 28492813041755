/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme, VFlow } from 'bold-ui'

interface SdcSecaoAlertaProps {
  titulo?: string
  conteudo?: string
}

export const SdcSecaoAlerta = (props: SdcSecaoAlertaProps) => {
  const { titulo, conteudo } = props
  const theme = useTheme()

  if (!conteudo) return null

  const styles = createStyles(theme)

  return (
    <VFlow vSpacing={0.25}>
      {titulo && <Text variant='h5'>{titulo}</Text>}
      <Text component='p' dangerouslySetInnerHTML={{ __html: conteudo }} style={styles.conteudoTextHtml} />
    </VFlow>
  )
}
const createStyles = (theme: Theme) => ({
  conteudoTextHtml: css`
    p:not(:first-of-type) {
      margin-top: 0.5rem;
    }

    p + ul {
      margin-top: 0.25rem;
    }

    ul {
      margin: 0;
      list-style: none;
      padding-left: 0.5rem;
    }

    li {
      display: block;
    }

    li::before {
      content: '•';
      display: inline-flex;
      width: 1ch;
      margin-right: 0.5rem;
    }

    ul > li:not(:first-of-type):not(ul ul > li) {
      margin-top: 0.5rem;
    }

    ul ul > li {
      margin-top: 0.125rem;
    }

    ul ul {
      padding-left: 1rem;
    }

    .highlight {
      background-color: ${theme.pallete.gray.c90};
      border: 0.063rem solid ${theme.pallete.gray.c80};
      margin-top: 0.25rem;
      padding: 0.5rem;
    }

    ul > li > .highlight {
      margin-left: calc(1ch + 0.313rem);
    }
  `,
})

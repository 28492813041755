/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import { EquipeSelectField, Form, FormRenderProps, SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { metaPath } from 'util/metaPath'
import { TipoAtendimentoSelectField } from 'view/lista-espera/components/TipoAtendimentoSelectField'

import { GarantiaAcessoFilterModel, GarantiaAcessoFilterPopperFormModel } from '../model-garantiaAcesso'

const path = metaPath<GarantiaAcessoFilterPopperFormModel>()

interface GarantiaAcessoTableFilterPopperFormProps {
  initialValues: GarantiaAcessoFilterPopperFormModel
  isDefaultFilter: boolean
  onSubmit: (values: GarantiaAcessoFilterModel) => void
  onClear: () => void
  onClose: () => void
}

export function GarantiaAcessoTableFilterPopperForm(props: GarantiaAcessoTableFilterPopperFormProps) {
  const { isDefaultFilter, initialValues, onSubmit, onClear, onClose } = props
  const {
    acesso: { unidadeSaude },
  } = useAcessoLotacaoOrEstagio()

  const handleSubmit = (values: GarantiaAcessoFilterModel) => {
    onSubmit({ ...values })
    onClose()
  }

  const renderForm = (formProps: FormRenderProps<GarantiaAcessoFilterPopperFormModel>) => {
    return (
      <Box style={styles.box}>
        <Grid gap={1}>
          <Cell size={6}>
            <HFlow alignItems='flex-end'>
              <DateRangeField name={path.periodo} label='Período do registro' />
            </HFlow>
          </Cell>
          <Cell size={6} />
          <Cell size={6}>
            <EquipeSelectField label='Equipe' name={path.equipes} unidadeSaudeId={unidadeSaude.id} multiple />
          </Cell>
          <Cell size={6}>
            <LotacaoAndEstagioSelectField
              label='Profissional'
              name={path.lotacoes}
              unidadeSaudeId={unidadeSaude.id}
              multiple
              includeEstagios
            />
          </Cell>

          <Cell size={12}>
            <TipoAtendimentoSelectField name={path.tiposAtendimento} label='Tipo de atendimento' multiple />
          </Cell>

          <Cell size={12}>
            <HFlow justifyContent='flex-end'>
              <Button onClick={onClose} size='small' skin='outline' kind='normal'>
                Fechar
              </Button>

              <Tooltip text='Redefinir filtros para o padrão'>
                <Button kind='normal' size='small' onClick={onClear} disabled={isDefaultFilter}>
                  <Icon style={styles.redoIcon} icon='redo' />
                  Voltar para padrão
                </Button>
              </Tooltip>

              <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
                Filtrar
              </SubmitButton>
            </HFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }
  return <Form initialValues={initialValues} render={renderForm} onSubmit={handleSubmit} />
}

const styles = {
  box: css`
    width: 42rem;
    margin-top: 1rem;
  `,
  redoIcon: css`
    padding-right: 0.5rem;
  `,
}

// @ts-ignore
export type Permission = { uri: string }
const Permissions = {
  atencaoDomiciliar: {
    visualizarAgendaDeAdDaEquipe: {
      cancelar: { uri: 'Atenção Domiciliar/Visualizar agenda de AD da equipe/Cancelar' },
      uri: 'Atenção Domiciliar/Visualizar agenda de AD da equipe',
      visualizarAgendaDeAdDoMunicipio: {
        uri: 'Atenção Domiciliar/Visualizar agenda de AD da equipe/Visualizar agenda de AD do município',
        cancelar: {
          uri: 'Atenção Domiciliar/Visualizar agenda de AD da equipe/Visualizar agenda de AD do município/Cancelar',
        },
        agendarEEditar: {
          uri:
            'Atenção Domiciliar/Visualizar agenda de AD da equipe/Visualizar agenda de AD do município/Agendar e editar',
        },
      },
      agendarEEditar: { uri: 'Atenção Domiciliar/Visualizar agenda de AD da equipe/Agendar e editar' },
    },
    gerarRelatorioDeAdDaEquipe: {
      uri: 'Atenção Domiciliar/Gerar relatório de AD da equipe',
      gerarRelatorioDeAdDoMunicipio: {
        uri: 'Atenção Domiciliar/Gerar relatório de AD da equipe/Gerar relatório de AD do município',
      },
    },
    visualizarListaDeAdDaEquipe: {
      cadastrar: { uri: 'Atenção Domiciliar/Visualizar lista de AD da equipe/Cadastrar' },
      visualizarListaDeAdDoMunicipio: {
        editar: {
          uri: 'Atenção Domiciliar/Visualizar lista de AD da equipe/Visualizar lista de AD do município/Editar',
        },
        uri: 'Atenção Domiciliar/Visualizar lista de AD da equipe/Visualizar lista de AD do município',
        excluir: {
          uri: 'Atenção Domiciliar/Visualizar lista de AD da equipe/Visualizar lista de AD do município/Excluir',
        },
      },
      editar: { uri: 'Atenção Domiciliar/Visualizar lista de AD da equipe/Editar' },
      excluir: { uri: 'Atenção Domiciliar/Visualizar lista de AD da equipe/Excluir' },
      uri: 'Atenção Domiciliar/Visualizar lista de AD da equipe',
    },
    uri: 'Atenção Domiciliar',
  },
  relatorios: {
    producao: {
      atendimentoOdontologicoIndividual: {
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Atendimento odontológico individual/Todos os profissionais',
          categoriaProfissional: {
            uri:
              'Relatórios/Produção/Atendimento odontológico individual/Todos os profissionais/Categoria profissional',
          },
        },
        todasAsEquipes: { uri: 'Relatórios/Produção/Atendimento odontológico individual/Todas as equipes' },
        uri: 'Relatórios/Produção/Atendimento odontológico individual',
        municipio: { uri: 'Relatórios/Produção/Atendimento odontológico individual/Município' },
        unidadeDeSaude: { uri: 'Relatórios/Produção/Atendimento odontológico individual/Unidade de saúde' },
      },
      visitaDomiciliar: {
        todosOsProfissionais: {
          categoriaProfissional: {
            uri: 'Relatórios/Produção/Visita domiciliar/Todos os profissionais/Categoria profissional',
          },
          uri: 'Relatórios/Produção/Visita domiciliar/Todos os profissionais',
        },
        todasAsEquipes: { uri: 'Relatórios/Produção/Visita domiciliar/Todas as equipes' },
        uri: 'Relatórios/Produção/Visita domiciliar',
        municipio: { uri: 'Relatórios/Produção/Visita domiciliar/Município' },
        unidadeDeSaude: { uri: 'Relatórios/Produção/Visita domiciliar/Unidade de saúde' },
      },
      avaliacaoDeElegibilidadeEAdmissao: {
        municipio: { uri: 'Relatórios/Produção/Avaliação de elegibilidade e admissão/Município' },
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Avaliação de elegibilidade e admissão/Todos os profissionais',
          categoriaProfissional: {
            uri:
              'Relatórios/Produção/Avaliação de elegibilidade e admissão/Todos os profissionais/Categoria profissional',
          },
        },
        todasAsEquipes: { uri: 'Relatórios/Produção/Avaliação de elegibilidade e admissão/Todas as equipes' },
        unidadeDeSaude: { uri: 'Relatórios/Produção/Avaliação de elegibilidade e admissão/Unidade de saúde' },
        uri: 'Relatórios/Produção/Avaliação de elegibilidade e admissão',
      },
      atividadeColetiva: {
        todosOsProfissionais: {
          categoriaProfissional: {
            uri: 'Relatórios/Produção/Atividade coletiva/Todos os profissionais/Categoria profissional',
          },
          uri: 'Relatórios/Produção/Atividade coletiva/Todos os profissionais',
        },
        unidadeDeSaude: { uri: 'Relatórios/Produção/Atividade coletiva/Unidade de saúde' },
        municipio: { uri: 'Relatórios/Produção/Atividade coletiva/Município' },
        todasAsEquipes: { uri: 'Relatórios/Produção/Atividade coletiva/Todas as equipes' },
        uri: 'Relatórios/Produção/Atividade coletiva',
      },
      sindromeNeurologicaPorZikaOuMicrocefalia: {
        uri: 'Relatórios/Produção/Síndrome neurológica por Zika ou Microcefalia',
        municipio: { uri: 'Relatórios/Produção/Síndrome neurológica por Zika ou Microcefalia/Município' },
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Síndrome neurológica por Zika ou Microcefalia/Todos os profissionais',
          categoriaProfissional: {
            uri:
              'Relatórios/Produção/Síndrome neurológica por Zika ou Microcefalia/Todos os profissionais/Categoria profissional',
          },
        },
        todasAsEquipes: { uri: 'Relatórios/Produção/Síndrome neurológica por Zika ou Microcefalia/Todas as equipes' },
        unidadeDeSaude: { uri: 'Relatórios/Produção/Síndrome neurológica por Zika ou Microcefalia/Unidade de saúde' },
      },
      resumoDeProducao: {
        uri: 'Relatórios/Produção/Resumo de produção',
        todasAsEquipes: { uri: 'Relatórios/Produção/Resumo de produção/Todas as equipes' },
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Resumo de produção/Todos os profissionais',
          categoriaProfissional: {
            uri: 'Relatórios/Produção/Resumo de produção/Todos os profissionais/Categoria profissional',
          },
        },
        municipio: { uri: 'Relatórios/Produção/Resumo de produção/Município' },
        unidadeDeSaude: { uri: 'Relatórios/Produção/Resumo de produção/Unidade de saúde' },
      },
      marcadoresDeConsumoAlimentar: {
        todasAsEquipes: { uri: 'Relatórios/Produção/Marcadores de consumo alimentar/Todas as equipes' },
        todosOsProfissionais: {
          categoriaProfissional: {
            uri: 'Relatórios/Produção/Marcadores de consumo alimentar/Todos os profissionais/Categoria profissional',
          },
          uri: 'Relatórios/Produção/Marcadores de consumo alimentar/Todos os profissionais',
        },
        uri: 'Relatórios/Produção/Marcadores de consumo alimentar',
        unidadeDeSaude: { uri: 'Relatórios/Produção/Marcadores de consumo alimentar/Unidade de saúde' },
        municipio: { uri: 'Relatórios/Produção/Marcadores de consumo alimentar/Município' },
      },
      vacinacao: {
        municipio: { uri: 'Relatórios/Produção/Vacinação/Município' },
        unidadeDeSaude: { uri: 'Relatórios/Produção/Vacinação/Unidade de saúde' },
        uri: 'Relatórios/Produção/Vacinação',
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Vacinação/Todos os profissionais',
          categoriaProfissional: { uri: 'Relatórios/Produção/Vacinação/Todos os profissionais/Categoria profissional' },
        },
        todasAsEquipes: { uri: 'Relatórios/Produção/Vacinação/Todas as equipes' },
      },
      atendimentoIndividual: {
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Atendimento individual/Todos os profissionais',
          categoriaProfissional: {
            uri: 'Relatórios/Produção/Atendimento individual/Todos os profissionais/Categoria profissional',
          },
        },
        todasAsEquipes: { uri: 'Relatórios/Produção/Atendimento individual/Todas as equipes' },
        uri: 'Relatórios/Produção/Atendimento individual',
        unidadeDeSaude: { uri: 'Relatórios/Produção/Atendimento individual/Unidade de saúde' },
        municipio: { uri: 'Relatórios/Produção/Atendimento individual/Município' },
      },
      procedimentos: {
        unidadeDeSaude: { uri: 'Relatórios/Produção/Procedimentos/Unidade de saúde' },
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Procedimentos/Todos os profissionais',
          categoriaProfissional: {
            uri: 'Relatórios/Produção/Procedimentos/Todos os profissionais/Categoria profissional',
          },
        },
        municipio: { uri: 'Relatórios/Produção/Procedimentos/Município' },
        todasAsEquipes: { uri: 'Relatórios/Produção/Procedimentos/Todas as equipes' },
        uri: 'Relatórios/Produção/Procedimentos',
      },
      procedimentosConsolidados: {
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Procedimentos consolidados/Todos os profissionais',
          categoriaProfissional: {
            uri: 'Relatórios/Produção/Procedimentos consolidados/Todos os profissionais/Categoria profissional',
          },
        },
        municipio: { uri: 'Relatórios/Produção/Procedimentos consolidados/Município' },
        unidadeDeSaude: { uri: 'Relatórios/Produção/Procedimentos consolidados/Unidade de saúde' },
        todasAsEquipes: { uri: 'Relatórios/Produção/Procedimentos consolidados/Todas as equipes' },
        uri: 'Relatórios/Produção/Procedimentos consolidados',
      },
      atendimentoDomiciliar: {
        todosOsProfissionais: {
          uri: 'Relatórios/Produção/Atendimento domiciliar/Todos os profissionais',
          categoriaProfissional: {
            uri: 'Relatórios/Produção/Atendimento domiciliar/Todos os profissionais/Categoria profissional',
          },
        },
        todasAsEquipes: { uri: 'Relatórios/Produção/Atendimento domiciliar/Todas as equipes' },
        uri: 'Relatórios/Produção/Atendimento domiciliar',
        unidadeDeSaude: { uri: 'Relatórios/Produção/Atendimento domiciliar/Unidade de saúde' },
        municipio: { uri: 'Relatórios/Produção/Atendimento domiciliar/Município' },
      },
      uri: 'Relatórios/Produção',
    },
    consolidados: {
      cadastroDomiciliarETerritorial: {
        municipio: { uri: 'Relatórios/Consolidados/Cadastro domiciliar e territorial/Município' },
        todosOsProfissionais: {
          categoriaProfissional: {
            uri:
              'Relatórios/Consolidados/Cadastro domiciliar e territorial/Todos os profissionais/Categoria profissional',
          },
          uri: 'Relatórios/Consolidados/Cadastro domiciliar e territorial/Todos os profissionais',
        },
        todasAsEquipes: { uri: 'Relatórios/Consolidados/Cadastro domiciliar e territorial/Todas as equipes' },
        unidadeDeSaude: { uri: 'Relatórios/Consolidados/Cadastro domiciliar e territorial/Unidade de saúde' },
        uri: 'Relatórios/Consolidados/Cadastro domiciliar e territorial',
      },
      cadastroIndividual: {
        todosOsProfissionais: {
          categoriaProfissional: {
            uri: 'Relatórios/Consolidados/Cadastro individual/Todos os profissionais/Categoria profissional',
          },
          uri: 'Relatórios/Consolidados/Cadastro individual/Todos os profissionais',
        },
        todasAsEquipes: { uri: 'Relatórios/Consolidados/Cadastro individual/Todas as equipes' },
        uri: 'Relatórios/Consolidados/Cadastro individual',
        unidadeDeSaude: { uri: 'Relatórios/Consolidados/Cadastro individual/Unidade de saúde' },
        municipio: { uri: 'Relatórios/Consolidados/Cadastro individual/Município' },
      },
      situacaoDoTerritorio: {
        uri: 'Relatórios/Consolidados/Situação do território',
        municipio: { uri: 'Relatórios/Consolidados/Situação do território/Município' },
        todasAsEquipes: { uri: 'Relatórios/Consolidados/Situação do território/Todas as equipes' },
        unidadeDeSaude: { uri: 'Relatórios/Consolidados/Situação do território/Unidade de saúde' },
      },
      uri: 'Relatórios/Consolidados',
    },
    gerenciais: {
      cuidadoCompartilhado: {
        todasAsEquipes: { uri: 'Relatórios/Gerenciais/Cuidado Compartilhado/Todas as equipes' },
        todosOsProfissionais: {
          uri: 'Relatórios/Gerenciais/Cuidado Compartilhado/Todos os profissionais',
          categoriaProfissional: {
            uri: 'Relatórios/Gerenciais/Cuidado Compartilhado/Todos os profissionais/Categoria profissional',
          },
        },
        uri: 'Relatórios/Gerenciais/Cuidado Compartilhado',
        unidadeDeSaude: { uri: 'Relatórios/Gerenciais/Cuidado Compartilhado/Unidade de saúde' },
        municipio: { uri: 'Relatórios/Gerenciais/Cuidado Compartilhado/Município' },
        cbo: { uri: 'Relatórios/Gerenciais/Cuidado Compartilhado/CBO' },
      },
      vacinacao: {
        todosOsProfissionais: {
          uri: 'Relatórios/Gerenciais/Vacinação/Todos os profissionais',
          categoriaProfissional: {
            uri: 'Relatórios/Gerenciais/Vacinação/Todos os profissionais/Categoria profissional',
          },
        },
        cbo: { uri: 'Relatórios/Gerenciais/Vacinação/CBO' },
        municipio: { uri: 'Relatórios/Gerenciais/Vacinação/Município' },
        todasAsEquipes: { uri: 'Relatórios/Gerenciais/Vacinação/Todas as equipes' },
        unidadeDeSaude: { uri: 'Relatórios/Gerenciais/Vacinação/Unidade de saúde' },
        uri: 'Relatórios/Gerenciais/Vacinação',
      },
      absenteismo: {
        uri: 'Relatórios/Gerenciais/Absenteísmo',
        todasAsEquipes: { uri: 'Relatórios/Gerenciais/Absenteísmo/Todas as equipes' },
        todosOsProfissionais: {
          uri: 'Relatórios/Gerenciais/Absenteísmo/Todos os profissionais',
          categoriaProfissional: {
            uri: 'Relatórios/Gerenciais/Absenteísmo/Todos os profissionais/Categoria profissional',
          },
        },
        municipio: { uri: 'Relatórios/Gerenciais/Absenteísmo/Município' },
        cbo: { uri: 'Relatórios/Gerenciais/Absenteísmo/CBO' },
        unidadeDeSaude: { uri: 'Relatórios/Gerenciais/Absenteísmo/Unidade de saúde' },
      },
      atendimentos: {
        unidadeDeSaude: { uri: 'Relatórios/Gerenciais/Atendimentos/Unidade de saúde' },
        todosOsProfissionais: {
          categoriaProfissional: {
            uri: 'Relatórios/Gerenciais/Atendimentos/Todos os profissionais/Categoria profissional',
          },
          uri: 'Relatórios/Gerenciais/Atendimentos/Todos os profissionais',
        },
        todasAsEquipes: { uri: 'Relatórios/Gerenciais/Atendimentos/Todas as equipes' },
        uri: 'Relatórios/Gerenciais/Atendimentos',
        municipio: { uri: 'Relatórios/Gerenciais/Atendimentos/Município' },
        cbo: { uri: 'Relatórios/Gerenciais/Atendimentos/CBO' },
      },
      uri: 'Relatórios/Gerenciais',
      cadastrarModelosPublicos: { uri: 'Relatórios/Gerenciais/Cadastrar modelos públicos' },
    },
    operacional: {
      gestante: {
        todasAsEquipes: { uri: 'Relatórios/Operacional/Gestante/Todas as equipes' },
        uri: 'Relatórios/Operacional/Gestante',
      },
      cadastroDoTerritorio: {
        todasAsEquipes: { uri: 'Relatórios/Operacional/Cadastro do território/Todas as equipes' },
        uri: 'Relatórios/Operacional/Cadastro do território',
      },
      crianca: {
        uri: 'Relatórios/Operacional/Criança',
        todasAsEquipes: { uri: 'Relatórios/Operacional/Criança/Todas as equipes' },
      },
      uri: 'Relatórios/Operacional',
      riscoCardiovascular: {
        todasAsEquipes: { uri: 'Relatórios/Operacional/Risco cardiovascular/Todas as equipes' },
        uri: 'Relatórios/Operacional/Risco cardiovascular',
      },
    },
    uri: 'Relatórios',
    processarRelatorios: { uri: 'Relatórios/Processar relatórios' },
  },
  visualizarCbo: { uri: 'Visualizar CBO' },
  cds: {
    fichaDeCadastroDomiciliar: {
      uri: 'CDS/Ficha de cadastro domiciliar',
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de cadastro domiciliar/Cadastrar, editar e excluir' },
    },
    fichaDeVisitaDomiciliar: {
      uri: 'CDS/Ficha de visita domiciliar',
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de visita domiciliar/Cadastrar, editar e excluir' },
    },
    fichaDeCadastroIndividual: {
      uri: 'CDS/Ficha de cadastro individual',
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de cadastro individual/Cadastrar, editar e excluir' },
    },
    uri: 'CDS',
    fichaDeAtendimentoDomiciliar: {
      uri: 'CDS/Ficha de atendimento domiciliar',
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de atendimento domiciliar/Cadastrar, editar e excluir' },
    },
    fichaComplementarSindromeNeurologicaPorZikaOuMicrocefalia: {
      cadastrarEditarEExcluir: {
        uri: 'CDS/Ficha complementar - Síndrome neurológica por Zika ou Microcefalia/Cadastrar, editar e excluir',
      },
      uri: 'CDS/Ficha complementar - Síndrome neurológica por Zika ou Microcefalia',
    },
    fichaDeConsumoAlimentar: {
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de consumo alimentar/Cadastrar, editar e excluir' },
      uri: 'CDS/Ficha de consumo alimentar',
    },
    fichaDeAtendimentoIndividual: {
      uri: 'CDS/Ficha de atendimento individual',
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de atendimento individual/Cadastrar, editar e excluir' },
    },
    fichaDeProcedimentos: {
      uri: 'CDS/Ficha de procedimentos',
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de procedimentos/Cadastrar, editar e excluir' },
    },
    fichaDeAvaliacaoDeElegibilidade: {
      uri: 'CDS/Ficha de avaliação de elegibilidade',
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de avaliação de elegibilidade/Cadastrar, editar e excluir' },
    },
    fichaDeAtendimentoOdontologico: {
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de atendimento odontológico/Cadastrar, editar e excluir' },
      uri: 'CDS/Ficha de atendimento odontológico',
    },
    fichaDeVacinacao: {
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de vacinação/Cadastrar, editar e excluir' },
      uri: 'CDS/Ficha de vacinação',
    },
    fichaDeAtividadeColetiva: {
      cadastrarEditarEExcluir: { uri: 'CDS/Ficha de atividade coletiva/Cadastrar, editar e excluir' },
      uri: 'CDS/Ficha de atividade coletiva',
    },
  },
  listarMunicipiosEResponsaveis: { uri: 'Listar municípios e responsáveis' },
  configuracaoMunicipal: {
    configurarAgendaPadrao: { uri: 'Configuração municipal/Configurar agenda padrão' },
    visualizarEnvioRnds: { uri: 'Configuração municipal/Visualizar envio RNDS' },
    configurarCertificado: { uri: 'Configuração municipal/Configurar certificado' },
    cadastrarEditarEExcluirGruposDeExames: {
      uri: 'Configuração municipal/Cadastrar, editar e excluir grupos de exames',
    },
    uri: 'Configuração municipal',
    cadastrarEditarEExcluirTipoDeServico: { uri: 'Configuração municipal/Cadastrar, editar e excluir tipo de serviço' },
  },
  visualizarPerfis: {
    uri: 'Visualizar perfis',
    cadastrarEditarEExcluir: { uri: 'Visualizar perfis/Cadastrar, editar e excluir' },
  },
  visualizarUnidadesDeSaude: {
    uri: 'Visualizar unidades de saúde',
    ativarOuInativarTipoDeServico: {
      todasAsUnidades: { uri: 'Visualizar unidades de saúde/Ativar ou inativar tipo de serviço/Todas as unidades' },
      uri: 'Visualizar unidades de saúde/Ativar ou inativar tipo de serviço',
    },
    configurarConsultaNoHorus: {
      todasAsUnidades: { uri: 'Visualizar unidades de saúde/Configurar consulta no Hórus/Todas as unidades' },
      uri: 'Visualizar unidades de saúde/Configurar consulta no Hórus',
    },
  },
  visualizarListaDeAtendimento: {
    cadastrarEditarEExcluir: { uri: 'Visualizar lista de atendimento/Cadastrar, editar e excluir' },
    registrarAtendimento: {
      uri: 'Visualizar lista de atendimento/Registrar atendimento',
      registrarAtendimentoDeVacinacao: {
        uri: 'Visualizar lista de atendimento/Registrar atendimento/Registrar atendimento de vacinação',
      },
    },
    uri: 'Visualizar lista de atendimento',
    visualizarEscutaInicial: {
      uri: 'Visualizar lista de atendimento/Visualizar escuta inicial',
      registrarEscutaInicial: {
        uri: 'Visualizar lista de atendimento/Visualizar escuta inicial/Registrar escuta inicial',
      },
    },
    informarQueCidadaoNaoAguardou: { uri: 'Visualizar lista de atendimento/Informar que cidadão não aguardou' },
    gerarDeclaracaoDeComparecimento: { uri: 'Visualizar lista de atendimento/Gerar declaração de comparecimento' },
  },
  configuracaoDeInstalacao: {
    configurarConexaoComHorus: { uri: 'Configuração de instalação/Configurar conexão com Hórus' },
    configurarServidores: { uri: 'Configuração de instalação/Configurar servidores' },
    configurarConexaoComInternet: { uri: 'Configuração de instalação/Configurar conexão com Internet' },
    uri: 'Configuração de instalação',
    configurarPrescricaoDigital: { uri: 'Configuração de instalação/Configurar prescrição digital' },
    configurarNumeroDeRequisicoesSimultaneas: {
      uri: 'Configuração de instalação/Configurar número de requisições simultâneas',
    },
    configurarConexaoComCadsus: { uri: 'Configuração de instalação/Configurar conexão com CADSUS' },
    configurarAgendaOnline: { uri: 'Configuração de instalação/Configurar agenda online' },
    configurarSeguranca: { uri: 'Configuração de instalação/Configurar segurança' },
    configurarUnificacaoDeBase: { uri: 'Configuração de instalação/Configurar unificação de base' },
    configurarVideochamadas: { uri: 'Configuração de instalação/Configurar videochamadas' },
  },
  visualizarProfissionais: {
    uri: 'Visualizar profissionais',
    visualizarAcessos: {
      configurarAgendaOnline: {
        todasAsUnidades: {
          uri: 'Visualizar profissionais/Visualizar acessos/Configurar agenda online/Todas as unidades',
        },
        uri: 'Visualizar profissionais/Visualizar acessos/Configurar agenda online',
      },
      fecharAgenda: {
        todasAsUnidades: { uri: 'Visualizar profissionais/Visualizar acessos/Fechar agenda/Todas as unidades' },
        uri: 'Visualizar profissionais/Visualizar acessos/Fechar agenda',
      },
      cadastrarEditarEExcluirLotacao: {
        uri: 'Visualizar profissionais/Visualizar acessos/Cadastrar, editar e excluir lotação',
        todasAsUnidades: {
          uri: 'Visualizar profissionais/Visualizar acessos/Cadastrar, editar e excluir lotação/Todas as unidades',
        },
      },
      configurarAgenda: {
        todasAsUnidades: { uri: 'Visualizar profissionais/Visualizar acessos/Configurar agenda/Todas as unidades' },
        uri: 'Visualizar profissionais/Visualizar acessos/Configurar agenda',
      },
      uri: 'Visualizar profissionais/Visualizar acessos',
      cadastrarEditarEExcluirAcesso: {
        uri: 'Visualizar profissionais/Visualizar acessos/Cadastrar, editar e excluir acesso',
      },
      cadastrarEditarEExcluirLotacaoIndigena: {
        uri: 'Visualizar profissionais/Visualizar acessos/Cadastrar, editar e excluir lotação indígena',
      },
    },
    cadastrarEditarEExcluir: { uri: 'Visualizar profissionais/Cadastrar, editar e excluir' },
    redefinirSenha: { uri: 'Visualizar profissionais/Redefinir senha' },
  },
  buscaAtivaDeVacinacao: {
    uri: 'Busca ativa de vacinação',
    todasAsEquipes: { uri: 'Busca ativa de vacinação/Todas as equipes' },
  },
  gestaoDeCadastrosDeCidadao: {
    unificacaoDeBases: { uri: 'Gestão de cadastros de cidadão/Unificação de bases' },
    visualizarCidadao: {
      uri: 'Gestão de cadastros de cidadão/Visualizar cidadão',
      cadastrarEEditar: {
        uri: 'Gestão de cadastros de cidadão/Visualizar cidadão/Cadastrar e editar',
        cadastrarEEditarCidadaoAldeado: {
          uri:
            'Gestão de cadastros de cidadão/Visualizar cidadão/Cadastrar e editar/Cadastrar e editar cidadão aldeado',
        },
      },
      excluir: { uri: 'Gestão de cadastros de cidadão/Visualizar cidadão/Excluir' },
      inativar: { uri: 'Gestão de cadastros de cidadão/Visualizar cidadão/Inativar' },
    },
    uri: 'Gestão de cadastros de cidadão',
    reterritorializacao: { uri: 'Gestão de cadastros de cidadão/Reterritorialização' },
    unificacaoDeCadastros: { uri: 'Gestão de cadastros de cidadão/Unificação de cadastros' },
  },
  visualizarProntuario: { uri: 'Visualizar prontuário' },
  importarCadastrosDeCidadao: { uri: 'Importar cadastros de cidadão' },
  gerarTrilhaDeAuditoria: { uri: 'Gerar trilha de auditoria' },
  acompanhamentos: {
    uri: 'Acompanhamentos',
    cidadaosVinculados: {
      todasAsEquipes: { uri: 'Acompanhamentos/Cidadãos vinculados/Todas as equipes' },
      uri: 'Acompanhamentos/Cidadãos vinculados',
    },
    condicoesDeSaude: {
      uri: 'Acompanhamentos/Condições de saúde',
      todasAsEquipes: { uri: 'Acompanhamentos/Condições de saúde/Todas as equipes' },
      problemasECondicoes: { uri: 'Acompanhamentos/Condições de saúde/Problemas e condições' },
      ivcf: { uri: 'Acompanhamentos/Condições de saúde/IVCF-20' },
    },
    territorio: {
      uri: 'Acompanhamentos/Território',
      territorioIndigena: { uri: 'Acompanhamentos/Território/Território indígena' },
    },
  },
  administracaoGeralDaInstalacao: { uri: 'Administração geral da instalação' },
  visualizarAgenda: {
    cancelar: {
      uri: 'Visualizar agenda/Cancelar',
      todasAsEquipes: { uri: 'Visualizar agenda/Cancelar/Todas as equipes' },
    },
    agendarEEditar: {
      todasAsEquipes: { uri: 'Visualizar agenda/Agendar e editar/Todas as equipes' },
      uri: 'Visualizar agenda/Agendar e editar',
    },
    uri: 'Visualizar agenda',
    informarFaltaDoCidadao: { uri: 'Visualizar agenda/Informar falta do cidadão' },
  },
  transmissaoDeDados: {
    visualizarRecebimentoDeDados: { uri: 'Transmissão de dados/Visualizar recebimento de dados' },
    uri: 'Transmissão de dados',
    configurarInstalacoesDeEnvio: { uri: 'Transmissão de dados/Configurar instalações de envio' },
    visualizarEnvioDeDados: { uri: 'Transmissão de dados/Visualizar envio de dados' },
  },
  videochamada: { realizarVideochamada: { uri: 'Videochamada/Realizar videochamada' }, uri: 'Videochamada' },
  cuidadoCompartilhado: {
    visualizarDiscussao: {
      registrarDiscussaoDeCaso: { uri: 'Cuidado Compartilhado/Visualizar discussão/Registrar discussão de caso' },
      uri: 'Cuidado Compartilhado/Visualizar discussão',
    },
    uri: 'Cuidado Compartilhado',
  },
  importarBeneficiariosDoBolsaFamilia: { uri: 'Importar beneficiários do bolsa família' },
  cadastrarEditarEExcluirLotesDeImunobiologico: { uri: 'Cadastrar, editar e excluir lotes de imunobiológico' },
  importarCnes: { uri: 'Importar CNES' },
  assinaturaEletronica: { uri: 'Assinatura eletrônica' },
  configurarAtencaoDomiciliar: { uri: 'Configurar atenção domiciliar' },
}
export default Permissions

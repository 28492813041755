/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { Form, FormPrompt, FormProps, SubmitButton } from 'components/form'
import changeValue from 'components/form/mutators/changeValue'
import theme from 'config/theme'
import { TipoExameEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Fragment } from 'react'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { GrupoExamesSidebar } from './components/grupo-exames/GrupoExamesSidebar'
import { SolicitacaoExameModel } from './model'
import SolicitarExamesFormFields from './SolicitarExamesFormFields'
import { solicitacaoExameValidator } from './validator'

export interface SolicitarExamesUrlParams {
  id?: ID
}

export interface SolicitarExamesFormModalProps
  extends Omit<FormProps<SolicitacaoExameModel>, 'initialValues' | 'render'> {
  cidadao: CidadaoAtendimento
  editing: boolean
  initialValues: SolicitacaoExameModel
  adicionados?: SolicitacaoExameModel[]
  alertMessage?: string
  iniciadoEm: Instant
  onClose(): void
}

export default function SolicitarExamesFormModal(props: SolicitarExamesFormModalProps) {
  const { cidadao, onSubmit, onClose, editing, alertMessage, initialValues, iniciadoEm, ...formProps } = props

  const isComum = initialValues.tipoExame === TipoExameEnum.COMUM

  const headerText = getHeaderText(editing, initialValues.tipoExame)

  const { analytics } = useFirebase()

  const onSubmitSucceeded = ({ values }) => {
    switch (values.tipoExame) {
      case TipoExameEnum.COMUM:
        analytics.logEvent('solicitar_exm_comum')
        break
      case TipoExameEnum.ALTO_CUSTO:
        analytics.logEvent('solicitar_exm_alto_custo')
        break
      case TipoExameEnum.OCI:
        analytics.logEvent('solicitar_proced_oci')
        break
      default:
    }
    onClose()
  }

  const handleClose = () => {
    onClose()
    analytics.logEvent('cancelar_solicitacao_exm')
  }

  return (
    <Form<SolicitacaoExameModel>
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      validate={solicitacaoExameValidator}
      initialValues={initialValues}
      mutators={{ changeValue }}
      render={(renderProps) => {
        return (
          <Fragment>
            <FormPrompt />
            <Modal open size='large' onClose={onClose}>
              <ModalBody
                style={css`
                  padding-top: 0;
                  padding-left: 0;
                  padding-bottom: 0;
                `}
              >
                <HFlow
                  hSpacing={0}
                  style={css`
                    grid-auto-columns: ${isComum ? 'auto 1fr' : '1fr'};
                  `}
                >
                  {isComum && (
                    <GrupoExamesSidebar
                      sexo={cidadao.sexo}
                      identidadeGenero={cidadao.identidadeGeneroDbEnum}
                      dataNascimento={cidadao.dataNascimento}
                      iniciadoEm={iniciadoEm}
                    />
                  )}

                  <VFlow
                    style={css`
                      padding: 2.5rem;
                      padding-right: 0;
                    `}
                  >
                    <Heading level={1}>{headerText}</Heading>
                    {alertMessage && (
                      <Alert inline type='warning'>
                        {alertMessage}
                      </Alert>
                    )}
                    <SolicitarExamesFormFields cidadao={cidadao} tipoExame={initialValues.tipoExame} />
                  </VFlow>
                </HFlow>
              </ModalBody>

              <ModalFooter
                style={css`
                  border-top: ${isComum ? `1px solid ${theme.pallete.gray.c80}` : 'none'};
                `}
              >
                <HFlow justifyContent='flex-end'>
                  <FooterButton kind='normal' onClick={handleClose}>
                    Cancelar
                  </FooterButton>
                  <SubmitButton type='button' kind='primary' handleSubmit={renderProps.handleSubmit} throttleSubmit>
                    Salvar
                  </SubmitButton>
                </HFlow>
              </ModalFooter>
            </Modal>
          </Fragment>
        )
      }}
      {...formProps}
    />
  )
}

const getHeaderText = (isEditing: boolean, tipoExame: TipoExameEnum): string => {
  const actionText = isEditing ? 'Editar' : 'Solicitar'
  switch (tipoExame) {
    case TipoExameEnum.COMUM:
      return `${actionText} exame comum`
    case TipoExameEnum.ALTO_CUSTO:
      return `${actionText} exame de alto custo`
    case TipoExameEnum.OCI:
      return `${actionText} OCI`
  }
}

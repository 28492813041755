/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, HFlow, Text, VFlow } from 'bold-ui'
import { memo, useCallback } from 'react'
import { TermosOutrosMedicamentosImpressaoPrescricao, TermosTalidomidaImpressaoPrescricao } from 'types/enums'

import { ImpressaoTermosDto } from '../../modal/impressao/ImpressaoMedicamentosInput'
import { MedicamentoSubtitle } from '../../model-prescricao'
import { MedicamentoImpressoInfoLabel } from './MedicamentoImpressoInfoLabel'
import { PrescricaoBoxItem } from './PrescricaoBoxItem'

interface TermosToPrintListProps {
  termos: string[]
  termosToPrint: ImpressaoTermosDto[]
  acitretinaIsotretinoinaTretinoinaSub: string
  isReimpressao: boolean
  findAlreadyPrintedElement(termo: ID): boolean
  onClickCheckbox(checked: boolean, termo: ImpressaoTermosDto): void
}

function TermosToPrintList(props: TermosToPrintListProps) {
  const {
    termos,
    termosToPrint,
    acitretinaIsotretinoinaTretinoinaSub,
    isReimpressao,
    findAlreadyPrintedElement,
    onClickCheckbox,
  } = props

  const isSelecionado = useCallback((termo: string) => termos.includes(termo), [termos])

  const alreadyPrinted = (id: ID) => !isReimpressao && findAlreadyPrintedElement(id)

  return (
    <VFlow
      vSpacing={0.5}
      style={css`
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      `}
    >
      {termosToPrint.map((termo) => (
        <PrescricaoBoxItem key={termo.termo}>
          <HFlow justifyContent='space-between'>
            <HFlow>
              <Checkbox
                checked={isSelecionado(termo.termo)}
                onChange={(e) => {
                  onClickCheckbox(e.target.checked, termo)
                }}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <VFlow vSpacing={0.3}>
                <Text fontWeight='bold'>
                  {TermosTalidomidaImpressaoPrescricao[termo.termo] ??
                    TermosOutrosMedicamentosImpressaoPrescricao[termo.termo]}
                </Text>
                <Text>
                  {Object.keys(TermosTalidomidaImpressaoPrescricao).includes(termo.termo)
                    ? MedicamentoSubtitle.TALIDOMIDA
                    : acitretinaIsotretinoinaTretinoinaSub}
                </Text>
              </VFlow>
            </HFlow>
            {alreadyPrinted(termo.termo) && <MedicamentoImpressoInfoLabel />}
          </HFlow>
        </PrescricaoBoxItem>
      ))}
    </VFlow>
  )
}

export default memo(TermosToPrintList)

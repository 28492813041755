/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'
import { useEffect } from 'react'

import { ReactComponent as SdcIcon } from '../../../images/suporte-decisao-clinica/health-system-outline.svg'
import { SdcSecaoAlerta } from './SdcSecaoAlerta'

export interface SdcDetalhesModalBodyProps {
  alertaSelecionado: AlertaSuporteDecisaoClinica
}

export const SdcDetalhesModalBody = (props: SdcDetalhesModalBodyProps) => {
  const { alertaSelecionado } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  const { alertasSdc, marcarAlertaComoLido } = useAlertasSdc()

  useEffect(() => {
    const alerta = alertasSdc.find((alerta) => alerta.id === alertaSelecionado.id)
    alerta && marcarAlertaComoLido(alerta)
  }, [alertaSelecionado.id, alertasSdc, marcarAlertaComoLido])

  return (
    <div css={styles.contentBody}>
      <Heading level={1} style={styles.sdcHeader}>
        <HFlow alignItems='center'>
          <Icon icon={SdcIcon} size={2.5} fill='primary' />
          Suporte à Decisão Clínica
        </HFlow>
      </Heading>
      <VFlow vSpacing={0.75}>
        <VFlow vSpacing={0} style={styles.mainHeading}>
          <Text variant='h3' color='primary'>
            {alertaSelecionado.titulo}
          </Text>
          <Text variant='h3' fontWeight='normal' color='primary'>
            {alertaSelecionado.subtitulo}
          </Text>
        </VFlow>
        <VFlow vSpacing={1}>
          <SdcSecaoAlerta conteudo={alertaSelecionado?.conteudoPersonalizado} />
          <SdcSecaoAlerta titulo='Exames de apoio diagnóstico' conteudo={alertaSelecionado?.exames} />
          <SdcSecaoAlerta titulo='Medicamentos sugeridos' conteudo={alertaSelecionado?.medicamentos} />
          <SdcSecaoAlerta titulo='Recomendações gerais' conteudo={alertaSelecionado?.recomendacoes} />
          <SdcSecaoAlerta titulo='Retorno' conteudo={alertaSelecionado?.retorno} />
          <SdcSecaoAlerta titulo='Orientações ao cidadão' conteudo={alertaSelecionado?.orientacoes} />
          {alertaSelecionado?.referencias?.length > 0 && (
            <VFlow vSpacing={0.5} style={styles.referencias}>
              <Text variant='h4'>Referências</Text>
              {alertaSelecionado?.referencias?.map((referencia, index) => (
                <Text key={index} variant='h5' fontWeight='normal' fontStyle='italic' display='block'>
                  {referencia}
                </Text>
              ))}
            </VFlow>
          )}
        </VFlow>
      </VFlow>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  contentBody: css`
    width: 32.25rem;
    padding: 2rem;
    padding-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    ${theme.breakpoints.up('lg')} {
      width: 37.5rem;
    }

    ::-webkit-scrollbar {
      width: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background: ${theme.pallete.surface.main};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: ${theme.pallete.gray.c70};
    }
  `,
  sdcHeader: css`
    padding-bottom: 1rem;
  `,
  mainHeading: css`
    padding-bottom: 1rem;
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  referencias: css`
    padding-top: 0.75rem;
    border-top: 1px solid ${theme.pallete.divider};
  `,
})

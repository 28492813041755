import { AlertaSdc, CondutaSdc } from 'graphql/types.generated'
import { ProblemaCondicaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/problemas-condicoes/model-problemasCondicoes'

export interface AlertaSuporteDecisaoClinica extends AlertaSdc {
  visualizado?: boolean
  exibido?: boolean
}

export interface SdcAtendimentoValues {
  problemasCondicoes: ProblemaCondicaoModel[]
  prontuarioId: ID
  atendProfData: Date
  pesoCidadao: string
}

export const isAlertaSdc = (obj: { __typename?: string }): obj is AlertaSuporteDecisaoClinica =>
  obj.__typename === 'AlertaSdc'

export const isCondutaSdc = (obj: { __typename?: string }): obj is CondutaSdc => obj.__typename === 'CondutaSdc'

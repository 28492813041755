/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { Procedimento } from 'graphql/types.generated'
import { Fragment } from 'react'
import { nomeCodigoProcedimentoRef } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util-resultadosExames'

interface ExameSolicitadoTitleFormatterProps {
  exame: Procedimento
}

export function ExameSolicitadoTitleFormatter(props: ExameSolicitadoTitleFormatterProps) {
  const { exame } = props

  const [nome, codigo] = nomeCodigoProcedimentoRef(exame)

  return (
    <Fragment>
      <Text fontWeight='bold'>{nome}</Text>
      <span>{` - ${codigo}`}</span>
    </Fragment>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Icon, TextInput, TextInputProps, Theme, Tooltip, useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'

interface VideochamadaUrlTextInput extends Omit<TextInputProps, 'icon'> {
  videochamadaUuid: string
}

export const VideochamadaUrlTextInput = React.memo((props: VideochamadaUrlTextInput) => {
  const { videochamadaUuid, ...rest } = props

  const theme = useTheme()
  const styles = createStyles(theme)
  const { analytics } = useFirebase()

  const alert = useAlert()
  const url = `${window.location.origin}/videochamada/${videochamadaUuid}`
  // TODO: @RNG Atualizar a tooltip do ícone do componente quando essa possibilidade for implementada no bold (#19586)
  return (
    <div css={styles.componentWrapper}>
      <div css={styles.textWrapper}>
        <TextInput style={styles.textStyle} value={url} disabled aria-hidden {...rest} />
      </div>

      <Tooltip text='Copiar'>
        <div css={styles.buttonWrapper}>
          <Button
            skin='ghost'
            size='small'
            style={styles.button}
            onClick={() => {
              navigator.clipboard.writeText(url)
              alert('success', 'Link da chamada copiado com sucesso.')
              analytics.logEvent('copiar_link_videochamada_AG')
            }}
          >
            <Icon icon='copyOutline' />
          </Button>
        </div>
      </Tooltip>
    </div>
  )
})

const createStyles = (theme: Theme) => ({
  buttonWrapper: css`
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: baseline;
    border: 1px solid ${theme.pallete.gray.c80};
    border-left: none;
    background-color: ${theme.pallete.gray.c90};
  `,
  button: css`
    display: flex;
    margin-left: 0;
    width: 100%;
    height: 100%;
  `,
  componentWrapper: css`
    display: flex;
  `,
  textWrapper: css`
    width: 100%;
  `,
  textStyle: css`
    border-right: none;
    display: flex;
    width: 100%;
  `,
})

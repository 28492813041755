/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { FooterButton } from 'components/footer-button'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { TitleGroup } from 'components/TitleGroup'
import { addDays } from 'date-fns'
import { CondutaSdc, ReceitaMedicamentoSdcDto } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'
import { useEffect, useState } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { OrientacaoFormModel } from '../orientacoes/types/OrientacoesModel'
import { PlanoFormModel } from '../PlanoForm'
import { MedicamentoFormModel, TipoDoseEnum } from '../prescricao-medicamento/model-prescricao'
import { PrescricaoCheckbox } from './PrescricaoCheckbox'

interface SdcCondutaModalProps {
  modalOpen: boolean
  setModalOpen(boolean): void
  name: MetaPath<PlanoFormModel>
}

export default function SdcCondutaModal(props: SdcCondutaModalProps) {
  const { modalOpen, setModalOpen, name } = props

  const [condutaModal, setCondutaModal] = useState<CondutaSdc>()
  const [mapMedicamentosParaPrescricao, setListaMedicamentosParaPrescricao] = useState<
    Map<string, ReceitaMedicamentoSdcDto>
  >(new Map())

  const handleTogglePrescricao = (prescricao: ReceitaMedicamentoSdcDto) => {
    setListaMedicamentosParaPrescricao((prevMap) => {
      const novoMap = new Map(prevMap)

      if (novoMap.has(prescricao.medicamentoCatmat.codigoCatMat)) {
        novoMap.delete(prescricao.medicamentoCatmat.codigoCatMat)
      } else {
        novoMap.set(prescricao.medicamentoCatmat.codigoCatMat, prescricao)
      }

      return novoMap
    })
  }

  const {
    cidadao: { idadeEmAnos },
  } = useAtendimentoContext()

  const { setGrupoDengue, condutasSdc, hasCondutaAplicada, marcarCondutaComoAplicada } = useAlertasSdc()
  const alert = useAlert()

  const { analytics } = useFirebase()

  useEffect(() => {
    const condutaSdcModal = condutasSdc.find((conduta) => conduta.tipo === 'MODAL')
    if (condutaSdcModal) {
      setCondutaModal(condutaSdcModal)
      setModalOpen(true)
    }
  }, [condutasSdc, setModalOpen])

  const medicamentosFormField = usePecField({ name: name.prescricaoMedicamento.medicamentos.absolutePath() })
  const orientacaoFormField = usePecField({ name: name.orientacao.absolutePath() })

  if (!condutaModal) return null

  const handleModalClose = () => {
    setModalOpen(false)
    setGrupoDengue(null)
    setCondutaModal(null)
    setListaMedicamentosParaPrescricao(new Map())
  }

  const handleAplicarConduta = () => {
    if (hasCondutaAplicada || medicamentosFormField?.input?.value?.length > 0) return

    const listaPrescricoes = Array.from(mapMedicamentosParaPrescricao.values())

    const medicamentoAutomatico: MedicamentoFormModel[] = listaPrescricoes
      .map(converterMedicamentoCondutaToMedicamentoFormModel)
      .filterNotFalsy()

    const orientacaoAutomatica: OrientacaoFormModel = { descricao: condutaModal.observacao }

    analytics.logEvent('aplicar_conduta_recomendada')
    medicamentosFormField.input.onChange(medicamentoAutomatico)
    orientacaoFormField.input.onChange([...orientacaoFormField.input.value, orientacaoAutomatica])

    alert('success', 'Conduta recomendada aplicada com sucesso.')
    marcarCondutaComoAplicada(condutaModal)
    handleModalClose()
  }

  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
      <ModalBody>
        <VFlow>
          <TitleGroup title={condutaModal.titulo} />
          <Text color='primary' fontSize={1} component='strong'>
            Grupo {condutaModal.grupoDengue} (ausência de sinais de alarme; sem comorbidades ou condições especiais)
          </Text>
          <Text>
            Ao aplicar a conduta recomendada, o PEC realiza <Text component='strong'>automaticamente</Text> as seguintes
            ações:
          </Text>
          {condutaModal.prescricoes?.isNotEmpty() && (
            <VFlow>
              <VFlow vSpacing={0.5}>
                <Text component='strong'>Prescrição de medicamentos: </Text>
                <Alert type='info' styles={{ wrapper: styles.alertContainer }}>
                  <Text color='primary' component='p'>
                    Selecione os medicamentos que deseja prescrever. Após aplicar a conduta,{' '}
                    <Text color='primary' fontWeight='bold'>
                      é possível ajustar a prescrição
                    </Text>
                    .
                  </Text>
                  <Text color='primary' component='li'>
                    Selecione apenas 1 entre{' '}
                    <Text color='primary' fontWeight='bold'>
                      Paracetamol ou Dipirona
                    </Text>
                    .
                  </Text>
                  {idadeEmAnos >= 13 && (
                    <Text color='primary' component='li'>
                      Selecione apenas 1 entre{' '}
                      <Text color='primary' fontWeight='bold'>
                        Dexcloferinamina ou Loratadina
                      </Text>
                      .
                    </Text>
                  )}
                </Alert>
                <VFlow vSpacing={0.5}>
                  {condutaModal.prescricoes.map((prescricao, index) => (
                    <PrescricaoCheckbox
                      key={index}
                      prescricao={prescricao}
                      handleTogglePrescricao={handleTogglePrescricao}
                    />
                  ))}
                </VFlow>
              </VFlow>
            </VFlow>
          )}
          <Text component='strong'>Campo "Orientações ao cidadão" na aba "Orientações":</Text>
          <Text dangerouslySetInnerHTML={{ __html: condutaModal.observacao }} />
          <Alert type='info' styles={{ wrapper: styles.alertContainer }}>
            <Text color='primary'>
              Após aplicar a conduta, revise os registros feitos automaticamente. Se necessário, edite-os antes de
              imprimir e entregar ao cidadão.
            </Text>
          </Alert>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <FooterButton onClick={handleModalClose}>Cancelar</FooterButton>
          <FooterButton kind='primary' onClick={handleAplicarConduta}>
            Aplicar conduta recomendada
          </FooterButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

// TODO@CODEBENDERS Refatorar pra um converter #24848
const converterMedicamentoCondutaToMedicamentoFormModel = (prescricao: ReceitaMedicamentoSdcDto) => {
  const dataInicioTratamento = new Date()
  const dataFimTratamento = addDays(dataInicioTratamento, prescricao.duracaoTratamentoEmDias)

  return (
    prescricao.prescrever && {
      id: prescricao.medicamentoCatmat?.medicamento.id,
      dataInicioTratamento: dateAsYyyyMmDd(dataInicioTratamento),
      dataFimTratamento: dateAsYyyyMmDd(dataFimTratamento),
      duracao: prescricao.duracaoTratamentoEmDias,
      escalaDuracao: prescricao.medidaTempoFrequencia,
      intervaloDose: prescricao.intervaloFrequencia,
      tipoFrequencia: prescricao.tipoFrequenciaDose,
      tipoDose: TipoDoseEnum.COMUM,
      isEditFirstLoad: false,
      viaAdministracao: {
        id: prescricao.aplicacaoMedicamento.id,
        nome: prescricao.aplicacaoMedicamento.nome,
      },
      posologia: prescricao.posologia,
      qtDose: prescricao.quantidadeDose,
      unidadeFornecimento: prescricao.medicamentoCatmat.medicamento.unidadeFornecimento,
      recomendacoes: prescricao.recomendacoes,
      quantidade: prescricao.quantidadeReceitada,
      principioAtivoCombo: prescricao.medicamentoCatmat,
      unidadeMedidaDose: prescricao.unidadeMedidaDose,
    }
  )
}

const styles = {
  alertContainer: css`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    & li {
      margin-left: 0.25rem;
      display: block;
    }

    & li::before {
      content: '•';
      display: inline-flex;
      width: 1ch;
      margin-right: 0.5rem;
    }
  `,
}

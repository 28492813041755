/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Modal, ModalBody, Theme, useTheme } from 'bold-ui'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'

import { SdcDetalhesModalBody } from './SdcDetalhesModalBody'
import { SdcDetalhesModalSideMenu } from './SdcDetalhesModalSideMenu'

interface SdcDetalhesModalProps {
  isModalOpen: boolean
  onClose: () => void
  alertaSelecionado: AlertaSuporteDecisaoClinica
}

// TODO@CODEBENDERS substituir o modal atual do SDC pelo novo modal do Bold com header fixo quando for disponibilizado #24923
export const SdcDetalhesModal = (props: SdcDetalhesModalProps) => {
  const { isModalOpen, onClose, alertaSelecionado } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Modal open={isModalOpen} onClose={onClose} size='auto' closeOnBackdropClick={false} style={styles.modalContainer}>
      <div css={styles.modalContentWrapper}>
        <SdcDetalhesModalSideMenu alertaSelecionado={alertaSelecionado} />
        <ModalBody style={styles.modalBody}>
          <SdcDetalhesModalBody alertaSelecionado={alertaSelecionado} />
        </ModalBody>
      </div>
    </Modal>
  )
}

const createStyles = (theme: Theme) => ({
  modalContainer: css`
    max-height: unset;
  `,
  modalContentWrapper: css`
    display: flex;
    flex-direction: row;
    height: 80vh;
    overflow-y: hidden;
    overflow-x: auto;

    @media (min-height: 768px) {
      height: 43rem;
    }

    ::-webkit-scrollbar {
      height: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background-color: ${theme.pallete.gray.c90};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: ${theme.pallete.gray.c70};
    }
  `,
  modalBody: css`
    padding: 0;
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SelectMenuItem, Text } from 'bold-ui'

import { LotacaoAgendaSelectTipoGrupoVazio } from '../model-lotacaoAgendaSelectField'

interface LotacaoAgendaSelectGrupoVazioItemProps {
  grupoTipo: LotacaoAgendaSelectTipoGrupoVazio
  hideAcessoRapido?: boolean
}

export function LotacaoAgendaSelectGrupoVazioItem(props: LotacaoAgendaSelectGrupoVazioItemProps) {
  const { grupoTipo, hideAcessoRapido = false } = props

  const textoProfissionais = hideAcessoRapido
    ? 'Digite para buscar um profissional.'
    : 'Digite para pesquisar por outros profissionais.'

  return (
    <SelectMenuItem style={styles.disabledItem}>
      <Text fontStyle='italic'>
        {grupoTipo === LotacaoAgendaSelectTipoGrupoVazio.FIXADOS_VAZIO
          ? 'Fixe um profissional para adicioná-lo à lista de acesso rápido.'
          : textoProfissionais}
      </Text>
    </SelectMenuItem>
  )
}

const styles = {
  disabledItem: css`
    pointer-events: none;
    cursor: not-allowed;
  `,
}

import { SelectDownshiftMenuProps } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'

import { LotacaoAgendaSelectModel } from './LotacaoAgendaSelect'

export enum LotacaoAgendaSelectTipoGrupoVazio {
  FIXADOS_VAZIO = 'FIXADOS_VAZIO',
  OUTROS_VAZIO = 'OUTROS_VAZIO',
  VAZIO = 'VAZIO',
}

export enum LotacaoAgendaSelectTipoGrupoCabecalho {
  CABECALHO_FIXADOS = 'CABECALHO_FIXADOS',
  CABECALHO_OUTROS = 'CABECALHO_OUTROS',
}

export type LotacaoAgendaSelectItemModel =
  | LotacaoAgendaSelectTipoGrupoVazio
  | LotacaoAgendaSelectTipoGrupoCabecalho
  | LotacaoAgendaSelectModel

export function isLotacaoAgendaSelectModel(item: LotacaoAgendaSelectItemModel): item is LotacaoAgendaSelectModel {
  return (item as LotacaoAgendaSelectModel)?.id !== undefined
}

export function isTipoGrupoVazio(item: LotacaoAgendaSelectItemModel): item is LotacaoAgendaSelectTipoGrupoVazio {
  return (
    item === LotacaoAgendaSelectTipoGrupoVazio.FIXADOS_VAZIO ||
    item === LotacaoAgendaSelectTipoGrupoVazio.OUTROS_VAZIO ||
    item === LotacaoAgendaSelectTipoGrupoVazio.VAZIO
  )
}

export function isTipoGrupoCabecalho(
  item: LotacaoAgendaSelectItemModel
): item is LotacaoAgendaSelectTipoGrupoCabecalho {
  return (
    item === LotacaoAgendaSelectTipoGrupoCabecalho.CABECALHO_FIXADOS ||
    item === LotacaoAgendaSelectTipoGrupoCabecalho.CABECALHO_OUTROS
  )
}

export interface LotacaoAgendaSelectFieldItemProps extends SelectDownshiftMenuProps<LotacaoAgendaSelectItemModel> {
  item: LotacaoAgendaSelectItemModel
  index: number
}

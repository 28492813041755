import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'
import { StatusCadastroImovelEnum } from 'view/visualizacaoTerritorial/model'

interface StatusCadastroSelectFieldProps
  extends Omit<SelectFieldProps<StatusCadastroImovelEnum>, 'items' | 'itemToString'> {}

export function StatusCadastroSelectField(props: StatusCadastroSelectFieldProps) {
  return <SelectField<StatusCadastroImovelEnum> items={items} itemToString={renderItem} {...props} />
}

export const itemToString: Record<StatusCadastroImovelEnum, string> = {
  COMPLETO: 'Completo',
  INCOMPLETO: 'Incompleto',
  RECUSADO: 'Recusado',
}

export const items = Object.keys(itemToString) as StatusCadastroImovelEnum[]

const renderItem = (item: StatusCadastroImovelEnum) => itemToString[item]

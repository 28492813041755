import { SelectHelperMenuItem } from 'bold-ui'
import React from 'react'

export interface MaisItensSelectMenuItemProps {
  selectMaxItems: number
}

export function MaisItensSelectMenuItem(props: MaisItensSelectMenuItemProps) {
  const { selectMaxItems } = props
  return (
    <SelectHelperMenuItem
      onClick={(ev) => {
        ev.stopPropagation()
        ev.preventDefault()
      }}
    >
      Mostrando somente os primeiros {selectMaxItems} resultados. Para ver mais resultados, refine sua busca.
    </SelectHelperMenuItem>
  )
}

import { Button, Cell, Grid, HFlow, Icon, Text, VFlow } from 'bold-ui'
import { orange } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'
import { HLabel } from 'components/HLabel'
import theme from 'config/theme'
import { css } from 'emotion'
import { Cbo, Equipe, Profissional, UnidadeSaude } from 'graphql/types.generated'
import React from 'react'

export interface LotacaoResponsavelCardModel {
  profissional: Pick<Profissional, 'id' | 'nome'>
  cbo: Pick<Cbo, 'id' | 'nome'>
  unidadeSaude: Pick<UnidadeSaude, 'id' | 'nome'>
  equipe?: Pick<Equipe, 'id' | 'nome'>
}

interface LotacaoResponsavelCardProps {
  responsavel: LotacaoResponsavelCardModel
  isLotacaoActive?: boolean
  onAlterarResponsavel?: () => void
}

export function LotacaoResponsavelCard(props: LotacaoResponsavelCardProps) {
  const { responsavel, isLotacaoActive, onAlterarResponsavel } = props
  const { profissional, cbo, unidadeSaude, equipe } = responsavel
  return (
    <Grid>
      <Cell size={7}>
        <Box style={styles.box}>
          <HFlow alignItems='center' justifyContent='space-between'>
            <VFlow vSpacing={0}>
              <Text fontWeight='bold'>{profissional != null ? profissional.nome : 'Sem responsável definido'}</Text>
              <HFlow
                alignItems='center'
                style={css`
                  grid-gap: 0.25rem;
                `}
              >
                <HLabel title='CBO'>{profissional != null ? cbo?.nome.capitalize() ?? 'Não informado' : null}</HLabel>
                {isLotacaoActive === false && <Icon icon='exclamationTriangleFilled' size={1} color={orange.c40} />}
              </HFlow>
              <HLabel title='Equipe'>{equipe?.nome ?? 'Sem equipe'}</HLabel>
              <HLabel title='Unidade de saúde'>{unidadeSaude.nome}</HLabel>
            </VFlow>

            {onAlterarResponsavel && (
              <Button onClick={onAlterarResponsavel} size='small'>
                <HFlow hSpacing={0.5}>
                  <Icon icon='exchange' />
                  Alterar responsável
                </HFlow>
              </Button>
            )}
          </HFlow>
        </Box>
      </Cell>
      <Cell size={5} />
    </Grid>
  )
}

const styles = {
  box: css`
    border: 1px solid ${theme.pallete.gray.c60};
  `,
}

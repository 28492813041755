import { useConfiguracoes } from 'hooks/configuracoes'
import React from 'react'
import { Route, Switch } from 'react-router'
import { LinksUteisView } from 'view/links-uteis/LinksUteisView'
import { InitialContainer } from 'view/login/InitialContainer'
import { InitialContent } from 'view/login/InitialContent'
import { LoginView } from 'view/login/LoginView'
import { LogoutView } from 'view/login/LogoutView'
import { RecuperarSenhaView } from 'view/recuperar-senha/RecuperarSenhaView'
import { SolicitarRecuperarSenhaView } from 'view/recuperar-senha/SolicitarRecuperarSenhaView'
import ResetRootView from 'view/reset/ResetRootView'
import { TermosDeUsoView } from 'view/termos-de-uso/TermosDeUsoView'
import { SairVideochamadaView } from 'view/videochamada/SairVideochamadaView'
import { VideochamadaEncerradaView } from 'view/videochamada/VideochamadaEncerradaView'
import { VideochamadaPerdaConexaoView } from 'view/videochamada/VideochamadaPerdaConexaoView'
import { VideochamadaRootView } from 'view/videochamada/VideochamadaRootView'
import { VideochamadaTimeOutView } from 'view/videochamada/VideochamadaTimeOutView'

export const PublicRootView = () => {
  return (
    <Switch>
      <Route component={LinksUteisView} path='/linksUteis' />
      <Route component={TermosDeUsoView} path='/termosUso' />
      <Route component={LogoutView} path='/logoutSuccess' />
      <Route component={ResetRootView} path='/reset' />
      <Route component={RecuperarSenhaView} path='/recuperarSenha/:token' exact />

      <Route component={VideochamadaEncerradaView} path='/videochamada/encerrada' />
      <Route component={VideochamadaTimeOutView} path='/videochamada/timeout' />
      <Route component={SairVideochamadaView} path='/videochamada/:uuid/sair' />
      <Route component={VideochamadaPerdaConexaoView} path='/videochamada/:uuid/perda-conexao' />
      <Route component={VideochamadaRootView} path='/videochamada/:uuid' />
      <Route component={InitialView} />
    </Switch>
  )
}

const InitialView = () => {
  const {
    configuracoes: { smtpConfigurado },
  } = useConfiguracoes()
  return (
    <InitialContainer>
      <InitialContent>
        <Switch>
          {smtpConfigurado && <Route component={SolicitarRecuperarSenhaView} path='/recuperarSenha' exact />}
          <Route component={LoginView} path='/' />
        </Switch>
      </InitialContent>
    </InitialContainer>
  )
}

import { defaultComponents as selectMenuDefaultComponents } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import React from 'react'

import {
  MaisItensSelectMenuItem,
  MaisItensSelectMenuItemProps,
} from '../../MaisItensSelectMenuItem/MaisItensSelectMenuItem'
import { LotacaoAgendaSelectFieldItemProps, LotacaoAgendaSelectTipoGrupoVazio } from '../model-lotacaoAgendaSelectField'
import { LotacaoAgendaSelectGrupoVazioItem } from './LotacaoAgendaSelectGrupoVazioItem'

interface LotacaoAgendaSelectSemAcessoRapidoItemProps
  extends LotacaoAgendaSelectFieldItemProps,
    MaisItensSelectMenuItemProps {}

export function LotacaoAgendaSelectSemAcessoRapidoItem(props: LotacaoAgendaSelectSemAcessoRapidoItemProps) {
  const { index, selectMaxItems: selectSize, ...rest } = props

  const {
    downshift: { getItemProps },
    item,
    renderItem,
  } = rest

  if (item === LotacaoAgendaSelectTipoGrupoVazio.VAZIO) {
    return <LotacaoAgendaSelectGrupoVazioItem grupoTipo={item} hideAcessoRapido />
  }

  return index < selectSize ? (
    <selectMenuDefaultComponents.Item index={index} {...rest}>
      {renderItem(item)}
    </selectMenuDefaultComponents.Item>
  ) : (
    <MaisItensSelectMenuItem {...getItemProps({ item, index })} selectMaxItems={selectSize} />
  )
}

import { addMinutes, getDay, getMinutes, set, startOfMinute } from 'date-fns'
import { range } from 'lodash'
import { isBetween } from 'util/date/compare'

import {
  AgendaEventModel,
  convertIntervalToMinutePrecision as convertIntervalToMinutesPrecision,
  DateInterval,
  SlotStatus,
  TimeInterval,
  TimeOfDay,
  Weekday,
  WeekTimeIntervals,
} from './model-agenda'

export function computeAvailableSlots(
  availableTimes: WeekTimeIntervals
): { isAvailable(slot: DateInterval): boolean; minTime(date: Date): Date; maxTime(date: Date): Date } {
  const getWeekdayConfig = (date: Date) => availableTimes[getDay(date)]

  const minMaxTimeMap = getMinMaxTimeMap(availableTimes)

  const isAvailable = (slot: DateInterval) =>
    getWeekdayConfig(slot.start)?.some((interval) => slotIsInsideInterval(slot, interval))

  const minTime = (date: Date) => setTime(date, minMaxTimeMap[getDay(date)].min)

  const maxTime = (date: Date) => setTime(date, minMaxTimeMap[getDay(date)].max)

  return { isAvailable, minTime, maxTime }
}

type SlotOccupiedStatus = SlotStatus.OCCUPIED | SlotStatus.PARTIALLY_OCCUPIED

export function computeOccupiedSlots(
  slotsInit: Date,
  stepInMinutes: number,
  events: ReadonlyArray<AgendaEventModel>
): { isOccupied(slot: DateInterval): SlotOccupiedStatus | false } {
  const occupiedSlotsMap = getOccupiedSlotsMap(startOfMinute(slotsInit), minutesToTicks(stepInMinutes), events)

  const isOccupied = (slot: DateInterval) => occupiedSlotsMap.get(startOfMinute(slot.start).getTime())

  return { isOccupied }
}

export function computeTimeUntilNextSlot(time: Date, slotInterval: number) {
  const maxTimeMinutes = getMinutes(time)
  const remainder = maxTimeMinutes % slotInterval
  return addMinutes(time, slotInterval - remainder - 1)
}

const getOccupiedSlotsMap = (
  init: Date,
  stepInTicks: number,
  events: ReadonlyArray<AgendaEventModel>
): Map<number, SlotOccupiedStatus> =>
  new Map(
    events.map(convertIntervalToMinutesPrecision).flatMap((ev) =>
      range(
        getSlotStartInTicks(init.getTime(), stepInTicks, ev.start.getTime()),
        // -1 para não ocupar próximo slot quando termina exatamente no início do próximo
        // +1 porque o `range` não inclui o `end`
        getSlotStartInTicks(init.getTime(), stepInTicks, ev.end.getTime() - 1) + 1,
        stepInTicks
      ).map((slotStartInTicks) => [slotStartInTicks, getSlotOccupiedStatus(slotStartInTicks, stepInTicks, ev)])
    )
  )

const getSlotOccupiedStatus = (slotStartInTicks: number, stepInTicks: number, ev: DateInterval): SlotOccupiedStatus =>
  slotStartInTicks < +ev.start || slotStartInTicks + stepInTicks > +ev.end
    ? SlotStatus.PARTIALLY_OCCUPIED
    : SlotStatus.OCCUPIED

const minutesToTicks = (minutes: number) => minutes * 60000

const getSlotStartInTicks = (slotsInitInTicks: number, stepInTicks: number, pointInTimeInTicks: number) =>
  slotsInitInTicks + Math.floor((pointInTimeInTicks - slotsInitInTicks) / stepInTicks) * stepInTicks

const slotIsInsideInterval = (slot: DateInterval, interval: TimeInterval) =>
  slotIsBetween(slot, timeIntervalInDay(slot.start, interval))

export const timeIntervalInDay = (date: Date, interval: TimeInterval) =>
  date && interval
    ? {
        start: setTime(date, interval.start),
        end: setTime(date, interval.end),
      }
    : undefined

const slotIsBetween = (slot: DateInterval, interval: DateInterval) =>
  isBetweenInterval(slot.start, interval) && isBetweenInterval(slot.end, interval)

const isBetweenInterval = (date: Date, interval: DateInterval) => isBetween(date, interval.start, interval.end)

const getMinMaxTimeMap = (availableTimes: WeekTimeIntervals): Record<Weekday, { min: TimeOfDay; max: TimeOfDay }> =>
  Object.values(Weekday).reduce((acc, curr: Weekday) => {
    acc[curr] = getMinMax(availableTimes, curr)
    return acc
  }, {} as Record<Weekday, { min: TimeOfDay; max: TimeOfDay }>)

const getMinMax = (availableTimes: WeekTimeIntervals, weekday: Weekday) => {
  const weekdayConfig = availableTimes[weekday]
  if (!weekdayConfig?.length) return { min: { hours: 7, minutes: 0 }, max: { hours: 19, minutes: 0 } }

  const result = { min: { hours: 24, minutes: 60 }, max: { hours: 0, minutes: 0 } }

  for (let { start, end } of weekdayConfig) {
    if (start.hours + start.minutes / 100 < result.min.hours + result.min.minutes / 100) result.min = start
    if (end.hours + end.minutes / 100 > result.max.hours + result.max.minutes / 100) result.max = end
  }

  return result
}

const setTime = (date: Date, time: TimeOfDay) =>
  set(date, { ...(time ?? { hours: 0, minutes: 0 }), seconds: 0, milliseconds: 0 })

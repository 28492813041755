/** @jsx jsx */
import { jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import { IvcfSubgrupoPerguntaEnum } from 'graphql/types.generated'

import { IVCF_SUBGRUPOS, IvcfAplicacaoModel } from '../../model-ivcf'
import { IvcfSubgrupoWrapper } from '../components/IvcfSubgrupoWrapper'
import { IvcfPerguntaView } from './IvcfPerguntaView'

interface IvcfSubgrupoViewProps {
  subgrupo: IvcfSubgrupoPerguntaEnum
  value: IvcfAplicacaoModel
}

export function IvcfSubgrupoView(props: IvcfSubgrupoViewProps) {
  const { subgrupo, value } = props
  const { perguntas } = IVCF_SUBGRUPOS[subgrupo]

  return (
    <IvcfSubgrupoWrapper subgrupo={subgrupo}>
      <VFlow vSpacing={0}>
        {perguntas.map((pergunta) => (
          <IvcfPerguntaView key={pergunta} pergunta={pergunta} value={value} />
        ))}
      </VFlow>
    </IvcfSubgrupoWrapper>
  )
}

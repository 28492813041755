/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { TableRowMenu, TableRowMenuButton } from 'components/table'
import { FormApi } from 'final-form'
import { Fragment, useState } from 'react'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { formatNomePrincipioAtivo } from '../../../aside/alergias/formatNomePrincipioAtivo'
import { categoriaAlergiaRecord } from '../../../aside/types/AlergiaModel'
import { AlergiasReacoesForm } from './AlergiasReacoesForm'
import { AlergiaReacaoModel } from './model'

export interface AlergiaReacaoRowProps {
  cidadao: CidadaoAtendimento
  model: AlergiaReacaoModel

  onChange(values: AlergiaReacaoModel): void
  onRemove(item: AlergiaReacaoModel): void
}

export function AlergiaReacaoRow(props: AlergiaReacaoRowProps) {
  const { model, onChange, onRemove } = props
  const [isEditing, setIsEditing] = useState(false)
  const alert = useAlert()

  const handleExibirLinha = () => setIsEditing(false)
  const handleEditar = () => setIsEditing(true)

  const handleRemove = () => {
    onRemove(model)
    alert('success', alertMessageRemoveAlergia(model))
  }

  const handleSubmit = (values: AlergiaReacaoModel, formApi: FormApi) => {
    onChange(values)
    setTimeout(formApi.reset)
    handleExibirLinha()
  }

  return (
    <EditableRow editing={isEditing}>
      {!isEditing && (
        <Fragment>
          <Grid
            style={css`
              margin: 0;
            `}
          >
            <Cell size={4} alignSelf='center'>
              {categoriaAlergiaRecord[model.categoriaSubstancia]?.descricao}
            </Cell>
            <Cell size={5} alignSelf='center'>
              {model.substanciaCbara?.nome ??
                model.substanciaImunobiologico?.nome ??
                formatNomePrincipioAtivo({
                  principioAtivo: model?.substanciaMedicamento?.medicamento?.principioAtivo,
                  concentracao: model?.substanciaMedicamento?.medicamento?.concentracao,
                })}
            </Cell>
            <Cell
              size={3}
              alignSelf='center'
              style={css`
                padding: 0.25rem 0;
                text-align: right;
              `}
            >
              <TableRowMenu>
                <TableRowMenuButton text='Editar' icon='penOutline' onClick={handleEditar} />
                <TableRowMenuButton text='Excluir' icon='trashOutline' onClick={handleRemove} />
              </TableRowMenu>
            </Cell>
          </Grid>
          <Observacao observacao={model.observacao} />
        </Fragment>
      )}
      {isEditing && (
        <VFlow
          style={css`
            padding: 1rem 0;
          `}
        >
          <Heading level={4}>Editar alergia / reação adversa</Heading>
          <AlergiasReacoesForm
            edicao
            initialValues={model}
            onCancel={handleExibirLinha}
            onSubmit={handleSubmit}
            initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
          />
        </VFlow>
      )}
    </EditableRow>
  )
}

const alertMessageRemoveAlergia = (model: AlergiaReacaoModel) =>
  `A avaliação da alergia à ${
    model.substanciaCbara?.nome ??
    model.substanciaImunobiologico?.nome ??
    formatNomePrincipioAtivo({
      principioAtivo: model?.substanciaMedicamento?.medicamento?.principioAtivo,
      concentracao: model?.substanciaMedicamento?.medicamento?.concentracao,
    })
  }
  foi excluída.`

import { isEmpty, isEqual } from 'bold-ui'
import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { parseISO } from 'date-fns'
import createDecorator, { Calculation } from 'final-form-calculate'
import { SituacaoProblema } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'
import { CiapCidPreNatal } from 'view/atendimentos/atendimento-individual/model'

import { dateAsYyyyMmDd } from '../../../../../../../util/date/formatDate'
import { Problema } from '../../../aside/types/ProblemaModel'
import {
  getW78,
  isCiapEncerramentoGestacao,
  isCiapGestacao,
  isCidEncerramentoGestacao,
  isCidFromFamiliaZ34,
  isCidGestacao,
} from '../../../pre-natal/util-preNatal'
import { convertDataProblema, convertProblemaCondicao } from './converter-problemasCondicoes'
import { ProblemaCondicaoModel } from './model-problemasCondicoes'
import {
  atualizarProblemaCondicao,
  findProblemaByCiapIdCidId,
  findProblemaById,
} from './utils/operations-problemasCondicoes'
import {
  isEvolucao,
  isProblemaComCiapW78,
  isProblemaCondicaoComCiapW78,
  isProblemaCondicaoEncerraGestacao,
  isProblemaCondicaoGravidezAltoRisco,
} from './utils/verifications-problemasCondicoes'

export type ProblemaCondicaoModelPrefix = { [innerPrefix: string]: ProblemaCondicaoModel }

const meta = metaPath<{ [x: string]: ProblemaCondicaoModel }>()

export const createProblemaCondicoesCalculator = (
  problemasCidadao: Problema[],
  dataNascimentoCidadao: LocalDate,
  ciapCidPreNatal: CiapCidPreNatal,
  somenteCiap: boolean,
  prefix: string,
  dataAtendimento: LocalDate,
  isAdicionandoNaLPC: boolean,
  isAntecedentes: boolean,
  problemasToDisabled: ProblemaCondicaoModel[]
) =>
  createDecorator(
    createProblemaCondicaoEvoluirCalculation(
      problemasCidadao,
      dataNascimentoCidadao,
      somenteCiap,
      ciapCidPreNatal,
      prefix
    ),
    createSituacaoProblemaCalculation(prefix),
    createIncluirNaListaProblemasCalculation(problemasCidadao, dataNascimentoCidadao, prefix),
    createCiapCidCalculation(
      problemasCidadao,
      dataNascimentoCidadao,
      ciapCidPreNatal,
      somenteCiap,
      prefix,
      dataAtendimento,
      isAdicionandoNaLPC,
      isAntecedentes,
      problemasToDisabled
    )
  )

export const createProblemaCondicaoEvoluirCalculation = (
  problemasCidadao: Problema[],
  dataNascimentoCidadao: LocalDate,
  somenteCiap: boolean,
  ciapCidPreNatal: CiapCidPreNatal,
  prefix: string
): Calculation => ({
  field: [meta[prefix].problemaCondicaoEvoluir.absolutePath()],
  updates: (value: Problema) => {
    const problema =
      findProblemaById(problemasCidadao, value?.id) ??
      findProblemaByCiapIdCidId(problemasCidadao, value?.ciap?.id, value?.cid10?.id)
    const devePreencherCidComCidZ34 = !somenteCiap && isProblemaComCiapW78(problema) && isEmpty(problema?.cid10)

    return {
      [prefix]: {
        ...convertProblemaCondicao(problema, dataNascimentoCidadao),
        ...(devePreencherCidComCidZ34 && { cid: ciapCidPreNatal.cid }),
      },
    }
  },
})

export const createSituacaoProblemaCalculation = (prefix: string): Calculation => ({
  field: [meta[prefix].situacaoProblema.absolutePath()],
  updates: (value: SituacaoProblema, _, allValues: { [x: string]: ProblemaCondicaoModel }) =>
    value === SituacaoProblema.ATIVO
      ? {
          [prefix]: {
            ...allValues[prefix],
            dataFim: undefined,
          },
        }
      : {
          [prefix]: {
            ...allValues[prefix],
          },
        },
})

export const createIncluirNaListaProblemasCalculation = (
  problemasCidadao: Problema[],
  dataNascimentoCidadao: LocalDate,
  prefix: string
): Calculation => ({
  field: [meta[prefix].incluirNaListaProblemas.absolutePath()],
  updates: (_, __, allValues: { [x: string]: ProblemaCondicaoModel }) => {
    const problemaCondicao = allValues[prefix]
    const problemaEncontrado =
      findProblemaById(problemasCidadao, problemaCondicao?.problemaId) ??
      findProblemaByCiapIdCidId(problemasCidadao, problemaCondicao?.ciap?.id, problemaCondicao?.cid?.id)

    const problema = problemaEncontrado
      ? {
          ...convertProblemaCondicao(problemaEncontrado, dataNascimentoCidadao, problemaCondicao),
          _id: problemaCondicao._id,
        }
      : { ...problemaCondicao }

    return !problemaCondicao?.incluirNaListaProblemas
      ? {
          [prefix]: {
            ...problemaCondicao,
            situacaoProblema: undefined,
            dataInicio: undefined,
            dataFim: undefined,
            observacao: undefined,
          },
        }
      : { [prefix]: problema }
  },
})

export const createCiapCidCalculation = (
  problemasCidadao: Problema[],
  dataNascimentoCidadao: LocalDate,
  ciapCidPreNatal: CiapCidPreNatal,
  somenteCiap: boolean,
  prefix: string,
  dataAtendimento: LocalDate,
  isAdicionandoNaLPC: boolean,
  isAntecedentes: boolean,
  problemasToDisabled: ProblemaCondicaoModel[]
): Calculation => ({
  field: [meta[prefix].ciap.absolutePath(), meta[prefix].cid.absolutePath()],
  updates: (
    value: CiapSelectFieldModel | CidSelectFieldModel,
    _,
    allValues: ProblemaCondicaoModelPrefix,
    prevValues: ProblemaCondicaoModelPrefix
  ) => {
    const problema = allValues[prefix]

    const prevProblema = prevValues[prefix]

    let problemaExistente = null

    if (!isAdicionandoNaLPC) {
      problemaExistente = problemasCidadao?.find(
        (problemaCidadao) =>
          problemaCidadao.situacao !== SituacaoProblema.RESOLVIDO &&
          problemaCidadao.ciap?.codigo === problema?.ciap?.codigo &&
          problemaCidadao.cid10?.codigo === problema?.cid?.codigo
      )
    }

    const isProblemaExistenteDisabled =
      problemasToDisabled && problemasToDisabled.some((o2) => problemaExistente?.id === o2.problemaId)

    if (deveLimparOndeTinhaW78(problema, prevProblema)) {
      return handleAlteracaoOndeTinhaCiapW78(prefix, problema)
    } else if (isCiapEncerramentoGestacao(value?.codigo) || isCidEncerramentoGestacao(value?.codigo)) {
      return {
        [prefix]: {
          ...problema,
          dataInicio: problema.problemaId
            ? problema.dataInicio
            : convertDataProblema(dateAsYyyyMmDd(parseISO(dataAtendimento)), parseISO(dataNascimentoCidadao)),
          incluirNaListaProblemas: true,
        },
      }
    } else if (isCiapGestacao(value?.codigo) && isUndefinedOrNull(problema?.cid)) {
      const w78JaExistente = getW78(problemasCidadao, problema)
      const problemaCondicao = handleAdicaoCiapGestacao(prefix, problema, somenteCiap, ciapCidPreNatal)
      const dataInicio = calculateDataInicio(problemaCondicao[prefix], dataAtendimento)
      return handleAtualizacaoProblemaExistente(
        prefix,
        w78JaExistente,
        problemaCondicao[prefix],
        dataNascimentoCidadao,
        dataInicio,
        isAdicionandoNaLPC,
        isAntecedentes
      )
    } else if (isCidGestacao(value?.codigo) && isUndefinedOrNull(problema?.ciap)) {
      const w78JaExistente = getW78(problemasCidadao, problema)
      const problemaCondicao = handleAdicaoCidGestacao(prefix, problema, ciapCidPreNatal)
      const dataInicio = calculateDataInicio(problemaCondicao[prefix], dataAtendimento)
      return handleAtualizacaoProblemaExistente(
        prefix,
        w78JaExistente,
        problemaCondicao[prefix],
        dataNascimentoCidadao,
        dataInicio,
        isAdicionandoNaLPC,
        isAntecedentes
      )
    } else if (problemaExistente && !isProblemaExistenteDisabled && !problema?.problemaCondicaoEvoluir) {
      return {
        [prefix]: {
          ...convertProblemaCondicao(problemaExistente, dataNascimentoCidadao),
        },
      }
    } else if (deveLimparCamposPreenchidosAutomaticamente(problema, prevProblema, somenteCiap)) {
      return limparCamposPreenchidosAutomaticamente(prefix, problema)
    } else return handleDefault(prefix, problema)
  },
  isEqual: (a, b) => isEqual(a, b),
})

function handleAdicaoCiapGestacao(
  prefix: string,
  problema: ProblemaCondicaoModel,
  somenteCiap: boolean,
  ciapCidPreNatal: CiapCidPreNatal
) {
  return {
    [prefix]: {
      ...problema,
      ...(!somenteCiap &&
        isProblemaCondicaoComCiapW78(problema) &&
        !isCidFromFamiliaZ34(problema.cid?.codigo) && {
          cid: {
            ...ciapCidPreNatal.cid,
          },
        }),
      incluirNaListaProblemas: true,
    },
  }
}

function handleAdicaoCidGestacao(prefix: string, problema: ProblemaCondicaoModel, ciapCidPreNatal: CiapCidPreNatal) {
  return {
    [prefix]: {
      ...problema,
      ...(isCidFromFamiliaZ34(problema.cid?.codigo) && {
        ciap: {
          ...ciapCidPreNatal.ciap,
        },
      }),
      incluirNaListaProblemas: true,
    },
  }
}

function handleDefault(prefix: string, problema: ProblemaCondicaoModel) {
  return {
    [prefix]: {
      ...problema,
    },
  }
}

function handleAtualizacaoProblemaExistente(
  prefix: string,
  problemaJaExistente: Problema,
  problemaCondicao: ProblemaCondicaoModel,
  dataNascimentoCidadao: string,
  dataInicio: LocalDate,
  isAdicionandoNaLPC: boolean,
  isAntecedentes: boolean
): ProblemaCondicaoModelPrefix {
  const isCiapW78 = isProblemaCondicaoComCiapW78(problemaCondicao)
  const situacaoProblema =
    isAntecedentes && isAdicionandoNaLPC
      ? SituacaoProblema.RESOLVIDO
      : !isAdicionandoNaLPC && isCiapW78 && SituacaoProblema.ATIVO

  const atualizacao = atualizarProblemaCondicao(problemaCondicao, problemaJaExistente, dataNascimentoCidadao)
  return !isAdicionandoNaLPC && problemaJaExistente && isCiapW78
    ? {
        [prefix]: {
          ...atualizacao,
        },
      }
    : {
        [prefix]: {
          ...problemaCondicao,
          dataInicio: convertDataProblema(dataInicio, parseISO(dataNascimentoCidadao)),
          situacaoProblema: situacaoProblema ? situacaoProblema : problemaCondicao.situacaoProblema,
        },
      }
}

function deveLimparCamposPreenchidosAutomaticamente(
  problema: ProblemaCondicaoModel,
  prevProblema: ProblemaCondicaoModel,
  somenteCiap: boolean
) {
  const codigoCiap = problema?.ciap?.codigo
  const prevCodigoCiap = prevProblema?.ciap?.codigo

  let wasEncerrarGestacao = isCiapEncerramentoGestacao(prevCodigoCiap)
  let isNotEncerramentoGestacao = !isCiapEncerramentoGestacao(codigoCiap)

  let wasCiapOrCidGestacao = isCiapGestacao(prevCodigoCiap)
  let isNotCiapOrCidGestacao = !isCiapGestacao(codigoCiap)

  if (!somenteCiap) {
    const codigoCid = problema?.cid?.codigo
    const prevCodigoCid = prevProblema?.cid?.codigo

    wasEncerrarGestacao = wasEncerrarGestacao || isCidEncerramentoGestacao(prevCodigoCid)
    isNotEncerramentoGestacao = isNotEncerramentoGestacao && !isCidEncerramentoGestacao(codigoCid)

    wasCiapOrCidGestacao = wasCiapOrCidGestacao || isCidGestacao(prevCodigoCid)
    isNotCiapOrCidGestacao = isNotCiapOrCidGestacao && !isCidGestacao(codigoCid)
  }

  return (
    (wasEncerrarGestacao || wasCiapOrCidGestacao) &&
    !isEvolucao(problema) &&
    isNotEncerramentoGestacao &&
    isNotCiapOrCidGestacao
  )
}

function limparCamposPreenchidosAutomaticamente(prefix: string, problema: ProblemaCondicaoModel) {
  return {
    [prefix]: {
      _id: null,
      problemaId: null,
      ciap: problema?.ciap,
      cid: problema?.cid,
      incluirNaListaProblemas: null,
      observacao: null,
      situacaoProblema: null,
      dataInicio: null,
      dataFim: null,
      problemaCondicaoEvoluir: null,
      automatico: null,
    },
  }
}

function deveLimparOndeTinhaW78(problema: ProblemaCondicaoModel, prevProblema: ProblemaCondicaoModel): boolean {
  return !isEvolucao(problema) && !isProblemaCondicaoComCiapW78(problema) && isProblemaCondicaoComCiapW78(prevProblema)
}

function handleAlteracaoOndeTinhaCiapW78(prefix: string, problema: ProblemaCondicaoModel) {
  const isProblemaAltoRiscoOuEncerraGestacao =
    isProblemaCondicaoGravidezAltoRisco(problema) || isProblemaCondicaoEncerraGestacao(problema)
  return !isProblemaAltoRiscoOuEncerraGestacao
    ? {
        [prefix]: {
          ...problema,
          cid: null,
          incluirNaListaProblemas: null,
          observacao: null,
          situacaoProblema: null,
          dataInicio: null,
          dataFim: null,
          automatico: null,
        },
      }
    : {
        [prefix]: {
          ...problema,
          cid: null,
        },
      }
}

function calculateDataInicio(problema: ProblemaCondicaoModel, dataAtendimento: LocalDate) {
  if (problema.problemaId) {
    return problema.dataInicio?.data
  } else {
    if (problema.dataInicio?.data) {
      return problema.dataInicio?.data
    } else {
      return dataAtendimento
    }
  }
}

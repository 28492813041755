/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, PagedTable, PagedTableProps, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Cns, Cpf, Telefone } from 'components/label'
import { TableBox } from 'components/table/TableBox'
import { VisualizarPaginaButton } from 'components/VisualizarPaginaButton'
import { RiscoVulnerabilidadeIvcfEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { IVCF_RESULTADOS } from 'view/atendimentos/detail/soap/objetivo/ivcf/model-ivcf'

import { AcompanhamentoCondicaoSaudeDto } from './model-acompanhamentoCondicaoSaude'

export interface AcompanhamentoCondicaoSaudeListingProps
  extends Omit<PagedTableProps<AcompanhamentoCondicaoSaudeDto>, 'columns'> {
  hasIvcfFilter?: boolean
}

export function AcompanhamentoCondicaoSaudeListing(props: AcompanhamentoCondicaoSaudeListingProps) {
  const { hasIvcfFilter, ...tableProps } = props

  const renderCidadao = (row: AcompanhamentoCondicaoSaudeDto) => {
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>{row.nomeSocialCidadao?.titleCase() ?? row.nomeCidadao?.titleCase()}</Text>
        {row.cpfCidadao ? (
          <HFlow hSpacing={0.25} alignItems='center'>
            <Text fontWeight='bold'>CPF</Text>
            <Cpf value={row.cpfCidadao} />
          </HFlow>
        ) : row.cnsCidadao ? (
          <HFlow hSpacing={0.25} alignItems='center'>
            <Text fontWeight='bold'>CNS</Text>
            <Cns value={row.cnsCidadao} />
          </HFlow>
        ) : (
          <Text>Cidadão sem CNS e CPF</Text>
        )}
      </VFlow>
    )
  }

  const renderTelefone = (row: AcompanhamentoCondicaoSaudeDto) => {
    return !row?.telefoneCelular && !row?.telefoneContato && !row?.telefoneResidencial ? (
      <Text>-</Text>
    ) : (
      <span>
        {row.telefoneResidencial && (
          <Fragment>
            <Telefone value={row.telefoneResidencial} /> <br />
          </Fragment>
        )}
        {row.telefoneCelular && (
          <Fragment>
            <Telefone value={row.telefoneCelular} /> <br />
          </Fragment>
        )}
        {row.telefoneContato && (
          <Fragment>
            <Telefone value={row.telefoneContato} /> <br />
          </Fragment>
        )}
      </span>
    )
  }

  const renderUltimoAtend = (row: AcompanhamentoCondicaoSaudeDto) => {
    return !row?.dataUltimoAtendimento ? (
      <Text>-</Text>
    ) : (
      <Fragment>
        {' '}
        {row.dataUltimoAtendimento && <DateTime value={row.dataUltimoAtendimento} format='DD/MM/YYYY' />}{' '}
      </Fragment>
    )
  }

  const renderIvcf = (row: AcompanhamentoCondicaoSaudeDto) => {
    return !row?.ivcfDataResultado && !row?.ivcfRiscoVulnerabilidade ? (
      <Text>Sem registros</Text>
    ) : (
      <VFlow vSpacing={0}>
        {row?.ivcfRiscoVulnerabilidade && (
          <Fragment>
            <Text>
              {colorLabel(IVCF_RESULTADOS[row.ivcfRiscoVulnerabilidade].shortTitle, row.ivcfRiscoVulnerabilidade)}
            </Text>
          </Fragment>
        )}
        {row?.ivcfDataResultado && (
          <VFlow vSpacing={0}>
            <Text>Registrado em</Text>
            <DateTime value={row.ivcfDataResultado} format='DD/MM/YYYY' />
          </VFlow>
        )}
      </VFlow>
    )
  }

  const styles = createStyles()

  return (
    <TableBox>
      <PagedTable<AcompanhamentoCondicaoSaudeDto>
        {...tableProps}
        columns={[
          {
            name: 'cidadao',
            header: 'Cidadão',
            sortable: true,
            render: renderCidadao,
            style: css`
              width: 18rem;
              padding: 1rem;
            `,
          },
          {
            name: 'endereco',
            header: 'Endereço',
            sortable: true,
            render: (row: AcompanhamentoCondicaoSaudeDto) => row.endereco.titleCase(),
            style: css`
              width: 26rem;
            `,
          },
          {
            name: 'telefone',
            header: 'Telefone',
            sortable: false,
            render: renderTelefone,
            style: css`
              width: 9rem;
            `,
          },
          {
            name: 'dataAtendimento',
            header: 'Último atendimento',
            sortable: true,
            render: renderUltimoAtend,
            style: css`
              width: 4.65rem;
            `,
          },
          ...(hasIvcfFilter
            ? [
                {
                  name: 'ivcf',
                  header: 'IVCF-20',
                  sortable: false,
                  render: renderIvcf,
                  style: styles.ivcfColumn,
                },
              ]
            : []),
          {
            name: 'visualizarCidadao',
            header: '',
            render: (row: AcompanhamentoCondicaoSaudeDto) => (
              <VisualizarPaginaButton path={`/cidadao/${row.idCidadao}/informacoes`} tooltipText='Visualizar cidadão' />
            ),
            style: { width: '4rem' },
          },
        ]}
      />
    </TableBox>
  )
}

const colorLabel = (nome: string, ivcfRiscoVulnerabilidade: RiscoVulnerabilidadeIvcfEnum) => {
  const ivcfStyles = createStyles(ivcfRiscoVulnerabilidade)
  return (
    <HFlow hSpacing={0}>
      <div
        css={css([
          ivcfStyles.indiceIvcf,
          ivcfStyles.ivcfLabel,
          css`
            width: 1rem;
            height: 1rem;
            border-radius: 0.125rem;
            margin-right: 0.5rem;
          `,
        ])}
      />
      {nome}
    </HFlow>
  )
}

const createStyles = (risco?: RiscoVulnerabilidadeIvcfEnum) => ({
  indiceIvcf: css`
    ${risco && `background: ${IVCF_RESULTADOS[risco].colorScale.c40};`}
  `,
  ivcfColumn: `
    width: 8rem;
  `,
  ivcfLabel: `
    width: 1rem;
    height: 1rem;
    border-radius: 0.125rem;
    margin-right: 0.5rem;
  `,
})

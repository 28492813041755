import { Link } from 'bold-ui'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const ControladorSection = () => {
  return (
    <TermosDeUsoHeadingSection title='3. Controlador' id='controlador'>
      <p>
        <b>
          A quem competem as decisões referentes ao tratamento de dados pessoais realizado no Prontuário Eletrônico do
          Cidadão (PEC) do Sistema e-SUS APS?
        </b>
      </p>
      <br />
      <p>
        A Lei Geral de Proteção de Dados Pessoais define como controlador, em seu Art. 5º, inciso VI, a pessoa natural
        ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados
        pessoais.
      </p>
      <br />
      <p>
        Além disso, a Autoridade Nacional de Proteção de Dados (ANPD), estabelece que há controladoria conjunta quando:{' '}
        <i>
          “Nas decisões comuns, duas ou mais entidades possuem uma intenção comum sobre as finalidades e meios de
          tratamento e tomam decisões em conjunto. Em contrapartida, nas decisões convergentes existem decisões
          distintas sendo tomadas, porém elas se complementam de tal forma que o tratamento não seria possível sem a
          participação de ambos os controladores”
        </i>
        , Guia Orientativo para Definições dos Agentes de Tratamento de Dados Pessoais e do Encarregado, publicado pela
        ANPD, pg. 14.
      </p>
      <br />
      <p>
        Para o Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, as decisões referentes ao tratamento de
        dados pessoais são de responsabilidade tanto do Ministério da Saúde, quanto dos <b>municípios</b> que aderem à
        respectiva estratégia, ou mesmo, quando for o caso, dos <b>estados</b>, exercendo, dessa forma, controladoria
        conjunta, uma vez que possuem poder de decisão sobre o tratamento de dados pessoais, há interesse mútuo, com
        base em finalidades próprias, sobre um mesmo tratamento e tomam decisões comuns ou convergentes sobre as
        finalidades e elementos essenciais do tratamento.
      </p>
      <br />
      <p>
        Endereço do Ministério da Saúde: Edifício Sede - Esplanada dos Ministérios, Bloco O, Brasília - DF, 70052-900.
      </p>
      <br />
      <p>
        E-mail:{' '}
        <Link fontSize={0.875} href='mailto:aps@saude.gov.br'>
          aps@saude.gov.br
        </Link>
      </p>
      <br />
      <p>Telefone do Ministério da Saúde: (61) 3315-2425</p>
    </TermosDeUsoHeadingSection>
  )
}

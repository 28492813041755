import React, { CSSProperties } from 'react'
import { EventWrapperProps } from 'react-big-calendar'

import { AgendaEventModel } from './model-agenda'

export interface AgendaEventProps<TEvent extends AgendaEventModel = AgendaEventModel>
  extends Omit<EventWrapperProps<TEvent>, 'style' | 'className'> {
  eventHasOverlap: boolean
}

export interface AgendaEventWrapperProps<TEvent extends AgendaEventModel = AgendaEventModel>
  extends EventWrapperProps<AgendaEventModel> {
  children: React.ComponentType<AgendaEventProps<TEvent>>
}

export function AgendaEventWrapper<TEvent extends AgendaEventModel>(props: AgendaEventWrapperProps<TEvent>) {
  const { style, className, children, ...rest } = convertEventComponentProps(props)
  return (
    <div style={style} className={className}>
      {React.createElement(children, { ...rest, eventHasOverlap: eventHasOverlap(style.width) })}
    </div>
  )
}

const convertEventComponentProps = <TEvent extends AgendaEventModel>(
  props: EventWrapperProps<TEvent> & { children: React.ComponentType<AgendaEventProps<TEvent>> }
) => ({
  ...props,
  style: {
    ...props.style,
    position: 'absolute',
    top: `${props.style.top}%`,
    left: `${props.style.xOffset}`,
    zIndex: 2,
  } as CSSProperties,
})

const WIDTH_WHEN_EVENT_HAS_NOT_OVERLAP = 'calc(100% - 0px)'
const eventHasOverlap = (width) => width !== WIDTH_WHEN_EVENT_HAS_NOT_OVERLAP

import { Button, Icon, Tooltip } from 'bold-ui'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'

import { useAlert } from '../../../../components/alert'
import { useErrorHandler } from '../../../../components/error'
import { downloadImpressaoAgendamentos } from './downloadImpressaoAgendamentos'

export interface ImpressaoAgendaButtonProps {
  areThereEventos: boolean
  lotacaoId: ID
  date: Date
  isAgendaAd: boolean
  nomeProfissional: string
}

export function ImpressaoAgendaButton(props: ImpressaoAgendaButtonProps) {
  const { areThereEventos, lotacaoId, date, isAgendaAd, nomeProfissional } = props

  const handleError = useErrorHandler()
  const alert = useAlert()
  const { analytics } = useFirebase()

  const clickBotaoImpressao = () => {
    analytics.logEvent('impressao_agendamentos_profissional')
    if (areThereEventos) {
      return downloadImpressaoAgendamentos({ lotacaoId, date, isAgendaAd, nomeProfissional }).catch(handleError)
    } else {
      alert('warning', 'Não existem agendamentos nesta data.')
    }
  }

  return (
    <Tooltip
      text={!areThereEventos ? 'Esta data não possui agendamentos para serem impressos' : 'Imprimir agendamentos'}
    >
      <Button
        onClick={clickBotaoImpressao}
        type='button'
        size='small'
        disabled={!lotacaoId || !areThereEventos}
        skin='ghost'
      >
        <Icon icon='printerOutline' />
      </Button>
    </Tooltip>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { addDays, subDays } from 'date-fns'
import { ToolbarProps } from 'react-big-calendar'
import { formatWeekdayDayMonthYear } from 'util/date/formatDate'

import { AgendaEventModel, TimeInterval, Weekday } from './model-agenda'

export interface AgendaToolbarProps extends Pick<ToolbarProps<AgendaEventModel, object>, 'date' | 'onNavigate'> {
  availableTimes?: Record<Weekday, TimeInterval[]>
}

export function AgendaToolbar(props: AgendaToolbarProps) {
  const { date, onNavigate, availableTimes } = props

  return (
    <HFlow>
      <div style={!availableTimes ? { pointerEvents: 'none', opacity: `${styles.disabled.opacity}` } : {}}>
        <Button type='button' skin='ghost' size='small' style={styles.button}>
          <Icon icon='angleLeft' onClick={() => onNavigate('PREV', subDays(date, 1))} />
        </Button>
        <Button type='button' skin='ghost' size='small' style={styles.button}>
          <Icon icon='angleRight' onClick={() => onNavigate('NEXT', addDays(date, 1))} />
        </Button>
      </div>
      <Heading
        level={2}
        style={css`
          color: ${gray.c20};
        `}
      >
        {formatWeekdayDayMonthYear(date?.getTime()).capitalize()}
      </Heading>
    </HFlow>
  )
}

const styles = {
  button: css`
    padding: 0;
    align-items: center;
  `,
  disabled: {
    opacity: 0.5,
  },
}

import { green } from 'bold-ui/lib/styles/colors'
import { useAcompanhamentoIdosoCardQuery, useUltimoIvcfQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'

import { IvcfFieldModel } from '../../objetivo/ivcf/model-ivcf'
import { ProntuarioCard } from '../ProntuarioCard'
import { AcompanhamentoIdosoCard } from './AcompanhamentoIdosoCard'

interface AcompanhamentoIdosoSectionProps {
  prontuarioId: ID
  cidadaoId: ID
  isFolhaDeRosto?: boolean
  ivcfAtendimentoAtual?: IvcfFieldModel
}

export function AcompanhamentoIdosoSection(props: AcompanhamentoIdosoSectionProps) {
  const { prontuarioId, cidadaoId, isFolhaDeRosto = false, ivcfAtendimentoAtual } = props
  const [canRender, setCanRender] = useState(false)

  const { data, loading } = useAcompanhamentoIdosoCardQuery({
    variables: {
      prontuarioId,
      cidadaoId,
    },
  })

  const {
    data: { ultimoIvcf },
    loading: loadingUltimoIvcf,
  } = useUltimoIvcfQuery({
    variables: {
      prontuarioId,
      cidadaoId,
    },
  })

  useEffect(() => {
    setCanRender(
      !!(data?.ultimaAvaliacaoMultidimensional || ultimoIvcf?.ivcf || ultimoIvcf?.fatoIvcf || ivcfAtendimentoAtual)
    )
  }, [data, ivcfAtendimentoAtual, ultimoIvcf])

  const tooltipText = 'Nenhuma avaliação multidimensional ou IVCF-20 registrado'

  return (
    <ProntuarioCard
      title='Acompanhamento da pessoa idosa'
      titleBackgroundColor={green.c90}
      tooltip={!canRender && tooltipText}
    >
      <AcompanhamentoIdosoCard
        data={data}
        ultimoIvcf={ultimoIvcf}
        loading={loading || loadingUltimoIvcf}
        canRender={canRender}
        isFolhaDeRosto={isFolhaDeRosto}
        ivcfAtendimentoAtual={ivcfAtendimentoAtual}
      />
    </ProntuarioCard>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, HFlow, Theme, Tooltip, useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import {
  CheckboxField,
  CidadaoAtendimentoSelectField,
  DateField,
  ErrorField,
  Form,
  FormRenderProps,
  HorarioAgendaSelectField,
  LocalAtendimentoSelectField,
  TextAreaField,
} from 'components/form'
import { CidadaoAtendimentoAdSelectField } from 'components/form/field/select/CidadaoAtendimentoSelectField/CidadaoAtendimentoADSelectField'
import { addMonths, formatISO, parseISO } from 'date-fns'
import { FORM_ERROR, FormApi } from 'final-form'
import {
  useAtualizarContatoCidadaoMutation,
  useCidadaoCadastroAgendamentoQuery,
  useEditarAgendamentoConsultaMutation,
  useSalvarAgendamentoConsultaMutation,
} from 'graphql/hooks.generated'
import { Cidadao } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import qs from 'qs'
import React, { useMemo } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'
import { formatNomeCidadao } from 'view/cidadao/util-cidadao'

import { downloadImpressaoAgendamentoCidadao } from '../components/impressao/downloadImpressaoAgendamentos'
import {
  convertConsultaModelToEditarInput,
  convertConsultaModelToInput,
  convertConsultaModelToUpdateContatoCidadaoInput,
} from '../converter-agenda'
import { useVerificarAgendamentosConflitantes } from '../hooks/useVerificarAgendamentosConflitantes'
import { AgendamentoConsultaModel, AgendamentoEdicaoModel, LotacaoAgendaModel } from '../model-agenda'
import { buildAgendamentosConflitantesErrorMessage } from '../util-agenda'
import { agendamentoConsultaDecorator } from './calculator-agenda'
import { ContatoCidadaoAgendamentoField } from './ContatoCidadaoAgendamentoField'
import { agendamentoConsultaValidator } from './validator-agenda'

export const meta = metaPath<AgendamentoConsultaModel>()

interface AgendamentoConsultaFormBaseProps {
  onSubmit?(): void
  onClose?(): void

  isAtencaoDomiciliar?: boolean
}

export interface NovoAgendamentoConsultaFormProps extends AgendamentoConsultaFormBaseProps {
  lotacao: LotacaoAgendaModel
  start?: number
  end?: number
}

interface EditarAgendamentoConsultaFormProps extends AgendamentoConsultaFormBaseProps {
  agendamento: AgendamentoEdicaoModel
}

type AgendamentoConsultaFormProps = NovoAgendamentoConsultaFormProps | EditarAgendamentoConsultaFormProps

interface QueryParams {
  cidadaoId: ID
}

export const AgendamentoConsultaForm = (props: AgendamentoConsultaFormProps) => {
  const { onClose, onSubmit, isAtencaoDomiciliar: isAD = false } = props
  const alert = useAlert()
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  const agendamentoEdicao = isEdicao(props) ? props.agendamento : null
  const lotacao = isEdicao(props) ? agendamentoEdicao.lotacao : props.lotacao
  const cidadaoDisabledTooltip = !!agendamentoEdicao ? 'Não é possível editar o cidadão' : ''

  const [salvarAgendamento] = useSalvarAgendamentoConsultaMutation()
  const [editarAgendamento] = useEditarAgendamentoConsultaMutation()
  const [atualizarContatoCidadao] = useAtualizarContatoCidadaoMutation()
  const { loading, verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()
  const theme = useTheme()
  const classes = createStyles(theme)
  const { analytics } = useFirebase()

  const queryParams: QueryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const {
    data: { cidadao: cidadaoParam },
  } = useCidadaoCadastroAgendamentoQuery({
    variables: { id: queryParams.cidadaoId },
    skip: !queryParams?.cidadaoId,
    fetchPolicy: 'cache-first',
  })

  const { start, end } = !isEdicao(props) && props
  const initialValues = useMemo<Partial<AgendamentoConsultaModel>>(() => {
    if (agendamentoEdicao) {
      return {
        horario: agendamentoEdicao.horario,
        data: formatISO(agendamentoEdicao.horario.inicial),
        cidadao: agendamentoEdicao.cidadao,
        contatoCidadao: {
          telefoneCelular: agendamentoEdicao.cidadao?.contato?.telefoneCelular,
          email: agendamentoEdicao.cidadao?.contato?.email,
        },
        observacoes: agendamentoEdicao.observacoes,
        localAtendimento: agendamentoEdicao.localAtendimento,
        imprimirAgendamento: true,
      }
    } else {
      const { telefoneCelular, email, ...cidadao } = cidadaoParam ?? ({} as Cidadao)
      return {
        horario: { inicial: new Date(start), final: new Date(end) },
        data: start ? formatISO(start) : undefined,
        cidadao: cidadao.id
          ? {
              ...cidadao,
              contato: {
                telefoneCelular,
                email,
              },
            }
          : undefined,
        telefoneCelular,
        email,
        imprimirAgendamento: true,
      }
    }
  }, [agendamentoEdicao, cidadaoParam, end, start])

  const updateContato = (values: AgendamentoConsultaModel) => {
    const telefoneCelularAlterado = values.cidadao?.contato.telefoneCelular !== values.contatoCidadao?.telefoneCelular
    const emailAlterado = values.cidadao?.contato.email !== values.contatoCidadao?.email

    if (telefoneCelularAlterado || emailAlterado)
      return atualizarContatoCidadao({
        variables: { input: convertConsultaModelToUpdateContatoCidadaoInput(values.cidadao.id, values.contatoCidadao) },
      })
  }
  const processaImpressaoAgendamento = (values: AgendamentoConsultaModel, agendamentoId: string) => {
    if (values.imprimirAgendamento) {
      const nomeCidadao = values.cidadao.nomeSocial ?? values.cidadao.nome
      downloadImpressaoAgendamentoCidadao({
        agendamentoId,
        nomeCidadao,
      })
    }
  }

  const save = (values: AgendamentoConsultaModel, formApi: FormApi) =>
    salvarAgendamento({ variables: { input: convertConsultaModelToInput(values, lotacao.id) } }).then(
      ({ data: { salvarAgendamentoConsulta: agendamentoId } }) => {
        alert('success', 'Agendamento de consulta cadastrado com sucesso.')
        processaImpressaoAgendamento(values, agendamentoId)
        analytics.logEvent('registrar_agendamento_consulta_AG')
        history.replace({ search: undefined })
        onSubmit?.()
        onClose()
        setTimeout(formApi.reset)
      }
    )

  const edit = (values: AgendamentoConsultaModel, formApi: FormApi, enviarEmailCidadao: boolean) =>
    editarAgendamento({
      variables: { input: convertConsultaModelToEditarInput(values, agendamentoEdicao.id, enviarEmailCidadao) },
    }).then(({ data: { editarAgendamentoConsulta: agendamentoId } }) => {
      alert('success', `Agendamento de ${formatNomeCidadao(values.cidadao)} editado com sucesso.`)
      processaImpressaoAgendamento(values, agendamentoId)
      history.replace({ search: undefined })
      onSubmit?.()
      onClose()
      setTimeout(formApi.reset)
    })

  const handleFormSubmit = async (values: AgendamentoConsultaModel, formApi: FormApi) => {
    const identificadorAgendamentoConflitanteInput = [
      { lotacaoId: lotacao.id, profissionalId: lotacao.profissional.id, horario: Number(values.horario.inicial) },
    ]
    try {
      const confirmed = await verificarAgendamentosConflitantes(
        values.cidadao.id,
        identificadorAgendamentoConflitanteInput,
        formatNomeCidadao(values.cidadao),
        agendamentoEdicao ? agendamentoEdicao.id : null
      )

      const enviarEmailCidadao = values.cidadao?.contato.email !== values.contatoCidadao?.email

      if (confirmed) await updateContato(values)
      else return

      if (agendamentoEdicao) {
        return await edit(values, formApi, enviarEmailCidadao)
      } else {
        return await save(values, formApi)
      }
    } catch (apolloError) {
      // TODO@RNG #17670 Quando eh clicado em qualquer parte da tela o popper eh fechado e portanto nao temos mais o form, verificar se iremos trabalhar para conseguir manter o mesmo aberto para poder apresentar os erros em cada field
      const error = buildAgendamentosConflitantesErrorMessage(apolloError)
      alert('danger', error)
    }
  }

  const handleCancelar = () => {
    history.replace({ search: undefined })
    onClose()
  }

  const renderForm = (formProps: FormRenderProps<Partial<AgendamentoConsultaModel>>) => {
    const { handleSubmit, values } = formProps

    return (
      <React.Fragment>
        <div
          css={css`
            min-height: 23rem;
          `}
        >
          <Grid>
            {/*TODO (RNG): Encontrar maneira de nao renderizar esta cell quando nao possui erro (#20422)*/}
            <Cell size={12}>
              <ErrorField name={FORM_ERROR} inline={false} type='alert' ignoreTouchedAndDirty />
            </Cell>
            {!!values.localAtendimento && (
              <Cell size={12}>
                <Alert type='info' inline>
                  Consultas realizadas fora da UBS são adicionadas automaticamente na lista de registro tardio de
                  atendimento no dia agendado.
                </Alert>
              </Cell>
            )}
            <Cell size={3}>
              <DateField
                name={meta.data}
                label='Data'
                minDate={serverTime}
                maxDate={addMonths(serverTime, 12)}
                clearable
                required
              />
            </Cell>
            <Cell size={3}>
              <HorarioAgendaSelectField
                name={meta.horario}
                lotacaoId={lotacao.id}
                dia={values.data ? parseISO(values.data) : undefined}
                disabled={!values.data}
                labels={{ inicial: 'Horário' }}
                atencaoDomiciliar={isAD}
                agendamentosIdsDesconsiderar={[agendamentoEdicao?.id]}
                clearable
                required
              />
            </Cell>
            <Cell size={6} />
            <Cell size={6}>
              {isAD ? (
                <Tooltip text={cidadaoDisabledTooltip}>
                  <CidadaoAtendimentoAdSelectField
                    name={meta.cidadao}
                    ativo
                    obito={false}
                    retrieveContato
                    equipeResponsavelId={lotacao.equipe?.id}
                    label='Cidadão'
                    required
                    disabled={!!agendamentoEdicao}
                  />
                </Tooltip>
              ) : (
                <Tooltip text={cidadaoDisabledTooltip}>
                  <CidadaoAtendimentoSelectField
                    name={meta.cidadao}
                    ativo
                    obito={false}
                    retrieveContato
                    label='Cidadão'
                    verificarPresenteNaListaAtendimento={false}
                    addCidadaoCallbackUrl={match.url}
                    required
                    disabled={!!agendamentoEdicao}
                  />
                </Tooltip>
              )}
            </Cell>
            <Cell size={6}>
              <LocalAtendimentoSelectField
                name={meta.localAtendimento}
                excludeUbs
                label='Local de atendimento fora da UBS'
              />
            </Cell>
            <Cell size={6} />
            <Cell size={12}>
              <CheckPermission permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}>
                <ContatoCidadaoAgendamentoField
                  name={meta.contatoCidadao}
                  disabled={!values.cidadao}
                  telefoneCelularRequired={!!values.cidadao?.contato?.telefoneCelular}
                  emailRequired={!!values.cidadao?.contato?.email}
                />
              </CheckPermission>
            </Cell>
            <Cell size={12}>
              <TextAreaField label='Observações' name={meta.observacoes} maxLength={200} />
            </Cell>
            <Cell size={4} />
          </Grid>
        </div>
        <HFlow justifyContent='flex-end'>
          <CheckboxField name={meta.imprimirAgendamento} label='Imprimir comprovante ao salvar' style={classes.print} />
          <Button kind='normal' onClick={handleCancelar} size='medium'>
            Cancelar
          </Button>
          <Button kind='primary' onClick={handleSubmit} size='medium' loading={loading}>
            Salvar
          </Button>
        </HFlow>
      </React.Fragment>
    )
  }

  return (
    <Form<Partial<AgendamentoConsultaModel>>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleFormSubmit}
      suppressNotificationError //Previne mostrar alerta de validação fora do popup
      validate={agendamentoConsultaValidator(serverTime)}
      decorators={agendamentoConsultaDecorator}
    />
  )
}

const isEdicao = (props: AgendamentoConsultaFormProps): props is EditarAgendamentoConsultaFormProps =>
  !!(props as any)?.agendamento

const createStyles = (theme: Theme) => ({
  print: css`
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: 2px;
    padding: 0.75rem 1rem;
  `,
})

/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Alert, Button, ButtonGroup, Heading, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { isEmpty } from 'lodash'
import { MetaPath } from 'util/metaPath'
import {
  AgendarConsultaCuidadoCompartilhadoFieldsModel,
  CondutaGroupEnum,
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
  EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel,
  TipoAgendamentoCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getLotacoesConsultaCompartilhadaCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { AgendarConsultaCompartilhadaCuidadoCompartilhadoPanel } from './AgendarConsultaCompartilhadaCuidadoCompartilhadoPanel'
import { AgendarConsultaPresencialCuidadoCompartilhadoPanel } from './AgendarConsultaPresencialCuidadoCompartilhadoPanel'
import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'
import { EnviarGarantiaAcessoCuidadoCompartilhadoPanel } from './EnviarGarantiaAcessoCuidadoCompartilhadoPanel'

interface AgendarConsultaCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
  isExecutanteFromEstabelecimentoEspecializado?: boolean
}

export const AgendarConsultaCuidadoCompartilhadoField = (props: AgendarConsultaCuidadoCompartilhadoFieldProps) => {
  const {
    path,
    cuidadoCompartilhado,
    videochamadaUuidState,
    isExecutanteFromEstabelecimentoEspecializado = false,
  } = props

  const { acesso: lotacao } = useAcessoLotacaoOrEstagio()

  const {
    input: { value: garantiaAcessoValue },
  } = usePecField({ name: path.garantiaAcesso, subscription: { value: true } })

  const {
    input: { value: tipoAgendamentoValue, onChange: onChangeTipoAgendamento },
  } = usePecField({ name: path.agendamento.tipoAgendamento, subscription: { value: true } })

  const garantiaAcessoInput = garantiaAcessoValue as EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel

  const isGarantiaAcesso = !isEmpty(garantiaAcessoInput)
  const isConsultaCidadaoPresencial = tipoAgendamentoValue === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO
  const isConsultaCompartilhada = tipoAgendamentoValue === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA

  const { lotacaoResponsavel } = getLotacoesConsultaCompartilhadaCuidadoCompartilhado(lotacao.id, cuidadoCompartilhado)

  const isProfissionalExecutante = cuidadoCompartilhado.lotacaoExecutanteAtual.id === lotacaoResponsavel.id
  const isProfissionalSolicitante = cuidadoCompartilhado.lotacaoSolicitante.id === lotacao.id

  const hideConsultaEntreProfissionais = isProfissionalSolicitante && isExecutanteFromEstabelecimentoEspecializado

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<
      AgendarConsultaCuidadoCompartilhadoFieldsModel | EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel
    >
      name={isGarantiaAcesso ? path.garantiaAcesso : path.agendamento}
      pathCondutaGroup={path.condutaGroup}
      value={isGarantiaAcesso ? CondutaGroupEnum.ENVIAR_GARANTIA_ACESSO : CondutaGroupEnum.AGENDAR_CONSULTA}
    >
      <VFlow>
        {!hideConsultaEntreProfissionais ? (
          <ButtonGroup>
            <Button
              kind={isConsultaCidadaoPresencial ? 'primary' : 'normal'}
              size='small'
              onClick={() => onChangeTipoAgendamento(TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO)}
              disabled={isGarantiaAcesso}
            >
              Consulta com o cidadão
            </Button>

            <Button
              kind={isConsultaCompartilhada ? 'primary' : 'normal'}
              size='small'
              onClick={() => onChangeTipoAgendamento(TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA)}
              disabled={isGarantiaAcesso}
            >
              Consulta entre profissionais
            </Button>
          </ButtonGroup>
        ) : (
          <Heading level={3}>Consulta com o cidadão</Heading>
        )}

        {!isGarantiaAcesso ? (
          <VFlow>
            {isProfissionalExecutante && (
              <Alert type='info' inline style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
                É possível enviar demandas de agendamentos com a <strong>presença do cidadão</strong> para o módulo de
                Garantia do acesso, caso não haja disponibilidade de agenda no momento.
              </Alert>
            )}
            {isConsultaCidadaoPresencial && (
              <AgendarConsultaPresencialCuidadoCompartilhadoPanel
                path={path}
                cuidadoCompartilhado={cuidadoCompartilhado}
              />
            )}
            {isConsultaCompartilhada && (
              <AgendarConsultaCompartilhadaCuidadoCompartilhadoPanel
                path={path}
                cuidadoCompartilhado={cuidadoCompartilhado}
                videochamadaUuidState={videochamadaUuidState}
              />
            )}
          </VFlow>
        ) : (
          <EnviarGarantiaAcessoCuidadoCompartilhadoPanel
            path={path}
            garantiaAcessoInput={garantiaAcessoValue}
            cuidadoCompartilhado={cuidadoCompartilhado}
          />
        )}
      </VFlow>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { isEstagio } from 'components/auth/useSessionUtils'
import { FooterButton } from 'components/footer-button'
import { PageLoading } from 'components/loading'
import { confirm } from 'components/modals/confirm'
import { RegistroAplicado, RegistrosDoseVacinacaoQuery, TipoRegistroVacinacaoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { noop } from 'lodash'
import { Fragment, useState } from 'react'
import { emptyArray } from 'util/array'
import { covidImunosRecord } from 'view/lote-imunobiologico/model'

import { InfoCardModalVacinacao, RegistroVacinacaoFormInput } from '../detail/vacinacao/VacinacaoCalendarioView'
import { AplicacaoVacinaModal } from './AplicacaoVacinaModal'
import { AprazamentoVacinaModal } from './AprazamentoVacinaModal'
import { RegistroDoseWrapper, RegistroDoseWrapperProps } from './components/RegistroDoseWrapper'
import { createModalProps, initialValuesRegistroVacinacao } from './condicoes/converter'
import { CondicoesFormModel, ImunobiologicoModel, InfoCidadaoVacina, RegistroVacinacaoFormModel } from './model'
import { RegistroAnteriorVacinaModal } from './RegistroAnteriorVacinaModal'
import { VacinacaoModalHeader } from './VacinacaoModalHeader'
import { getRegistroVacinacao } from './vacinacaoUtils/getRegistroVacinacao'
import { checkErrors, validateSubModal } from './validator'

export interface VacinacaoModalItem {
  imunobiologico: ImunobiologicoModel
  doseId: ID
  doseNome: string
  idadeRecomendada: string
  registros: RegistroDoseWrapperProps[]
  hasOnlyEquivalentesAplicadas: boolean
}

interface VacinacaoModalProps {
  registrosDoseQueryResult: RegistrosDoseVacinacaoQuery
  modalOpen: boolean
  handleModalClose(): void
  handleOnSubmit?(input: RegistroVacinacaoFormInput): Promise<void>
  handleOnDelete?(
    idRegistroUnico: ID,
    tipoModal: SubModalVacinacaoOptions,
    idUnicoRegistroAprazamentoProxDose?: ID,
    idUnicoRegistroAplicacaoProxDose?: ID
  ): void
  setIsVacinacaoModalOpen(value: boolean): void
  loading: boolean
  infoCardModal: InfoCardModalVacinacao
  infoCidadaoVacina?: InfoCidadaoVacina
  registrosModificados?: RegistroVacinacaoFormModel[]
  setRegistroEmEdicao?(registro: RegistroVacinacaoFormModel): void
  registroEmEdicao?: RegistroVacinacaoFormModel
  condicoesVacinacao: CondicoesFormModel
  dataAtendimento: Instant
  dataNascimento: string
  registrosAplicados: RegistroAplicado[]
  isAcompanhamentoVacinal: boolean
  readOnly?: boolean
  setIsPreviousRegistryModalOpen?(value: boolean): void
  isPreviousRegistryModalOpen?: boolean
}

export const sairSemSalvarConfirm = (handleConfirm: () => void) => {
  confirm({
    title: 'Deseja cancelar o registro?',
    body: 'As alterações realizadas serão perdidas.',
    confirmLabel: 'Sim',
    cancelLabel: 'Não',
    depthLevel: 5,
    onConfirm: () => handleConfirm(),
  })()
}

export type SubModalVacinacaoOptions = 'APRAZAMENTO' | 'APLICACAO' | 'ANTERIOR'

export const VacinacaoModal = (props: VacinacaoModalProps) => {
  const {
    modalOpen,
    loading,
    infoCardModal,
    registrosModificados = emptyArray,
    handleOnDelete = noop,
    setRegistroEmEdicao,
    registroEmEdicao,
    condicoesVacinacao,
    dataAtendimento,
    dataNascimento,
    registrosAplicados,
    registrosDoseQueryResult,
    isAcompanhamentoVacinal,
    isPreviousRegistryModalOpen,
    readOnly = false,
  } = props

  const [currentModalOpen, setCurrentModalOpen] = useState<SubModalVacinacaoOptions>(null)
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()

  const modalInfo = createModalProps(
    infoCardModal,
    registrosDoseQueryResult,
    registrosModificados,
    isEstagio(acesso) ? acesso?.lotacaoSupervisora?.profissional?.nome : profissional?.nome,
    acesso?.cbo.nome,
    acesso?.unidadeSaude.nome
  )

  const imunobiologicoModificadoAtendimento = (tipoRegistro: 'APRAZAMENTO' | 'APLICACAO') => {
    const registroFiltrado = getRegistroVacinacao(
      {
        doseId: tipoRegistro === 'APRAZAMENTO' ? modalInfo.doseId : undefined,
        imunobiologicoId: modalInfo.imunobiologico.id,
        tipoRegistro:
          tipoRegistro === 'APLICACAO' ? TipoRegistroVacinacaoEnum.APLICACAO : TipoRegistroVacinacaoEnum.APRAZAMENTO,
        isRegistroAnterior: false,
      },
      {
        isRegistrosAplicados: false,
        registros: registrosModificados,
      }
    )

    if (registroFiltrado) return true
    return false
  }

  const handleSubModalVacinacaoOpen = (value: SubModalVacinacaoOptions, registro?: RegistroVacinacaoFormModel) => {
    const error = validateSubModal(
      {
        imunobiologicoId: infoCardModal.imunobiologico.id,
        doseId: infoCardModal.doseId,
        tipoModal: value,
        handleSubModalVacinacaoClose: handleSubModalVacinacaoClose,
        idUnicoRegistro: registro?.idUnicoRegistro,
        registrosAplicadosAtendimento: registrosModificados,
        registrosAplicados,
        handleOnSubmit: () => {
          props.setIsVacinacaoModalOpen(false)
          setCurrentModalOpen(value)
        },
      },
      condicoesVacinacao.gestante,
      condicoesVacinacao.puerpera
    )
    if (error.impeditivos.length > 0 || error.naoImpeditivos.length > 0) {
      checkErrors(error)
    } else {
      props.setIsVacinacaoModalOpen(false)
      setCurrentModalOpen(value)
    }
  }

  const handleSubModalVacinacaoClose = () => {
    props.setIsPreviousRegistryModalOpen(false)
    props.setIsVacinacaoModalOpen(false)
    setCurrentModalOpen(null)
    setRegistroEmEdicao(null)
  }

  const handleCloseAndHideModal = () => {
    handleSubModalVacinacaoClose()
    props.handleModalClose()
  }

  const handleOnEdit = (idUnicoRegistro: ID, value: SubModalVacinacaoOptions) => {
    const registro = registrosModificados.find((e) => e.idUnicoRegistro === idUnicoRegistro)
    setRegistroEmEdicao(registro)
    handleSubModalVacinacaoOpen(value, registro)
  }

  const { isRegistroTardio } = useAtendimentoContext() || {}

  const aplicacoesEquivalentes: RegistroDoseWrapperProps[] = modalInfo.hasOnlyEquivalentesAplicadas
    ? modalInfo.registros.filter((item) => item.isAplicacaoEquivalente)
    : []

  const aplicacoesToShow: RegistroDoseWrapperProps[] = modalInfo.hasOnlyEquivalentesAplicadas
    ? modalInfo.registros
    : modalInfo.registros.filter((item) => !item.isAplicacaoEquivalente)

  return (
    <Fragment>
      <Modal open={modalOpen} onClose={props.handleModalClose} size='auto'>
        <ModalBody>
          <VFlow>
            <VacinacaoModalHeader
              idadeRecomendada={modalInfo.idadeRecomendada}
              nomeDose={modalInfo.doseNome}
              imunobiologicoNome={modalInfo.imunobiologico.nome}
              imunobiologicoSigla={modalInfo.imunobiologico.sigla}
            />
            {modalInfo.hasOnlyEquivalentesAplicadas && !readOnly && (
              <VFlow
                style={css`
                  max-width: 65%;
                `}
              >
                <Alert type='warning'>Cidadão já possui uma dose equivalente aplicada</Alert>
              </VFlow>
            )}
            {!loading ? (
              aplicacoesToShow.length > 0 ? (
                modalInfo?.registros.map((registro, index) => (
                  <RegistroDoseWrapper
                    {...registro}
                    key={index}
                    isCovid={!!covidImunosRecord[infoCardModal?.imunobiologico?.id]}
                    handleOnDelete={handleOnDelete}
                    handleOnEdit={handleOnEdit}
                    readOnly={readOnly}
                    hasOnlyEquivalentesAplicadas={modalInfo.hasOnlyEquivalentesAplicadas}
                  />
                ))
              ) : (
                <Text fontStyle='italic'>Essa dose não possui nenhum registro.</Text>
              )
            ) : (
              <PageLoading message='Carregando' />
            )}
          </VFlow>
        </ModalBody>

        {!readOnly && (
          <ModalFooter>
            <HFlow justifyContent={!isAcompanhamentoVacinal ? 'space-between' : 'flex-end'}>
              {!isRegistroTardio && (
                <FooterButton
                  kind={isAcompanhamentoVacinal ? 'primary' : 'normal'}
                  onClick={() => handleSubModalVacinacaoOpen('ANTERIOR')}
                >
                  Transcrição de caderneta
                </FooterButton>
              )}
              {!isAcompanhamentoVacinal && (
                <HFlow>
                  {!imunobiologicoModificadoAtendimento('APRAZAMENTO') && (
                    <FooterButton kind='primary' onClick={() => handleSubModalVacinacaoOpen('APRAZAMENTO')}>
                      Aprazar
                    </FooterButton>
                  )}
                  {!imunobiologicoModificadoAtendimento('APLICACAO') && (
                    <FooterButton kind='primary' onClick={() => handleSubModalVacinacaoOpen('APLICACAO')}>
                      Aplicar
                    </FooterButton>
                  )}
                </HFlow>
              )}
            </HFlow>
          </ModalFooter>
        )}
      </Modal>

      {!readOnly && (
        <Fragment>
          <RegistroAnteriorVacinaModal
            idadeRecomendada={modalInfo?.idadeRecomendada}
            imunobiologico={modalInfo?.imunobiologico}
            doseId={modalInfo?.doseId}
            doseNome={modalInfo?.doseNome}
            modalOpen={currentModalOpen === 'ANTERIOR'}
            handleModalClose={handleCloseAndHideModal}
            handleOnSubmit={props.handleOnSubmit}
            initialValues={initialValuesRegistroVacinacao(infoCardModal, registroEmEdicao, true)}
            dataAtendimento={dataAtendimento}
            dataNascimento={dataNascimento}
            isAcompanhamentoVacinal={isAcompanhamentoVacinal}
            isPreviousRegistryModalOpen={isPreviousRegistryModalOpen}
            hasOnlyEquivalentesAplicadas={modalInfo.hasOnlyEquivalentesAplicadas}
            aplicacoesEquivalentes={aplicacoesEquivalentes}
          />

          {currentModalOpen === 'APRAZAMENTO' && (
            <AprazamentoVacinaModal
              imunobiologico={modalInfo?.imunobiologico}
              idadeRecomendada={modalInfo?.idadeRecomendada}
              doseId={modalInfo?.doseId}
              doseNome={modalInfo?.doseNome}
              modalOpen={currentModalOpen === 'APRAZAMENTO'}
              handleModalClose={handleCloseAndHideModal}
              handleOnSubmit={props.handleOnSubmit}
              initialValues={initialValuesRegistroVacinacao(infoCardModal, registroEmEdicao, false)}
              dataAtendimento={dataAtendimento}
            />
          )}

          {currentModalOpen === 'APLICACAO' && (
            <AplicacaoVacinaModal
              imunobiologico={modalInfo?.imunobiologico}
              idadeRecomendada={modalInfo?.idadeRecomendada}
              doseId={modalInfo?.doseId}
              doseNome={modalInfo?.doseNome}
              modalOpen={currentModalOpen === 'APLICACAO'}
              handleModalClose={handleCloseAndHideModal}
              handleOnSubmit={props.handleOnSubmit}
              initialValues={initialValuesRegistroVacinacao(infoCardModal, registroEmEdicao, true)}
              gestante={condicoesVacinacao.gestante}
              puerpera={condicoesVacinacao.puerpera}
              dataAtendimento={dataAtendimento}
              hasOnlyEquivalentesAplicadas={modalInfo.hasOnlyEquivalentesAplicadas}
              aplicacoesEquivalentes={aplicacoesEquivalentes}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

import { addDays, parseISO } from 'date-fns'
import {
  AcompanhamentoPreNatalQuery,
  AtendimentoProfissional,
  AtendimentoProfissionalPreNatal,
  Medicao,
  ResultadoExame,
} from 'graphql/types.generated'
import { isEmpty, orderBy } from 'lodash'
import { tipoEdemaRecord } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { isBetween } from 'util/date/compare'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { OrigemDum } from 'view/atendimentos/detail/components/modals/medicoes/model-medicoes'
import { AtendimentoProfUltimaGestacao } from 'view/atendimentos/detail/components/modals/types/model-historicoPreNatal'
import {
  ResultadoEspecificoPrenatal,
  ResultadoExameEspecificoModel,
  ResultadosExamesModel,
} from 'view/atendimentos/detail/soap/objetivo/resultados-exames/model-resultadosExames'
import {
  MEDICOES_PRE_NATAL,
  PreNatalFormModel,
  QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO,
  TipoPreNatal,
} from 'view/atendimentos/detail/soap/pre-natal/model-preNatal'

import { HistoricoPreNatalModel, MedicoesPreNatal } from '../../../../components/modals/types/model-historicoPreNatal'
import { isInicioOuContinuacaoPreNatal } from '../../../pre-natal/util-preNatal'
import {
  convertMedicoesAtendimentoAtual,
  convertResultadoExameEspecificoModelToResultadoExamePreNatal,
} from '../converter-acompanhamentoPreNatal'
import { ResultadoExamePreNatal } from '../model-acompanhamentoPreNatal'

function compareAscDataExame(exameLeft: ResultadoExamePreNatal, exameRight: ResultadoExamePreNatal) {
  const dateLeft = parseISO(exameLeft.dataRealizacao)
  const dateRight = parseISO(exameRight.dataRealizacao)

  const diff = dateLeft.getTime() - dateRight.getTime()
  const idDiff = exameRight.id ? (exameLeft.id ? exameLeft.id > exameRight.id : true) : false

  if (diff < 0) {
    return -1
  } else if (diff > 0) {
    return 1
  } else {
    return idDiff ? 1 : -1
  }
}

export function getListaEcoOrdenadaDesc(
  resultadosExamesAnteriores: ResultadoExamePreNatal[],
  dum: Date,
  resultadosExamesAtendimentoAtual?: ResultadoExameEspecificoModel[]
): [ResultadoExamePreNatal[], boolean, boolean] {
  let listaEcoOrdenadaDesc: ResultadoExamePreNatal[] = []
  resultadosExamesAnteriores?.forEach((exame) => {
    if (exame.especifico?.igSemanas || exame.especifico?.igDias || exame.especifico?.dpp) {
      listaEcoOrdenadaDesc.push(exame)
    }
  })
  let hasdppEcoAtendimento = false
  let hasExamesPreNatalAtendimento = false

  if (dum) {
    resultadosExamesAtendimentoAtual?.forEach((resultadoExame) => {
      const resultadoPreNatal = resultadoExame?.resultado as ResultadoEspecificoPrenatal
      if (resultadoPreNatal?.dpp || resultadoPreNatal?.idadeGestacional) {
        listaEcoOrdenadaDesc.push(convertResultadoExameEspecificoModelToResultadoExamePreNatal(resultadoExame))
        hasdppEcoAtendimento = resultadoPreNatal?.dpp && true
        hasExamesPreNatalAtendimento = true
      }
    })
  }

  return [
    listaEcoOrdenadaDesc.sort((a, b) => compareAscDataExame(b, a)),
    hasExamesPreNatalAtendimento,
    hasdppEcoAtendimento,
  ]
}

interface DatasGestacao {
  dataInicioGestacao: Date
  dataProvavelParto: Date
  isDataInicioGestacaoEcografica: boolean
}

export function getInicioGestacaoEDataProvavelParto(
  dum: Date,
  listaEcoOrdenadaDesc: ResultadoExamePreNatal[]
): DatasGestacao {
  if (dum) {
    const dataLimite = addDays(dum, QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO)

    for (let i = 0; i < listaEcoOrdenadaDesc?.length; i++) {
      const dataRealizacaoExame = parseISO(listaEcoOrdenadaDesc[i].dataRealizacao)

      if (dum <= dataRealizacaoExame && dataRealizacaoExame <= dataLimite) {
        if (listaEcoOrdenadaDesc[i].especifico?.igSemanas) {
          const idadeGestacionalNaDataDaRealizacaoExame =
            listaEcoOrdenadaDesc[i].especifico.igSemanas * 7 + (listaEcoOrdenadaDesc[i].especifico.igDias ?? 0)
          const dataInicioGestacao = addDays(dataRealizacaoExame, -idadeGestacionalNaDataDaRealizacaoExame)
          const dataProvavelParto = listaEcoOrdenadaDesc[i].especifico.dpp
            ? parseISO(listaEcoOrdenadaDesc[i].especifico.dpp)
            : null

          return { dataInicioGestacao, dataProvavelParto, isDataInicioGestacaoEcografica: true }
        }

        const dataProvavelParto = listaEcoOrdenadaDesc[i].especifico.dpp
          ? parseISO(listaEcoOrdenadaDesc[i].especifico.dpp)
          : null

        return {
          dataInicioGestacao: dum,
          dataProvavelParto,
          isDataInicioGestacaoEcografica: false,
        }
      }
    }
  }
  return {
    dataInicioGestacao: dum,
    dataProvavelParto: null,
    isDataInicioGestacaoEcografica: false,
  }
}

function getDumAtual(valoresComMedicaoAtual: AtendimentoProfissional[]): Date {
  for (let i = valoresComMedicaoAtual?.length - 1; i >= 0; i--) {
    const medicoes = valoresComMedicaoAtual[i]?.medicoes
    if (!isEmpty(medicoes) && medicoes[0].dum) {
      return parseISO(medicoes[0].dum)
    }
  }
}

function defineDumComStatus(valoresComMedicaoAtual: AtendimentoProfissional[], dumAtendimentoAtual?: string) {
  const dumPersistida = getDumAtual(valoresComMedicaoAtual)
  const dumFinal = dumAtendimentoAtual ? parseISO(dumAtendimentoAtual) : dumPersistida

  let origemDum: OrigemDum = OrigemDum.SEM_DUM
  if (dumAtendimentoAtual && dumPersistida) {
    origemDum = OrigemDum.AMBOS
  } else if (dumAtendimentoAtual) {
    origemDum = OrigemDum.ATENDIMENTO_ATUAL
  } else if (dumPersistida) {
    origemDum = OrigemDum.ATENDIMENTO_ANTERIOR
  }

  return { dum: dumFinal, origemDum }
}

type MedicaoAtendProfPreNatal = Pick<
  Medicao,
  'id' | 'dataMedicao' | 'valorBatimentoCardiacoFetal' | 'dum' | 'valorAlturaUterina' | 'valorPeso' | 'medicaoAnterior'
>

function convertMedicoesUltimaGestacao(
  indexMedicao: number,
  medicao: MedicaoAtendProfPreNatal,
  atendimentoProfissionalPreNatal: AtendimentoProfissionalPreNatal,
  dataMedicao: number | Date
): MedicoesPreNatal {
  return {
    numeroConsulta: indexMedicao + 1,
    dataDaMedicao: dateAsYyyyMmDd(dataMedicao),
    movimentacaoFetal: atendimentoProfissionalPreNatal?.movimentacaoFetal,
    edema: tipoEdemaRecord[atendimentoProfissionalPreNatal?.tipoEdema],
    batimentoCardiacoFetal: medicao?.valorBatimentoCardiacoFetal,
    alturaUterina: medicao?.valorAlturaUterina,
    peso: medicao?.valorPeso,
    isRegistradoAgora: false,
  }
}

function hasMedicoesPreNatalAtendimentoAtual(preNatalAtendimentoAtual: PreNatalFormModel): boolean {
  if (isUndefinedOrNull(preNatalAtendimentoAtual)) return false
  return MEDICOES_PRE_NATAL.some((item) => preNatalAtendimentoAtual[item])
}

function mustShowMedicoesAtendimentoAtual(
  tipoPreNatal: TipoPreNatal,
  pesoAtendimentoAtual: number,
  preNatalAtendimentoAtual: PreNatalFormModel
): boolean {
  return (
    isInicioOuContinuacaoPreNatal(tipoPreNatal) &&
    (!isUndefinedOrNull(pesoAtendimentoAtual) || hasMedicoesPreNatalAtendimentoAtual(preNatalAtendimentoAtual))
  )
}

export interface ConvertDadosPreNatalProps {
  data: AcompanhamentoPreNatalQuery
  preNatalAtendimentoAtual?: PreNatalFormModel
  resultadosExamesAtendimentoAtual?: ResultadosExamesModel
  pesoAtendimentoAtual?: number
  dumAtendimentoAtual?: string
  dataAtendimento?: Instant
  resultadosExamesAnteriores?: ResultadoExame[]
  tipoPreNatal?: TipoPreNatal
}

export function convertDadosPreNatal({
  data,
  dataAtendimento,
  dumAtendimentoAtual,
  preNatalAtendimentoAtual,
  resultadosExamesAtendimentoAtual,
  pesoAtendimentoAtual,
  resultadosExamesAnteriores,
  tipoPreNatal,
}: ConvertDadosPreNatalProps): HistoricoPreNatalModel {
  let medicoes: MedicoesPreNatal[] = []

  const atendimentosProfissionaisUltimaGestacao = data?.atendimentosProfUltimaGestacao

  const { dum, origemDum } = defineDumComStatus(
    atendimentosProfissionaisUltimaGestacao as AtendimentoProfissional[],
    dumAtendimentoAtual
  )

  const ultimoDiaDoPrimeiroTrimestreGestacao = addDays(dum, QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO)

  const resultadosExamesAnterioresPrimeiroTrimestre = resultadosExamesAnteriores?.filter((exame) =>
    isBetween(parseISO(exame.dataRealizacao), dum, ultimoDiaDoPrimeiroTrimestreGestacao)
  )
  const resultadosExamesAtendimentoAtualPrimeiroTrimestre = Object.values(
    resultadosExamesAtendimentoAtual?.resultadosSemSolicitacao ?? {}
  )?.filter((exame) => isBetween(parseISO(exame.dataRealizado), dum, ultimoDiaDoPrimeiroTrimestreGestacao))

  const [listaEcoOrdenadaDesc, hasExamesPreNatalAtendimento, hasDppEcoAtendimento] = getListaEcoOrdenadaDesc(
    resultadosExamesAnterioresPrimeiroTrimestre,
    dum,
    resultadosExamesAtendimentoAtualPrimeiroTrimestre
  )

  const { dataInicioGestacao, dataProvavelParto, isDataInicioGestacaoEcografica } = getInicioGestacaoEDataProvavelParto(
    dum,
    listaEcoOrdenadaDesc
  )

  atendimentosProfissionaisUltimaGestacao?.forEach((atendProf, index) => {
    const medicao = !isEmpty(atendProf.medicoes) ? atendProf.medicoes[0] : null
    const dataMedicao = medicao?.dataMedicao ?? atendProf.iniciadoEm
    medicoes.push(convertMedicoesUltimaGestacao(index, medicao, atendProf.atendimentoProfissionalPreNatal, dataMedicao))
  })

  mustShowMedicoesAtendimentoAtual(tipoPreNatal, pesoAtendimentoAtual, preNatalAtendimentoAtual) &&
    medicoes.push(
      convertMedicoesAtendimentoAtual(dataAtendimento, preNatalAtendimentoAtual, pesoAtendimentoAtual, medicoes?.length)
    )

  medicoes = orderBy(medicoes, 'numeroConsulta', 'desc')
  return {
    medicoesPreNatal: medicoes,
    dataProvavelPartoEcografica: dataProvavelParto,
    dum,
    dataInicioGestacao,
    isDataInicioGestacaoEcografica,
    hasExamesPreNatalAtendimento,
    hasDppEcoAtendimento,
    origemDum,
  } as HistoricoPreNatalModel
}

export function getAltoRisco(atendimentos?: AtendimentoProfUltimaGestacao[]) {
  return atendimentos?.some((atendProf) => atendProf.atendimentoProfissionalPreNatal?.preNatal?.altoRisco)
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, useTheme } from 'bold-ui'
import { Color } from 'csstype'

interface SdcNotificationBadgeProps {
  visible?: boolean
  fill?: Color
  size?: number
  style?: ExternalStyles
}

export const SdcNotificationBadge = (props: SdcNotificationBadgeProps) => {
  const { visible = false, fill, size = 0.5, style: externalStyles } = props

  const theme = useTheme()
  const resolvedFill = fill || theme.pallete.primary.main
  const styles = createStyles(visible, resolvedFill, size)

  return <span css={css(styles.notificationBadge, externalStyles)} />
}

const createStyles = (isVisible: boolean, fill: string, width: number) => ({
  notificationBadge: css`
    border-radius: 50%;
    height: ${width}rem;
    width: ${width}rem;
    background-color: ${fill};
    visibility: ${isVisible ? 'visible' : 'hidden'};
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { MetaPath } from 'util/metaPath/metaPath'

import { ReactComponent as SdcIcon } from '../../../../../../images/suporte-decisao-clinica/health-system-outline.svg'
import { PlanoFormModel } from '../PlanoForm'
import SdcCondutaPlanoButton from './SdcCondutaButton'

export interface SdcCondutaBoxProps {
  name: MetaPath<PlanoFormModel>
  onClickVisualizarConduta: () => void
}

export function SdcCondutaPlanoBox(props: SdcCondutaBoxProps) {
  return (
    <Box>
      <VFlow vSpacing={0.5}>
        <HFlow hSpacing={0.5} alignItems='center'>
          <SdcIcon />
          <Text variant='h3' fontWeight='bold'>
            Conduta recomendada do SDC
          </Text>
        </HFlow>
        <VFlow vSpacing={0}>
          <Text variant='h5' color='primary' fontWeight='bold'>
            Cidadão com CIAP2 ou CID10 de dengue identificado.
          </Text>
          <Text variant='h5' fontWeight='normal'>
            O Suporte à Decisão Clínica disponibiliza a{' '}
            <Text
              color='primary'
              fontWeight='normal'
              textDecoration='underline'
              style={styles.visualizarConduta}
              onClick={props.onClickVisualizarConduta}
            >
              conduta completa para dengue
            </Text>{' '}
            e permite automatizar ações essenciais no atendimento, conforme as recomendações do Ministério da Saúde.
            Clique no botão abaixo para visualizar a conduta recomendada e decidir se deseja aplicá-la.
          </Text>
        </VFlow>
        <SdcCondutaPlanoButton name={props.name} style={styles.divSdcCondutaPlanoButton} />
      </VFlow>
    </Box>
  )
}

const styles = {
  divSdcCondutaPlanoButton: css`
    padding-top: 0.25rem;
  `,
  visualizarConduta: css`
    cursor: pointer;
  `,
}

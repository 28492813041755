/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Heading, HFlow, Text, VFlow } from 'bold-ui'
import { isProcedimentoSisab } from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { HLabel } from 'components/HLabel'
import { useFlags } from 'config/useFlagsContext'
import { ResultadoExame } from 'graphql/types.generated'
import { Fragment } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { formatDate } from 'util/date/formatDate'
import { formatNumber } from 'util/number'
import { isEmpty } from 'util/validation/Util'
import {
  mapResultadosPuericulturaEnumLabel,
  mapResultadosQualitativosEnumLabel,
  resultadosExamesEspecificosFactory,
  showCodigoProcedimentoRef,
} from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util-resultadosExames'

import { historicoSubsectionStyles } from '../../../model/model-historico'

interface HistoricoResultadoExamesTableProps {
  examesResultados: ResultadoExame[]
}

export default function HistoricoResultadoExamesTable(props: HistoricoResultadoExamesTableProps) {
  const { examesResultados } = props

  const { PILOTO_ESTRUTURA_GRUPO_DENGUE } = useFlags()

  const colunasTabelaResultadoExames = [
    {
      name: 'exame',
      header: 'Exame',
      render: (item: ResultadoExame) => (
        <VFlow
          vSpacing={0.5}
          style={css`
            padding: 1rem 0;
          `}
        >
          {showCodigoProcedimentoRef(item.exame)}
          {item.dataSolicitacao && (
            <HFlow hSpacing={0.25}>
              <HLabel title='Data de solicitação:'>{formatDate(item.dataSolicitacao)}</HLabel>
            </HFlow>
          )}
        </VFlow>
      ),
      style: css`
        width: 40%;
        vertical-align: top;
      `,
    },
    {
      name: 'resultado',
      header: 'Resultado',
      render: (item: ResultadoExame) => {
        const { dataRealizacao, dataResultado, resultado, especifico, exame } = item
        const { codigo, procedimentoReferencia } = exame
        const exameCodigo = isProcedimentoSisab(codigo) ? procedimentoReferencia?.codigo : codigo
        const exameEspecifico = resultadosExamesEspecificosFactory?.[exameCodigo]

        const hasIgSemanas = !isUndefinedOrNull(especifico?.igSemanas)
        const hasIgDias = !isUndefinedOrNull(especifico?.igDias)
        const resultadoSanitized = resultado?.removeTags()
        const hasResultado =
          especifico ||
          especifico?.valor ||
          especifico?.resultadoExamePuericulturaEnum ||
          especifico?.resultadoExameEstruturadoQualitativoEnum ||
          hasIgSemanas ||
          resultadoSanitized ||
          dataRealizacao ||
          dataResultado

        return hasResultado ? (
          <VFlow
            vSpacing={0}
            style={css`
              padding: 0.5rem 0;
            `}
          >
            {especifico && (
              <Fragment>
                {especifico.valor && (
                  <Text>{`${
                    !isEmpty(exameEspecifico.properties.precision)
                      ? formatNumber(especifico.valor, exameEspecifico.properties.precision)
                      : formatNumber(especifico.valor, 0)
                  } ${exameEspecifico.properties.unit}`}</Text>
                )}
                {especifico.resultadoExamePuericulturaEnum && (
                  <Text fontWeight='bold'>
                    {mapResultadosPuericulturaEnumLabel[especifico.resultadoExamePuericulturaEnum]}
                  </Text>
                )}
                {especifico.resultadoExameEstruturadoQualitativoEnum && PILOTO_ESTRUTURA_GRUPO_DENGUE && (
                  <Text fontWeight='bold'>
                    {mapResultadosQualitativosEnumLabel[especifico.resultadoExameEstruturadoQualitativoEnum]}
                  </Text>
                )}
                <HFlow hSpacing={0.25}>
                  {hasIgSemanas && (
                    <HLabel title='Idade gestacional eco:'>
                      {`${'semana'.pluralizeAndConcatValue(especifico.igSemanas)} ${
                        hasIgDias ? `e ${'dia'.pluralizeAndConcatValue(especifico.igDias)}` : ''
                      }`}
                    </HLabel>
                  )}
                  {hasIgSemanas && especifico.dpp && <Text>|</Text>}
                  {especifico.dpp && <HLabel title='DPP eco:'>{formatDate(especifico.dpp)}</HLabel>}
                </HFlow>
              </Fragment>
            )}
            {resultadoSanitized && (
              <Text
                style={css`
                  white-space: pre-line;
                `}
                dangerouslySetInnerHTML={{ __html: resultado }}
              />
            )}
            {(dataRealizacao || dataResultado) && (
              <HFlow hSpacing={0.25}>
                <HLabel title='Data de realização:'>{formatDate(dataRealizacao)}</HLabel> |
                <HLabel title='Data de resultado:'>{formatDate(dataResultado)}</HLabel>
              </HFlow>
            )}
          </VFlow>
        ) : (
          <Text>-</Text>
        )
      },
      style: css`
        width: 40%;
      `,
    },
  ]

  return (
    <VFlow vSpacing={0.5}>
      <Heading level={4} style={historicoSubsectionStyles.objetivo}>
        Resultados de exames
      </Heading>
      <DataTable<ResultadoExame>
        rows={examesResultados}
        columns={colunasTabelaResultadoExames}
        style={css`
          overflow-wrap: anywhere;
        `}
      />
    </VFlow>
  )
}

import React, { useContext } from 'react'
import { LotacaoAgendaModel } from 'view/agenda/model-agenda'

import { AgendaContextModel } from './model-agendaContext'

const DEFAULT_VALUES: AgendaContextModel = {
  isAD: false,
  lotacao: null,
}

export const AgendaContext = React.createContext<AgendaContextModel>(DEFAULT_VALUES)

interface AgendaProviderProps {
  isAD: boolean
  lotacao: LotacaoAgendaModel
  children: React.ReactNode
}

export function AgendaProvider(props: AgendaProviderProps) {
  const { children, isAD, lotacao } = props
  return <AgendaContext.Provider value={{ isAD, lotacao }}>{children}</AgendaContext.Provider>
}

export const useAgendaContext = () => useContext(AgendaContext)

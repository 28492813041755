/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { isEmpty, VFlow } from 'bold-ui'
import { TextAreaField } from 'components/form'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { TipoExameEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ExameListSelectField } from './components/ExameListSelectField'
import { SolicitacaoExameModel } from './model'

export interface SolicitarExamesFormFieldsProps {
  cidadao: CidadaoAtendimento
  tipoExame: TipoExameEnum
}

const path = metaPath<SolicitacaoExameModel>()

export default function SolicitarExamesFormFields(props: SolicitarExamesFormFieldsProps) {
  const { cidadao, tipoExame } = props

  const cid10Required = tipoExame === TipoExameEnum.ALTO_CUSTO || tipoExame === TipoExameEnum.OCI

  return (
    <VFlow>
      <ExameListSelectField name={path.examesSolicitados} cidadao={cidadao} tipoExame={tipoExame} />

      <CidSelectField
        name={path.cid10}
        label='CID 10'
        renderGrupoCiapRelacionada={false}
        sexo={isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : null}
        required={cid10Required}
      />

      <TextAreaField
        name={path.justificativa}
        label='Justificativa do procedimento'
        maxLength={500}
        placeholder='Insira a justificativa do procedimento.'
        required
        style={css`
          resize: none;
          height: 5rem;
        `}
      />

      <TextAreaField
        name={path.observacao}
        label='Observações'
        maxLength={250}
        placeholder='Insira as observações do procedimento.'
        style={css`
          resize: none;
          height: 4rem;
        `}
      />
    </VFlow>
  )
}

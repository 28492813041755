import { Alert } from 'bold-ui'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { DEFAULT_SELECT_SIZE } from 'components/form/field/select/useAsyncQuerySelect'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { useDataUltimoProcessamentoQuery, useImovelUltimasVisitasQuery } from 'graphql/hooks.generated'
import { PageParams, TipoImovelEnum, VisitasQueryInput } from 'graphql/types.generated'
import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

import { VisualizarUltimasVisitasPagedList } from './components/VisualizarUltimasVisitasPagedList'
import { ImovelUrlParams } from './model-visualizacao-imovel'

export function VisualizarUltimasVisitasView() {
  const { resetExpandedItems, ...accordionProps } = useAccordionControl({})
  const { imovelId } = useParams<ImovelUrlParams>()

  const [visitasQueryInput, setVisitasQueryInput] = useState<VisitasQueryInput>({
    imovelId: imovelId,
    pageParams: { size: DEFAULT_SELECT_SIZE, fetchPageInfo: true },
  })

  const onChangePageParams = useCallback(
    (pageParams: PageParams) => {
      setVisitasQueryInput((prevState) => ({ ...prevState, pageParams }))
    },
    [setVisitasQueryInput]
  )

  const {
    data: { visitas, imovel },
    loading,
  } = useImovelUltimasVisitasQuery({
    variables: {
      input: visitasQueryInput,
      imovelId: imovelId,
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: { dataUltimoProcessamento },
  } = useDataUltimoProcessamentoQuery()

  return loading ? (
    <LoadingIndicator />
  ) : (
    <PageContent fluid type='filled'>
      <Alert type='info' style={{ marginBottom: '1.5rem', padding: '0.25rem' }}>
        São apresentadas somente as visitas já contabilizadas no último processamento de relatórios, ou seja, as que
        foram registradas até {formatDateAndHoursMinutes(dataUltimoProcessamento)}. Caso um cidadão seja removido do
        imóvel após a visita ser realizada, tal visita continuará sendo listada aqui.
      </Alert>
      <VisualizarUltimasVisitasPagedList
        visitas={visitas.content}
        pageInfo={visitas.pageInfo}
        isLoading={loading}
        isDomicilio={imovel.tipoImovel === TipoImovelEnum.DOMICILIO}
        resetExpandedItems={resetExpandedItems}
        onChangePageParams={onChangePageParams}
        {...accordionProps}
      />
    </PageContent>
  )
}

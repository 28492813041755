import { HeadingSection, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useFlags } from 'config/useFlagsContext'
import { useSolicitacoesExameUnpagedQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty } from 'lodash'
import React from 'react'
import { Fragment, useMemo } from 'react'
import { MetaArray } from 'util/metaPath'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util-atendimentoIndividual'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AdicionarSolicitacaoExameButtons } from './AdicionarSolicitacaoExameButtons'
import { convertSolicitacaoFromAtendToSolicitacaoWithInfo } from './converter'
import { downloadSolicitacaoExames } from './DownloadSolicitacaoExames'
import SolicitacaoExamesTable from './ExamesTable'
import { SolicitacaoExameModel } from './model'
import { SolicitarExamesRootView } from './SolicitarExamesRootView'
import { convertHistoricoSolicitacaoExameToImpressao, joinSolicitacoesExame } from './utils'

export interface ExamesViewProps {
  name: MetaArray<SolicitacaoExameModel>
  cidadao: CidadaoAtendimento
  prontuarioId: ID
  atendimentoId: ID
  atendimentoProfissional: AtendimentoProfissional
  isObservacaoAndAuxiliar: boolean
}

export function ExamesView(props: ExamesViewProps) {
  const { name, cidadao, prontuarioId, atendimentoId, atendimentoProfissional, isObservacaoAndAuxiliar } = props

  const alert = useAlert()
  const { analytics } = useFirebase()
  const { PILOTO_SOLICITAR_PROCED_OCI_ENABLED } = useFlags()

  const {
    observacao: { isAtendimentoObservacao },
    tiposAtendimento: { isAtendimentoProcedimentos },
  } = useAtendimentoContext()

  const {
    handleSubmit,
    removeItem,
    handleRowChanged,
    input: { value: solicitacoesExameFromAtend },
  } = useEditableListField<SolicitacaoExameModel>({
    name: name,
  })

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissional.id)

  const {
    data: { solicitacoesExame },
    loading,
  } = useSolicitacoesExameUnpagedQuery({
    variables: {
      input: {
        prontuarioId,
        editAtendProfId,
      },
    },
  })

  const hasSolicitacaoExames = !isEmpty(solicitacoesExameFromAtend)

  const itemsToPrint = convertSolicitacaoFromAtendToSolicitacaoWithInfo(solicitacoesExameFromAtend, {
    dataReferencia: atendimentoProfissional.iniciadoEm,
    atendimentoProfissionalId: atendimentoProfissional.id,
    lotacao: atendimentoProfissional.lotacao,
  })

  const allContent = useMemo(
    () =>
      joinSolicitacoesExame(solicitacoesExame?.content, solicitacoesExameFromAtend, {
        dataReferencia: atendimentoProfissional.iniciadoEm,
        atendimentoProfissionalId: atendimentoProfissional.id,
        lotacao: atendimentoProfissional.lotacao,
      }),
    [
      atendimentoProfissional.id,
      atendimentoProfissional.iniciadoEm,
      atendimentoProfissional.lotacao,
      solicitacoesExame,
      solicitacoesExameFromAtend,
    ]
  )

  const handleImpressao = () => {
    analytics.logEvent('imprimir_solicitacoes_EXM')
    downloadSolicitacaoExames(
      convertHistoricoSolicitacaoExameToImpressao(itemsToPrint, prontuarioId, atendimentoId, cidadao.id),
      () => alert('success', `Geração da impressão de solicitações ocorreu com sucesso.`)
    ).catch((_) => alert('danger', 'Falha na impressão da solicitação'))
  }

  const headingTitle = PILOTO_SOLICITAR_PROCED_OCI_ENABLED
    ? 'Solicitação de exames/procedimentos'
    : 'Solicitação de exames'
  const imprimirTooltip = !hasSolicitacaoExames
    ? `Não foram realizadas solicitações${PILOTO_SOLICITAR_PROCED_OCI_ENABLED ? '' : ' de exames'} neste atendimento.`
    : 'Imprimir todas as solicitações deste atendimento.'
  const disabledImpressao = !hasSolicitacaoExames

  return (
    <Fragment>
      <HeadingSection level={4} vSpace={8} title={headingTitle} />
      <VFlow>
        {!isAtendimentoProcedimentos && (
          <AdicionarSolicitacaoExameButtons
            imprimirTooltip={imprimirTooltip}
            disabledImpressao={disabledImpressao}
            handleImpressao={handleImpressao}
          />
        )}
        <SolicitarExamesRootView
          cidadao={cidadao}
          handleSubmit={handleSubmit}
          handleUpdate={handleRowChanged}
          adicionados={solicitacoesExameFromAtend}
          existentes={solicitacoesExame?.content}
          iniciadoEm={atendimentoProfissional.iniciadoEm}
        />

        <SolicitacaoExamesTable
          atendimentoId={atendimentoId}
          prontuarioId={prontuarioId}
          removeItem={removeItem}
          loading={loading}
          cidadaoId={cidadao.id}
          dataReferencia={atendimentoProfissional.iniciadoEm}
          lotacao={atendimentoProfissional.lotacao}
          isObservacaoAndAuxiliar={isObservacaoAndAuxiliar}
          items={allContent}
          solicitacoesExameFromAtend={solicitacoesExameFromAtend}
          imprimirRegistradoAgora={{
            tooltip: imprimirTooltip,
            disabled: disabledImpressao,
            handleImpressao: handleImpressao,
          }}
        />
      </VFlow>
    </Fragment>
  )
}

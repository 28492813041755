/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Tooltip } from 'bold-ui'
import { Box } from 'components/Box'
import { useFlags } from 'config/useFlagsContext'

interface ExamesTableSubheaderProps {
  imprimirTooltip: string
  disabledImpressao: boolean
  handleImpressao: () => void
}

export default function ExamesTableSubheader(props: ExamesTableSubheaderProps) {
  const { imprimirTooltip, disabledImpressao, handleImpressao } = props

  const { PILOTO_SOLICITAR_PROCED_OCI_ENABLED } = useFlags()

  return PILOTO_SOLICITAR_PROCED_OCI_ENABLED ? (
    <Box style={styles.box}>
      <Tooltip text={imprimirTooltip} placement='bottom'>
        <Button kind='normal' size='small' onClick={handleImpressao} disabled={disabledImpressao}>
          <HFlow hSpacing={0.5}>
            <Icon icon='printerOutline' />
            Imprimir registrado agora
          </HFlow>
        </Button>
      </Tooltip>
    </Box>
  ) : null
}

const styles = {
  box: css`
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
  `,
}

import { Button, HFlow, Icon, Tooltip } from 'bold-ui'
import { purple } from 'bold-ui/lib/styles/colors'
import {
  useAcompanhamentoPreNatalCardQuery,
  useDataUltimaConsultaOdontoGestanteQuery,
  useResultadosExamePreNatalQuery,
} from 'graphql/hooks.generated'
import { IdadeGestacionalAcompanhamentoPreNatal, ResultadoExame } from 'graphql/types.generated'
import { isNull, isUndefined } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { tipoGravidezRecord } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { STATUS_DUM_ATENDIMENTO_ANTERIOR } from 'view/atendimentos/detail/components/modals/medicoes/model-medicoes'
import {
  HistoricoPreNatalModel,
  MedicoesPreNatalAtendimentoModel,
} from 'view/atendimentos/detail/components/modals/types/model-historicoPreNatal'

import { PreNatalCardContentModel } from '../../../pre-natal/model-preNatal'
import { ProntuarioCard } from '../../ProntuarioCard'
import { downloadAcompanhamentoPreNatal } from '../impressao/DownloadAcompanhamentoPreNatal'
import { convertDadosPreNatal, getAltoRisco } from '../util/util-acompanhamentoPreNatal'
import { PreNatalCard } from './PreNatalCard'

const NAO_INFORMADO = 'Não informado'

interface PreNatalSectionProps extends Omit<MedicoesPreNatalAtendimentoModel, 'dataAtendimento'> {
  atendimentoId: ID
  prontuarioId: ID
  cidadaoId: ID
  isAltoRiscoAtendimentoAtual?: boolean
  readOnly?: boolean
  idadeGestacional?: IdadeGestacionalAcompanhamentoPreNatal
  dataAtendimento: Instant
}

const preNatalAsideSectionContent = (
  isDisabled: boolean,
  atendimentoId: ID,
  prontuarioId: ID,
  dataAtendimento: Instant
) => (
  <HFlow>
    <Tooltip text={isDisabled ? 'Não é possível imprimir pré-natal sem a DUM preenchida.' : 'Imprimir'}>
      <Button
        onClick={() => downloadAcompanhamentoPreNatal({ atendimentoId, prontuarioId, dataAtendimento })}
        type='button'
        size='small'
        skin='ghost'
        disabled={isDisabled}
      >
        <Icon icon='printerOutline' />
      </Button>
    </Tooltip>
  </HFlow>
)

export function PreNatalSection({
  prontuarioId,
  cidadaoId,
  atendimentoId,
  isAltoRiscoAtendimentoAtual,
  preNatalAtendimentoAtual,
  dumAtendimentoAtual,
  resultadosExamesAtendimentoAtual,
  dataAtendimento,
  readOnly = false,
  idadeGestacional,
}: PreNatalSectionProps) {
  const { data, loading } = useAcompanhamentoPreNatalCardQuery({
    variables: {
      input: prontuarioId,
    },
  })

  const { data: resultadosExame, loading: loadingExames } = useResultadosExamePreNatalQuery({
    variables: {
      input: {
        prontuarioId,
      },
    },
  })

  const resultadosExamesAnteriores = resultadosExame?.resultadosExame?.content as ResultadoExame[]

  const dataConverted: HistoricoPreNatalModel = useMemo(
    () =>
      convertDadosPreNatal({
        data,
        preNatalAtendimentoAtual,
        resultadosExamesAtendimentoAtual,
        dumAtendimentoAtual,
        dataAtendimento,
        resultadosExamesAnteriores,
      }),
    [
      data,
      preNatalAtendimentoAtual,
      resultadosExamesAtendimentoAtual,
      dumAtendimentoAtual,
      dataAtendimento,
      resultadosExamesAnteriores,
    ]
  )

  const hasDataInicioGestacao = !isUndefinedOrNull(dataConverted?.dataInicioGestacao)

  const { data: dataOdonto, loading: loadingDataOdonto } = useDataUltimaConsultaOdontoGestanteQuery({
    variables: { input: { prontuarioId, cidadaoId, dataInicioGestacao: dataConverted?.dataInicioGestacao } },
    skip: loading || !hasDataInicioGestacao,
  })

  const atendProfGestacaoMaisRecente = data?.atendimentosProfUltimaGestacao?.last()

  const loadingPreNatal = loading || loadingDataOdonto

  const tipoGravidezAtendimento = !isNull(preNatalAtendimentoAtual?.tipoGravidez)
    ? tipoGravidezRecord[preNatalAtendimentoAtual?.tipoGravidez?.value]
    : null

  const tipoGravidezDatabase =
    atendProfGestacaoMaisRecente?.atendimentoProfissionalPreNatal?.preNatal?.tipoGravidez?.descricao

  const hasRiscoGravidezAtendimento =
    !isUndefined(tipoGravidezAtendimento) && tipoGravidezAtendimento !== tipoGravidezDatabase

  const tipoGravidez = hasRiscoGravidezAtendimento
    ? tipoGravidezAtendimento ?? NAO_INFORMADO
    : tipoGravidezDatabase ?? NAO_INFORMADO

  const isAltoRisco = isAltoRiscoAtendimentoAtual || getAltoRisco(data.atendimentosProfUltimaGestacao)

  const content: PreNatalCardContentModel = {
    ...dataConverted,
    dataUltimaConsulta: atendProfGestacaoMaisRecente?.iniciadoEm,
    quantidadeConsultas: data?.atendimentosProfUltimaGestacao?.length,
  }

  const [initialHasExames, setInitialHasExames] = useState<boolean>(null)
  const [initialIgEco, setInitialIgEco] = useState<IdadeGestacionalAcompanhamentoPreNatal>(null)
  useEffect(() => {
    if (!loading && !loadingExames) {
      setInitialHasExames(content.hasExamesPreNatalAtendimento)
      setInitialIgEco(idadeGestacional)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loadingExames])
  const isDppEcoAtualizadoAgora = content?.hasExamesPreNatalAtendimento !== initialHasExames
  const isIgEcoAtualizadoAgora = idadeGestacional?.isEcografica !== initialIgEco?.isEcografica

  return (
    <ProntuarioCard
      title='Pré-natal'
      titleBackgroundColor={purple.c90}
      content={preNatalAsideSectionContent(
        !STATUS_DUM_ATENDIMENTO_ANTERIOR.has(dataConverted?.origemDum),
        atendimentoId,
        prontuarioId,
        dataAtendimento
      )}
    >
      <PreNatalCard
        content={content}
        dataUltimaConsultaOdontoGestante={dataOdonto?.dataUltimaConsultaOdontoGestante}
        loading={loadingPreNatal}
        isAltoRisco={isAltoRisco}
        tipoGravidez={tipoGravidez}
        hasTipoGravidezAtendimento={hasRiscoGravidezAtendimento}
        readOnly={readOnly}
        idadeGestacional={idadeGestacional}
        isDppEcoAtualizadoAgora={isDppEcoAtualizadoAgora}
        isIgEcoAtualizadoAgora={isIgEcoAtualizadoAgora}
      />
    </ProntuarioCard>
  )
}

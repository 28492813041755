import { HFlow, Tag, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import React from 'react'

import { LotacaoAgendaSelectModel } from '../LotacaoAgendaSelect'

interface LotacaoAgendaSelectProfissionalItemProps {
  item: LotacaoAgendaSelectModel
}

export function LotacaoAgendaSelectProfissionalItem(props: LotacaoAgendaSelectProfissionalItemProps) {
  const {
    item: { profissional, cbo, equipe, ativo, hasConfiguracaoAgenda },
  } = props

  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>
        {profissional.nome} {profissional.nomeSocial && '(Nome social)'} - <Cpf value={profissional.cpf} />
      </Text>
      <HLabel title='CBO '>
        {cbo.nome} - {cbo.cbo2002}
      </HLabel>
      <HLabel title='Equipe '> {(equipe && `${equipe.nome} - ${equipe.ine}`) || 'Sem equipe'}</HLabel>
      <HFlow>
        {!ativo && <Tag>Inativo</Tag>}
        {!hasConfiguracaoAgenda && <Tag>Sem agenda</Tag>}
      </HFlow>
    </VFlow>
  )
}

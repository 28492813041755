/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, HFlow, Radio, RadioProps, Theme, Tooltip, useTheme } from 'bold-ui'
import { useMemo, useRef } from 'react'

import { UseFieldProps, usePecField } from '../final-form/hooks/useField'

type RadioWithDescField = UseFieldProps<any> & Omit<RadioProps, 'name'>

export interface RadioFieldContainerProps extends RadioWithDescField {
  children?: React.ReactNode
  tooltipText?: string
  isRadioHidden?: boolean
}

RadioFieldContainer.defaultProps = {
  label: '',
} as RadioFieldContainerProps

export function RadioFieldContainer(props: RadioFieldContainerProps) {
  const { input, meta, style, children, label, tooltipText, disabled, isRadioHidden, ...rest } = usePecField({
    type: 'radio',
    ...props,
  })

  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme, disabled), [disabled, theme])

  const container = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!disabled) {
      input.onChange(input.value)
      container.current?.focus()
      e.preventDefault()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      input.onChange(input.value)
      e.preventDefault()
    }
  }

  const RadioComponent = () => {
    return <Radio {...input} {...rest} label={label} disabled={disabled} tabIndex={-1} />
  }

  return (
    <Tooltip text={tooltipText}>
      <div
        onMouseDown={handleMouseDown}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        ref={container}
        css={css(classes.container, !isRadioHidden ? classes.containerInteractive : null, style)}
      >
        <HFlow hSpacing={0.5} alignItems='center'>
          {isRadioHidden ? (
            <div css={css(classes.radioHidden)}>
              <RadioComponent />
            </div>
          ) : (
            <RadioComponent />
          )}

          {children}
        </HFlow>
      </div>
    </Tooltip>
  )
}

const createStyles = (theme: Theme, disabled: boolean) => ({
  container: css`
    padding: 1rem;
    border: 1px solid ${disabled ? theme.pallete.gray.c80 : theme.pallete.gray.c60};
    border-radius: 2px;
    background: ${disabled ? theme.pallete.gray.c90 : theme.pallete.surface.main};
    transition: all 0.2s ease;
  `,

  containerInteractive: css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    &:focus {
      ${!disabled &&
      `
      outline: none;
      box-shadow: ${focusBoxShadow(theme)};
      `};
    }
  `,
  radioHidden: css`
    content-visibility: hidden;
  `,
})

import {
  CondicoesInput,
  EsquemaCalendarioVacinalQuery,
  EstrategiaVacinacaoEnum,
  RegistrosDoseVacinacaoQuery,
  TipoRegistroVacinacaoEnum,
} from 'graphql/types.generated'
import _, { Dictionary } from 'lodash'
import moment from 'moment'
import { isUndefinedOrNull } from 'util/checks'
import { stringGrouper } from 'util/string/string-grouper'
import { isEmpty } from 'util/validation/Util'
import { v4 as uuidv4 } from 'uuid'
import { DoseItemProps } from 'view/atendimentos/detail/components/vacinacao/DoseItem'
import { ImunobiologicoRowProps } from 'view/atendimentos/detail/components/vacinacao/ImunobiologicoRow'
import {
  InfoCardModalVacinacao,
  RegistrosAplicadosDto,
} from 'view/atendimentos/detail/vacinacao/VacinacaoCalendarioView'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'
import { LoteImunobiologicoFabricanteSelectFieldModel } from 'view/lote-imunobiologico/components/LoteImunobiologicoFabricanteSelectField'
import { covidImunosRecord } from 'view/lote-imunobiologico/model'

import { RegistroDoseAccordionItemProps } from '../components/RegistroDoseAccordionItem'
import {
  BaseCalendarioVacinalModel,
  CondicoesFormModel,
  DoseItemModel,
  dosesEquivalentesItems,
  dosesEquivalentesVop,
  ImunobiologicoRowModel,
  isBcg,
  RegistroVacinacaoFormModel,
  StatusDoseEnum,
  VacinacaoEstrategiaIdEnum,
  VacinacaoImunobiologicoIdEnum,
  VacinaEquivalenteModel,
} from '../model'
import { VacinacaoModalItem } from '../VacinacaoModal'
import { getStatusDoseVacinacao } from '../vacinacaoUtils'
import { getRegistroVacinacao } from '../vacinacaoUtils/getRegistroVacinacao'

interface DoseItem {
  model: DoseItemModel
}

interface CalendarioVacinalProps {
  abaCalendario: ImunobiologicoRowProps[]
  abaOutros: ImunobiologicoRowProps[]
}

export const convertCondicoesToInput = (
  form: CondicoesFormModel,
  registroVacinacao: RegistroVacinacaoFormModel[]
): CondicoesInput => {
  return (
    form && {
      isGestante: form.gestante,
      isPuerpera: form.puerpera,
      isViajante: form.viajante,
      isComunicanteHanseniase: getComunicanteHanseniaseValue(registroVacinacao),
    }
  )
}

export const convertAprazamentoProxDose = (registroPai: RegistroVacinacaoFormModel) => {
  return {
    estrategiaVacinacao: registroPai.estrategiaVacinacao,
    dataAprazamento: registroPai.dataAprazamentoProxDose,
    imunobiologicoSigla: registroPai.imunobiologicoSigla || registroPai.outrosImunosImunobiologico.sigla,
    imunobiologicoNome: registroPai.imunobiologicoNome || registroPai.outrosImunosImunobiologico.nome,
    dataRegistro: registroPai.dataRegistro,
    isRegistroAnterior: registroPai.isRegistroAnterior,
    origemDados: registroPai.origemDados,
    tipoRegistroVacinacao: TipoRegistroVacinacaoEnum.APRAZAMENTO,
    idUnicoRegistro: uuidv4(),
    imunobiologicoId: registroPai.imunobiologicoId || registroPai.outrosImunosImunobiologico.id,
    doseNome: registroPai.proximaDoseNome,
    doseId: registroPai.proximaDoseId,
    aplicacaoLink: {
      doseId: registroPai.doseId || registroPai.outrosImunosDose?.id,
      imunobiologicoId: registroPai.imunobiologicoId || registroPai.outrosImunosImunobiologico?.id,
      idUnicoRegistro: registroPai.idUnicoRegistro,
    },
  } as RegistroVacinacaoFormModel
}

export const createListWithUpdatedRegistries = (
  formValues: RegistroVacinacaoFormModel,
  registrosAplicadosAtendimento: RegistroVacinacaoFormModel[]
) => {
  let newRegistriesList: RegistroVacinacaoFormModel[] = null
  let updatedRegistry = { ...formValues }

  // O registro que esta sendo editado possui um aprazamento automatico vinculado
  if (updatedRegistry.aprazamentoProxDoseLink && updatedRegistry.dataAprazamentoProxDose) {
    let aprazamentoAutomatico = {
      ...(getRegistroVacinacao(
        {
          imunobiologicoId: updatedRegistry.aprazamentoProxDoseLink.imunobiologicoId,
          doseId: updatedRegistry.aprazamentoProxDoseLink.doseId,
        },
        { registros: registrosAplicadosAtendimento, isRegistrosAplicados: false }
      ) as RegistroVacinacaoFormModel),
    }

    aprazamentoAutomatico.dataAprazamento = updatedRegistry.aprazamentoProxDoseLink.dataAprazamentoProxDose
    aprazamentoAutomatico.estrategiaVacinacao = updatedRegistry.estrategiaVacinacao

    newRegistriesList = filterUpdateRegistriesList(
      registrosAplicadosAtendimento,
      updatedRegistry,
      aprazamentoAutomatico
    )

    // O registro que esta sendo editado vai criar um aprazamento automatico pois foi setado a data
  } else if (updatedRegistry.dataAprazamentoProxDose) {
    const aprazamentoAutomatico = convertAprazamentoProxDose(formValues)

    updatedRegistry.aprazamentoProxDoseLink = {
      doseId: aprazamentoAutomatico.doseId,
      imunobiologicoId: aprazamentoAutomatico.imunobiologicoId,
      idUnicoRegistro: aprazamentoAutomatico.idUnicoRegistro,
      estrategiaVacinacao: aprazamentoAutomatico.estrategiaVacinacao,
      dataAprazamentoProxDose: aprazamentoAutomatico.dataAprazamentoProxDose,
    }

    newRegistriesList = filterUpdateRegistriesList(
      registrosAplicadosAtendimento,
      updatedRegistry,
      aprazamentoAutomatico
    )
  } else {
    if (updatedRegistry.aplicacaoLink) {
      const aplicacaoPai = {
        ...(getRegistroVacinacao(
          {
            imunobiologicoId: updatedRegistry.aplicacaoLink.imunobiologicoId,
            doseId: updatedRegistry.aplicacaoLink.doseId,
          },
          {
            registros: registrosAplicadosAtendimento,
            isRegistrosAplicados: false,
          }
        ) as RegistroVacinacaoFormModel),
      }

      // TODO (APRAZAMENTO AUTOMATICO) atualizar aplicacao pai pois o aprazamento automatico foi alterado, setar para null a data e desvincular? so a data pq estategia nao faz sentido mudar a do pai
      newRegistriesList = filterUpdateRegistriesList(registrosAplicadosAtendimento, updatedRegistry, aplicacaoPai)
    } else {
      newRegistriesList = filterUpdateRegistriesList(registrosAplicadosAtendimento, updatedRegistry)
    }
  }

  return newRegistriesList
}

export const createListWithSaveRegistry = (
  formValues: RegistroVacinacaoFormModel,
  registrosAplicadosAtendimento: RegistroVacinacaoFormModel[]
) => {
  let newRegistriesList = [...registrosAplicadosAtendimento]
  const newRegistry = { ...formValues, idUnicoRegistro: uuidv4() }

  // O novo registro vai criar um aprazamento automatico ou editar um aprazamento existente pois foi setado a data
  if (newRegistry.dataAprazamentoProxDose) {
    // Verifica se existe um registro manual de aprazamento
    const aprazamentoManualExistente = getRegistroVacinacao(
      {
        imunobiologicoId: newRegistry.imunobiologicoId,
        doseId: newRegistry.proximaDoseId,
      },
      {
        registros: registrosAplicadosAtendimento,
        isRegistrosAplicados: false,
      }
    ) as RegistroVacinacaoFormModel

    // Caso a pŕoxima dose já esteja aprazada antes da criação do novo registro, somente atualizar este aprazamento (o link já foi cadastrado anteriormente)
    if (newRegistry.aprazamentoProxDoseLink) {
      let aprazamentoExistente = {
        ...(getRegistroVacinacao(
          {
            imunobiologicoId: newRegistry.aprazamentoProxDoseLink.imunobiologicoId,
            doseId: newRegistry.aprazamentoProxDoseLink.doseId,
          },
          { registros: registrosAplicadosAtendimento, isRegistrosAplicados: false }
        ) as RegistroVacinacaoFormModel),
      }

      aprazamentoExistente = {
        ...aprazamentoExistente,
        dataAprazamento: newRegistry.aprazamentoProxDoseLink.dataAprazamentoProxDose,
        estrategiaVacinacao: newRegistry.estrategiaVacinacao,
      }

      newRegistriesList = [
        ...filterUpdateRegistriesList(registrosAplicadosAtendimento, aprazamentoExistente),
        newRegistry,
      ]
    }
    // Uma exceção do if anterior: quando a próxima dose já está aprazada e ocorre a aplicação de uma dose com os mesmos valores de aprazamento, resultando em dois aprazamentos duplicados.
    // Este if garante que, se existir um aprazamento manual, o link seja criado corretamente sem duplicacoes.
    else if (!isUndefinedOrNull(aprazamentoManualExistente)) {
      newRegistry.aprazamentoProxDoseLink = {
        imunobiologicoId: aprazamentoManualExistente.imunobiologicoId,
        idUnicoRegistro: aprazamentoManualExistente.idUnicoRegistro,
        doseId: aprazamentoManualExistente.doseId,
        dataAprazamentoProxDose: aprazamentoManualExistente.dataAprazamento,
        estrategiaVacinacao: aprazamentoManualExistente.estrategiaVacinacao,
      }

      newRegistriesList = [
        ...filterUpdateRegistriesList(registrosAplicadosAtendimento, aprazamentoManualExistente),
        newRegistry,
      ]
    } else {
      const aprazamentoProxDose = convertAprazamentoProxDose(newRegistry)

      newRegistry.aprazamentoProxDoseLink = {
        doseId: aprazamentoProxDose.doseId,
        imunobiologicoId: aprazamentoProxDose.imunobiologicoId,
        idUnicoRegistro: aprazamentoProxDose.idUnicoRegistro,
        estrategiaVacinacao: aprazamentoProxDose.estrategiaVacinacao,
        dataAprazamentoProxDose: aprazamentoProxDose.dataAprazamento,
      }

      newRegistriesList = [...newRegistriesList, aprazamentoProxDose, newRegistry]
    }
  } else {
    newRegistriesList = [...newRegistriesList, newRegistry]
  }

  return newRegistriesList
}

const filterUpdateRegistriesList = (
  registrosAplicadosAtendimento: RegistroVacinacaoFormModel[],
  firstRegistry: RegistroVacinacaoFormModel,
  secondRegistry?: RegistroVacinacaoFormModel
) => {
  return secondRegistry
    ? [
        ...registrosAplicadosAtendimento.filter(
          (item) =>
            item.idUnicoRegistro !== firstRegistry.idUnicoRegistro &&
            item.idUnicoRegistro !== secondRegistry.idUnicoRegistro
        ),
        firstRegistry,
        secondRegistry,
      ]
    : [
        ...registrosAplicadosAtendimento.filter((item) => item.idUnicoRegistro !== firstRegistry.idUnicoRegistro),
        firstRegistry,
      ]
}

export const filterDeleteRegistriesList = (
  registrosAplicadosAtendimento: RegistroVacinacaoFormModel[],
  firstRegistryId: ID,
  secondRegistryId?: ID
) => {
  return secondRegistryId
    ? registrosAplicadosAtendimento.filter(
        (item) => item.idUnicoRegistro !== firstRegistryId && item.idUnicoRegistro !== secondRegistryId
      )
    : registrosAplicadosAtendimento.filter((item) => item.idUnicoRegistro !== firstRegistryId)
}

export const convertEsquemaCalendarioVacinalQueryToProps = (
  data: EsquemaCalendarioVacinalQuery,
  cidadao: CidadaoAtendimento,
  dataAtendimento: Instant,
  prontuarioId: string,
  calendario: Dictionary<BaseCalendarioVacinalModel[]>,
  loadRegistrosAplicados: RegistrosAplicadosDto[],
  dosesEquivalentesAplicadas: Map<ID, Set<ID>>
): CalendarioVacinalProps => {
  const loadRegistrosCalendario: Map<string, RegistrosAplicadosDto> = new Map()

  const loadRegistrosOutrosImunos: RegistrosAplicadosDto[] = []

  const loadRegistrosEquivalentes: RegistrosAplicadosDto[] = []

  if (loadRegistrosAplicados) {
    loadRegistrosAplicados?.forEach((value: RegistrosAplicadosDto) => {
      if (calendario[value.imunobiologicoId]?.find((item) => item.dose.id === value.doseId)) {
        const key = value.imunobiologicoId + value.doseId
        const existingValue = loadRegistrosCalendario.get(key)
        let registroAplicado = { ...value }

        if (existingValue) {
          registroAplicado = {
            ...(existingValue.dataAplicacao < value.dataAplicacao ? value : existingValue),
            quantidadeAplicacoes: value.quantidadeAplicacoes + existingValue.quantidadeAplicacoes,
          }
        }

        loadRegistrosCalendario.set(key, registroAplicado)
      } else {
        if (
          value.dataAplicacao &&
          getVacinaEquivalenciaRegistro(
            value.imunobiologicoId,
            value.doseId,
            value.estrategiaId,
            dosesEquivalentesAplicadas
          )
        ) {
          loadRegistrosEquivalentes.push(value)
        }
        loadRegistrosOutrosImunos.push(value)
      }
    })
  }

  const dosesCalendarioProps = calendarioDosesToProps(
    loadRegistrosCalendario,
    data,
    cidadao,
    dataAtendimento,
    prontuarioId,
    loadRegistrosEquivalentes
  )

  const doseOutrosProps = outrasDosesToProps(loadRegistrosOutrosImunos, cidadao, prontuarioId)
  return { abaCalendario: dosesCalendarioProps, abaOutros: doseOutrosProps }
}

const calendarioDosesToProps = (
  registrosAplicadosCalendario: Map<string, RegistrosAplicadosDto>,
  data: EsquemaCalendarioVacinalQuery,
  cidadao: CidadaoAtendimento,
  dataAtendimento: Instant,
  prontuarioId: string,
  registrosAplicadosEquivalentes: RegistrosAplicadosDto[]
): ImunobiologicoRowProps[] => {
  return _.chain(data.esquemaCalendarioVacinal)
    .groupBy('imunobiologico.id')
    .map((value, key) => {
      let dataAprazamento: string
      let dataAplicacao: string
      let dataRegistroAprazamento: Instant
      let quantidadeAplicacoes: number
      let hasDoseEquivalenteOnly: boolean
      const doseProp = value.map<DoseItem>((doseItem) => {
        dataAprazamento = null
        dataAplicacao = null
        dataRegistroAprazamento = null
        quantidadeAplicacoes = null
        hasDoseEquivalenteOnly = false

        let aplicacoesEquivalentes = []

        // Caso a dose tenha uma aplicação, não é necessário verificar a aplicação na dose com equivalência
        const doseRegistrada = registrosAplicadosCalendario.get(key + doseItem.dose.id)

        if (
          registrosAplicadosEquivalentes.isNotEmpty() &&
          (!doseRegistrada || (doseRegistrada && !doseRegistrada?.dataAplicacao))
        ) {
          const registroEquivalenteItem = getVacinaEquivalenciaRegistroByDoseAtual(
            doseItem.imunobiologico.id,
            doseItem.dose.id,
            null
          )

          const doseEquivalente = registrosAplicadosEquivalentes.filter(
            (registro) =>
              registro.imunobiologicoId === doseItem.imunobiologico.id ||
              registro.imunobiologicoId === registroEquivalenteItem?.imunobiologicoEquivalenteId
          )

          if (
            doseEquivalente?.some(
              (item) => item.estrategiaId === VacinacaoEstrategiaIdEnum.ROTINA_ID || item.estrategiaId === null
            )
          ) {
            aplicacoesEquivalentes =
              doseEquivalente.isNotEmpty() &&
              doseEquivalente.filter(
                (item) =>
                  (registroEquivalenteItem?.imunobiologicoId === doseItem.imunobiologico.id ||
                    registroEquivalenteItem?.imunobiologicoEquivalenteId === doseItem.imunobiologico.id) &&
                  registroEquivalenteItem?.doseAtualId === doseItem.dose.id &&
                  (registroEquivalenteItem?.dosesEquivalentesIds?.includes(item.doseId) ||
                    registroEquivalenteItem?.dosesEquivalentesIds === null) &&
                  (registroEquivalenteItem?.estrategiaId === item.estrategiaId || item.estrategiaId === null)
              )

            if (aplicacoesEquivalentes.isNotEmpty()) {
              quantidadeAplicacoes = aplicacoesEquivalentes.reduce(
                (quantidadeAplicacoes, item) => quantidadeAplicacoes + item.quantidadeAplicacoes,
                0
              )
              dataAplicacao = aplicacoesEquivalentes.reduce((prev, current) =>
                prev && prev.dataAplicacao > current.dataAplicacao ? prev : current
              ).dataAplicacao
              hasDoseEquivalenteOnly = true
            }
          }
        }

        if (doseRegistrada) {
          if (doseRegistrada?.dataAplicacao) {
            dataAplicacao = doseRegistrada.dataAplicacao
            quantidadeAplicacoes = +doseRegistrada.quantidadeAplicacoes
          }

          dataAprazamento = doseRegistrada?.dataAprazamento
          dataRegistroAprazamento = doseRegistrada?.dataRegistroAprazamento
        }

        return {
          model: {
            calendarioId: doseItem.id,
            imunobiologicoId: key,
            doseId: doseItem.dose.id,
            nome: doseItem.dose.nomeApresentacaoDose,
            idadeRecomendada: doseItem.idadeRecomendada,
            status: getStatusDoseVacinacao(
              cidadao.dataNascimento,
              dataAtendimento,
              doseItem.faixaEtaria.diasInicio,
              doseItem.faixaEtaria.diasFim,
              !!dataAplicacao,
              hasDoseEquivalenteOnly
            ),
            mostrarNoCalendarioDoGrupo: doseItem.mostrarNoCalendarioDoGrupo,
            dataAprazamento: dataAprazamento,
            imunobiologicoNome: doseItem.descricaoImunobiologico,
            prontuarioId: prontuarioId,
            imunobiologicoNomeCompleto: doseItem.imunobiologico.nome,
            imunobiologicoSigla: doseItem.imunobiologico.sigla,
            cidadaoCpf: cidadao?.cpf,
            cidadaoId: cidadao.id,
            dataAplicacao: dataAplicacao,
            quantidadeAplicacoes: quantidadeAplicacoes,
            dataRegistroAprazamento,
            hasDoseEquivalenteOnly: hasDoseEquivalenteOnly,
          },
        }
      })
      return {
        model: {
          imunobiologicoId: key,
          nome: value[0].descricaoImunobiologico,
          doses: doseProp,
          ordem: value[0].ordemImuno,
        },
      } as ImunobiologicoRowProps
    })
    .sortBy('model.ordem')
    .value()
}

const outrasDosesToProps = (
  listToTransform: RegistrosAplicadosDto[],
  cidadao: CidadaoAtendimento,
  prontuarioId: string
): ImunobiologicoRowProps[] => {
  return _.chain(listToTransform)
    .groupBy('imunobiologicoId')
    .map((value) => {
      const doseProp = value.map<DoseItem>((doseItem) => {
        return {
          model: {
            calendarioId: doseItem.imunobiologicoId + doseItem.doseId + 'outrosImunos',
            imunobiologicoId: doseItem.imunobiologicoId,
            doseId: doseItem.doseId,
            imunobiologicoSigla: doseItem.imunobiologicoSigla,
            imunobiologicoNomeCompleto: doseItem.imunobiologicoNome,
            nome: doseItem.doseNomeApresentacao,
            status: doseItem?.dataAplicacao ? StatusDoseEnum.APLICADA : StatusDoseEnum.DISPONIVEL,
            mostrarNoCalendarioDoGrupo: true,
            prontuarioId: prontuarioId,
            cidadaoId: cidadao?.id,
            cidadaoCpf: cidadao?.cpf,
            dataAprazamento: doseItem?.dataAprazamento,
            dataAplicacao: doseItem?.dataAplicacao,
            quantidadeAplicacoes: doseItem?.quantidadeAplicacoes,
            dataRegistroAprazamento: doseItem.dataRegistroAprazamento,
          },
        } as DoseItemProps
      })
      return {
        model: {
          imunobiologicoId: value[0].imunobiologicoId,
          nome: value[0].imunobiologicoNome,
          doses: doseProp,
        },
      } as ImunobiologicoRowProps
    })
    .sortBy('model.nome')
    .value()
}

export const updateDosesCalendarioVacinalProps = (
  estadoInicial: ImunobiologicoRowProps[],
  registrosVacinacao: RegistroVacinacaoFormModel[],
  dosesEquivalentesAplicadas: Map<ID, Set<ID>>
): ImunobiologicoRowProps[] => {
  const newEstadoInicial: ImunobiologicoRowProps[] = [...estadoInicial]

  registrosVacinacao.forEach((registroAplicado) => {
    let row = newEstadoInicial.find((element) => element.model.imunobiologicoId === registroAplicado.imunobiologicoId)

    let doseExistente = row?.model.doses.find((dose) => dose.model.doseId === registroAplicado.doseId)

    const doseEquivalente = getVacinaEquivalenciaRegistro(
      registroAplicado.outrosImunosImunobiologico?.id,
      registroAplicado.outrosImunosDose?.id,
      registroAplicado.estrategiaVacinacao,
      dosesEquivalentesAplicadas
    )

    if (doseEquivalente) {
      let rowEquivalente = newEstadoInicial.find(
        (row) => row.model?.imunobiologicoId === doseEquivalente.imunobiologicoId
      )

      let doseAtualOnRow = rowEquivalente?.model?.doses.find(
        (dose) => dose.model.doseId === doseEquivalente.doseAtualId
      )

      if (doseAtualOnRow !== undefined) {
        doseAtualOnRow.model.status = StatusDoseEnum.DISPONIVEL
        doseAtualOnRow.model.dataAplicacao = registroAplicado.dataAplicacao
        doseAtualOnRow.model.quantidadeAplicacoes
          ? (doseAtualOnRow.model.quantidadeAplicacoes += 1)
          : (doseAtualOnRow.model.quantidadeAplicacoes = 1)
        doseAtualOnRow.model.registradoAtendimento = true
      }
    }

    if (row && doseExistente) {
      doseExistente.model.registradoAtendimento = true
      if (registroAplicado.tipoRegistroVacinacao === TipoRegistroVacinacaoEnum.APLICACAO) {
        doseExistente.model.status = StatusDoseEnum.APLICADA
        doseExistente.model.dataAplicacao = registroAplicado.dataAplicacao
        doseExistente.model.quantidadeAplicacoes
          ? (doseExistente.model.quantidadeAplicacoes += 1)
          : (doseExistente.model.quantidadeAplicacoes = 1)
      } else if (registroAplicado.tipoRegistroVacinacao === TipoRegistroVacinacaoEnum.APRAZAMENTO) {
        doseExistente.model.dataAprazamento = registroAplicado.dataAprazamento
        doseExistente.model.dataRegistroAprazamento = moment.now()
      }
    }
  })
  return newEstadoInicial
}

export const updateDosesOutrosImunosProps = (
  estadoInicial: ImunobiologicoRowProps[],
  registrosAplicados: RegistroVacinacaoFormModel[],
  cidadao: CidadaoAtendimento,
  prontuarioId: string,
  calendario: Dictionary<BaseCalendarioVacinalModel[]>
): ImunobiologicoRowProps[] => {
  const newEstadoInicial: ImunobiologicoRowProps[] = [...estadoInicial]
  const registrosOutrosImunosAplicados: RegistroVacinacaoFormModel[] = []

  registrosAplicados.forEach((registroAplicado) => {
    if (
      !calendario[registroAplicado?.imunobiologicoId || registroAplicado?.outrosImunosImunobiologico?.id]?.find(
        (item) => item.dose.id === registroAplicado.outrosImunosDose?.id || item.dose.id === registroAplicado.doseId
      )
    ) {
      registrosOutrosImunosAplicados.push(registroAplicado)
    }
  })

  registrosOutrosImunosAplicados.forEach((registroAplicado) => {
    let row = newEstadoInicial.find(
      (registroExistente) =>
        registroExistente.model.imunobiologicoId === registroAplicado.outrosImunosImunobiologico?.id ||
        registroExistente.model.imunobiologicoId === registroAplicado.imunobiologicoId
    )

    let newRow = !row && createOutrosImunosRowModel(registroAplicado)

    let dose = row?.model?.doses.find(
      (doseExistente) =>
        doseExistente.model.doseId === registroAplicado.outrosImunosDose?.id ||
        doseExistente.model.doseId === registroAplicado.doseId
    )
    let newDose = !dose && createOutrosImunosDoseItemProps(registroAplicado, cidadao, prontuarioId)

    if (newRow) {
      newEstadoInicial.push({ model: newRow } as ImunobiologicoRowProps)
      newEstadoInicial.sort((a, b) => (a.model.nome > b.model.nome ? 1 : -1))
    }

    if (newDose) {
      const rowNewDose = newEstadoInicial.find(
        (registroExistente) => registroExistente.model.imunobiologicoId === newDose.model.imunobiologicoId
      )
      rowNewDose?.model?.doses?.push(newDose)
      rowNewDose?.model.doses.sort((a, b) => (a.model.ordem > b.model.ordem ? 1 : -1))
    }

    const doseToUpdate = dose || newDose
    doseToUpdate.model.registradoAtendimento = true
    doseToUpdate.model.calendarioId = doseToUpdate.model.imunobiologicoId + doseToUpdate.model.doseId + 'outrosImunos'
    if (registroAplicado.tipoRegistroVacinacao === TipoRegistroVacinacaoEnum.APLICACAO) {
      doseToUpdate.model.status = StatusDoseEnum.APLICADA
      doseToUpdate.model.dataAplicacao = registroAplicado.dataAplicacao
      doseToUpdate.model.quantidadeAplicacoes
        ? (doseToUpdate.model.quantidadeAplicacoes += 1)
        : (doseToUpdate.model.quantidadeAplicacoes = 1)
    } else if (registroAplicado.tipoRegistroVacinacao === TipoRegistroVacinacaoEnum.APRAZAMENTO) {
      doseToUpdate.model.dataAprazamento = registroAplicado.dataAprazamento
      doseToUpdate.model.dataRegistroAprazamento = moment.now()
      doseToUpdate.model.status = newDose ? StatusDoseEnum.DISPONIVEL : doseToUpdate.model.status
    }
  })

  return newEstadoInicial.sort((a, b) => (a.model.nome > b.model.nome ? 1 : -1))
}

export const createOutrosImunosRowModel = (registroAplicado: RegistroVacinacaoFormModel): ImunobiologicoRowModel => {
  return {
    imunobiologicoId: registroAplicado.outrosImunosImunobiologico?.id || registroAplicado.imunobiologicoId,
    nome: registroAplicado.outrosImunosImunobiologico?.nome || registroAplicado.imunobiologicoNome,
    doses: [],
  } as ImunobiologicoRowModel
}

const createOutrosImunosDoseItemProps = (
  registroAplicado: RegistroVacinacaoFormModel,
  cidadao: CidadaoAtendimento,
  prontuarioId: string
): DoseItemProps => {
  return {
    model: {
      prontuarioId: prontuarioId,
      cidadaoId: cidadao.id,
      cidadaoCpf: cidadao.cpf,
      doseId: registroAplicado.outrosImunosDose?.id || registroAplicado.doseId,
      imunobiologicoId: registroAplicado.outrosImunosImunobiologico?.id || registroAplicado.imunobiologicoId,
      imunobiologicoNomeCompleto:
        registroAplicado.outrosImunosImunobiologico?.nome || registroAplicado.imunobiologicoNome,
      imunobiologicoSigla: registroAplicado.outrosImunosImunobiologico?.sigla || registroAplicado.imunobiologicoSigla,
      nome: registroAplicado.outrosImunosDose?.nomeApresentacaoDose || registroAplicado.doseNome,
      mostrarNoCalendarioDoGrupo: true,
      ordem: registroAplicado.outrosImunosDose?.ordem,
    } as DoseItemModel,
  } as DoseItemProps
}

export const createModalProps = (
  infoCardModal: InfoCardModalVacinacao,
  vacinacaoModalInfo: RegistrosDoseVacinacaoQuery,
  registrosModificados: ReadonlyArray<RegistroVacinacaoFormModel>,
  nomeProfissional: string,
  cboProfissional: string,
  unidadeSaudeProfissional: string
): VacinacaoModalItem => {
  const vacinacaoItem: VacinacaoModalItem = {
    imunobiologico: {
      id: infoCardModal?.imunobiologico?.id,
      nome: infoCardModal?.imunobiologico?.nome,
      sigla: infoCardModal?.imunobiologico?.sigla,
    },
    idadeRecomendada: infoCardModal?.idadeRecomendada,
    doseId: infoCardModal?.doseId,
    doseNome: infoCardModal?.doseNome,
    registros: [],
    hasOnlyEquivalentesAplicadas: false,
  }

  vacinacaoModalInfo?.registrosDoseVacinacao.map((registro) => {
    let registroDoseItem = {
      cbo: registro.cbo,
      dataAplicacao: registro.dataAplicacao,
      dataAprazamento: registro.dataAprazamento,
      dataRegistro: registro.dataRegistro,
      localDaAplicacao: registro.localAplicacao,
      nomeFabricante: registro.fabricante,
      estrategia: registro.estrategia,
      grupoAtendimento: registro.grupoAtendimento,
      nomeProfissional: registro.profissional,
      lote: registro.lote,
      observacoes: registro.descricao,
      viaDeAdministracao: registro.viaAdministracao,
      isGestante: registro.isGestante,
      isPuerpera: registro.isPuerpera,
      isViajante: registro.isViajante,
      isComunicanteHanseniase: registro.isComunicanteHanseniase,
      dataValidadeLote: registro.dataValidadeLote,
      unidadeSaude: registro.unidadeSaude,
      origemDados: registro.origemDados,
      isRegistroAnterior: registro.isRegistroAnterior,
      isAplicadoExterior: registro.isAplicadoExterior,
      isAplicacaoEquivalente: registro?.isAplicacaoEquivalente,
    } as RegistroDoseAccordionItemProps

    // Como é necessário sabermos qual foi a dose equivalente aplicada, nesse caso populamos essa informação no RegistroDoseWrapperProps
    if (registro?.isAplicacaoEquivalente) {
      registroDoseItem = {
        ...registroDoseItem,
        doseNome: registro?.doseNome,
        doseSigla: registro?.doseSigla,
      }
    }
    vacinacaoItem.registros.push(registroDoseItem)
  })

  // Ou esse registro modificado trata-se do registro certo para esse imuno/dose ou ele é equivalente
  registrosModificados?.map((registroModificado) => {
    let imunoDoseEquivalente =
      !registroModificado.dataAprazamento &&
      getVacinaEquivalenciaRegistroVacinacaoFormModel(
        registroModificado,
        infoCardModal?.imunobiologico?.id,
        infoCardModal?.doseId,
        registroModificado?.isRegistroAnterior
      )

    // A VIP REF é considerada equivalente somente quando há registros das doses da VOP REF1 E REF2
    if (imunoDoseEquivalente?.imunobiologicoEquivalenteId === VacinacaoImunobiologicoIdEnum.VOP) {
      const registrosModificadosVopDoseId = registrosModificados
        .filter((registro) => registro.outrosImunosImunobiologico?.id === VacinacaoImunobiologicoIdEnum.VOP)
        .flatMap((registro) => registro.outrosImunosDose?.id)

      if (!dosesEquivalentesVop.every((value) => registrosModificadosVopDoseId.includes(value))) {
        imunoDoseEquivalente = undefined
      }
    }

    const imunoDoseRegistroMatch =
      (infoCardModal?.imunobiologico?.id === registroModificado?.outrosImunosImunobiologico?.id ||
        infoCardModal?.imunobiologico?.id === registroModificado?.imunobiologicoId) &&
      (infoCardModal?.doseId === registroModificado?.outrosImunosDose?.id ||
        infoCardModal?.doseId === registroModificado?.doseId)

    if (imunoDoseRegistroMatch || imunoDoseEquivalente) {
      let registroDoseItem = {
        idUnicoRegistro: registroModificado.idUnicoRegistro,
        cbo: cboProfissional,
        dataAplicacao: registroModificado?.dataAplicacao,
        dataAprazamento: registroModificado?.dataAprazamento,
        dataRegistro: registroModificado?.dataRegistro,
        localDaAplicacao: registroModificado?.localAplicacaoVacinacao?.nome,
        estrategia:
          registroModificado.isPesquisaEstrategia && registroModificado.isRegistroAnterior
            ? EstrategiaVacinacaoEnum.PESQUISA
            : registroModificado?.estrategiaVacinacao?.nome,
        grupoAtendimento: registroModificado?.grupoAtendimento?.nome,
        nomeProfissional: nomeProfissional,
        imunobiologicoId: registroModificado?.isRegistroAnterior
          ? registroModificado.outrosImunosImunobiologico?.id
          : registroModificado.imunobiologicoId,
        doseId: registroModificado?.isRegistroAnterior
          ? registroModificado?.outrosImunosDose?.id
          : registroModificado?.doseId,
        doseNome: registroModificado?.isRegistroAnterior
          ? registroModificado?.outrosImunosDose?.nome
          : registroModificado?.doseNome,
        doseSigla: registroModificado?.isRegistroAnterior
          ? registroModificado?.outrosImunosDose?.sigla
          : registroModificado?.doseSigla,
        lote: registroModificado?.loteNome || registroModificado?.loteImunobiologico?.lote,
        observacoes: registroModificado?.observacoes,
        viaDeAdministracao: registroModificado?.viaAdministracao?.nome,
        nomeFabricante:
          registroModificado?.loteImunobiologico?.fabricante?.nome || registroModificado?.loteFabricante?.nome,
        isGestante: registroModificado?.isGestante,
        isPuerpera: registroModificado?.isPuerpera,
        isViajante: registroModificado?.isViajante,
        isComunicanteHanseniase: registroModificado?.isComunicanteHanseniase,
        isRegistroAnterior: registroModificado.isRegistroAnterior,
        origemDados: registroModificado?.origemDados,
        dataValidadeLote: registroModificado?.loteDataValidade || registroModificado?.loteImunobiologico?.validade,
        unidadeSaude: unidadeSaudeProfissional,
        registradoAtendimento: infoCardModal?.registradoAtendimento,
        // Encontrar o aprazamento da proxima dose no aplicacao
        idUnicoRegistroAprazamentoProxDose: registroModificado.aprazamentoProxDoseLink?.idUnicoRegistro,
        // Encontrar a aplicacao que criou o aprazamento
        idUnicoRegistroAplicacaoProxDose: registroModificado.aplicacaoLink?.idUnicoRegistro,
        isAplicadoExterior: registroModificado.isAplicadoExterior,
        condicoesVacinacao: {
          comunicanteHanseniase: registroModificado?.isComunicanteHanseniase,
          gestante: registroModificado?.isGestante,
          puerpera: registroModificado?.isPuerpera,
          viajante: registroModificado?.isViajante,
        },
        isAplicacaoEquivalente: false,
      } as RegistroDoseAccordionItemProps

      if (!imunoDoseRegistroMatch) {
        registroDoseItem = {
          ...registroDoseItem,
          isAplicacaoEquivalente: true,
        }
      }
      vacinacaoItem.registros.push(registroDoseItem)
    }
  })

  return {
    ...vacinacaoItem,
    hasOnlyEquivalentesAplicadas:
      !isEmpty(vacinacaoItem.registros) && !vacinacaoItem.registros.some((item) => !item.isAplicacaoEquivalente),
  }
}

export const hasAplicacaoEquivalenteByEstrategia = (
  aplicacoesEquivalentes: RegistroDoseAccordionItemProps[],
  estrategiaId: ID,
  isRegistroAnterior: boolean
) =>
  aplicacoesEquivalentes.some((item) => item.isAplicacaoEquivalente) &&
  (estrategiaId === VacinacaoEstrategiaIdEnum.ROTINA_ID || isRegistroAnterior)

export const pluralizeAplicacoesEquivalentesString = (aplicacoesEquivalentes: RegistroDoseAccordionItemProps[]) => {
  const dosesEquivalentesString = stringGrouper(...aplicacoesEquivalentes.map((item) => item.doseSigla))
  const ultimaAplicacaoEquivalente = aplicacoesEquivalentes.reduce((prev, current) =>
    prev && prev.dataAplicacao > current.dataAplicacao ? prev : current
  )
  const dataUltimaAplicacaoEquivalente = new Date(ultimaAplicacaoEquivalente.dataAplicacao).toLocaleDateString('pt-BR')

  return aplicacoesEquivalentes.length > 1
    ? `Já foram aplicadas doses equivalentes no cidadão. Doses aplicadas: ${dosesEquivalentesString}. Data da última aplicação equivalente: ${dataUltimaAplicacaoEquivalente}`
    : `Já foi aplicada uma dose equivalente no cidadão. Dose aplicada: ${dosesEquivalentesString}. Data da aplicação equivalente: ${dataUltimaAplicacaoEquivalente}`
}

export const initialValuesRegistroVacinacao = (
  infoCardModal: InfoCardModalVacinacao,
  registroEmEdicao: RegistroVacinacaoFormModel,
  isAplicacaoOrTranscricacao: boolean
) => {
  return {
    ...registroEmEdicao,
    imunobiologicoId: infoCardModal?.imunobiologico?.id,
    doseId: infoCardModal?.doseId,
    loteFabricante:
      isAplicacaoOrTranscricacao &&
      ({
        nome: covidImunosRecord[infoCardModal?.imunobiologico?.id] || registroEmEdicao?.loteFabricante?.nome,
      } as LoteImunobiologicoFabricanteSelectFieldModel),
    dataAprazamentoProxDose: isAplicacaoOrTranscricacao && undefined,
  } as RegistroVacinacaoFormModel
}

const getComunicanteHanseniaseValue = (registrosVacinacao: RegistroVacinacaoFormModel[]) => {
  const bcg = registrosVacinacao.find(
    (registro) =>
      isBcg(registro.imunobiologicoId || registro.outrosImunosImunobiologico.id) &&
      registro.tipoRegistroVacinacao === 'APLICACAO'
  )

  return bcg !== undefined ? bcg.isComunicanteHanseniase ?? false : undefined
}

const findVacinaEquivalenteByRegistro = (
  imunobiologicoRegistroId: ID,
  doseRegistroId: ID,
  estrategiaRegistroId: ID,
  doseAtualId: ID,
  imunobiologicoId: ID,
  isRegistroAnterior: boolean
): VacinaEquivalenteModel => {
  return dosesEquivalentesItems.find(
    (item) =>
      item.doseAtualId === doseAtualId &&
      (item.imunobiologicoId === imunobiologicoId || item.imunobiologicoEquivalenteId === imunobiologicoId) &&
      (item.dosesEquivalentesIds?.includes(doseRegistroId) ||
        (item.dosesEquivalentesIds === null && doseRegistroId !== item.doseAtualId)) &&
      (item.imunobiologicoId === imunobiologicoRegistroId ||
        item.imunobiologicoEquivalenteId === imunobiologicoRegistroId) &&
      (item.estrategiaId === estrategiaRegistroId || isRegistroAnterior)
  )
}

const getVacinaEquivalenciaRegistroVacinacaoFormModel = (
  registroVacinacao: RegistroVacinacaoFormModel,
  imunobiologicoId: ID,
  doseAtualId: ID,
  isRegistroAnterior: boolean
): VacinaEquivalenteModel => {
  const registroDoseImunoEstrategia = {
    doseId: isRegistroAnterior ? registroVacinacao?.outrosImunosDose?.id : registroVacinacao?.doseId,
    imunobiologicoId: isRegistroAnterior
      ? registroVacinacao?.outrosImunosImunobiologico?.id
      : registroVacinacao.imunobiologicoId,
    estrategiaId: registroVacinacao?.estrategiaVacinacao?.id,
  }

  return findVacinaEquivalenteByRegistro(
    registroDoseImunoEstrategia.imunobiologicoId,
    registroDoseImunoEstrategia.doseId,
    registroDoseImunoEstrategia.estrategiaId,
    doseAtualId,
    imunobiologicoId,
    isRegistroAnterior
  )
}

const getVacinaEquivalenciaRegistro = (
  imunobiologicoId: ID,
  doseId: ID,
  estrategiaId: ID,
  dosesEquivalentesAplicadas: Map<ID, Set<ID>>
): VacinaEquivalenteModel => {
  const dose = dosesEquivalentesItems.find(
    (item) =>
      (item?.imunobiologicoId === imunobiologicoId || item?.imunobiologicoEquivalenteId === imunobiologicoId) &&
      (item?.dosesEquivalentesIds?.includes(doseId) ||
        (item?.dosesEquivalentesIds === null && doseId !== item?.doseAtualId)) &&
      (item?.estrategiaId === estrategiaId || estrategiaId === null)
  )

  if (dose?.dosesEquivalentesIds?.length > 1) {
    if (!dosesEquivalentesAplicadas.has(dose.imunobiologicoId)) {
      dosesEquivalentesAplicadas.set(dose.imunobiologicoId, new Set<ID>())
    }

    const imunobiologicoSet = dosesEquivalentesAplicadas.get(dose.imunobiologicoId)
    imunobiologicoSet.add(doseId)

    if (dose.dosesEquivalentesIds.some((id) => !imunobiologicoSet.has(id))) {
      return null
    }
  }

  return dose
}

export const getVacinaEquivalenciaRegistroByDoseAtual = (
  imunobiologicoId: ID,
  doseId: ID,
  estrategiaId: ID
): VacinaEquivalenteModel => {
  return dosesEquivalentesItems.find(
    (item) =>
      item?.imunobiologicoId === imunobiologicoId &&
      item?.doseAtualId === doseId &&
      (item?.estrategiaId === estrategiaId || estrategiaId === null)
  )
}

import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const AceitacaoTermosDeUsoSection = () => {
  return (
    <TermosDeUsoHeadingSection title='1. Aceitação do Termo de Uso' id='aceitacao_do_termo_de_uso'>
      <p>
        O presente Termo de Uso se refere a um contrato de adesão firmado entre o usuário e o fornecedor deste serviço,
        o Ministério da Saúde, localizado no Edifício Sede - Esplanada dos Ministérios, Bloco O, Brasília - DF,
        70052-900.
      </p>
      <br />
      <p>
        O uso deste serviço está condicionado à ciência dos termos e das políticas associadas. O usuário deverá ler tais
        termos e políticas, certificar-se de havê-los entendido, estar consciente de todas as condições estabelecidas no
        Termo de Uso e se comprometer a cumpri-las.
      </p>
      <br />
      <p>
        Ao utilizar o serviço, o usuário manifesta estar ciente com relação ao conteúdo deste Termo de Uso e estará
        legalmente vinculado a todas as condições aqui previstas.
      </p>
    </TermosDeUsoHeadingSection>
  )
}

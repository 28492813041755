/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'

export interface TipoEstabelecimentoField {
  name: string
  required?: boolean
}

export function TipoEstabelecimentoField(props: TipoEstabelecimentoField) {
  const { name, required } = props

  const estabelecimentos = [
    { label: 'Unidade Básica de Saúde', value: TipoEstabelecimentoEnum.UBS },
    { label: 'Centro de Especialidades Odontológicas', value: TipoEstabelecimentoEnum.CEO },
    { label: 'Unidade de Pronto Atendimento', value: TipoEstabelecimentoEnum.UPA },
    { label: 'Policlínica', value: TipoEstabelecimentoEnum.POLICLINICA },
  ]

  return (
    <FormControl label='Tipos de estabelecimento' required={required}>
      <VFlow vSpacing={0.5}>
        {estabelecimentos.map((estabelecimento) => (
          <CheckboxField
            name={name}
            label={estabelecimento.label}
            required={required}
            value={estabelecimento.value}
            style={styles.checkbox}
          />
        ))}
      </VFlow>
    </FormControl>
  )
}

const styles = {
  checkbox: css`
    margin: 0 -0.25rem;
  `,
}
